import './confirmation.component.scss';
import * as _ from 'lodash';
import { questionnaireOfferConfirmModal } from './modals/offerConfirmModal.component';
import { cancellationConfirmModal } from './modals/cancellationConfirmModal.component';
import { RetentionRequest } from '../cancellation/types/RetentionRequest';

export class ConfirmationController {
    private fullName: string;
    private isChecked: boolean;
    private isCheckboxDisabled: boolean;
    private isFormInvalid: boolean;
    private isRftp: boolean;
    private isInTerm: boolean;
    private isAtTerm: boolean;
    private isOutOfTerm: boolean;
    public isLoading: boolean;
    private terminationFee: number;
    private paymentType: number[];
    private paymentTypeNum: number;
    private rmr: number;
    private totalFees: number;
    private pastDueBalance: number;
    private timeFrameId: number;
    private certUrl: string;
    private currentDate: Date;
    private TERMINATION_PAYMENT_TYPE: number = 3;
    private RMR_PAYMENT_TYPE: number = 21;
    private PAST_DUE_BALANCE_TYPE: number = 1;
    public retentionAnswerOptionID = 0;
    public previousQuestionInfo: any;
    public questionInfo: any;
    public taxInformation: any;
    private ALERT_DIALOG_CREDIT_CARD_ERROR: any = 'pastDueBalance-error';
    public retentionAttempt: RetentionRequest;
    public contractEndDateIsInsideRenewalPeriod: boolean;
    private tier0OfferToDisplay: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $scope: any,
        private readonly $uibModal: any,
        private readonly $log: ng.ILogService,
        private readonly constants: any,
        private readonly dataContext: any,
        private $stateParams: any,
        private readonly alertService: any,
        private $q: any) {
        'ngInject';

        this.isCheckboxDisabled = true;
        this.isFormInvalid = false;
        this.isLoading = false;
        this.isRftp = false;
        this.isInTerm = false;
        this.isAtTerm = false;
        this.isOutOfTerm = false;
        this.retentionAnswerOptionID = $stateParams.retentionAnswerOptionID;
        this.tier0OfferToDisplay = $stateParams.tier0OfferToDisplay;

        this.$scope.$on(this.constants.events.completeCancellation, (event: any, result: any) => {

            if (!_.isNil(result) && !_.isNil(result.CustomerPayment) && !_.isNil(result.CustomerPayment.CustomerPaymentID)) {
                this.createCancelRetentionEpisode(result.CustomerPayment.CustomerPaymentID);
            }
            else if (!_.isNil(result) && !_.isNil(result.CustomerPaymentID)) {
                this.createCancelRetentionEpisode(result.CustomerPaymentID);
            }

            //aca specific checks
            else if (!_.isNil(result.data) && !_.isNil(result.data.CustomerPayment) && !_.isNil(result.data.CustomerPayment.CustomerPaymentID)) {
                this.createCancelRetentionEpisode(result.data.CustomerPayment.CustomerPaymentID);
            }
            else if (!_.isNil(result.data) && !_.isNil(result.data.CustomerPaymentID)) {
                this.createCancelRetentionEpisode(result.data.CustomerPaymentID);
            }
        });

    }

    public async $onInit() {
        this.isLoading = true;
        // if the user hasn't spoken with a rep yet, they need to call in to cancel
        const hasSelfService = await this.dataContext.cancel.hasSelfServiceRetentionOpen();
        if (hasSelfService) {
            // redirect to 'please call us' screen
            this.$log.info('customer must call customer support');
            this.$state.go('cancel.contact');
        }

        this.isLoading = false;

        this.currentDate = new Date();
        this.isLoading = true;

        if (this.tier0OfferToDisplay == null) {
            this.tier0OfferToDisplay = JSON.parse(localStorage.getItem('tier0OfferToDisplay'));
        }

        this.questionInfo = this.constants.retentionConfig.confirmation;
        this.questionInfo.RetentionAnswerOptionID = this.retentionAnswerOptionID;
        this.previousQuestionInfo = _.find(this.constants.retentionConfig,
            (config: any) => {
                return config.RetentionAnswerOptionID === this.retentionAnswerOptionID;
            });
        this.$q.all([
            this.dataContext.account.getTimeFrame(),
            this.dataContext.account.getContractInfo()
        ]).then(async (results: any[]) => {
            this.timeFrameId = results[0].TimeFrameId;
            this.contractEndDateIsInsideRenewalPeriod = results[1][0].RemainingMonths <= results[1][0].RenewalPeriodMonths;
            if (!this.contractEndDateIsInsideRenewalPeriod) {
                this.previousQuestionInfo = this.constants.retentionConfig.default;
            }
            this.dataContext.retention.getRetentionOfferType(this.previousQuestionInfo.offer.RetentionOfferTypeID).then((result: any) => {
                this.previousQuestionInfo.offer = result;
                this.questionInfo.offer = this.previousQuestionInfo.offer;
            }).catch((error: any[]) => {
                this.$log.error('Error fetching retention offer type.', error);
            });
            
            if (this.timeFrameId === this.constants.timeFrames.RFTP) {
                this.isRftp = true;
            } else if (this.timeFrameId === this.constants.timeFrames.InTerm) {
                this.isInTerm = true;
            } else if (this.timeFrameId === this.constants.timeFrames.AtTerm) {
                this.isAtTerm = true;
            } else {
                this.isOutOfTerm = true;
            }

            await this.getCancellationFees(this.timeFrameId);

        }).catch((error: any) => {
            this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                'We were unable to retrieve your account balance , please call <a href="tel:+18005685753">1-800-568-5753</a> to cancel');
            this.$log.error('Error fetching account details such as timeframe, retention offer type or the total cancellation fees.', error);
            this.isLoading = false;
            return this.$q.reject(error);
        });
    }

    public getOffer(offer: any) {
        this.$uibModal.open({
            component: questionnaireOfferConfirmModal.selector,
            size: 'lg',
            resolve: {
                offerInfo: () => {
                    return offer;
                }
            }
        });
    }

    public confirmCancellation() {
        let modalInstance = this.$uibModal.open({
            component: cancellationConfirmModal.selector,
            size: 'lg'
        });

        if ((document.getElementsByName("xxTrustedFormCertUrl")[0] as HTMLInputElement)) {
            this.certUrl = (document.getElementsByName("xxTrustedFormCertUrl")[0] as HTMLInputElement).value;
        }

        let paymentTypesToProcess: number[] = [];
        return modalInstance.result.then(async () => {
            if (this.pastDueBalance < 0) {
                //Since customer is in a credit state collect no payment and leave for manual review
                this.createCancelRetentionEpisode(null);
            }
            else if (!this.isRftp) {
                if (this.isInTerm) {
                    paymentTypesToProcess.push(this.RMR_PAYMENT_TYPE,
                        this.TERMINATION_PAYMENT_TYPE,
                        this.PAST_DUE_BALANCE_TYPE);
                } else {
                    paymentTypesToProcess.push(this.RMR_PAYMENT_TYPE, 0, this.PAST_DUE_BALANCE_TYPE);
                }
                this.$scope.$broadcast(this.constants.events.processCreditCardPayment, paymentTypesToProcess);
            } else {
                if (this.totalFees > 0) {
                    paymentTypesToProcess.push(0, 0, this.PAST_DUE_BALANCE_TYPE);
                    this.$scope.$broadcast(this.constants.events.processCreditCardPayment, paymentTypesToProcess);
                } else {
                    //Since there is no cancellation fees, we're passing in a null customer payment ID
                    this.createCancelRetentionEpisode(null);
                }
            }
        });
    }

    public async getCancellationFees(timeFrameId: any) {
        await this.dataContext.account.getAccountCancellationFees(timeFrameId).then((result: any) => {
            this.terminationFee = result.TerminationFee;
            this.rmr = result.Rmr;
            this.pastDueBalance = result.PastDueBalance;
            this.taxInformation = {
                TaxAmount: result.TotalTaxCalculated,
                MonitoringFeeTax: result.RmrTax,
                TerminationFeeTax: result.TerminationFeeTax,
                TaxDocumentStatus: result.TaxDocumentStatus,
                TaxTransactionCode: result.TaxTransactionCode,
                TaxTransactionID: result.TaxTransactionId,
                TaxDocumentStatusMonitoring: result.TaxDocumentStatusMonitoring,
                TaxTransactionCodeMonitoring: result.TaxTransactionCodeMonitoring,
                TaxTransactionIDMonitoring: result.TaxTransactionIdMonitoring,
                TaxDocumentStatusTermination: result.TaxDocumentStatusTermination,
                TaxTransactionCodeTermination: result.TaxTransactionCodeTermination,
                TaxTransactionIDTermination: result.TaxTransactionIdTermination
            }
            this.totalFees = this.terminationFee + this.rmr + this.pastDueBalance;
            this.isLoading = false;
        });
    }

    public updateCheckbox() {
        if (_.isNil(this.currentDate) || _.isNil(this.fullName)) {
            this.isCheckboxDisabled = true;
            this.isChecked = false;
        }
        else {
            this.isCheckboxDisabled = false;
        }
    }

    private async createCancelRetentionEpisode(customerPaymentId: number) {
        this.isLoading = true;
        if (this.isRftp) {
            this.retentionAttempt = {
                retentionEpisodeTypeId: this.constants.retentionEpisodeType.ESR,
                timeFrameId: this.timeFrameId,
                customerPaymentId: customerPaymentId,
                certificateUrl: this.certUrl
            };
            
        }
        else {
            this.retentionAttempt = {
                retentionEpisodeTypeId: this.constants.retentionEpisodeType.ManualCancel,
                timeFrameId: this.timeFrameId,
                customerPaymentId: customerPaymentId,
                certificateUrl: this.certUrl
            };
            
        }
        try {
            var episode = await this.dataContext.retention.submitRetentionAttempt(this.retentionAttempt);
            if (!_.isNil(episode.RetentionEpisodeId)) {
                try {
                    await this.dataContext.retention.submitCertificate({ certificateUrl: this.certUrl, retentionEpisodeId: episode.RetentionEpisodeId });
                } catch (e) {
                    this.$log.error(`Error encountered when submitting certificate. Attempted to submit with certURL: ${this.certUrl} for Retention Episode Id: ${episode.RetentionEpisodeId}`);
                }
                return this.$state.go('cancellation.cancellationComplete', { retentionEpisodeID: episode.RetentionEpisodeId });
            }
        }
        catch (error) {
            this.$log.error(error);
        }
        this.isLoading = false;
    }

    private showErrorAlert(dialog: any, message: any) {
        this.alertService
            .get(dialog)
            .setMessage(message)
            .setType('danger')
            .open();
    }
}

export const confirmationComponent = {
    template: require('./confirmation.component.html'),
    controller: ConfirmationController
};