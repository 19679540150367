class MasterCodeService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    get() {
        return this.Restangular
            .one('mastercode')
            .get();
    }

    put(mastercode) {
        return this.Restangular
            .one('mastercode')
            .customPUT(mastercode);
    }
}

export default MasterCodeService;