import template from './movingConfirmation.html';

import _ from 'lodash';
import moment from 'moment';

class MovingConfirmationController {
    /*@ngInject*/
    constructor(dataContext) {
        this.dataContext = dataContext;
    }

    $onChanges() {
        this.getRelocationInfo();
        this.getCurrentCustomerInfo();
    }

    getRelocationInfo() {
        return this.dataContext.relocation.getRelocationInfo().then((result) => {
            return this.relocationInfo = result;
        });
    }

    getCurrentCustomerInfo() {
        return this.dataContext.account.getCustomerInfo().then((result) => {
            this.currentCustomerInfo = result;
        });
    }

}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        isLoading: '<',
        movingStatus: '=',
        relocationInfo: '<'
    },
    controller: MovingConfirmationController
};