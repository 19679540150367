import template from './premiseAddress.html';

class PremiseAddressController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }
}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        sameAddress: '=',
        shippingAddressFormInvalid: '<',
        onSaveCore: '&',
        isEditing: '&',
        states: '<'
    },
    controller: PremiseAddressController
};