import _ from 'lodash';

/*
 *  Angular LoadScript
 *
 *  Let angular load and execute lazy javascript from partials!
 *  
 *  This module is the result of  this issue: "1.2.0rc1 regression: script tags not loaded via ngInclude"
 *  Issue url: https://github.com/angular/angular.js/issues/3756
 * 
 *  As of Angular 1.2.0 the ngInclude scripts does not permit execution of javascript from included partials.
 *  This little module execute code inside script tags with "javascript-lazy" attribute after partial loading,
 *  thus re-enabling this feature.
 *
 *  ( please have a look at the issue comments, this angular feature was never planned nor included properly,
 *  was only a drawback of using jQuery for partial inclusion )
 * 
 *  This angular module have been created by @subudeepak(https://github.com/subudeepak) based on the code posted by @endorama (https://github.com/endorama) 
 *  (based upon the code
 *  posted by @olostan (https://github.com/olostan) )
 * 
 *  Simply add this file, load ngLoadScript module as application dependency and use type="text/javascript-lazy"
 *  as type for script you which to load lazily in partials.
 * 
 * License: 2013 - released to the Public Domain.
 */

let scriptDirective = ($window) => {
    'ngInject';
    return {
        restrict: 'E',
        scope: {
            ngSrc: '@'
        },
        link: (scope, elem, attr) => {
            if (attr.type === 'text/javascript-lazy') {
                const s = $window.document.createElement('script');
                s.type = 'text/javascript';

                const src = elem.attr('src');

                if (!_.isNil(scope.ngSrc)) {
                    s.src = scope.ngSrc;
                } else if (!_.isNil(src)) {
                    s.src = src;
                } else {
                    const code = elem.text();
                    s.text = code;
                }

                $window.document.head.appendChild(s);
                elem.on('$destroy', () => {
                    s.parentNode.removeChild(s);
                });
            }
        }
    };
};

export default scriptDirective;