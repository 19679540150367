import angular from 'angular';
import 'angular-ui-grid';
import 'angular-ui-bootstrap';

import commonModule from '../../../common';

import './ecommInfoModal.scss';

import addPaymentModalComponent from './addPaymentModal.component';
import editPaymentModalComponent from './editPaymentModal.component';
import editAutopayModalComponent from './editAutopayModal.component';
import deletePaymentModalComponent from './deletePaymentModal.component';
import addressValidationModal from './addressValidationModal.component';
import makePaymentModal from './makePaymentModal.component';
import paymentHistoryModal from './paymentHistoryModal.component';
import syncInfoModal from './syncInfoModal.component';
import ecommInfoModal from './ecommInfoModal.component';
import syncRapidModal from './syncRapidModal.component';
import creditCardExpireWarningModal from './creditCardExpireWarningModal.component';
import medicalInfoTOSModal from './medicalInfoTOS.component';
import medicalInfoDeleteModal from './medicalInfoDelete.component';
import { voziqOfferModal } from './voziqOfferModal.component';
import addAddOnModal from './addAddOnModal.component';
import EnrollmentAddOnModal from './enrollmentAddOnModal.component';
import TermsAddOnModal from './termsAddOnModal.component';
import SuccessAddOnModal from './successAddOnModal.component';
import ConfirmAddOnModal from './confirmAddOnModal.component';
import ErrorAddOnModal from './errorAddOnModal.component';

export default angular.module('fp:customerportal:myaccount:settings:modals',
        [
            'ui.bootstrap',
            'ui.grid',
            'ui.grid.pagination',
            commonModule.name
        ])
    .component('fpAddPaymentModal', addPaymentModalComponent)
    .component('fpEditPaymentModal', editPaymentModalComponent)
    .component('fpEditAutopayModal', editAutopayModalComponent)
    .component('fpDeletePaymentModal', deletePaymentModalComponent)
    .component('fpAddressValidationModal', addressValidationModal)
    .component('fpMakePaymentModal', makePaymentModal)
    .component('fpPaymentHistoryModal', paymentHistoryModal)
    .component('fpSyncInfoModal', syncInfoModal)
    .component('fpECommInfoModal', ecommInfoModal)
    .component('fpSyncRapidModal', syncRapidModal)
    .component('fpCreditCardExpireWarningModal', creditCardExpireWarningModal)
    .component('fpMedicalInfoModal', medicalInfoTOSModal)
    .component('fpMedicalInfoDeleteModal', medicalInfoDeleteModal)
    .component('fpVoziqOfferModal', voziqOfferModal)
    .component('fpAddAddOnModal', addAddOnModal)
    .component('fpEnrollmentAddOnModal', EnrollmentAddOnModal)
    .component('fpTermsAddOnModal', TermsAddOnModal)
    .component('fpSuccessAddOnModal', SuccessAddOnModal)
    .component('fpConfirmAddOnModal', ConfirmAddOnModal)
    .component('fpErrorAddOnModal', ErrorAddOnModal);