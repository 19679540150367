import { VoziqOfferDto } from "@app/cancellation/offer/types/index";

export class VoziqOfferModalController {
    public modalInstance: any;
    public resolve: any;
    private offers: [VoziqOfferDto];


    constructor(private $uibModal: any,
        public  $scope: any,
        private constants: any) {
        'ngInject';
        this.offers = this.resolve.offers;
        this.$scope = $scope;
        this.constants = constants;
    }

    public close() {
        this.modalInstance.dismiss();
        let chatBtn = <HTMLElement>document.querySelector(".ie-div-position-customer-chat");
        chatBtn.style.display = "block";
    }

    public continue(offerId: number) {
        this.modalInstance.close();
        let chatBtn = <HTMLElement>document.querySelector(".ie-div-position-customer-chat");
        chatBtn.style.display = "none";
        let modalInstance = this.$uibModal.open({
            template: '<fp-docusign-contract-modal config="config"></fp-docusign-contract-modal>',
            size: 'lg',
            backdrop: 'static',
            controller: ($scope: any) => {
                'ngInject';
                $scope.config = {
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss,
                    title: 'SPECIAL OFFER',
                    offerId,
                    confirmButtonOne: {
                        text: 'Continue',
                        shouldShow: true,
                        action: modalInstance.close
                    },
                    confirmButtonTwo: {
                        shouldShow: true,
                        text: 'Cancel',
                        action: modalInstance.close
                    }
                }
            }
        });
    }
}

export const voziqOfferModal = {
    selector: 'fpVoziqOfferModal',
    template: require('./voziqOfferModal.html'),
    bindings: {
        modalInstance: '<',
        resolve: '<',
        config: '='
    },
    controller: VoziqOfferModalController
}