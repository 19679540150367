import template from './movingBanner.html';
import './movingBanner.scss';

class MovingBannerController {
    /*@ngInject*/
    constructor($state) {
        this.$state = $state;
    }
}

export default {
    template: template,
    bindings: {},
    controller: MovingBannerController
};