import * as angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import { offerComponent } from './offer.component';
import { renewalOfferComponent } from './renewalOffer.component';
import { offerBannerComponent } from './offerBanner.component';
import { additionalOfferComponent } from './additionalOffer.component';
import { doubleAdditionalOfferComponent } from './doubleAdditionalOffer.component';
import { rateLockOfferComponent } from './rateLockOffer.component';

export const offer = angular.module('fp:customerportal:cancellation:offer',
        [
            'ui.router',
            commonModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider
                .state('cancellation.offer',
                    {
                        parent: 'cancellation',
                        url: '/{retentionOfferTypeId:int}/offer/{retentionQuestionnaireAnswerID:int}/{retentionAnswerOptionID:int}',
                        template: '<fp-offer></fp-offer>'
                    })
                .state('cancellation.offer.singleAdditional',
                    {
                        parent: 'cancellation.offer',
                        url: '/single',
                        template: '<fp-additional-offer></fp-single-additional-offer>'
                    })
                .state('cancellation.offer.doubleAdditional',
                    {
                        parent: 'cancellation.offer',
                        url: '/double',
                        template: '<fp-double-additional-offer></fp-double-additional-offer>'
                    })
                .state('cancellation.offer.renewal',
                    {
                        parent: 'cancellation.offer',
                        url: '/renewal',
                        template: '<fp-renewal-offer></fp-renewal-offer>',
                        params: {
                            term: null,
                            offerVerbiage: null,
                            offerInfo: null,
                            tier0Offer: null
                        }
                    })
                .state('cancellation.offer.ratelock',
                    {
                        parent: 'cancellation.offer',
                        url: '/ratelock',
                        template: '<fp-rate-lock-offer></fp-rate-lock-offer>',
                        params: {
                            term: null,
                            offerVerbiage: null,
                            offerInfo: null,
                            tier0Offer: null
                        }
                    });
        })
    .component('fpOffer', offerComponent)
    .component('fpOfferBanner', offerBannerComponent)
    .component('fpAdditionalOffer', additionalOfferComponent)
    .component('fpDoubleAdditionalOffer', doubleAdditionalOfferComponent)
    .component('fpRenewalOffer', renewalOfferComponent)
    .component('fpRateLockOffer', rateLockOfferComponent);
    