import './offer.component.scss';
import { RetentionRequest } from '../../cancellation/types/RetentionRequest';
import { RetentionOfferType } from "../../cancellation/types/RetentionOfferType";
import * as _ from 'lodash';
import { ContractPdfModel } from "../../cancellation/types/ContractPdfModel";
import { GetRenewalContractTextRequest } from "@../../cancellation/types/GetRenewalContractTextRequest";
import { QuestionnaireAnswerRequest } from '../types/QuestionnaireAnswerRequest';
import { questionnaireOfferConfirmModal } from '../modals/offerConfirmModal.component'

export class RenewalOfferController {
    public retentionOfferTypeId = 0;
    public retentionQuestionnaireAnswerID = 0;
    public retentionAnswerOptionID = 0;
    public offer: RetentionOfferType;
    public currentDate: Date;
    public isSubmitting: boolean = false;
    public contractText: ContractPdfModel;
    public isLoading: boolean = true;
    public isComplete: boolean;
    public isDocusignComplete: boolean = false;
    public isDocusignFailed: boolean = false;
    public docusignUrl: any;
    public offerInfo: any;
    public isFailed: boolean;
    public questionInfo: any;
    public tier0Offer: any;

    constructor($stateParams: any, private readonly constants: any, private readonly dataContext: any, private readonly $window: ng.IWindowService, private $scope: any, private readonly $state: any, private readonly $log: any, private $uibModal: any) {
        'ngInject';
        this.retentionOfferTypeId = $stateParams.retentionOfferTypeId;
        this.retentionQuestionnaireAnswerID = $stateParams.retentionQuestionnaireAnswerID;
        this.retentionAnswerOptionID = $stateParams.retentionAnswerOptionID;
        this.offerInfo = $stateParams.offerInfo;
        this.tier0Offer = $stateParams.tier0Offer;
    }

    $onInit() {
        this.isComplete = false;
        this.isFailed = false;
        this.currentDate = new Date();

        //this should only fire on page reload, UI navigation will populate the values view the router, this is our stopgap
        if (this.offerInfo == null) {
            this.offerInfo = JSON.parse(localStorage.getItem('offer'));
            this.retentionOfferTypeId = this.offerInfo.RetentionOfferTypeID;
        }

        if (this.tier0Offer == null) {
            this.tier0Offer = JSON.parse(localStorage.getItem('tier0OfferToDisplay'));
        }

        //add the eloqua coupon call here after docusign is completed
        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningCompleted') {
                    const retentionAttempt: RetentionRequest = {
                        retentionOfferTypeId: this.retentionOfferTypeId,
                        questionnaireAnswerId: this.retentionQuestionnaireAnswerID,
                        retentionEpisodeTypeId: this.constants.retentionEpisodeType.Save,
                        closedDate: new Date()
                    };
                    this.dataContext.retention.UpdateRetentionQuestionnaire(retentionAttempt).then(() => {
                        this.dataContext.retention.UpdateRetentionEpisode(retentionAttempt).then(() => {
                            this.isComplete = true;
                        }).catch((error: any[]) => {
                            this.$log.error('Error updating retention session.', error);
                        });
                    }).catch((error: any[]) => {
                        this.$log.error('Error updating retention questionnaire.', error);
                    });
                }
            }, false);

        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningDeclined') {
                    this.isDocusignFailed = true;
                    this.isFailed = true;
                }
            }, false);

        this.dataContext.retention.submitRetentionAttempt({
            retentionEpisodeTypeId: this.constants.retentionEpisodeType.Save,
            retentionOfferTypeId: this.retentionOfferTypeId
        }).then((result: any) => {
            this.docusignUrl = result.SigningURL;
            this.isLoading = false;
        }).catch((error: any) => {
            this.isLoading = false;
            this.isDocusignFailed = true;
        });
    }

    navigateToHome() {
        this.$state.go('home');
    }

    async continueToCancel() {
        this.$state.go('cancellation.confirmation', {
            retentionAnswerOptionID: 1,
            //uirouter, state.go, how to pass object between views              
            tier0OfferToDisplay: this.tier0Offer
        });
    }
}

export const renewalOfferComponent = {
    template: require('./renewalOffer.component.html'),
    controller: RenewalOfferController
};