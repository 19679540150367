import * as angular from 'angular';
import * as _ from 'lodash';

import 'angular-ui-router';
import 'angular-http-auth';
import 'ng-idle';
import 'angular-match-height';
import 'angular-recaptcha';

import commonModule from '../common';

import { trackOrderComponent } from './trackOrder.component';
import orderComponent from './order/order.component';
import confirmationModal from './confirmationModal/confirmationModal.component';
import headerComponent from '../common/header/header.component';
import footerComponent from '../common/footer/footer.component';
import shellComponent from './shell/shell.component';

export const trackOrderModule = angular.module('fp:customerportal:trackorder', [
        'ui.router',
        'http-auth-interceptor',
        'vcRecaptcha',
        'ngIdle',
        'partnermarketing.matchHeight',

        commonModule.name,
])

    .config(/*@ngInject*/ ($stateProvider: ng.ui.IStateProvider, constants: any) => {
        $stateProvider
            .state('trackOrder', {
                url: '/trackOrder',
                abstract: true,
                template: require('./shell/shell.html'),
                resolve: {
                    clearUserSession: /*@ngInject*/ (dataContext: any) => {
                        dataContext.user.reset();
                    }
                },
                data: {
                    sectionType: constants.sectionTypes.trackOrder,
                    hideNavigation: true
                }
            })
            .state('trackOrder.home', {
                url: '',
                template: '<fp-track-order></fp-track-order>',
            });
    })

    .component('fpTrackOrder', trackOrderComponent)
    .component('fpOrder', orderComponent)
    .component('fpConfirmationModal', confirmationModal)
    .component('fpTrackOrderHeader', headerComponent)
    .component('fpTrackOrderFooter', footerComponent)
    .component('fpTrackOrderShell', shellComponent);
