import _ from 'lodash';
import './moving.scss';
import template from './moving.html';
import moment from 'moment';

class MovingController {
    /*@ngInject*/
    constructor(dataContext, $q, $state) {
        this.dataContext = dataContext;
        this.$state = $state;
        this.$q = $q;
        this.movingStatus = 'No Status';
        this.setStep('newResidence');
    }

    $onInit() {
        this.isLoading = true;

        return this.$q.all([
            this.getCustomerInfo(),
            this.getStates(),
            this.getCompanyHolidays(),
            this.getRelocationInfo(),
            this.getUserInfo()
        ]).finally(() => {
            this.isLoading = false;
            this.changeState();
        });
    }

    showNode(value) {
        this.splitForm = value;
        this.updateProgressBar({ val: this.splitForm });
    }

    getRelocationInfo() {
        return this.dataContext.relocation.getRelocationInfo().then((result) => {
            return this.relocationInfo = result;
        });
    }

    getUserInfo() {
        return this.dataContext.user.getUserInfo().then((result) => {
            this.userInfo = result;
        });
    }

    getCustomerInfo() {
        return this.dataContext.account.getCustomerInfo().then((result) => {
            this.customerInfo = result;
        });
    }

    getStates() {
        return this.dataContext.states.getStates().then((result) => {
            let states = [];
            _.forEach(result.Data, (stateInfo) => {
                states.push(stateInfo.State);
            });
            this.states = states;
        });
    }

    getCompanyHolidays() {
        return this.dataContext.supportService.getCompanyHolidays().then((result) => {
            this.holidays = result;
        });
    }

    changeState() {
        if (!_.isNil(this.relocationInfo)) {
            this.isMoveActive = _.isNil(this.relocationInfo.AddressProcessedDate);
            this.isOrderActive = _.isNil(this.relocationInfo.OrderProcessedDate);
            let orderProcDate = !_.isNil(this.relocationInfo.OrderProcessedDate) ? this.relocationInfo.OrderProcessedDate : this.relocationInfo.OrderProcessingDate;
            const orderDate = moment(orderProcDate).add(1, 'days').format();
            const today = moment().format();
            if (!this.isOrderActive && (this.isMoveActive || today < orderDate)) {
                this.movingStatus = "Sent";
            } else if (this.isOrderActive) {
                this.movingStatus = "Pending";
            } else {
                this.movingStatus = 'No Status';
                this.relocationInfo = null;
            }
        }
    }

    setStep(step) {
        this.step = step;
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        updateProgressBar: '&'
    },
    controller: MovingController
};