import template from './billingAddress.html';

class BillingAddressController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }
}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        sameAddress: '=',
        shippingAddressFormInvalid: '<',
        onSaveCore: '&',
        isEditing: '&',
        states: '<'
    },
    controller: BillingAddressController
};