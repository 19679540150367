import template from './linkNav.html';

import _ from 'lodash';
import moment from 'moment';

class LinkNavController {
    /*@ngInject*/
    constructor($state, $uibModal, $timeout, segment, constants) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.segment = segment;
        this.constants = constants;
    }

    $onChanges() {
        if (!_.isNil(this.relocationInfo)) {
            if (this.$state.current.name === 'moving.confirmation') {
                this.confirmationPage = true;
                this.isMoveActive = false;
            } else {
                this.isMoveActive = _.isNil(this.relocationInfo.AddressProcessedDate);
                this.isOrderActive = _.isNil(this.relocationInfo.OrderProcessedDate);
                let ordDate = !_.isNil(this.relocationInfo.OrderProcessedDate) ? this.relocationInfo.OrderProcessedDate : this.relocationInfo.OrderProcessingDate;
                const orderDate = moment(ordDate).add(1, 'days').format();
                const today = moment().format();

                if (!this.isOrderActive && (this.isMoveActive || today < orderDate)) {
                    this.isMoveActive = true;
                } else if (this.isOrderActive) {
                    this.isMoveActive = true;
                } else {
                    this.isMoveActive = false;
                }
            }
        }
    }

    stopMove() {
        this.segment.registerEvent('Move Portal - Stop Move', {});
        let modalInstance = this.$uibModal.open({
            template: '<fp-stop-move-modal config="config"></fp-stop-move-modal>',
            size: 'md',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        premisesAddress: this.customerInfo.PremisesAddress
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });
        modalInstance.result.then((result) => {
            this.$state.reload();
            this.$timeout(() => {
                if (result) {
                    this.propagateAlert({ alert: true });
                } else {
                    this.propagateAlert({ alert: false });
                }
            }, 1000);

        });
    }

    editForm() {
        this.$state.go('moving.form');
    }

    onClickEditMove() {
        this.segment.registerEvent('Move Portal - Edit Move', {});
        this.$state.go('moving.form', null, { reload: true });
    }

    onClickContactUs() {
        this.segment.registerEvent('Move Portal - Click Having Trouble Button', {});
        this.$state.go('support.contact');
    }
}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        relocationInfo: '<',
        propagateAlert: '&',
        hideLinksWhenMoveDone: '<'
    },
    controller: LinkNavController
};