import _ from 'lodash';

export default class {
    /*@ngInject*/
    constructor(dataContext, constants, $q, $state) {
        this.user = dataContext.user;
        this.constants = constants;
        this.$q = $q;
        this.$state = $state;
    }
    
    getEnabledSubnavItems(state) {
        return _.filter(_.get(this.$state.get(state), 'data.subnav', []), (i) => {
            return _.isNil(i.enabled) || (_.isFunction(i.enabled) && i.enabled(this.user.current) === true) || (i.enabled === true);
        });
    }
}