import * as _ from 'lodash';
import * as angular from 'angular';

import './setupEquipmentSSO.scss';
import template from './setupEquipmentSSO.html';

class SetupEquipmentController {
    /*@ngInject*/
    constructor($scope, $window, $document, $timeout, constants, dataContext, fpStore, mobileAppContext) {
        this.dataContext = dataContext;
        this.constants = constants;
        this.showSpinner = true;
        this.showIframe = false;
        this.$scope = $scope;
        this.$window = $window;
        this.$document = $document;
        this.$timeout = $timeout;
        this.fpStore = fpStore;

        const accessToken = dataContext.user.loadAccessToken();
        const setupWizardUrl = !_.isEmpty(constants.urls.setupWizard)
            ? `${constants.urls.setupWizard}/sso/${accessToken}`
            : '/_setup/sso';
        this.setupUrl = `${setupWizardUrl}?app=true&isBrowser=${!mobileAppContext.get()}`;
    }

    $onInit() {
        this.adjustScreenSize();

        // TODO: Figure out what this is doing, seems a little crazy -sdb 2019-06-07
        if (!this.fpStore.has('FPRedirectToADC', 'session') ||
            this.fpStore.has('FPRedirectToADC', 'session') && !this.fpStore.get('FPRedirectToADC', 'session')) {
            this.fpStore.set('FPRedirectToADC', 'true', 'session');

            //Redirect to ADC to set the Cookies and then back to Equipment Setup
            this.dataContext.user.getAdcRedirectUrl()
                .then(url => {
                    this.showSpinner = false;
                    this.$window.location.href = url + '&postLoginUrl=' + this.$window.location.origin + '/setup';
                });
        } else {
            this.fpStore.remove('FPRedirectToADC', 'session');

            this.showIframe = true;
            this.$timeout(() => {
                this.adjustScreenSize();
            });
        }
    }

    turnOffSpinner() {
        this.showSpinner = false;
        this.$scope.$apply();
    }

    adjustScreenSize() {
        //Adjust the Iframe on Mobile Device
        const isIphone = /(iPhone)/i.test(navigator.userAgent);
        const isIpad = /(iPad)/i.test(navigator.userAgent);
        const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

        //Re-calculate app-content to always use inner height
        const appContent = this.$document[0].getElementById('app-content');
        angular.element(appContent).css('height', this.$window.innerHeight + 'px');

        //For iPhone and iPad, substract the navigation bar
        if ((isIphone || isIpad) && isSafari) {
            document.getElementById('app-page').setAttribute('style',
                'min-height: ' + (this.$window.innerHeight).toString() + 'px !important');

            angular.element(appContent).css('height', this.$window.innerHeight - 67 + 'px');
            const setupEquipment = angular.element(document.querySelector('.setup-equipment-component'));
            setupEquipment.css('height', this.$window.innerHeight + 'px !important');
        } else {
            document.getElementById('app-page').setAttribute('style',
                'min-height: ' + (this.$window.innerHeight - 67).toString() + 'px !important');

            angular.element(appContent).css('height', this.$window.innerHeight - 67 + 'px');

            const setupEquipment2 = angular.element(document.querySelector('.setup-equipment-component'));
            setupEquipment2.css('height', this.$window.innerHeight - 67 + 'px');
        }
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<'
    },
    controller: SetupEquipmentController
};