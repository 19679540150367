import './radioUpgrade.scss';
import AccountDataService from'../common/data/account.service';
import { initRecaptcha } from '../trackOrder/utility';
import { Error} from '../trackOrder/types';

export class RadioUpgradeController {
    private searchAttempt = 0;
    private identifier: string;
    private accountId: number;
    public confirmation: {
        exists: boolean,
        result: string,
    };
    public error: Error;
    public address: any;
    public loading = true;
    public refreshTokenAtInterval: any;
    public errorMessage: "An en error occurred placing your order. Please call in to support."
    

    constructor(
        private readonly accountDataService: AccountDataService,
        private readonly $scope: ng.IScope | any,
        private readonly $interval: ng.IIntervalService,
    ) {
        'ngInject';
        this.$scope = $scope;
        this.$scope.captchaToken = null;

        this.$scope.$watch('captchaToken',
            () => {
                if (this.$scope.captchaToken && this.searchAttempt === 0) {

                    this.search();
                }
            });
    }

    $onInit() {
        this.initializeCaptcha();
    }

    public $onDestroy() {
        if (this.refreshTokenAtInterval) {
            // when the user leaves, cancel the $timeout instance
            this.$interval.cancel(this.refreshTokenAtInterval);
        }
    }

    public refreshCaptcha() {
        this.initializeCaptcha();
    }

    public startCaptchaAutoRefresh() {
        this.refreshTokenAtInterval = this.$interval(() => {
            this.initializeCaptcha();
        }, 90 * 1000);
    }

    public redirect() {
        location.href = `login?redirectTo=/account`;
    }

    public search() {
        this.identifier = location.pathname.split('/')[2];
        this.accountDataService.getCustomerAddress(this.identifier)
            .then(result => {
                this.address = result.address;
                this.accountId = result.accountId;
            })
            .catch(() => {
                this.error = {
                    exists: true,
                    message: ''
                }
            })
            .finally(() => {
                this.searchAttempt++;
                this.refreshCaptcha();
                this.loading = false;
            });
    }

    public placeOrder() {
        let orderInfo = {
            "accountId": this.accountId,
            "addressDto": this.address
        }
        this.accountDataService.placeRadioUpgradeOrder(orderInfo).then((result: any) => {
            if (result.success) {
                this.confirmation = {
                    exists: true,
                    result: 'Order placed successfully',
                };
                if(this.error && this.error.exists) this.error.exists = false;
            } else {
                if(this.confirmation && this.confirmation.exists) this.confirmation.exists = false;
                this.error = {
                    exists: true,
                    message: result.message,
                };
            }
        }).catch(() => {
            this.error = {
                exists: true,
                message: this.errorMessage,
            }
        });
    }

    private initializeCaptcha() {
        initRecaptcha(this.$scope,"fp-radio-upgrade", "fpRadioUpgrade", "accountShipping");
    }
}

export const radioUpgradeComponent = {
    template: require('./radioUpgrade.html'),
    bindings: {
        message: '<'
    },
    controller: RadioUpgradeController
};