import _ from 'lodash';
import './removeDispatchInstanceModal.scss';
import template from './removeDispatchInstanceModal.html';

class RemoveDispatchInstanceModalController {
    /* @ngInject */
    constructor() {
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
    }

    submit() {
        this.$close(true);
    }

    close() {
        this.$close(false);
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: RemoveDispatchInstanceModalController
};