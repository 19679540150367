let aDisabledDirective = () => {
    return {
        compile: (tElement, tAttrs) => {
            //Disable ngClick
            tAttrs.ngClick = '!(' + tAttrs.fpADisabled + ') && (' + tAttrs.ngClick + ')';

            //return a link function
            return (scope, element, attributes) => {
                //Toggle 'disabled' to class when aDisabled becomes true
                scope.$watch(attributes.fpADisabled, (newValue) => {
                    if (newValue !== undefined) {
                        element.toggleClass('disabled', newValue);
                    }
                });

                //Disable href on click
                element.on('click', (e) => {
                    if (scope.$eval(attributes.fpADisabled)) {
                        e.preventDefault();
                    }
                });
            };
        }
    };
};

export default aDisabledDirective;