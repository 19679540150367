import * as _ from 'lodash';
import * as moment from 'moment';

export class MovingInfoController {
    alertDialog = 'moving:stopMove';
    cancelMoveMsg = 'Your move has been stopped successfully.';
    cancelOrderMsg = 'Your mover\'s kit order has been canceled successfully. Please update your premise address through the Account Settings option under My Account.';
    hideAddressOnMoveDone = false;
    hideLinksWhenMoveDone = false;
    relocationInfo: any;
    openInstructions: any;
    userInfo: any;
    showOffer: boolean = false;

    constructor(private dataContext: any, private constants: any, private alertService: any, private $state: any, private segment: any) {
        'ngInject';
    }

    $onInit() {
    }

    $onChanges() {
        this.getRelocationInfo();
        if (this.userInfo && this.userInfo.Settings && _.some(this.userInfo.Settings, {'Name': 'RelocationPortal:MoversKitOffer', 'Value': 'True'})) {
            this.showOffer = true;
        }
    }

    getRelocationInfo() {
        return this.dataContext.relocation.getRelocationInfo().then((result: any) => {
            this.relocationInfo = result;
            this.hideMovingAddressInfo();
            return this.relocationInfo;
        });
    }

    propagateAlert(alert: any) {
        const today = moment();
        const movingDate = moment(this.relocationInfo.DateRelocating);
        const orderDate = moment(this.relocationInfo.OrderProcessingDate);
        if (alert) {
            if (orderDate <= movingDate && today < orderDate) {
                return this.showGenericSuccessAlert(this.cancelMoveMsg);
            }
            if (movingDate < orderDate && today < movingDate) {
                return this.showGenericSuccessAlert(this.cancelMoveMsg);
            }
            if (orderDate > movingDate && today > movingDate) {
                return this.showGenericSuccessAlert(this.cancelOrderMsg);
            }
            if (orderDate < movingDate && today > orderDate) {
                return this.showGenericSuccessAlert(this.cancelMoveMsg);
            }
        } else {
            this.showGenericErrorAlert();
        }
    }

    hideMovingAddressInfo() {
        if (!_.isNil(this.relocationInfo)) {
            this.hideAddressOnMoveDone = !_.isNil(this.relocationInfo.AddressProcessedDate);
            this.hideLinksWhenMoveDone = this.hideAddressOnMoveDone && !_.isNil(this.relocationInfo.OrderProcessedDate);
        }
    }

    onClickStartMove() {
        this.segment.registerEvent('Move Portal - Start Move', {});
        this.$state.go('moving.form');
    }

    onClickInstructionDropdown() {
        this.openInstructions = !this.openInstructions;
        if(this.openInstructions)
            this.segment.registerEvent('Move Portal - Click Instructions Dropdown', {});
    }

    showGenericSuccessAlert(dialogMessage: string) {
        this.alertService
            .get(this.alertDialog)
            .setMessage(`${dialogMessage}`)
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    showGenericErrorAlert() {
        this.alertService
            .get(this.alertDialog)
            .setMessage('Your move could not be stopped at this time. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: require('./movingInfo.html'),
    bindings: {
        isLoading: '<',
        movingStatus: '<',
        customerInfo: '<',
        relocationInfo: '<',
        userInfo: '<'
    },
    controller: MovingInfoController
};