import _ from 'lodash';

import template from './dispatchNotifications.html';
import './dispatchNotifications.scss';

class DispatchNotificationsController {
    /*@ngInject*/
    constructor($log, dataContext, $q, mobileAppContext) {
        this.$log = $log;
        this.dataContext = dataContext;
        this.$mobileAppContext = mobileAppContext;
        this.$q = $q;
        this.isMobileApp = this.$mobileAppContext.get() || window.innerWidth <= 768;

    }

    $onInit() {
        //need to get and set status of these notifications from...somewhere?
        this.formatPrimaryPhoneNumber();
    }

    toggleEmailNotification(dispatchNotificationTypeID) {
        var match = this.userDispatchNotificationSettings.find((n) => n.DispatchNotificationTypeID ===
            dispatchNotificationTypeID);
            
        var dispatchNotificationSettingUpdate = {
            DispatchNotificationTypeID: dispatchNotificationTypeID
        };

        //setting isnt set (hasnt yet been created in SQL for user), set it to enabled
        if (match == undefined) {
            dispatchNotificationSettingUpdate.IsEmailActive = true;
            //we dont pass the SMS property here as this would be the "first time setting" action, thus SMS should be false until customer sets
        }
        //setting is set, set it to opposite value
        else {
            dispatchNotificationSettingUpdate.IsEmailActive = !match.IsEmailActive;
            //we pass the other property to ensure we're not accepting default values from the dto
            dispatchNotificationSettingUpdate.IsSMSActive = match.IsSMSActive;
        }

        this.isLoading = true;
        this.dataContext.dispatch.setUserDispatchNotificationSettings(dispatchNotificationSettingUpdate).then(() => {
            //setting didnt exist for customer prior to this update
            if (match == undefined) {
                this.dataContext.dispatch.getUserDispatchNotificationSettings().then((result) => {
                    //this is for the UI ng bindings to reflect status
                    this.userDispatchNotificationSettings = result;
                    var match = this.userDispatchNotificationSettings.find((n) => n.DispatchNotificationTypeID === dispatchNotificationTypeID);
                    var matchIndex = this.userDispatchNotificationSettings.indexOf(match);
                    this.userDispatchNotificationSettings[matchIndex].IsEmailActive = dispatchNotificationSettingUpdate.IsEmailActive;
                });
            } else {
                var matchIndex = this.userDispatchNotificationSettings.indexOf(match);
                this.userDispatchNotificationSettings[matchIndex].IsEmailActive = dispatchNotificationSettingUpdate.IsEmailActive;
            }

            this.isLoading = false;
        });

    }

    toggleSMSNotification(dispatchNotificationTypeID) {
        var match = this.userDispatchNotificationSettings.find((n) => n.DispatchNotificationTypeID ===
            dispatchNotificationTypeID);

        var dispatchNotificationSettingUpdate = {
            DispatchNotificationTypeID: dispatchNotificationTypeID
        };

        //setting isnt set, set it to enabled
        if (match == undefined) {
            dispatchNotificationSettingUpdate.IsSMSActive = true;
            //we dont pass the email property here as this would be the "first time setting" action, thus email should be false until customer sets
        }
        //setting is set, set it to opposite value
        else {
            dispatchNotificationSettingUpdate.IsSMSActive = !match.IsSMSActive;
            //we pass the other property to ensure we're not accepting default values from the dto
            dispatchNotificationSettingUpdate.IsEmailActive = match.IsEmailActive;
        }

        this.isLoading = true;
        this.dataContext.dispatch.setUserDispatchNotificationSettings(dispatchNotificationSettingUpdate).then(() => {
            //setting didnt exist for customer prior to this update
            if (match == undefined) {
                this.dataContext.dispatch.getUserDispatchNotificationSettings().then((result) => {
                    //this is for the UI ng bindings to reflect status
                    this.userDispatchNotificationSettings = result;
                    var match = this.userDispatchNotificationSettings.find((n) => n.DispatchNotificationTypeID === dispatchNotificationTypeID);
                    var matchIndex = this.userDispatchNotificationSettings.indexOf(match);
                    this.userDispatchNotificationSettings[matchIndex].IsSMSActive = dispatchNotificationSettingUpdate.IsSMSActive;
                });
            } else {
                var matchIndex = this.userDispatchNotificationSettings.indexOf(match);
                this.userDispatchNotificationSettings[matchIndex].IsSMSActive = dispatchNotificationSettingUpdate.IsSMSActive;
            }

            this.isLoading = false;
        });
    }

    formatPrimaryPhoneNumber() {
        const digits = this.dataContext.user.current.PrimaryPhoneNumber.replace(/\D/g, '');

        const areaCode = digits.substring(digits.length - 10, digits.length - 7);
        const firstPart = digits.substring(digits.length - 7, digits.length - 4);
        const secondPart = digits.substring(digits.length - 4);

        this.formattedPhoneNumber = `(${areaCode}) ${firstPart} - ${secondPart}`;
    }

    shouldDisplayNotificationSetting(dispatchNotificationTypeID) {
        var targetProperty = this.dispatchNotificationTypes.find((n) => n.DispatchNotificationTypeID === dispatchNotificationTypeID);

        if (targetProperty != undefined) {
            if (targetProperty.IsActive) {
                return true;
            }

            return false;
        }

        return false;
    }

    setNotificationStatus(dispatchNotificationTypeID, notificationType) {

        var targetSetting = '';

        if (notificationType === 'email') {
            targetSetting = 'IsEmailActive';
        } else {
            targetSetting = 'IsSMSActive';
        }

        var match = this.userDispatchNotificationSettings.find((n) => n.DispatchNotificationTypeID ===
            dispatchNotificationTypeID);

        if (match == undefined) {
            return false;
        }

        if (match[targetSetting]) {
            return true;
        }

        return false;
    }
}

export default {
    template: template,
    bindings: {
        dispatchNotificationTypes: '<',
        userDispatchNotificationSettings: '<'
    },
    controller: DispatchNotificationsController
};