import template from './panelWaitSpinner.html';

let panelWaitSpinnerDirective = () => {
    return {
        restrict: 'E',
        template: template,
        controller: PanelWaitSpinner,
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            show: '=?',
            showLoadingMessage: '=?',
            loadingMessage: '=?',
            showIcon: '=?',
            iconClass: '=?'
        }
    };
};

class PanelWaitSpinner {
    /*@ngInject*/ 
    constructor(_) {
        if (_.isNil(this.showLoadingMessage)) {
            this.showLoadingMessage = false;
        }

        if (_.isNil(this.showIcon)) {
            this.showIcon = true;
        }

        if (this.showLoadingMessage && _.isNil(this.loadingMessage)) {
            this.loadingMessage = 'Loading. Please wait...';
        }

        if (this.showIcon && _.isNil(this.iconClass)) {
            this.iconClass = 'fa fa-circle-o-notch fa-spin';
        }
    }
}

export default panelWaitSpinnerDirective;
