import _ from 'lodash';
import template from './changePassword.html';

class ChangePasswordController {
    /*@ngInject*/
    constructor($q, $state, dataContext) {
        this.$state = $state;
        this.dataContext = dataContext;

        this.minLength = 10;
        this.maxLength = 100;

        this.isBusy = false;
        this.message = null;

        this.challenge = {
            prompt: null,
            response: null,
            hasError: false,
            isConfirmed: _.isEmpty(this.token)
        };

        if (!this.challenge.isConfirmed) {
            this.getSecurityQuestion()
                .finally(() => {
                    this.isBusy = false;
                    this.hasError = this.hasError || _.isEmpty(this.challenge.prompt);
                });
        }
    }

    isValid() {
        return !_.isEmpty(this.newPassword) &&
               !_.isEmpty(this.confirm) &&
               (this.challenge.isConfirmed || !_.isEmpty(this.challenge.response));
    }

    submit() {
        this.message = null;
        this.isBusy = true;
        
        return this.dataContext.user
            .changePassword(
                this.token,
                this.username,
                this.oldPassword,
                this.newPassword,
                this.confirm,
                this.challenge.response
            )
            .then((result) => {
                return this.$state.go('login.home', {
                    message: 'Password change successful'
                });
            }, (error) => {
                this.message = !_.isNil(error) && !_.isNil(error.data)
                    ? error.data.Message
                    : error;
            })
            .finally(() => {
                this.isBusy = false;
                this.hasError = !_.isEmpty(this.message);
            });
    }

    getSecurityQuestion() {
        this.isBusy = true;
        return this.dataContext.user
            .getSecurityQuestion(
                this.token,
                this.username
            )
            .then((result) => {
                this.challenge.prompt = result.Question;
                this.hasError = false;
            }, (error) => {
                this.message = !_.isNil(error) && !_.isNil(error.data)
                    ? error.data.Message
                    : error;
                this.hasError = true;
            });
    }
}

export default {
    template: template,
    bindings: {
        token: '@',
        message: '@'
    },
    controller: ChangePasswordController
};