import * as angular from 'angular';
import { Error, TrackOrderDto } from '../types';
import './order.component.scss';

class OrderController {

    q: ng.IQService;
    log: ng.ILogService;
    window: ng.IWindowService;
    scope: ng.IScope;

    /*@ngInject*/
    constructor(
        private readonly $q: ng.IQService,
        private readonly $log: ng.ILogService,
        private readonly $window: ng.IWindowService,
        private readonly $scope: ng.IScope,
    ) {
        'ngInject';
        this.q = $q;
        this.log = $log;
        this.window = $window;
        this.scope = $scope;
    }
}

export default {
    template: require('./order.component.html'),
    bindings: {
        order: '<',
        open: '&'
    },
    controller: OrderController
};