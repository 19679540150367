import moment from 'moment';
import template from './footer.html';
import _ from 'lodash';

class FooterController {
    /*@ngInject*/ 
    constructor(constants, $state, dataContext) {
        this.customerSupport = constants.customerSupport;
        this.constants = constants;
        this.$state = $state;
        this.dataContext = dataContext;

        this.user = dataContext.user.current;

        // Eloqua Refer-A-Friend Personalized URL
        let rafSetting = _.find(this.user.Settings, s => { return s.Name === 'CustomerPortal:EloquaRAFPURL';});
        this.rafpurl = this.user.Settings && rafSetting && rafSetting.Value;
    }

    getYear() {
        return moment().year();
    }

    showFooter() {
        return !_.get(this.$state, '$current.data.suppressFooter', false);
    }
}

export default {
    template: template,
    bindings: {},
    controller: FooterController
};