class ConfigurationsService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    getAll() {
        return this.Restangular
            .one('Configurations')
            .withHttpConfig({ cache: false })
            .get()
            .then((res) => {
                return res.plain();
            });
    }
}

export default ConfigurationsService;