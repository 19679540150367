import * as _ from 'lodash';
import template from './successAddOnModal.html';

class SuccessAddOnModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, constants, $log, alertService, $state) {
        this.$modal = $uibModal;
        this.$log = $log;
        this.dataContext = dataContext;
        this.constants = constants;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.alertService = alertService;
        this.$state = $state;
    }

    close() {
        this.$dismiss();
        location.reload();
    }
    
}

export default {
    template: template,
    bindings: {
        config: '<',
        safetyButtonAddOn: '<',
        confirm: '<',
        videoAnalytics: '<'
    },
    controller: SuccessAddOnModalController
};