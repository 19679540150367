import template from './errorAddOnModal.html';


class ErrorAddOnModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, constants, $log, alertService) {
        this.$modal = $uibModal;
        this.$log = $log;
        this.dataContext = dataContext;
        this.constants = constants;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.alertService = alertService;
    }

    close() {
        this.$dismiss();
    } 
    
}

export default {
    template: template,
    bindings: {
        config: '<',
        safetyButtonAddOn: '<',
        oldQuantity: '<',
        newQuantity: '<',
        isFeatureRemovedWithInSixMonths: '<'
    },
    controller: ErrorAddOnModalController
};