import template from './shell.html';
import _ from 'lodash';

class ShellController {
    /*@ngInject*/ 
    constructor($rootScope, $state, $timeout, constants, headerService, mobileAppContext, $window, $location, $anchorScroll) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.constants = constants;
        this.fromApp = mobileAppContext.get();
        this.navOpen = false;
        this.headerService = headerService;
        this.$window = $window;
        this.inputOverlay = false;
        this.onSetupScreen = false;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
    }

    $onInit() {
        this.addInputEvents();
        this.ifSetup();

        this.$timeout(() => {
            if (!_.isNil(this.$location.hash())) {
                this.$anchorScroll.yOffset = 100;
                this.$anchorScroll();
            }
        });
    }

    addInputEvents() {
        this.$window.document.body.addEventListener('focusin', (event) => {
            var tagName = event.target.tagName.toLowerCase();
            if (tagName === 'input' || tagName === 'textarea' || tagName === 'select') {
                this.$timeout(() => {
                    this.inputOverlay = true;
                }, 0);
            }
        });
        this.$window.document.body.addEventListener('focusout', (event) => {
            this.$timeout(() => {
                this.inputOverlay = false;
            }, 0);
        });
    }

    getState() {
        return this.$state.current.name;
    }

    ifSetup() {
        if (this.getState() === 'setup' && !this.fromApp) {
            this.onSetupScreen = true;
        }
    }
    
    displaySubHeader() {
        let subNavItems = this.headerService.getEnabledSubnavItems(this.$state.$current.name);
        return !_.isNil(subNavItems) 
            && subNavItems.length > 1;
    }

    closeNav() {
        this.$rootScope.$broadcast(this.constants.events.hideMenu);
    }
}

export default {
template: template,
    bindings: {},
transclude: {
    'headerSlot': '?shellHeader',
    'navSlot': '?shellNav',
    'footerSlot': '?shellFooter'
},
controller: ShellController
};