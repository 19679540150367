import * as angular from 'angular';
import 'angular-ui-router';

import documentsModule from './documents';
import emergencyModule from './emergency';
import preferencesModule from './preferences';
import referModule from './refer';
import settingsModule from './settings';
import dispatchServicesModule from './dispatchServices';

import commonModule from '../common';

import { myaccountComponent } from './myaccount.component';

export const myaccountModule = angular.module('fp:customerportal:account',
    [
        documentsModule.name,
        emergencyModule.name,
        preferencesModule.name,
        referModule.name,
        settingsModule.name,
        dispatchServicesModule.name,
        commonModule.name,
        'ui.router'
    ])
    .config(/*@ngInject*/ ($stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('account',
                {
                    abstract: true,
                    parent: 'secure',
                    url: '/account',
                    template: '<fp-my-account></fp-my-account>',
                    resolve: {
                        paymentSummary: /*@ngInject*/ (dataContext: any) => {
                            return dataContext.payment.getPaymentSummary()
                                .then((result: any) => {
                                    return result.PaymentSummaryInfo;
                                });
                        },
                        pendingPayments: /*@ngInject*/ (dataContext: any) => {
                            return dataContext.payment.getPendingPayments();
                        }
                    }
                });
    })
    .component('fpMyAccount', myaccountComponent);