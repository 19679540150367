import { IQService, IRootScopeService } from 'angular';
import { IService as IRestangularService } from 'restangular';
import * as _ from 'lodash';
import ApplicationStateDataService from './applicationState.service';
import { PetInformationDto } from '../../myaccount/settings/types/PetInformationObject';

class PetsDataService {

    $rootScope: IRootScopeService;
    Restangular: IRestangularService;
    constants: any;
    applicationStateService: ApplicationStateDataService;
    $q: IQService;

    PetInformation: any;

    /*@ngInject*/
    constructor(
        $rootScope: IRootScopeService,
        Restangular: IRestangularService,
        constants: any,
        applicationStateService: ApplicationStateDataService,
        $q: IQService
    ) {
        'ngInject';
        this.$rootScope = $rootScope;
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
    }
    
    getCustomerPets() {
        return this.Restangular
            .all('Pets')
            .one('CustomerPets')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.accountSettings, error);
                return this.$q.reject(error);
            });
    }

    updateCustomerPets(petInformationObject: PetInformationDto) {
        return this.Restangular
            .all('Pets')
            .customPOST(petInformationObject)
            .then((result) => {
                return result;
            });
    }
}

export default PetsDataService;