let touchEndDirective = () => {
    return {
        restrict: 'A',
        controller: TouchEndDirective
    };
};

class TouchEndDirective {
    /* @ngInject */
    constructor($scope, $element) {
        let onTouchEnd = (event) => {
            let method = $element.attr('fp-touchend');
            $scope.$event = event;
            $scope.$apply(method);
        };
        $element.bind('touchend', onTouchEnd);
    }
}

export default touchEndDirective;