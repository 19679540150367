let config = (_) => {
    'ngInject';
    _.mixin({
        findDirty: (before, after, predicate) => {
            return _.filter(after, (r) => {
                let temp = _.find(before, (l) => {
                    return _.isFunction(predicate)
                        ? predicate(l, r)
                        : l === r;
                });

                return _.isNil(temp) || !_.isMatch(temp, r);
            });
        }
    });
};

export default config;
