import _ from 'lodash';
import AbstractPasswordValidator from './AbstractPasswordValidator';

class LengthPasswordValidator extends AbstractPasswordValidator {
    constructor(minLength, maxLength) {
        super();

        if (_.isNil(minLength)) {
            throw 'minLength cannot be null or undefined.';
        }

        if (_.isNil(maxLength)) {
            throw 'maxLength cannot be null or undefined.';
        }

        this.minLength = minLength;
        this.maxLength = maxLength;
    }

    validate(password) {
        return !_.isNil(password) && password.length >= this.minLength && password.length <= this.maxLength;
    }
}

export default LengthPasswordValidator;