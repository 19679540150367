export enum ContractTypeEnum {
    OneYear = 1,
    TwoYear = 2,
    ThreeYear = 3,
    MonthToMonth = 4,
    FourYear = 8,
    FiveYear = 9,
    Variable = 10,
    ThreeHalf = 11,
    OneYearAutoRenew = 12,
    ThreeYearAutoRenew = 13,
    ThreeHalfAutoRenew = 14
}