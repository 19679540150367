class DataCacheService {
    /*@ngInject*/
    constructor(_, constants, CacheFactory) {
        let cache = CacheFactory.get('fpCustomerPortal');
        if (_.isNil(cache)) {
            CacheFactory.createCache('fpCustomerPortal', {
                storageMode: 'memory', // sessionStorage
                deleteOnExpire: 'aggressive',
                cacheFlushInterval: constants.cacheRefreshInterval
            });
        }

        this.instance = CacheFactory.get('fpCustomerPortal');
    }

    clear() {
        this.instance.removeAll();
    }
}

export default DataCacheService;
