const STORAGE_TYPE = 'session';

class MobileAppContextService {
    /*@ngInject*/
    constructor($rootScope, fpStore, constants) {
        this.$rootScope = $rootScope;
        this._store = fpStore;
        this._event = constants.events.mobile.contextChanged;
        this._key = constants.storage.fromApp;
    }

    get() {
        return this._store.has(this._key, STORAGE_TYPE)
            ? this._store.get(this._key, STORAGE_TYPE)
            : false;
    }

    set(isMobileApp) {
        this._store.set(this._key, isMobileApp, STORAGE_TYPE);
        this.$rootScope.$broadcast(this._event, isMobileApp, STORAGE_TYPE);
    }

    reset() {
        this._store.remove(this._key, STORAGE_TYPE);
        this.$rootScope.$broadcast(this._event, false);
    }
}

export default MobileAppContextService;