import angular from 'angular';
import 'angular-ui-router';

import changePasswordComponent from './changePassword.component';
import commonModule from '../../common';

export default angular.module('fp:customerportal:login:changepassword', [
        'ui.router',
        commonModule.name
])
    .config(/*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('login.changePassword',
            {
                url: '/changePassword?token&message',
                template: '<fp-change-password token="{{ token }}" message="{{ message }}"></fp-change-password>',
                controller: /*@ngInject*/ ($scope, $stateParams) => {
                    $scope.token = $stateParams.token;
                    $scope.message = $stateParams.message;
                }
            });
    })
    
    .component('fpChangePassword', changePasswordComponent);