import * as angular from 'angular';
import 'angular-ui-router';
import * as _ from 'lodash';
import { IStateService } from 'angular-ui-router';

import commonModule from '../common';

import { cancellationComponent } from './cancellation.component';
import { cancellationHeaderComponent } from './header.component';
import { questionnaire } from './questionnaire';
import { offer } from './offer';
import { modals } from './modals';
import { confirmationComponent } from './confirmation.component';
import { overviewComponent } from './overview.component';
import { cancellationCompleteComponent } from './cancellationComplete.component';
import { linkExpiredComponent } from './linkExpired.component';
import { failedPaymentComponent } from './failedPayment.component';
import { contactToCancelComponent } from './contact-to-cancel.component';
import DataContext from '../common/data/dataContext.service';
import { cancelV2Component } from './v2.component';

const overrideSessionRedirect = async (destination: string, dataContext: DataContext, $state: IStateService, constants: any) => {
    // check account status, timeframe, and session
    const accountPromise = dataContext.account.getAccountSummary();
    const timeFramePromise = dataContext.account.getTimeFrame();
    const sessionPromise = dataContext.retention.getRetentionSession();

    const account = await accountPromise;
    const timeFrame = await timeFramePromise;

    // if status is pending cancellation or pending return in RFTP
    // re-route to cancellation complete page
    const accountIsPendingCancel = account && account.Status === 'Pending Cancel';
    const accountIsPendingReturn = account && account.Status === 'Pending Return';
    const accountIsRFTP = timeFrame && timeFrame.TimeFrameName === constants.timeFrameNames.RFTP;
    const shouldRedirectToComplete = accountIsPendingCancel || (accountIsPendingReturn && accountIsRFTP);

    if (!shouldRedirectToComplete) throw "Account is not pending cancel. Link is expired.";

    const session = await sessionPromise;
    if (!session || session.CancelOrReturnRetentionEpisodeID == null) throw "Retention episode could not be found.";

    $state.go(destination, { retentionEpisodeID: session.CancelOrReturnRetentionEpisodeID });
}

export const cancellationModule = angular.module('fp:customerportal:cancellation',
    [
        'ui.router',
        commonModule.name,
        questionnaire.name,
        offer.name,
        modals.name
    ])
    .config(/*@ngInject*/
        ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider
                .state('expired',
                    {
                        url: '/cancellation/expired',
                        template: '<fp-link-expired></fp-link-expired>'
                    })
                .state('cancellation',
                    {
                        abstract: true,
                        url: '/cancellation',
                        template: '<fp-cancellation></fp-cancellation>'
                    })
                .state('cancellation.overview',
                    {
                        parent: 'cancellation',
                        url: '',
                        template: '<fp-cancellation-overview></fp-cancellation-overview>'
                    })
                .state('cancellation.confirmation',
                    {
                        parent: 'cancellation',
                        url: '/{retentionAnswerOptionID:int}/confirmation',
                        template: '<fp-cancellation-confirmation></fp-cancellation-confirmation>',
                        params: {
                            tier0OfferToDisplay: null
                        }
                    })
                .state('cancellation.cancellationComplete',
                    {
                        parent: 'cancellation',
                        url: '/{retentionEpisodeID:int}/cancellationComplete',
                        template: '<fp-cancellation-complete></fp-cancellation-complete>'
                    })
                .state('failedPayment',
                    {
                        url: '/cancellation/failedPayment',
                        template: '<fp-failed-payment></fp-failed-payment>'
                    })
                .state('cancel', {
                    // using short name to avoid breaking links
                    // login page uses ?token for single sign on flow
                    url: '/cancel?t',
                    template: '<fp-cancel-v2></fp-cancel-v2>'
                })
                .state('cancel.contact', {
                    url: '/contact',
                    template: '<fp-contact-to-cancel></fp-contact-to-cancel>'
                });
        })
    .run(/*@ngInject*/($rootScope: ng.IRootScopeService, constants: any, $state: IStateService, $stateParams: any, dataContext: DataContext) => {
        $rootScope.$on(constants.events.stateChangeStart,
            async (event: any, toState: any, toParams: any, fromState: any, fromParams: any, options: any) => {
                dataContext.isLoading = true;
                try {
                    if (toState.name.indexOf('cancellation') !== -1) {
                        dataContext.applicationState.change(
                            constants.sectionTypes.cancellation as string,
                            constants.actionTypes.stateChange as string,
                            'from [' + fromState.name + '] to [' + toState.name + ']');
                    }

                    // Don't session guard non cancellation pages or the final cancellation offer page to allow the user to select the secondary offer
                    if (toState.parent !== 'cancellation'
                        || toState.name === 'cancellation.offer'
                        || toState.Name === 'cancellation.cancellationComplete'
                        || toState.Name === 'cancellation.failedPayment'
                    ) {
                        throw "Skipping session validation";
                    }

                    // check if session is valid
                    const isValid = await dataContext.retention.validateSession();

                    if (!isValid) {
                        // Log state error here that the user has an invalid session
                        dataContext.applicationState.error(constants.sectionTypes.cancellation, 'User navigation prevented as user no longer has a valid session. Rerouting to expiration page.');
                        event.preventDefault();


                        try {
                            await overrideSessionRedirect('cancellation.cancellationComplete', dataContext, $state, constants);
                        } catch (error) {
                            console.warn('An error occurred in checking the account state. Fallback to default routing behavior.', error);
                            $state.go('expired');
                        }
                    }
                } catch (error) {
                    console.warn("An error occurred during state change.", error);
                }
                dataContext.isLoading = false;
            });
    })
    .component('fpCancellation', cancellationComponent)
    .component('fpCancellationHeader', cancellationHeaderComponent)
    .component('fpCancellationConfirmation', confirmationComponent)
    .component('fpCancellationOverview', overviewComponent)
    .component('fpCancellationComplete', cancellationCompleteComponent)
    .component('fpLinkExpired', linkExpiredComponent)
    .component('fpFailedPayment', failedPaymentComponent)
    .component('fpCancelV2', cancelV2Component)
    .component('fpContactToCancel', contactToCancelComponent);
