import * as angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import { questionnaireComponent } from './questionnaire.component';

export const questionnaire = angular.module('fp:customerportal:cancellation:questionnaire',
        [
            'ui.router',
            commonModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider: ng.ui.IStateProvider) => {
            $stateProvider
                .state('cancellation.questionnaire',
                    {
                        parent: 'cancellation',
                        url: '/questionnaire',
                        template: '<fp-questionnaire></fp-questionnaire>'
                    })
        })
    .component('fpQuestionnaire', questionnaireComponent)
    