import angular from 'angular';

import 'angular-ui-router';

import forgotPasswordComponent from './forgotPassword.component';
import forgotUsernameComponent from './forgotUsername.component';

import commonModule from '../../common';

export default angular.module('fp:customerportal:login:forgotpassword', [
        'ui.router',

        commonModule.name
])

    .config(/*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('login.forgot-password',
            {
                url: '/forgotPassword',
                template: '<fp-forgot-password></fp-forgot-password>'
            })
            .state('login.forgot-username',
            {
                url: '/forgotUsername',
                template: '<fp-forgot-username></fp-forgot-username>'
            });
    })

    .component('fpForgotPassword', forgotPasswordComponent)
    .component('fpForgotUsername', forgotUsernameComponent);