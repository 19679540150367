import './questionnaire.component.scss';
import { QuestionnaireAnswerTemplate } from "../../cancellation/types/QuestionnaireAnswerTemplate";
import * as _ from 'lodash';
import { QuestionnaireAnswerRequest } from '../types';
import { questionnaireOfferConfirmModal } from '../modals/offerConfirmModal.component'
import { offerCommentsModal } from '../modals/offerCommentsModal.component'


export class QuestionnaireController {
    public stateIsReason = true;
    public timeFrameId: number;
    public isLoading: boolean;
    public selectedReason: string;
    public selectedSecondaryReason: string;
    public primaryReasons: any[];
    public answers: QuestionnaireAnswerTemplate[];
    public secondaryAnswers: QuestionnaireAnswerTemplate[];
    public displaySecondaryAnswerSelections: boolean;
    public displayConfirmationButton: boolean;
    public disableSelection: boolean;
    public retentionAnswers: any;
    public tier1OfferToDisplay: any;
    public tier2OfferToDisplay: any;
    public tier0OfferToDisplay: any;
    public previousRetentionSessionAnswers: any;
    public previousRetentionSession: any;
    public loadedPreviousSession: boolean;
    public isMobile: boolean;
    public dropdownPrimaryReason: string;
    public comments: string;
    public haveRequestedComments = false;

    constructor(private $state: any,
        private $scope: any,
        private readonly dataContext: any,
        public constants: any,
        private $q: any,
        private $stateParams: any,
        private readonly $log: any,
        private $uibModal: any) {
        'ngInject';

        $scope.$on('$stateChangeSuccess',
            (event: any, toState: any) => {
                if (toState) {
                    this.updateStateIsReason(toState);
                }
            });
    }

    public $onInit() {
        this.isLoading = true;
        this.loadedPreviousSession = false;

        if (window.screen.width <= 400) { // 768px portrait
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }

        this.$q.all([
            this.dataContext.account.getTimeFrame(),
            // Preload data
            this.dataContext.retention.getRetentionOfferType(1),

            this.dataContext.retention.getRetentionSession(),
            this.dataContext.retention.getRetentionQuestionAnswers(),

            //retrieve answer list
            this.dataContext.retention.getRetentionAnswerOption()

        ]).then((results: any[]) => {
            this.timeFrameId = results[0].TimeFrameId;

            this.previousRetentionSession = results[2];
            this.previousRetentionSessionAnswers = results[3];


            this.primaryReasons = _.filter(results[4],
                (r: any) => {
                    return r.ParentRetentionAnswerOptionID == null
                });

            this.answers = _.map(this.primaryReasons, (answer: any) => {
                return new QuestionnaireAnswerTemplate(
                    answer.RetentionAnswerOptionID, answer.Name, answer.Reason,
                    answer.ParentRetentionAnswerOptionID, answer.IconName, answer.IsActive,
                    answer.RetentionOfferTypeID);
            });

            if (this.previousRetentionSession != null) {
                //only attempt to load previous session data if the session on record is within validity period
                //and we do not have a date completed on the session
                if ((this.previousRetentionSession.EligibilityEndDateUTC > new Date().toISOString())
                    && this.previousRetentionSession.DateCompletedUTC == null) {
                    //only attempt to load answers if our most recent valid session
                    //has a session ID on it that matches the session ID of the retention answer
                    if (this.previousRetentionSessionAnswers[0] != null) {
                        if (this.previousRetentionSession.RetentionQuestionnaireSessionID == this.previousRetentionSessionAnswers[0].RetentionQuestionnaireSessionID) {
                            this.loadPreviousSession(this.previousRetentionSessionAnswers);
                        }
                    }
                }
            }
            else {
                //hide the UI elements on init
                this.displaySecondaryAnswerSelections = false;
                this.displayConfirmationButton = false;
                this.disableSelection = false;
            }

        }).finally(() => {
            this.isLoading = false;
        });

        this.updateStateIsReason(this.$state.current);
    }

    public secondaryReasonFiltering(reasonDescription: any) {
        //this sets the parent button class
        this.selectedReason = reasonDescription;

        this.selectedSecondaryReason = null;

        let primaryReason = this.primaryReasons.find(r => r.Reason == this.selectedReason);

        this.secondaryAnswers = _.map(primaryReason.SecondaryAnswerOptions, (answer: any) => {
            return new QuestionnaireAnswerTemplate(
                answer.RetentionAnswerOptionID, answer.Name, answer.Reason,
                answer.ParentRetentionAnswerOptionID, answer.IconName, answer.IsActive,
                answer.RetentionOfferTypeID);
        });

        //secondary answer is ready for display,
        //confirmation should be false until selection
        //of secondary reason
        this.displaySecondaryAnswerSelections = true;
        this.displayConfirmationButton = false;
    }

    public secondaryReasonSelection(secondaryReason: any) {
        this.selectedSecondaryReason = secondaryReason;

        this.displayConfirmationButton = true;
    }

    public confirmationSelection() {
        let answerText = this.comments;

        /* the purpose behind this design is we soft require comments by the customer.
           we want to display a modal once to suggest they provide comments, assuming they
           dont modify their input we don't want to block them again from submitting their
           answers
        */
        if (this.haveRequestedComments === false) {
            this.haveRequestedComments = true;
            //some value check here to see if they put in more than a word or two
            if ((answerText != undefined && answerText.length < 10) || answerText === undefined) {
                this.$uibModal.open({
                    component: offerCommentsModal.selector,
                    size: 'md'
                });
            } else {
                this.retrieveOffers();
            }
        } else {
            this.retrieveOffers();
        }

    }

    public retrieveOffers() {
        this.disableSelection = true;
        let secondaryReason = this.secondaryAnswers.find(s => s.Reason == this.selectedSecondaryReason);

        //transmit retention answers
        if (this.loadedPreviousSession == false) {
            this.submitQuestionnaireAnswers(this.selectedReason, secondaryReason)
                .then(() => {
                    this.dataContext.retention.searchRetentionOffer(secondaryReason.RetentionAnswerOptionId).then(
                        (result: any) => {

                            this.tier1OfferToDisplay = _.filter(result,
                                function(o) {
                                    return ((o.OfferTier == 1 || o.OfferTier == -1) && o.IsDeleted == false);
                                });

                            this.tier2OfferToDisplay = _.filter(result,
                                function(o) {
                                    return ((o.OfferTier == 2 || o.OfferTier == -2) && o.IsDeleted == false);
                                });

                            this.tier0OfferToDisplay = _.filter(result,
                                function(o) {
                                    return o.OfferTier == 0 && o.IsDeleted == false;
                                });
                        });

                    setTimeout(function() {
                            let offerList = document.getElementById('offer-menu');
                            if (offerList != null) {
                                offerList.scrollIntoView({
                                    behavior: 'smooth'
                                });
                            }
                        },
                        1500);
                });
        } else {
            this.dataContext.retention.searchRetentionOffer(secondaryReason.RetentionAnswerOptionId).then((result: any) => {

                this.tier1OfferToDisplay = _.filter(result, function (o) {
                    return ((o.OfferTier == 1 || o.OfferTier == -1) && o.IsDeleted == false);
                });

                this.tier2OfferToDisplay = _.filter(result, function (o) {
                    return ((o.OfferTier == 2 || o.OfferTier == -2) && o.IsDeleted == false);
                });

                this.tier0OfferToDisplay = _.filter(result, function (o) {
                    return o.OfferTier == 0 && o.IsDeleted == false;
                });
            });

            setTimeout(function () {
                    let offerList = document.getElementById('offer-menu');
                    if (offerList != null) {
                        offerList.scrollIntoView({
                            behavior: 'smooth'
                        });
                    }
                },
                1500);
        }
    }

    public loadPreviousSession(previousSession: any) {
        /*
         * this loading assumes that 2 answers per session will exists in
         * retentionquestionnairesession table for the sessionID. once deployed
         * there should only be 1 set of answers for each session
         * the child answer is written to SQL second when transmitted via submitQuestionnaireAnswers()
         * so it is for that reason we set the parent object to position 2 of the array bellow
         * 
         */
        let parentReasonId = previousSession[1].RetentionAnswerOptionID;
        let secondaryReasonId = previousSession[0].RetentionAnswerOptionID;

        let parentReason = this.primaryReasons.find(r => r.RetentionAnswerOptionID == parentReasonId);

        this.secondaryAnswers = _.map(parentReason.SecondaryAnswerOptions, (answer: any) => {
            return new QuestionnaireAnswerTemplate(
                answer.RetentionAnswerOptionID, answer.Name, answer.Reason,
                answer.ParentRetentionAnswerOptionID, answer.IconName, answer.IsActive,
                answer.RetentionOfferTypeID);
        });

        let secondaryReason = this.secondaryAnswers.find(s => s.RetentionAnswerOptionId == secondaryReasonId);

        this.displaySecondaryAnswerSelections = true;
        this.displayConfirmationButton = true;
        this.disableSelection = true;
        this.comments = previousSession[0].AnswerText;

        this.selectedReason = parentReason.Reason;
        this.selectedSecondaryReason = secondaryReason.Reason;
        this.loadedPreviousSession = true;
        this.retrieveOffers();

    }


    public continueToCancel() {
        this.$state.go('cancellation.confirmation', {
            retentionAnswerOptionID: 1,
            //uirouter, state.go, how to pass object between views              
            tier0OfferToDisplay: this.tier0OfferToDisplay
        });
    }

    public async submitQuestionnaireAnswers(primaryReason: any, secondaryReason: any) {
        let primaryReasonObject = this.primaryReasons.find(r => r.Reason == primaryReason);
        const answer = new QuestionnaireAnswerRequest();
        answer.QuestionNumber = 1;
        //the difference in ID and Id is intentional here          
        answer.RetentionAnswerOptionIDs =
            [primaryReasonObject.RetentionAnswerOptionID, secondaryReason.RetentionAnswerOptionId];
        answer.AnswerText = this.comments;
        await this.dataContext.retention.submitQuestionnaireAnswers(answer);
    }

    public acceptOffer(offer: any) {
        var checkForMoving = this.selectedReason == 'moving';

        localStorage.setItem('offer', JSON.stringify(offer));
        localStorage.setItem('cancellationReasonMoving', checkForMoving.toString());
        localStorage.setItem('tier0OfferToDisplay', JSON.stringify(this.tier0OfferToDisplay));

        if (offer.Name !== "callforsupport_tier1") {
            this.$uibModal.open({
                component: questionnaireOfferConfirmModal.selector,
                size: 'lg',
                resolve: {
                    offerInfo: () => {
                        return offer;
                    },
                    cancellationReasonMoving: () => {
                        return checkForMoving;
                    }
                }
            });
        }
    }

    public getSelectedClass(reason: string) {
        return this.selectedReason !== reason ? 'Deselected' : 'Selected';
    }

    private updateStateIsReason(state: any) {
        if (state.name === 'cancellation.questionnaire.check' || state.name === 'cancellation.questionnaire.feedback') { this.stateIsReason = false; } else { this.stateIsReason = true; }
    }
}

export const questionnaireComponent = {
    template: require('./questionnaire.component.html'),
    controller: QuestionnaireController
};