import _ from 'lodash';
import AbstractPasswordValidator from './AbstractPasswordValidator';

class RegexPasswordValidator extends AbstractPasswordValidator {
    constructor(regex) {
        super();

        if (_.isNil(regex)) {
            throw 'regex cannot be null or undefined.';
        }

        this.regex = regex;
    }

    validate(password) {
        return !_.isNil(password) && this.regex.test(password);
    }
}

export default RegexPasswordValidator;