class PermitService {
    /*@ngInject*/
    constructor(Restangular, constants, applicationStateService) {
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
    }

    get() {
        return this.Restangular
            .one('permit')
            .get()
            .then((result) => {
                this.permit = result;
                return result;
            });
    }

    put(permit) {
        return this.Restangular
            .one('permit')
            .customPUT({
                Permit: permit
            })
            .then((result) => {
                this.applicationStateService.dataChange(this.constants.sectionTypes.emergencySettings, this.constants.dataTypes.permit,  this.permit, permit, false);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }
}

export default PermitService;