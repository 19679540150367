import angular from 'angular';
import commonModule from '../common';
import setupEquipmentSSOComponent from './setupEquipmentSSO.component';

export default angular.module('fp:customerportal:setup',
    [
        commonModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('setup', {
                url: '/setup',
                parent: 'secure',
                template: '<fp-setup-equipment-sso></fp-setup-equipment-sso>',
                data: {
                    navigationMenu: constants.navigationMenuSections.majorSections.setupEquipment,
                    suppressFooter: true
                },
                onEnter: function (dataContext, $state, monitoringPlansService, $timeout) {
                    let servicePlanInfo = dataContext.user.current.ServicePlanInfo || {};
                    let isCameraPlanUser = monitoringPlansService.isSAVPlan(servicePlanInfo);
                    
                    if (isCameraPlanUser) {
                            $timeout(() => {
                                $state.go('unavailable');
                        });
                    }
                }        
            });
    })
    .component('fpSetupEquipmentSso', setupEquipmentSSOComponent);


