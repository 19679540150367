import _ from 'lodash';

import template from './nav.html';

class NavController {
    /*@ngInject*/ 
    constructor($scope, $state, $window, $q, $timeout, $log, dataContext, navMenuItems, constants) {
        this.$state = $state;
        this.dataContext = dataContext;
        this.$window = $window;
        this.$timeout = $timeout;
        this.profileMenu = 'logoutPopover';
        this.constants = constants;
        this.$log = $log;
        this.navMenuItemsService = navMenuItems;

        this.user = dataContext.user.current;

        this.dataContext.user.getAddOnInformation().then((result) => {
            this.addOns = result;
            this.allStateAddOn = _.find(this.addOns,
                function(obj) {
                    return obj.AddOn === constants.serviceAddOns.IdentityProtection.Id;
                });
        });

        $scope.$on(constants.events.data.userInfoReceived, (evt, user) => {
            this.user = user;
        });

        $scope.$on(constants.events.showMenu, (evt, show) => {
            this.showNav(show, true);
        });

        $scope.$on(constants.events.hideMenu, (evt, show) => {
            this.showNav(show, false);
        });

        $scope.$on(constants.events.refreshMenu, () => {
            $timeout(() => {
                this.loadMenuItems();
                this.getInitials();
            });
        });
    }

    $onInit() {
        this.loadMenuItems();
        this.getInitials();

        if (_.isNil(this.showSystemName)) {
            this.$timeout(() => {
                this.showSystemName = true;
            });
        }
        if (_.isNil(this.showLogout)) {
            this.$timeout(() => {
                this.showLogout = true;
            });
        }
    }

    $onChanges(change) {
        if (!_.isNil(change.items)) {
            this.$timeout(() => {
                this.loadMenuItems();
                this.getInitials();
            });
        }
    }

    getInitials() {
        this.dataContext.user.getUserInfo()
            .then(() => {
                this.initials = this.user.FirstName.charAt(0) + this.user.LastName.charAt(0);
            });
    }

    ssoToAdcEditAccount() {
        this.$state.go('adc.editAccountInfo');
    }

    getNavOpen() {
        return this.navOpen ? 'open' : 'closed';
    }

    stateName() {
        return this.$state.current.name;
    }

    loadMenuItems() {
        this.navMenuItems = _.isEmpty(this.items)
            ? this.navMenuItemsService.getEnabled()
            : this.items;
    }

    showNav(show, defaultValue) {
        this.$timeout(() => {
            this.navOpen = !_.isNil(show) ? show : defaultValue;
        });
    }

    isAuthenticated() {
        return this.dataContext.user.isAuthenticated();
    }

    isSubMenuItemActive(item) {
        if (item.name === this.constants.serviceAddOns.IdentityProtection.Name) {
            if (!_.isNil(this.allStateAddOn)) {
                return true;
            }
            return false;
        }
        return true;
    }

    isActive(section, item) {
        let result = false;

        if (!_.isNil(item) && _.isFunction(item.isActive)) {
            result = item.isActive(item);
        }
        else if (!_.isNil(this.$state.$current.data) && !_.isNil(this.$state.$current.data.navigationMenu)) {
            if (!_.isNil(this.$state.$current.data.navigationSubSection) &&
                this.$state.$current.data.navigationSubSection === section) {
                result = true;
            } else {
                result = this.$state.$current.data.navigationMenu === section;
            }
        } else {
            result = false;
        }

        return result;
    }

    hasSubMenu(item) {
        return !_.isEmpty(item.sub);
    }

    logout() {
        if (_.isNil(this.shopLogout())) {
            this.closeNavbar();
            this.dataContext.user.logout()
                .finally(() => {
                    return this.$state.go('login.home');
                });
        }    
    }

    closeNavbar() {
        this.showNav(false);
    }
}

export default {
    template: template,
    bindings: {
        items: '<',
        showSystemName: '<',
        showLogout: '<',
        busy: '<',
        shopLogout: '&'
    },
    controller: NavController
};