import _ from 'lodash';

import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor($log, dataContext, mobileAppContext) {
        this.$log = $log;
        this.dataContext = dataContext;
        this.contract = {};
        this.mobileAppContext = mobileAppContext;
        this.isLoadingContract = true;
        this.isAmazonAccount = false;
    }

    inMobileApp() {
        return this.mobileAppContext.get();
    }

    $onInit() {
        this.dataContext.account.isAmazonAccount().then((result) => {
            this.isAmazonAccount = result;
        })
        .catch((error) => {
            this.$log.error('Error getting account is amazon or not.', error);
        });
        return this.dataContext.document.getContractSummary()
            .then((result) => {
                this.contract = _.cloneDeep(result);
            })
            .catch((error) => {
                this.$log.error('Error fetching contract summary.', error);
            })
            .finally(() => {
                this.isLoadingContract = false;
            });
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};