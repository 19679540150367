import _ from 'lodash';
import template from './medicalInfo.html';
import './medicalInfo.scss';
import AbstractSectionController from '../AbstractSectionController';

import { CentralStationContactWithMedicalInformationDto } from '../../myaccount/settings/types/CentralStationContactWithMedicalInformationDto';
import { CentralStationNewContactDto } from '../../myaccount/settings/types/CentralStationNewContactDto';
import { CentralStationContactPatientUpdateRequestDto } from '../../myaccount/settings/types/CentralStationContactPatientUpdateRequestDto';
import { CentralStationContactMedicalConditionDto } from '../../myaccount/settings/types/CentralStationContactMedicalConditionDto';
import { CentralStationContactAllergyConditionDto } from '../../myaccount/settings/types/CentralStationContactAllergyConditionDto';
import { CentralStationContactConditionsDto } from '../../myaccount/settings/types/CentralStationContactConditionsDto'


class AccountMedicalInfoController extends AbstractSectionController {
    /*@ngInject*/
    constructor(dataContext, constants, alertService, $uibModal) {
        super();

        this.dataContext = dataContext;
        this.constants = constants;
        this.alertService = alertService;
        this.ALERT_DIALOG = 'medicalInfo';
        this.$uibModal = $uibModal;

        this.medicalContacts = [{
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            medicalCondition: null,
            additionalMedicalConditions: [],
            allergyCondition: null,
            additionalAllergyConditions: []
        }];

        this.acceptedTOS = false;
        this.contactsAndConditions = new CentralStationContactConditionsDto();
        this.contactsAndConditionsLoaded = false;
        this.conditionListLoaded = false;
        this.showContactForm = false;
        this.initialContactAdded = false;
        this.disableDelete = false;
    }

    $onInit() {
        this.isLoading = true;
        this.getSiteContactsAndConditions();
        this.getConditionsList();
    }

    isMedicalConditionDisabled(condition, medicalContact, additionalCondition) {

        var isSelected = medicalContact.medicalCondition === condition.ConditionCode;
        var isSelectedInAdditional = medicalContact.additionalMedicalConditions.some(ac => ac.ConditionCode === condition.ConditionCode && ac.ConditionCode !== additionalCondition.ConditionCode);

        return isSelected || isSelectedInAdditional;
        }

    isAllergyConditionDisabled(condition, medicalContact, additionalCondition) {
        var isSelected = medicalContact.allergyCondition === condition.ConditionCode;
        var isSelectedInAdditional = medicalContact.additionalAllergyConditions.some(ac => ac.ConditionCode === condition.ConditionCode && ac.ConditionCode !== additionalCondition.ConditionCode);

        return isSelected || isSelectedInAdditional;
    }

    addContact() {
        this.showContactForm = true;
        this.disableDelete = true;

        if (this.initialContactAdded === true || this.contactsAndConditions.length === 0) {
            this.medicalContacts.push({
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                medicalCondition: null,
                additionalMedicalConditions: [],
                allergyCondition: null,
                additionalAllergyConditions: []
            });

            }

        this.initialContactAdded = true;
    }

    showRemoveContactElement(elementIndex) {
        //show only when there are contacts already on the account
        if (this.contactsAndConditions.length > 0) {
            return true;
        }

        //show if there are no contacts on the account, and multiple contacts are being created
        if (this.contactsAndConditions.length === 0 && this.medicalContacts.length > 1) {
            //do not allow removal of the first contact if there are no contacts already on the account
            if (elementIndex === 0) {
                return false;
            }

            return true;
        }

        return false;
    }

    removeContact(contactIndex) {

        var modalInstance = this.$uibModal.open({
            template: '<fp-medical-info-delete-modal config="config"></fp-medical-info-delete-modal>',
            size: 'sm',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        modal: this.$uibModal
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });

        modalInstance.result.then(
            (result) => {
                if (result) {
                    var targetContact = this.contactsAndConditions[contactIndex];

                    if (targetContact.ContactNumber === 0) {
                        this._showGenericErrorAlert(
                            'An error has occurred. We could not delete your contact, please contact Frontpoint Support for assistance.');
                    } else {
                        this.isLoading = true;
                        this.deleteContact(targetContact.ContactNumber).then((result) => {
                            this.contactsAndConditions.splice(contactIndex, 1);
                            this.isLoading = false;
                            this.stopEditing();
                            this._showGenericSuccessAlert('Successfully updated your contacts!');
                        });

                    }
                }
            }
        );
    }

    addAdditionalMedicalCondition(contactIndex) {
        this.medicalContacts[contactIndex].additionalMedicalConditions.push({});
    }

    removeBaseMedicalCondition(contactIndex) {
        this.medicalContacts[contactIndex].medicalCondition = null;
    }

    removeAdditionalMedicalCondition(medicalContact, conditionIndex) {
        var targetContact = this.medicalContacts.filter(c => c.$$hashKey === medicalContact.$$hashKey);
        targetContact[0].additionalMedicalConditions.splice(conditionIndex, 1);
    }

    additionalMedicalConditionsAllowed(contactIndex) {
        //the 1 here represents the initial medical condition
        if ((this.medicalContacts[contactIndex].additionalMedicalConditions.length + 1) < this.medicalConditionsList.length) {
            return true;
        }

        return false;
    }

    addAdditionalAllergyCondition(contactIndex) {
        this.medicalContacts[contactIndex].additionalAllergyConditions.push({});
    }

    removeBaseAllergyCondition(contactIndex) {
        this.medicalContacts[contactIndex].allergyCondition = null;
    }

    additionalAllergyConditionsAllowed(contactIndex) {
        //the 1 here represents the initial allergy condition
        if ((this.medicalContacts[contactIndex].additionalAllergyConditions.length + 1) < this.allergyConditionsList.length) {
            return true;
        }

        return false;
    }

    removeAdditionalAllergyCondition(medicalContact, conditionIndex) {
        var targetContact = this.medicalContacts.filter(c => c.$$hashKey === medicalContact.$$hashKey);
        targetContact[0].additionalAllergyConditions.splice(conditionIndex, 1);
    }

    openTOS() {
        var modalInstance = this.$uibModal.open({
            template: '<fp-medical-info-modal config="config"></fp-medical-info-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        modal: this.$uibModal
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });

        modalInstance.result.then(
            (result) => {
                if (result) {
                    this.acceptedTOS = true;
                } else {
                    this.acceptedTOS = false;
                }
            }
        );
    }

    maxBirthday() {
        return new Date();
    }

    minBirthday() {
        var today = new Date();
        var minBirthday = today.setFullYear(today.getFullYear() - 120);

        return minBirthday;
    }

    getSiteContactsAndConditions() {
        return this.dataContext.medicalInformation.getSiteContactsAndConditions()
            .then((result) => {
                this.contactsAndConditions = result;
                this.isLoading = false;
            });
    }

    combineMedicalConditionsLists(rapidConditions) {
        var customConditionsToAdd =
            this.constants.CustomMedicalConditions.filter(
                c => !rapidConditions.some(rc => rc.ConditionDescription === c.ConditionDescription));

        return customConditionsToAdd;
    }

    combineAllergyConditionsLists(rapidConditions) {
        var customConditionsToAdd =
            this.constants.CustomAllergyConditions.filter(
                c => !rapidConditions.some(rc => rc.ConditionDescription === c.ConditionDescription));

        return customConditionsToAdd;
    }

    getConditionsList() {
        return this.dataContext.medicalInformation.getConditionList()
            .then((result => {
                this.medicalConditionsList = _.filter(result, function (c) { return c.ConditionCategory === "Medical" && c.ConditionCode !== "0" });
                this.allergyConditionsList = _.filter(result, function (c) { return c.ConditionCategory === "Allergy" && c.ConditionCode !== "0" });

                var customConditionsToAdd = this.combineMedicalConditionsLists(this.medicalConditionsList);
                this.medicalConditionsList = this.medicalConditionsList.concat(customConditionsToAdd);

                customConditionsToAdd = this.combineAllergyConditionsLists(this.allergyConditionsList);
                this.allergyConditionsList = this.allergyConditionsList.concat(customConditionsToAdd);
            }));
    }

    deleteContact(contactNumber) {
        return this.dataContext.medicalInformation.deleteSiteContact(contactNumber)
            .then((result => {

            }));
    }

    removeNewContact(contactIndex) {
        this.medicalContacts.splice(contactIndex, 1);

        if (this.medicalContacts.length === 0) {
            this.disableDelete = false;
            this.acceptedTOS = false;
        }
    }

    onEditCore() {
        if (this.contactsAndConditions.length === 0) {
            this.showContactForm = true;
        }

        if (this.medicalContacts.length === 0) {
            this.medicalContacts = [{
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                medicalCondition: null,
                additionalMedicalConditions: [],
                allergyCondition: null,
                additionalAllergyConditions: []
            }];

            this.initialContactAdded = true;
        }
    }

    onSaveCore() {
        this.isLoading = true;
        this.disableDelete = false;

        var formattedMedicalContacts = this.prepareContactMedicalObject();

        return this.dataContext.medicalInformation.setSiteContactsAndConditions(formattedMedicalContacts)
            .then((result) => {
                this.getSiteContactsAndConditions().then(() => {
                    var message = formattedMedicalContacts.length > 1
                        ? 'Your contacts have been created.'
                        : 'Your contact has been created.';
                    this._showGenericSuccessAlert('Success! ' + message);
                    this.stopEditing();
                    this.resetForm();
                    this.showContactForm = false;
                    this.isLoading = false;
                });

            });
    }

    onCancelCore() {
        this.showContactForm = false;
        this.disableDelete = false;
        this.stopEditing();
        this.resetForm();
    }

    prepareContactMedicalObject() {
        var formattedMedicalContacts = this.medicalContacts.map(contact => {
            var instance = new CentralStationContactWithMedicalInformationDto();

            instance.NewContact = new CentralStationNewContactDto();
            instance.ContactPatientUpdateRequest = new CentralStationContactPatientUpdateRequestDto();
            instance.ContactMedicalConditions = [];
            instance.ContactAllergyConditions = [];


            instance.NewContact.FirstName = contact.firstName;
            instance.NewContact.LastName = contact.lastName;

            instance.ContactPatientUpdateRequest.DateOfBirth = contact.dateOfBirth ? contact.dateOfBirth.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }) : '';
            instance.ContactPatientUpdateRequest.DateOfBirthChangeFlag = contact.dateOfBirth ? true : false;

            if (contact.medicalCondition !== null) {
                var baseMedicalCondition = new CentralStationContactMedicalConditionDto();

                if (this.constants.CustomMedicalConditions.some(condition => condition.ConditionCode === contact.medicalCondition)) {

                    var condition = this.constants.CustomMedicalConditions.filter(
                        condition => condition.ConditionCode === contact.medicalCondition);

                    baseMedicalCondition.MedicalConditionCode = "0";
                    baseMedicalCondition.Comment = condition[0].ConditionDescription;
                } else {
                    baseMedicalCondition.MedicalConditionCode = contact.medicalCondition;
                }

                instance.ContactMedicalConditions.push(baseMedicalCondition);
            }

            if (contact.additionalMedicalConditions.length > 0) {
                contact.additionalMedicalConditions.map(additionalMedicalCondition => {
                    //customer added additional row but no value set
                    if (additionalMedicalCondition.ConditionCode !== undefined) {
                        var conditionDto = new CentralStationContactMedicalConditionDto();

                        if (this.constants.CustomMedicalConditions.some(condition => condition.ConditionCode === additionalMedicalCondition.ConditionCode)) {

                            var condition = this.constants.CustomMedicalConditions.filter(
                                condition => condition.ConditionCode === additionalMedicalCondition.ConditionCode);

                            conditionDto.MedicalConditionCode = "0";
                            conditionDto.Comment = condition[0].ConditionDescription;
                        } else {
                            conditionDto.MedicalConditionCode = additionalMedicalCondition.ConditionCode;
                        }

                        instance.ContactMedicalConditions.push(conditionDto);
                    }
                });
            }

            if (contact.allergyCondition !== null) {
                var baseAllergyCondition = new CentralStationContactAllergyConditionDto();

                if (this.constants.CustomAllergyConditions.some(condition => condition.ConditionCode === contact.allergyCondition)) {

                    var condition = this.constants.CustomAllergyConditions.filter(
                        condition => condition.ConditionCode === contact.allergyCondition);

                    baseAllergyCondition.AllergyCode = "0";
                    baseAllergyCondition.Comment = condition[0].ConditionDescription;
                } else {
                    baseAllergyCondition.AllergyCode = contact.allergyCondition;
                }

                instance.ContactAllergyConditions.push(baseAllergyCondition);
            }

            if (contact.additionalAllergyConditions.length > 0) {
                contact.additionalAllergyConditions.map(additionalAllergyCondition => {
                    //customer added additional row but no value set
                    if (additionalAllergyCondition.ConditionCode !== undefined) {

                        var conditionDto = new CentralStationContactAllergyConditionDto();

                        if (this.constants.CustomAllergyConditions.some(condition => condition.ConditionCode === additionalAllergyCondition.ConditionCode)) {

                            var condition = this.constants.CustomAllergyConditions.filter(
                                condition => condition.ConditionCode === additionalAllergyCondition.ConditionCode);

                            conditionDto.AllergyCode = "0";
                            conditionDto.Comment = condition[0].ConditionDescription;
                        } else {
                            conditionDto.AllergyCode = additionalAllergyCondition.ConditionCode;
                        }

                        instance.ContactAllergyConditions.push(conditionDto);
                    }
                });
            }

            return instance;

        });

        return formattedMedicalContacts;
    }

    resetForm() {
        this.medicalContacts = [{
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            medicalCondition: null,
            additionalMedicalConditions: [],
            allergyCondition: null,
            additionalAllergyConditions: []
        }];

        this.acceptedTOS = false;
        this.initialContactAdded = false;
    }

    _showGenericSuccessAlert(message) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(message)
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert(messsage) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(messsage)
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {

    },
    controller: AccountMedicalInfoController
};