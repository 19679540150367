import template from './creditCardList.html';
import './creditCardList.scss';
import _, { result } from 'lodash';
import moment from 'moment';

import { addressValidationModal } from '../../cancellation/modals/addressValidationModal.component'

class CreditCardListController {
    /*@ngInject*/
    constructor(dataContext, creditCardService, $log, constants, alertService, $uibModal, $scope, $state, $q) {
        this.dataContext = dataContext;
        this.creditCardService = creditCardService;
        this.constants = constants;
        this.$uibModal = $uibModal;
        this.alertService = alertService;
        this.displayCardForm = false;
        this.hasCreditCard = false;
        this.shouldShowSavedCards = true;
        this.isFormInvalid = true;
        this.$log = $log;
        this.$scope = $scope;
        this.$state = $state;
        this.$q = $q;
        this.hasFailedPayment = false;
        this.isACA = false;

        this.ALERT_DIALOG_CREDIT_CARD_ERROR = 'credit-card-error';
        this.ALERT_DIALOG_BOOK_DISPATCH_ERROR = 'bookDispatchError';
        this.ALERT_DIALOG_BOOK_DISPATCH_UPCOMING_ERROR = 'upcomingDispatches';

        this.GUARD_DISPATCH_PAYMENT_ID = 40;

        this.$scope.$on(this.constants.events.processCreditCardPayment, (event, paymentType) => {
            this.submitCreditCardInfo(paymentType);
        });

        this.$scope.$on(this.constants.events.dispatch.processDispatchPayment, (event, taxReadyInstance) => {
            this.submitDispatchPayment(taxReadyInstance);
        });
    }

    $onInit() {
        this.$scope.$watch('creditCardForm.$invalid', (isInvalid) => {
            if (!isInvalid) {
                this.isFormInvalid = false;
            } else {
                this.isFormInvalid = true;
            }
        });

        this.creditCards = [
            { CreditCardType: 'visa', curClass: 'fpDisabled' },
            { CreditCardType: 'amex', curClass: 'fpDisabled' },
            { CreditCardType: 'mastercard', curClass: 'fpDisabled' },
            { CreditCardType: 'discover', curClass: 'fpDisabled' }
        ];

        this.ccExpMonths = [
            { value: 1, text: '01', disabled: false },
            { value: 2, text: '02', disabled: false },
            { value: 3, text: '03', disabled: false },
            { value: 4, text: '04', disabled: false },
            { value: 5, text: '05', disabled: false },
            { value: 6, text: '06', disabled: false },
            { value: 7, text: '07', disabled: false },
            { value: 8, text: '08', disabled: false },
            { value: 9, text: '09', disabled: false },
            { value: 10, text: '10', disabled: false },
            { value: 11, text: '11', disabled: false },
            { value: 12, text: '12', disabled: false }
        ];

        this.states = [];
        this.billingAddress = null;
        this.cards = [];

        this.isLoading = true;
        this.getStates().then(() => {
            this.getAccountOwner();
            this.getCreditCards().then(() => {
                return this.dataContext.account.getCustomerInformationV2()
                    .then((result) => {
                        this.customer = _.cloneDeep(result.CustomerInformation);
                        this.billingAddress = _.cloneDeep(result.BillingAddress);
                        this.billingAddress = this.fillCountryInfo(this.billingAddress);
                        this.firstName = this.customer.FirstName;
                        this.lastName = this.customer.LastName;

                        this.selectDefaultCard();
                        if (!this.customer.IsAccountCreditable) {
                            this.shouldShowSavedCards = true;
                            this.isACA = true;
                        }

                    });
            }).finally(() => {
                this.isLoading = false;
            });
        });
    }

    displayForm(display) {
        this.displayCardForm = display;
    }

    isMatchingCardType(cardType) {
        return this.creditCardService.getCardType(this.newCardNumber) === cardType;
    }

    getCreditCardYears() {
        let years = [];
        let currentDateYear = 2000 + ((new Date()).getYear() - 100);
        for (let x = 0; x <= 10; x++) {
            years.push(currentDateYear + x);
        }
        return years;
    }

    disableCCExpMonths() {
        _.forEach(this.ccExpMonths, (month) => {
            if (moment().year() === this.CcExpYear)
                month.disabled = month.value <= moment().month();
            else
                month.disabled = false;
        });
    }

    getStates() {
        return this.dataContext.states.getStates().then((result) => {
            this.states = _.concat(this.states, result.Data);
            return this.states;
        });
    }

    fillCountryInfo(address) {
        if (_.isString(address.State)) {
            address.State = _.find(this.states, (s) => {
                return s.State === address.State;
            });
        }

        if (_.isNil(address.Country)) {
            address.Country = address.State.Country;
        }

        return address;
    }

    getCreditCards() {
        return this.dataContext.customerBillingInfo.getCustomerBillingInfo().then((result) => {
            let customerBillingInfo = _.cloneDeep(result.Data);
            this.cards = _.filter(customerBillingInfo,
                cbi => {
                    return cbi.IsCredit;
                });
        }).finally(() => {
            // If there is no card, only show new credit card info details
            if (!_.isEmpty(this.cards)) {
                this.hasCreditCard = true;
            } else {
                this.displayCardForm = true;
            }
        });
    }

    getAccountOwner() {
        return this.dataContext.account.getCustomerInfo().then((result) => {
            let customerInfo = _.cloneDeep(result.CustomerInformation);
            if (!customerInfo.IsAccountCreditable) {
                this.shouldShowSavedCards = false;
            }
        })
    }

    selectDefaultCard() {
        this.selectCard(_.find(this.cards, { IsDefaultPayment: true }));
    }

    selectCard(card) {
        this.displayCardForm = false;
        this.selectedCard = _.cloneDeep(card);
        this.resetFields();

        if (_.isNil(this.selectedCard)) {
            this.displayCardForm = true;
        }
    }

    toShopAddress(fpAddress, addressType) {
        return {
            city: fpAddress.City,
            firstName: this.customer.FirstName,
            lastName: this.customer.LastName,
            countryName: fpAddress.Country,
            line1: fpAddress.Address1,
            line2: fpAddress.Address2,
            postalCode: fpAddress.PostalCode,
            regionId: fpAddress.State.State,
            type: addressType
        };
    }

    resetFields() {
        // Clear added card info
        this.newCardNumber = '';
        this.CcExpMonth = '';
        this.CcExpYear = '';
    }

    doesDuplicateExists(newcbi) {
        return this.getCreditCards()
            .then(() => {
                this.selectDefaultCard();
                let cardCheck = _.some(this.cards, (card) => {
                    return (newcbi.CCExp === card.CCExp && newcbi.LastFour === card.LastFour);
                });
                return cardCheck;
            });
    }

    validateAddress(address) {
        let formattedAddress = {
            Address1: address.Address1,
            Address2: address.Address2,
            City: address.City,
            PostalCode: address.PostalCode,
            State: address.State
        };

        return this.dataContext.address.validateAddress(formattedAddress)
            .then((response) => {
                if (!this.dataContext.address.isValidResponse(response)) {
                    let returnedAddress = {
                        Address1: address.Address1,
                        Address2: address.Address2,
                        City: response.City,
                        PostalCode: response.Zip,
                        State: response.StateAbrev
                    };

                    let modalInstance = this.$uibModal.open({
                        component: addressValidationModal.selector,
                        size: 'lg',
                        resolve: {
                            address: () => { return address; },
                            returnedAddress: () => { return returnedAddress; }
                        }
                    });

                    return modalInstance.result.then((result) => {
                        if (_.isNil(result)) {
                            this.isEditMode = false;
                            return false;
                        } else {
                            if (address.State !== result.State || address.City !== result.City) {
                                address.State = result.State;
                                address.City = result.City;
                            }
                            return true;
                        }
                    });
                } else {
                    return true;
                }
            });
    }

    submitCreditCardInfo(paymentType) {
        if (!this.displayCardForm && !this.isACA) {
            // Process payment with selected card
            this.submitPayment(this.selectedCard, paymentType);
        }
        else {
            // Process payment with new card
            this.newBillingAddress = this.toShopAddress(this.billingAddress, 'billing');

            let billingInfo = {
                IsCredit: true,
                CCExp: this.CcExpMonth.text + '/' + this.CcExpYear.toString().substring(2, this.CcExpYear.toString().length),
                AccountNumber: this.newCardNumber,
                IsActive: false,
                IsDeleted: false,
                LastFour: this.newCardNumber.toString().substring(_.size(this.newCardNumber.toString()) - 4, _.size(this.newCardNumber.toString())),
                Line1: this.newBillingAddress.line1,
                Line2: this.newBillingAddress.line2,
                City: this.newBillingAddress.city,
                State: this.newBillingAddress.regionId,
                PostalCode: this.newBillingAddress.postalCode,
                FirstName: this.firstName,
                LastName: this.lastName,
                CustomerBillingInfoID: 0,
                UserCustomerID: null,
                ContactID: 0,
                RoutingNumber: null,
                VerificationNumber: null,
                DateCreated: null,
                CreatedByID: 0,
                PaymentDay: null,
                CreditCardType: null,
                CheckType: null,
                CheckAccountType: null,
                LeadID: null,
                AccountId: 0,
                NickName: '',
                AddressID: 0,
                IsDefaultPayment: false,
                IsDefaultRmr: false,
                Attention: null
            };

            let billingAddress = {
                Address1: _.get(billingInfo, 'Line1', ''),
                Address2: _.get(billingInfo, 'Line2', ''),
                Attention: _.get(billingInfo, 'Attention', ''),
                City: _.get(billingInfo, 'City', ''),
                Phone: _.get(billingInfo, 'Phone', ''),
                PostalCode: _.get(billingInfo, 'PostalCode', ''),
                State: _.get(billingInfo, 'State', '')
            };

            if (this.isACA) {
                return this.validateAddress(billingAddress)
                    .then((result) => {
                        if (!result) {
                            return true;
                        }
                        this.isLoading = true;
                        let newCBI = this.dataContext.customerBillingInfo.saveCustomerBillingInfo(billingInfo)
                            .then((result) => {
                                if (result.Success) {
                                    let chargingRequest = {
                                        CustomerInformation: {
                                            CustomerInformation: {
                                                Phone: this.customer.Phone,
                                                AltPhone: this.customer.AltPhone,
                                                Email: this.customer.Email,
                                                IsResidential: this.customer.IsResidential,
                                                Company: this.customer.Company,
                                                AltEmail: this.customer.AltEmail
                                            }
                                        },
                                        PaymentInfo: {
                                            CardCcExp: billingInfo.CCExp,
                                            CardCcNumb: this.newCardNumber,
                                            AccountName: billingInfo.FirstName + ' ' + billingInfo.LastName,
                                            CardHolderFirstName: billingInfo.FirstName,
                                            CardHolderLastName: billingInfo.LastName,
                                            AccountNumber: this.newCardNumber,
                                            InitialPaymentType: 1
                                        },
                                        OtherAddress: {
                                            Address1: billingInfo.Line1,
                                            Address2: billingInfo.Line2,
                                            State: billingInfo.State,
                                            City: billingInfo.City,
                                            PostalCode: billingInfo.PostalCode
                                        },
                                        AddressType: 'Other',
                                        SaveCustomerBillingInfo: false,
                                        CustomerBillingInfoID: newCBI.CustomerBillingInfoID,
                                        ChargeTypes: [paymentType],
                                        GrandTotal: paymentType.Amount,
                                        IsAcaCustomer: this.isACA
                                    }
                                    this.submitACAPayment(newCBI, chargingRequest, paymentType);
                                }
                                else {
                                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Error processing payment.');
                                }
                            })
                            .catch(error => {
                                this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Error processing payment.');
                                this.$log.error('Error processing payment', error);
                                return this.$q.reject(error);
                            }).finally(() => {
                                this.isLoading = false;
                            });
                    });
            }
            else {
                return this.validateAddress(billingAddress)
                    .then((result) => {
                        if (!result) {
                            return false;
                        }

                        this.doesDuplicateExists(billingInfo).then((result) => {
                            if (result) {
                                this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Duplicate card exists.');
                                this.$log.error('Error adding credit card.');
                            }
                            else {
                                this.isLoading = true;
                                return this.dataContext.customerBillingInfo.saveCustomerBillingInfo(billingInfo)
                                    .then((result) => {
                                        if (result.Success) {
                                            this.submitPayment(result.Data[0], paymentType);
                                        }
                                        else {
                                            this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Error processing payment.');
                                        }
                                    })
                                    .catch(error => {
                                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Error processing payment.');
                                        this.$log.error('Error processing payment', error);
                                        return this.$q.reject(error);
                                    }).finally(() => {
                                        this.isLoading = false;
                                    });
                            }
                        });
                    });
            }
        }
    }

    validateCCExp(cbi) {
        let ccExpMonth = Number(cbi.CCExp.substring(0, 2));
        let ccExpYear = Number(cbi.CCExp.substring(3, 5));
        let year = Number(moment().format("YY"));
        let month = Number(moment().format("MM"));

        let showError = false;

        /*is the expiration between now +/- 5 years.
         this is a FRINGE scenario but just covering for it
         how many customers will ever try to cancel their account
         with a card that has an exp year of more than 5 years ago.
         not many, but not impossible. and card issuers are usually 
         5 years max validity so this is just our safe case
         */
        const ccExpWithinVailidtyRange = ((ccExpYear >= (year - 5)) && (ccExpYear <= (year + 5)));

        if (ccExpWithinVailidtyRange) {
            if (year > ccExpYear || year === ccExpYear && month >= ccExpMonth) {
                showError = true;
            }
        } else {
            showError = true;
        }

        return showError;
    }

    async submitPayment(cbi, paymentType) {

        let paymentInfo = {
            AccountID: cbi.AccountId,
            CustomerBillingInfoID: cbi.CustomerBillingInfoID,
            UserCustomerID: cbi.UserCustomerID,
            Amount: this.grandTotal,
            PaymentType: paymentType[0],
            TaxInformation: {
                TaxAmount: this.taxInformation.TaxAmount,
                TaxTransactionCode: this.taxInformation.TaxTransactionCodeMonitoring,
                TaxTransactionID: this.taxInformation.TaxTransactionIDMonitoring,
                TaxDocumentStatus: this.taxInformation.TaxDocumentStatusMonitoring
            }
        };

        let paymentInfoMonitoring = {
            AccountID: cbi.AccountId,
            CustomerBillingInfoID: cbi.CustomerBillingInfoID,
            UserCustomerID: cbi.UserCustomerID,
            Amount: this.monitoringFee,
            PaymentType: paymentType[0],
            TaxInformation: {
                TaxAmount: this.taxInformation.TaxAmount,
                TaxTransactionCode: this.taxInformation.TaxTransactionCodeMonitoring,
                TaxTransactionID: this.taxInformation.TaxTransactionIDMonitoring,
                TaxDocumentStatus: this.taxInformation.TaxDocumentStatusMonitoring
            }
        };

        let paymentInfoTermination = {
            AccountID: cbi.AccountId,
            CustomerBillingInfoID: cbi.CustomerBillingInfoID,
            UserCustomerID: cbi.UserCustomerID,
            Amount: this.terminationFee,
            PaymentType: paymentType[1],
            TaxInformation: {
                TaxAmount: this.taxInformation.TaxAmount,
                TaxTransactionCode: this.taxInformation.TaxTransactionCodeTermination,
                TaxTransactionID: this.taxInformation.TaxTransactionIDTermination,
                TaxDocumentStatus: this.taxInformation.TaxDocumentStatusTermination
            }
        };

        let paymentInfoPastDueBalance = {
            AccountID: cbi.AccountId,
            CustomerBillingInfoID: cbi.CustomerBillingInfoID,
            UserCustomerID: cbi.UserCustomerID,
            Amount: this.pastDueBalance,
            PaymentType: paymentType[2]
        };

        this.isLoading = true;

        if (this.validateCCExp(cbi)) {
            this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                'Credit Card expiration is not valid, please update your payment method.');
            this.isLoading = false;
            return;
        }

        if (this.isRftp && paymentInfoPastDueBalance.Amount > 0) {
            await this.dataContext.payment.makePayment(paymentInfoPastDueBalance)
                .then((result) => {
                    this.$scope.$emit(this.constants.events.completeCancellation, result);
                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                        'Error processing Past Due payment.');
                    this.$log.error('Error processing Past Due Balance payment', error);
                    this.isLoading = false;
                    return this.$q.reject(error);
                });
        }

        if (this.isInTerm) {
            await this.dataContext.payment.makePayment(paymentInfoMonitoring)
                .then((result) => {
                    if (paymentInfoTermination.Amount <= 0 && paymentInfoPastDueBalance.Amount <= 0) {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    }
                    this.$log.debug(
                        'Processed Monitoring Fee. Moving on to Contract Termination and Past Due Balance if applicable');
                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                        'Error processing Monitoring Fee payment.');
                    this.$log.error('Error processing Monitoring Fee payment', error);
                    this.isLoading = false;
                    return this.$q.reject(error);
                });

            if (paymentInfoTermination.Amount > 0 && paymentInfoPastDueBalance.Amount <= 0) {
                return await this.dataContext.payment.makePayment(paymentInfoTermination)
                    .then((result) => {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Contract Termination payment.');
                        this.$log.error('Error processing Contract Termination Fee payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });

            } else if (paymentInfoTermination.Amount <= 0 && paymentInfoPastDueBalance.Amount > 0) {
                return await this.dataContext.payment.makePayment(paymentInfoPastDueBalance)
                    .then((result) => {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing past due payment.');
                        this.$log.error('Error processing Past Due Balance payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });
            }
            // If the customer has both an ETF and a past due fees
            else {
                await this.dataContext.payment.makePayment(paymentInfoTermination)
                    .then((result) => {
                        this.$log.debug(
                            'Processed Monitoring Fee and Contract Termination Fee. Moving on to Past Due Balance');
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Contract Termination payment.');
                        this.$log.error('Error processing Contract Termination Fee payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });

                return await this.dataContext.payment.makePayment(paymentInfoPastDueBalance)
                    .then((result) => {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Past Due payment.');
                        this.$log.error('Error processing Past Due Fee payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });
            }

        }

        if (!this.isRftp && !this.isInTerm) {
            await this.dataContext.payment.makePayment(paymentInfoMonitoring)
                .then((result) => {
                    if (paymentInfoPastDueBalance.Amount <= 0) {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    }
                    this.$log.debug(
                        'Processed Monitoring Fee. Moving on to Contract Termination and Past Due Balance if applicable');
                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Error processing Monitoring Fee payment.');
                    this.$log.error('Error processing Monitoring Fee payment', error);
                    this.isLoading = false;
                    return this.$q.reject(error);
                });

            if (paymentInfoPastDueBalance.Amount > 0) {
                return await this.dataContext.payment.makePayment(paymentInfoPastDueBalance)
                    .then((result) => {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Past Due Balance payment.');
                        this.$log.error('Error processing Past Due Balance payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });
            }
        }
    }

    async submitACAPayment(cbi, chargingRequest, paymentType) {

        let chargingRequestMonitoringInfo = {
            CustomerInformation: {
                CustomerInformation: {
                    Phone: this.customer.Phone,
                    AltPhone: this.customer.AltPhone,
                    Email: this.customer.Email,
                    IsResidential: this.customer.IsResidential,
                    Company: this.customer.Company,
                    AltEmail: this.customer.AltEmail
                }
            },
            PaymentInfo: {
                CardHolderFirstName: chargingRequest.PaymentInfo.CardHolderFirstName,
                CardHolderLastName: chargingRequest.PaymentInfo.CardHolderLastName,
                CardCcExp: chargingRequest.PaymentInfo.CardCcExp,
                CardCcNumb: chargingRequest.PaymentInfo.CardCcNumb,
            },
            OtherAddress: {
                Address1: chargingRequest.OtherAddress.Address1,
                Address2: chargingRequest.OtherAddress.Address2,
                State: chargingRequest.OtherAddress.State,
                City: chargingRequest.OtherAddress.City,
                PostalCode: chargingRequest.OtherAddress.PostalCode
            },
            TaxTransactionCode: this.taxInformation.TaxTransactionCodeMonitoring,
            TaxTransactionID: this.taxInformation.TaxTransactionIDMonitoring,
            GrandTotal: this.monitoringFee,
            AddressType: chargingRequest.AddressType,
            CustomerBillingInfoID: chargingRequest.CustomerBillingInfoID,
            SaveCustomerBillingInfo: chargingRequest.SaveCustomerBillingInfo,
            TaxAmount: this.taxInformation.MonitoringFeeTax,
            ChargeTypes: [{ PaymentTypeId: 8, Name: 'Monitoring Prepayment', Amount: this.monitoringFee }],
            IsAcaCustomer: chargingRequest.IsAcaCustomer
        }

        let chargingRequestTerminationInfo = {
            CustomerInformation: {
                CustomerInformation: {
                    Phone: this.customer.Phone,
                    AltPhone: this.customer.AltPhone,
                    Email: this.customer.Email,
                    IsResidential: this.customer.IsResidential,
                    Company: this.customer.Company,
                    AltEmail: this.customer.AltEmail
                }
            },
            PaymentInfo: {
                CardHolderFirstName: chargingRequest.PaymentInfo.CardHolderFirstName,
                CardHolderLastName: chargingRequest.PaymentInfo.CardHolderLastName,
                CardCcExp: chargingRequest.PaymentInfo.CardCcExp,
                CardCcNumb: chargingRequest.PaymentInfo.CardCcNumb,
            },
            OtherAddress: {
                Address1: chargingRequest.OtherAddress.Address1,
                Address2: chargingRequest.OtherAddress.Address2,
                State: chargingRequest.OtherAddress.State,
                City: chargingRequest.OtherAddress.City,
                PostalCode: chargingRequest.OtherAddress.PostalCode
            },
            TaxTransactionCode: this.taxInformation.TaxTransactionCodeTermination,
            TaxTransactionID: this.taxInformation.TaxTransactionIDTermination,
            GrandTotal: this.terminationFee,
            AddressType: chargingRequest.AddressType,
            CustomerBillingInfoID: chargingRequest.CustomerBillingInfoID,
            SaveCustomerBillingInfo: chargingRequest.SaveCustomerBillingInfo,
            TaxAmount: this.taxInformation.TerminationFeeTax,
            ChargeTypes: [
                { PaymentTypeId: 3, Name: 'Contract Termination', Amount: this.terminationFee }],
            IsAcaCustomer: chargingRequest.IsAcaCustomer
        }

        let chargingRequestPastDueBalanceInfo = {
            CustomerInformation: {
                CustomerInformation: {
                    Phone: this.customer.Phone,
                    AltPhone: this.customer.AltPhone,
                    Email: this.customer.Email,
                    IsResidential: this.customer.IsResidential,
                    Company: this.customer.Company,
                    AltEmail: this.customer.AltEmail
                }
            },
            PaymentInfo: {
                CardHolderFirstName: chargingRequest.PaymentInfo.CardHolderFirstName,
                CardHolderLastName: chargingRequest.PaymentInfo.CardHolderLastName,
                CardCcExp: chargingRequest.PaymentInfo.CardCcExp,
                CardCcNumb: chargingRequest.PaymentInfo.CardCcNumb,
            },
            OtherAddress: {
                Address1: chargingRequest.OtherAddress.Address1,
                Address2: chargingRequest.OtherAddress.Address2,
                State: chargingRequest.OtherAddress.State,
                City: chargingRequest.OtherAddress.City,
                PostalCode: chargingRequest.OtherAddress.PostalCode
            },
            GrandTotal: this.pastDueBalance,
            AddressType: chargingRequest.AddressType,
            CustomerBillingInfoID: chargingRequest.CustomerBillingInfoID,
            SaveCustomerBillingInfo: chargingRequest.SaveCustomerBillingInfo,
            ChargeTypes: [{ PaymentTypeId: 1, Name: 'Past Due Payment', Amount: this.pastDueBalance }],
            IsAcaCustomer: chargingRequest.IsAcaCustomer
        }

        let notificationData = {
            PastDueBalance: this.pastDueBalance,
            ContractPayout: this.terminationFee,
            FinalMonthPayment: this.monitoringFee
        }

        this.isLoading = true;

        if (this.isRftp && this.pastDueBalance > 0) {
            await this.dataContext.payment.savePaymentInfo(chargingRequestPastDueBalanceInfo)
                .then((result) => {
                    this.$scope.$emit(this.constants.events.completeCancellation, result);
                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                        'Error processing Past Due payment.');
                    this.$log.error('Error processing Past Due Balance payment', error);
                    this.isLoading = false;
                    return this.$q.reject(error);
                });
        }

        if (this.isInTerm) {
            await this.dataContext.payment.savePaymentInfo(chargingRequestMonitoringInfo)
                .then((result) => {
                    if (chargingRequestTerminationInfo.ChargeTypes.Amount <= 0 && this.pastDueBalance <= 0) {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    }
                    this.$log.debug(
                        'Processed Monitoring Fee. Moving on to Contract Termination and Past Due Balance if applicable');
                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                        'Error processing Monitoring Fee payment.');
                    this.$log.error('Error processing Monitoring Fee payment', error);
                    this.isLoading = false;
                    return this.$q.reject(error);
                });

            if (this.terminationFee > 0 && this.pastDueBalance <= 0) {
                return await this.dataContext.payment.savePaymentInfo(chargingRequestTerminationInfo)
                    .then((result) => {
                        this.dataContext.payment.sendAcaNotification(notificationData);
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Contract Termination payment.');
                        this.$log.error('Error processing Contract Termination Fee payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });

            } else if (this.terminationFee <= 0 && this.pastDueBalance > 0) {
                return await this.dataContext.payment.savePaymentInfo(chargingRequestPastDueBalanceInfo)
                    .then((result) => {
                        this.dataContext.payment.sendAcaNotification(notificationData);
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing past due payment.');
                        this.$log.error('Error processing Past Due Balance payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });
            }
            // If the customer has both an ETF and a past due fees
            else {
                await this.dataContext.payment.savePaymentInfo(chargingRequestTerminationInfo)
                    .then((result) => {
                        this.$log.debug(
                            'Processed Monitoring Fee and Contract Termination Fee. Moving on to Past Due Balance');
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Contract Termination payment.');
                        this.$log.error('Error processing Contract Termination Fee payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });

                return await this.dataContext.payment.savePaymentInfo(chargingRequestPastDueBalanceInfo)
                    .then((result) => {
                        this.dataContext.payment.sendAcaNotification(notificationData);
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Past Due payment.');
                        this.$log.error('Error processing Past Due Fee payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });
            }

        }

        if (!this.isRftp && !this.isInTerm) {
            await this.dataContext.payment.savePaymentInfo(chargingRequestMonitoringInfo)
                .then((result) => {
                    if (chargingRequestPastDueBalanceInfo.ChargeTypes[0].Amount <= 0) {
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    }
                    this.$log.debug(
                        'Processed Monitoring Fee. Moving on to Contract Termination and Past Due Balance if applicable');
                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR, 'Error processing Monitoring Fee payment.');
                    this.$log.error('Error processing Monitoring Fee payment', error);
                    this.isLoading = false;
                    return this.$q.reject(error);
                });

            if (chargingRequestPastDueBalanceInfo.ChargeTypes[0].Amount > 0) {
                return await this.dataContext.payment.savePaymentInfo(chargingRequestPastDueBalanceInfo)
                    .then((result) => {
                        this.dataContext.payment.sendAcaNotification(notificationData);
                        this.$scope.$emit(this.constants.events.completeCancellation, result);
                    })
                    .catch(error => {
                        this.showErrorAlert(this.ALERT_DIALOG_CREDIT_CARD_ERROR,
                            'Error processing Past Due Balance payment.');
                        this.$log.error('Error processing Past Due Balance payment', error);
                        this.isLoading = false;
                        this.hasFailedPayment = true;
                        if (this.hasFailedPayment) {
                            try {
                                this.isLoading = true;
                                this.dataContext.retention.expireSessionOnPaymentFailure(this.retentionEpisodeId);
                            } catch (error) {
                                this.$log.error(error);
                            }
                            this.isLoading = false;
                            this.$state.go('failedPayment');
                        }
                        return this.$q.reject(error);
                    });
            }
        }
    }

    async submitDispatchPayment(taxReadyInstances) {
        this.isLoading = true;

        //a final check for appropriate cost
        if (taxReadyInstances.length === 0) {
            this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_ERROR,
                "We ran into an issue when trying to process your dispatches. Please try again.");
            this.isLoading = false;
            return;
        }

        //create the balance to be charge
        var dispatchBalance = {
            AccountID: this.selectedCard.AccountId,
            Amount: taxReadyInstances.DispatchesTax.TotalAmountWithTax,
            CustomerBillingInfoID: this.selectedCard.CustomerBillingInfoID,
            UserCustomerID: this.selectedCard.UserCustomerID,
            PaymentType: this.GUARD_DISPATCH_PAYMENT_ID,
            TaxInformation: {
                TaxAmount: taxReadyInstances.DispatchesTax.TaxAmount,
                TaxTransactionCode: taxReadyInstances.DispatchesTax.TaxTransactionCode,
                TaxTransactionID: taxReadyInstances.DispatchesTax.TaxTransactionID,
                TaxDocumentStatus: taxReadyInstances.DispatchesTax.Status !== null && taxReadyInstances.DispatchesTax.Status !== undefined ? taxReadyInstances.DispatchesTax.Status : 9000
            }
        };

        //use selected CBI/form card
        if (!this.displayCardForm) {

            //validate CC expiry 
            if (this.validateCCExp(this.selectedCard)) {
                this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_ERROR, "Credit Card expiration is not valid, please update your payment method and try again.");
                this.$scope.$emit(this.constants.events.dispatch.failedPaidDispatch, false);
                return;
            }

            if (dispatchBalance.Amount == null || dispatchBalance.TaxInformation.TaxAmount == null) {
                this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_ERROR, "We ran into an issue processing your dispatches. Please try again.");
                this.$scope.$emit(this.constants.events.dispatch.failedPaidDispatch, false);
                return;
            }

            this.dataContext.payment.makePayment(dispatchBalance)
                .then((customerPaymentResult) => {
                    if ((customerPaymentResult != null || customerPaymentResult != undefined) && customerPaymentResult.Success) {

                        taxReadyInstances.Dispatches.forEach(function (instance) {
                            instance.CustomerPaymentID = customerPaymentResult.CustomerPaymentID;
                        });

                        this.dataContext.dispatch.createUserDispatches(taxReadyInstances).then(() => {
                            this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, true, taxReadyInstances);
                        }).catch(error => {
                            this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_UPCOMING_ERROR, 'An error occurred when attempting to book your dispatches. Please try again');
                            this.$log.error('An error occurred when attempting to book your dispatches. Please try again', error);
                            this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, false);
                            return this.$q.reject(error);
                        });;

                    } else {
                        this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_UPCOMING_ERROR, 'An error occurred when attempting to book your dispatches. Please try again');
                        this.$log.error('An error occurred when attempting to book your dispatches. Please try again', error);
                        this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, false);
                        return this.$q.reject(error);
                    }

                })
                .catch(error => {
                    this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_UPCOMING_ERROR, 'An error occurred when attempting to book your dispatches. Please try again');
                    this.$log.error('An error occurred when attempting to book your dispatches. Please try again', error);
                    this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, false);
                    return this.$q.reject(error);
                });
        }

        // Process payment with new card
        else {

            this.newBillingAddress = this.toShopAddress(this.billingAddress, 'billing');

            let billingInfo = {
                IsCredit: true,
                CCExp: this.CcExpMonth.text + '/' + this.CcExpYear.toString().substring(2, this.CcExpYear.toString().length),
                AccountNumber: this.newCardNumber,
                IsActive: false,
                IsDeleted: false,
                LastFour: this.newCardNumber.toString().substring(_.size(this.newCardNumber.toString()) - 4, _.size(this.newCardNumber.toString())),
                Line1: this.newBillingAddress.line1,
                Line2: this.newBillingAddress.line2,
                City: this.newBillingAddress.city,
                State: this.newBillingAddress.regionId,
                PostalCode: this.newBillingAddress.postalCode,
                FirstName: this.firstName,
                LastName: this.lastName,
                CustomerBillingInfoID: 0,
                UserCustomerID: null,
                ContactID: 0,
                RoutingNumber: null,
                VerificationNumber: null,
                DateCreated: null,
                CreatedByID: 0,
                PaymentDay: null,
                CreditCardType: null,
                CheckType: null,
                CheckAccountType: null,
                LeadID: null,
                AccountId: 0,
                NickName: '',
                AddressID: 0,
                IsDefaultPayment: false,
                IsDefaultRmr: false,
                Attention: null
            };

            let billingAddress = {
                Address1: _.get(billingInfo, 'Line1', ''),
                Address2: _.get(billingInfo, 'Line2', ''),
                Attention: _.get(billingInfo, 'Attention', ''),
                City: _.get(billingInfo, 'City', ''),
                Phone: _.get(billingInfo, 'Phone', ''),
                PostalCode: _.get(billingInfo, 'PostalCode', ''),
                State: _.get(billingInfo, 'State', '')
            };

            this.validateAddress(billingAddress)
                .then((result) => {
                    if (!result) {
                        return false;
                    }

                    this.doesDuplicateExists(billingInfo).then((result) => {
                        if (result) {
                            this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_ERROR, 'Duplicate card exists.');
                            this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, false);
                        }
                        else {
                            this.isLoading = true;
                            return this.dataContext.customerBillingInfo.saveCustomerBillingInfo(billingInfo)
                                .then((result) => {
                                    if (result.Success) {

                                        //this.selectedCard is not going to be the new card we added, need to update
                                        //dispatchBalance prior to charging
                                        dispatchBalance.CustomerBillingInfoID = result.Data[0].CustomerBillingInfoID;

                                        this.dataContext.payment.makePayment(dispatchBalance)
                                            .then((customerPaymentResult) => {
                                                if ((customerPaymentResult != null || customerPaymentResult != undefined) && customerPaymentResult.Success) {

                                                    taxReadyInstances.Dispatches.forEach(function (instance) {
                                                        instance.CustomerPaymentID = customerPaymentResult.CustomerPaymentID;
                                                    });

                                                    this.dataContext.dispatch.createUserDispatches(taxReadyInstances).then(() => {
                                                        this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, true, taxReadyInstances);
                                                    });

                                                } else {
                                                    this.showErrorAlert("upcomingDispatches", 'An error occurred when attempting to book your dispatches. Please try again');
                                                    this.$log.error('An error occurred when attempting to book your dispatches. Please try again', error);
                                                    this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, false);
                                                    return this.$q.reject(error);
                                                }

                                            })
                                            .catch(error => {
                                                this.showErrorAlert("upcomingDispatches", 'An error occurred when attempting to book your dispatches. Please try again');
                                                this.$log.error('An error occurred when attempting to book your dispatches. Please try again', error);
                                                this.$scope.$emit(this.constants.events.dispatch.finalizePaidDispatch, false);
                                                return this.$q.reject(error);
                                            });
                                    }
                                    else {
                                        this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_ERROR, 'Error processing payment.');
                                    }
                                })
                                .catch(error => {
                                    this.showErrorAlert(this.ALERT_DIALOG_BOOK_DISPATCH_ERROR, 'Error processing payment.');
                                    this.$log.error('Error processing payment', error);
                                    return this.$q.reject(error);
                                }).finally(() => {
                                    this.isLoading = false;
                                });
                        }
                    });
                });
        }
    }

    showErrorAlert(dialog, message) {
        this.alertService
            .get(dialog)
            .setMessage(message)
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        hasCreditCard: '=',
        isLoading: '=',
        isFormInvalid: '=',
        grandTotal: '<',
        monitoringFee: '<',
        terminationFee: '<',
        pastDueBalance: '<',
        isRftp: '<',
        isInTerm: '<',
        taxInformation: '<',
        shouldShowSavedCards: '<'
    },
    controller: CreditCardListController
};