import template from './editAutopayModal.html';
import _ from 'lodash';
import moment from 'moment';


class EditAutopayModalController {
    /* @ngInject */
    constructor(dataContext, $log, constants) {
        this.data = this.config.data;
        this.$log = $log;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.dataContext = dataContext;
        this.constants = constants;
        this.onLoad();
    }

    onLoad() {
        this.getACHOffer();
        this.data.selectedCBI.CustomerBillingInfoID === 0 ? this.newCBI = true : this.newCBI = false;
        if (this.data.selectedCBI.IsCredit) {
            this.data.selectedCBI.CreditCardType = this.data.selectedCBI.CreditCardType.substring(0, 1).toUpperCase() + this.data.selectedCBI.CreditCardType.substring(1, this.data.selectedCBI.CreditCardType.length);
        }
        this.selectedCBI = this.data.selectedCBI;
        this.paymentInfo = this.data.paymentInfo;
        this.getCustomerBillingInfo();
    }

    getCustomerBillingInfo() {
        this.dataContext.customerBillingInfo.getCustomerBillingInfo().then((result) => {
            this.customerBillingInfo = result.Data;
        });
    }

    saveCBI() {
        this.isLoading = true;
        if (this.selectedCBI.AccountId === 0) {
            this.selectedCBI = _.find(this.customerBillingInfo, { 'CustomerBillingInfoID': this.selectedCBI.CustomerBillingInfoID }) || _.last(this.customerBillingInfo);
        }
        if (this.selectedCBI.IsCredit) {
            this.selectedCBI.AccountNumber = this.selectedCBI.CardCcNumb || '********' + this.selectedCBI.LastFour;
        }
        if (!this.selectedCBI.IsCredit) {
            this.selectedCBI.CheckAccountType = 'C';
            this.selectedCBI.CheckType = 'P';
        }

        this.selectedCBI.IsDefaultRmr = true;

        this.dataContext.customerBillingInfo.updateCustomerBillingInfo(this.selectedCBI)
            .then((result) => {
                this.$close(result);
            })
            .catch((error) => {
                var result = { Success: false };
                this.$log.error('Failed to edit customerBillingInfo', error);
                this.$close(result);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getACHOffer() {
        this.dataContext.customerBillingInfo
            .getACHOfferAsync(this.data.selectedCBI.AccountId, !this.data.selectedCBI.IsCredit).then((result) => {
                if (_.isNil(result) || result.Action === 0) {
                    this.offerActionId = result.Action;
                    return;
                }
                this.offerActionId = result.Action;
                this.effectiveDate = moment(result.Amendment.NewContractSignDate).format('MM/DD/YYYY');
                this.rmrDiscount = (result.Amendment.OldRMR - result.Amendment.NewRMR).toFixed(2);
            });
    }

    close() {
        this.$dismiss();
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        config: '='
    },
    controller: EditAutopayModalController
};