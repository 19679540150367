import _ from 'lodash';

export default class {
    /*@ngInject*/
    constructor($state) {
        this.$state = $state;
        this.currentBreadcrumbs = [];
        this.allBreadcrumbs = {
            movingPortal: [
                { order: 1, state: 'moving', name: 'Move Portal', path: 'pre' },
                { order: 2, state: 'moving.form', name: "Movers Kit Delivery", path: 'pre' },
                { order: 3, state: 'moving.confirmation', name: 'Confirmation', path: 'pre' },

                { order: 1, state: 'moving', name: 'Move Portal', path: 'post' },
                { order: 2, state: 'moving.form', name: 'Update Move Details', path: 'post' },
                { order: 3, state: 'moving.confirmation', name: 'Confirmation', path: 'post' }
            ]
        }
    }

    hasBreadcrumbs(module) {
        return _.some(this.allBreadcrumbs[module], { 'state': this.$state.current.name });
    }

    getCurrentBreadcrumbs(module, path) {
        this.currentBreadcrumbs = [];
        let numberOfBreadcrumbs = _.find(this.allBreadcrumbs[module], { 'state': this.$state.current.name, 'path': path }).order;
        let breadcrumbs = _.filter(this.allBreadcrumbs[module], { 'path': path });
        _.forEach(breadcrumbs, crumb => {
            if (crumb.order <= numberOfBreadcrumbs && !_.includes(this.currentBreadcrumbs, crumb)) {
                this.currentBreadcrumbs.push(crumb);
            }
        });
        return this.currentBreadcrumbs;
    }
}
