import _ from 'lodash';
import './notifications.scss';
import template from './notifications.html';
import AbstractSectionController from '../AbstractSectionController';

const disclaimerModalTemplate = '<fp-sms-disclaimer-modal data-on-close="$close(result)" data-on-dismiss="$dismiss()"></fp-sms-disclaimer-modal>';

class NotificationController extends AbstractSectionController {
    /*@ngInject*/
    constructor($log, $q, $timeout, $uibModal, alertService, userService, monitoringPlansService, constants, dataContext) {
        super();

        this.$log = $log;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;

        this.ALERT_DIALOG = 'preferences:notifications';

        this.hasVideoPlan = monitoringPlansService.isSAVPlan(userService.current.ServicePlanInfo);
    }
    
    $onChanges() {
        // would need to modify this setting on the backend properly in the event the altphone is nulled out in WB or elsewhere,
        // in the meantime, have to live with fixing it here.
        if (this.hasVideoPlan === true) {
            this.$timeout(() => {
                this.onSelectPrimaryPhoneNumber();
            });
        }
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.notifications);
    }

    onSaveCore() {
        this.isLoading = true;
        return this.dataContext.settings.save(this.notifications)
            .then(() => {
                this._showGenericSuccessAlert();
            })
            .catch((error) => {
                this.$log.error('Error updating notification preferences.', error);
                this._showGenericErrorAlert();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.notifications = this.pristine;
    } 

    isSmsEnabled() {
        if (_.isNil(this.notifications) || _.isNil(this.notifications.Items)) {
            return false;
        }

        return _.some(this.notifications.Items, item => item.Sms);
    }

    onSelectPrimaryPhoneNumber() {
        this.notifications.UsePrimaryPhoneNumber = true;
    }

    onSelectAlternatePhoneNumber() {
        this.notifications.UsePrimaryPhoneNumber = false;
    }

    onEmailSelected(item) {
        item.Email = !item.Email;
    }

    onSmsSelected(item) {
        // Disabling sms messaging. No need to show the disclaimer.
        if (item.Sms) {
            item.Sms = false;
            return;
        }

        // Enabling sms message but not for the fist time. No need to show the disclaimer.
        if (this.isSmsEnabled()) {
            item.Sms = true;
            return;
        }

        // Enabling sms messaging for the first time. Show the disclaimer.
        const modal = this.$uibModal.open({
            template: disclaimerModalTemplate
        });

        modal.result.then((result) => {
            if (result) {
                item.Sms = true;
            }
        });
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your notification subscriptions been updated.')
            .setType('success')
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your notification subscriptions. Please try again.')
            .setType('danger')
            .open();
    }
}

export default {
    template: template,
    bindings: {
        customer: '<',
        notifications: '<',
        isLoading: '<'
    },
    controller: NotificationController
};