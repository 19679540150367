import * as angular from 'angular';
import 'angular-ui-router';
import { IStateProvider } from 'angular-ui-router';

import { marketingCampaignComponent } from './campaign.component'

export default angular.module('fp:customerportal:shop', [
        'ui.router'
    ])
    .config( /*@ngInject*/ ($stateProvider: IStateProvider) => {
        $stateProvider
            .state('shop', {
                url: '/shop', //so the URL doesn't match the external site. works ok on everything except IOS when they match, it used to be /shop_
                parent: 'secure',
                abstract: true
            })
            .state('shop.campaign', {
                url: '/shop/marketing/campaign?name',
                parent: 'secure',
                template: '<fp-marketing-campaign></fp-marketing-campaign>'
            })
            .state('shop.home', {
                url: '*path',
                external: {
                    url: '/shop{path}',
                    target: '_self'
                }
            } as any)
            .state('shop.offer', {
                url: '/offer/{offerId}/product/{productId}/offer_hardware_type/{offer_hardware_type}',
                external: {
                    url: '/shop/offer/{offerId}/product/{productId}/offerHardwareType/{offer_hardware_type}',
                    target: '_self'
                }
            } as any);
    })
    .component('fpMarketingCampaign', marketingCampaignComponent);
