import './offer.component.scss';

export class OfferBannerController {
    public retentionOfferTypeId = 0;
    public retentionQuestionnaireAnswerID = 0;
    public offerVerbiage: any;
    public showConfirmationSection = true;

    constructor(private $stateParams: any, private $state: any, private dataContext: any, private readonly constants: any, private $scope: any) {
        'ngInject';
        this.retentionOfferTypeId = $stateParams.retentionOfferTypeId;
        this.retentionQuestionnaireAnswerID = $stateParams.retentionQuestionnaireAnswerID;
        this.offerVerbiage = $stateParams.offerVerbiage;
        $scope.$on('$stateChangeSuccess', (event: any, toState: any) => {
            if (toState &&
                (toState.name === 'cancellation.offer.doubleAdditional')) {
                this.showConfirmationSection = false;
            }
        });
    }

    public $onInit() {
        //this should only fire on page reload, UI navigation will populate the values view the router, this is our stopgap
        if (this.offerVerbiage == null) {
            this.offerVerbiage = localStorage.getItem('offerVerbiage');
        }
    }
}

export const offerBannerComponent = {
    template: require('./offerBanner.component.html'),
    controller: OfferBannerController
};