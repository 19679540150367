import './paymentCard.scss';
import template from './paymentCard.html';

class PaymentCardController {
    /*@ngInject*/
    constructor() {

    }
}

export default {
    template: template,
    transclude: true,
    bindings: {
        card: '<',
        onClick: '&'
    },
    controller: PaymentCardController
};