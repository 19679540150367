let focusIfDirective = ($timeout) => {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope, element, attributes, controller) => {
            let dom = element[0];
            let focus = (condition) => {
                if (condition) {
                    $timeout(() => {
                        dom.focus();
                    }, scope.$eval(attributes.focusDelay) || 0);
                }
            };

            if (attributes.fpFocusIf) {
                scope.$watch(attributes.fpFocusIf, focus);
            } else {
                focus(true);
            }
        }
    };
};

export default focusIfDirective;