import template from './refer.html';

class ReferController {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {},
controller: ReferController
};