import _ from 'lodash';
import template from './mobileFooter.html';

class MobileFooterController {
    /*@ngInject*/ 
    constructor($state, constants, dataContext, $window, monitoringPlansService) {
        this.accountTemplate = 'accountPopOver';
        this.supportTemplate = 'supportPopOver';
        this.$state = $state;
        this.constants = constants;
        this.dataContext = dataContext;
        this.user = dataContext.user;
        this.$window = $window;
        this.isCameraPlan = monitoringPlansService.isSAVPlan(dataContext.user.current.ServicePlanInfo);
        this.addOns = [];

        dataContext.user.getAddOnInformation().then((result) => {
            this.addOns = result;
        });

        let navHasDispatchBetaConfig = this.constants.navHasDispatchBetaConfig;
        let userHaDispatchBetaAccess = this.dataContext.user.hasSetting('CustomerPortal:UserHasDispatchBetaAccess', 'true');
        let coverageProvidesDispatchAccess = this.dataContext.user.hasApplicationSetting('CoverageProvidesDispatchAccess', 'true');

        this.shouldDisplayDispatchMenuItems = ((navHasDispatchBetaConfig && userHaDispatchBetaAccess) || (this.user.current.ServicePlanInfo.HasDispatchServiceCoverage && coverageProvidesDispatchAccess));

    }

    showAddOn(Id) {
        if (!_.isEmpty(this.addOns)) {
            this.serviceAddOn = _.find(this.addOns,
                function(obj) {
                    return obj.AddOn === Id;
                });
            return !_.isNil(this.serviceAddOn);
        }
        return false;
    }

    isSelected(names) {
        var isSelected;
        _.forEach(names, name => {
            if (this.$state.current.name.indexOf(name) !== -1)
                isSelected = true;
        });
        return isSelected;
    }

    goToState(state) {
        this.$state.go(state);
    }
}

export default {
    template: template,
    controller: MobileFooterController,
    bindings: {
        inputOverlay: '<'
    }
};
