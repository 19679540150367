import './cancellation.component.scss';
import './failedPayment.scss';

export class FailedPaymentController {

}

export const failedPaymentComponent = {
    template: require('./FailedPayment.component.html'),
    controller: FailedPaymentController
};