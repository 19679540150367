import _ from 'lodash';
import template from './insurance.html';
import AbstractSectionController from '../AbstractSectionController';

const modalTemplate = '<fp-documents-content-modal data-on-close="$dismiss()" data-title="{{$ctrl.title}}" data-url="{{$ctrl.url}}"></fp-documents-content-modal>';

class InsuranceController extends AbstractSectionController {
    /*@ngInject*/
    constructor($uibModal, $window, constants, dataContext, pluginService) {
        super();
        this.$uibModal = $uibModal;
        this.$window = $window;
        this.constants = constants;
        this.dataContext = dataContext;
        this.pluginService = pluginService;
    }

    canViewPdfInBrowser() {
        return this.pluginService.hasAXPlugin(this.$window.navigator.plugins);
    }

    openModal() {
        const captureCtx = this;

        this.$uibModal.open({
            template: modalTemplate,
            controllerAs: '$ctrl',
            controller: /*@ngInject*/ function() {
                this.title = 'Certificate of Insurance';
                this.url = captureCtx.getUri();
            },
            size: 'lg'
        });
    }

    getUri() {
        let token = this.dataContext.user.loadAccessToken();
        if (_.isNil(token)) {
            token = '';
        }

        token = token.replace(/"/g, '');

        return `/api/document/Insurance?token=${token}`;
    }
}

export default {
    template: template,
    bindings: {},
    controller: InsuranceController
};