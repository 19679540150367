import * as _ from 'lodash';

import './billing.scss';
import template from './billing.html';

import AbstractSectionController from '../AbstractSectionController';

const PAYMENT_HISTORY_SETTING = 'CustomerPortal:PaymentHistory';

class AccountBillingController extends AbstractSectionController {
    /*@ngInject*/
    constructor(
        $q, $uibModal, $filter, $state, $timeout, $cookies,
        dataContext, alertService, creditCardService, monitoringPlansService, constants) {

        super();
        this.$q = $q;
        this.$uibModal = $uibModal;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.$state = $state;
        this.dataContext = dataContext;
        this.alertService = alertService;
        this.creditCardService = creditCardService;
        this.monitoringPlans = monitoringPlansService;
        this.constants = constants;
        this.ALERT_DIALOG = 'accountBilling';
        this.$cookies = $cookies;

        this.isPaymentHistoryEnabled = dataContext.user.hasSetting(PAYMENT_HISTORY_SETTING, 'true');

        this.PENDING_PAYMENT_ERROR_MSG = 'Our records indicate that your account has a pending payment. Additional ' +
            'payments cannot be made until all pending payments have cleared. If you need to make another payment, ' +
            `please call support at ${this.$filter('tel')(this.constants.customerSupport.phone, { showCountry: true })}.`;

        this.isCameraPlan = monitoringPlansService.isSAVPlan(dataContext.user.current.ServicePlanInfo);
        this.dataContext.account.getCustomerInformationV2()
            .then((result) => {
                this.customerInformation = result;
                if (this.customerInformation && this.customerInformation.PremisesAddress) {
                    this.showAddOnsSection = this.customerInformation.PremisesAddress.Country.trim() === 'USA';
                }
            });
        this.dataContext.account.isEligibleForVideoAnalytics()
            .then((result) => {
                this.isEligibleForVideoAnalytics = result;
            });
    }

    $onInit() {
        this._showRapidAlert();

        this.showEditCbiOnLoad = !_.isNil(this.$state.params.editCBI);
        this.showAddOnValidation = false;

        this.refreshData()
            .then(() => {
                const stateParams = this.$state.params || {};                                
                this.editExpiredCbiOnLoad();

                if (stateParams.makePayment &&
                    this.hasOverdueBalance() &&
                    !this.hasPendingPayments()) {

                    return this.$timeout(() => {
                        return this.openMakePaymentModal();
                    });
                }

                return this.$q.resolve();
            });

        this.dataContext.account.getAddOns()
            .then((result) => {
                this.addOns = result;
                this.activeAddOns = _.filter(this.addOns, ['IsActive', true]);
                this.inAppPersonalSafetyButtonAddOn = _.filter(this.addOns,
                    (addOn) => { return addOn.Name.toLowerCase() == this.constants.AddOns.safetyButton.toLowerCase(); });
                this.videoAnalyticsAddOn = _.filter(this.activeAddOns,
                    (addOn) => { return addOn.Name.toLowerCase() == this.constants.AddOns.videoAnalytics.toLowerCase(); });
            });

        this.dataContext.account.getAccountAddOns()
            .then((result) => {
                this.accountAddOns = result;
                this.activeAccountAddOns = _.filter(this.accountAddOns, (result) => {
                    return result.DateRemoved == null;
                });
                this.inAppPersonalSafetyButtonAddOns = _.filter(this.activeAccountAddOns,
                    (accAddOn) => {
                        return accAddOn.AddOn.Name.toLowerCase() == this.constants.AddOns.safetyButton.toLowerCase();
                    });
                this.accountvideoAnalyticAddOn = _.filter(this.activeAccountAddOns,
                    (accAddOn) => {
                        return accAddOn.AddOn.Name.toLowerCase() == this.constants.AddOns.videoAnalytics.toLowerCase();
                    });
                this.accountSystemSafetyButtonAddOns = _.filter(this.inAppPersonalSafetyButtonAddOns, ['IsSystemAddOn', true]);
                this.accountRetentionSafetyButtonAddOns = _.filter(this.inAppPersonalSafetyButtonAddOns,
                    (accAddOn) => {
                        return accAddOn.RetentionEpisodeId != null;
                    });
                this.accountSystemRetentionSafetyButtonAddOnsQuantity = this.accountSystemSafetyButtonAddOns.length + this.accountRetentionSafetyButtonAddOns.length;
                this.accountAdditionalSafetyButtonAddOns = _.filter(this.inAppPersonalSafetyButtonAddOns, (addOn) => {
                    return addOn.IsSystemAddOn == false && addOn.RetentionEpisodeId == null;
                });
                this.existingAdditionalAddOnsQuantity = this.accountAdditionalSafetyButtonAddOns.length;
                this.additionalAddOnsQuantity = this.existingAdditionalAddOnsQuantity;
            });
    }

    $onChanges(changes) {
        this.editExpiredCbiOnLoad();
    }

    refreshData() {
        this.isLoading = true;
        return this.$q.all([
            this.getCustomerBillingInfo(),
            this.getMonitoringAdcInfo(),
            this.getPaymentInfo(),
            this.getPaymentSummary(),
            this.getPendingPayments()
        ]).finally(() => {
            this.isLoading = false;
        });
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.paymentSummaryInfo);
    }

    onSaveCore() {

    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.paymentSummaryInfo = this.pristine;
    }

    onCancel() {
        // The customer has stopped editing addOns without saving.
        this.stopEditing();
        this.additionalAddOnsQuantity = this.existingAdditionalAddOnsQuantity;
        this.showAddOnValidation = false;
    }

    isMaximumNumberCC() {
        return (_.size(_.filter(this.CustomerBillingInfo, ['IsCredit', true]))) >= 4;
    }

    isMaximumNumberACH() {
        return (_.size(_.filter(this.CustomerBillingInfo, ['IsCredit', false]))) >= 2;
    }

    addCBI(isCredit) {
        this.selectedCBI = {
            CustomerBillingInfoID: 0,
            IsCredit: isCredit
        };

        const modalInstance = this.$uibModal.open({
            template: '<fp-add-payment-modal config="config"></fp-add-payment-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        selectedCBI: this.selectedCBI,
                        states: this.states,
                        creditCardYears: this.getCreditCardYears(),
                        billingAddress: this.customerInfo.BillingAddress,
                        modal: this.$uibModal,
                        customerBillingInfo: this.CustomerBillingInfo,
                        hasOverdueBalance: this.hasOverdueBalance(),
                        accountPastDueBalance: this.paymentSummaryInfo.AccountPastDue
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });

        modalInstance.result
            .then((result) => {
                if (result.successful && !result.OverdueBalancePaymentAttempted) {
                    this._showGenericSuccessAlert('Your payment has been added.');
                    this.setAutopay(result);
                }
                else if (result.successful && result.OverdueBalancePaymentAttempted && result.OverdueBalancePaymentSuccessful) {
                    this._showPastDueAlert(
                        'We saved your payment method and processed your past-due balance successfully.',
                        'success');
                }
                else if (!result.successful && result.OverdueBalancePaymentAttempted && !result.OverdueBalancePaymentSuccessful) {
                    this._showPastDueAlert(
                        'We saved your payment method, but we ran into an issue when trying to process your past-due payment with this payment method. Please try making a payment again.', 'danger');
                }
                else if (!result.successful && _.includes(result.message, 'Duplicate')) {
                    this._showGenericErrorAlert(result.message || 'We encountered an issue adding your payment information.');
                }
                else if (!result.successful && _.includes(result.message, 'credit card')) {
                    this._showGenericErrorAlert(result.message || 'We encountered an issue adding your payment information.');
                }
                else if (this.isMaximumNumberCC() && (!this.isMaximumNumberACH())) {
                    this._showGenericErrorAlert('There are too many credit cards on file.');
                }
                else if ((!this.isMaximumNumberCC()) && this.isMaximumNumberACH()) {
                    this._showGenericErrorAlert('There are too many bank accounts on file.');
                }
                else {
                    this._showGenericErrorAlert('We encountered an issue adding your payment information.');
                }
            }).finally(() => {
                return this.refreshData();
            });
    }

    deleteCBI(cbi) {
        cbi.CBIType = cbi.IsCredit ? cbi.CreditCardType.substring(0, 1).toUpperCase() + cbi.CreditCardType.substring(1, cbi.CreditCardType.length) : cbi.CheckType + ' ' + cbi.CheckAccountType + ' Account';
        cbi.EncryptedNumber = cbi.IsCredit ? 'XXXX-XXXX-XXXX-' + cbi.LastFour : '*****' + cbi.LastFour;
        cbi.isDefaultPaymentOrRMR = (cbi.IsDefaultPayment || cbi.IsDefaultRmr);

        const modalInstance = this.$uibModal.open({
            template: '<fp-delete-payment-modal config="config"></fp-delete-payment-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        cbiToDelete: cbi,
                        customerBillingInfo: this.CustomerBillingInfo,
                        paymentInfo: this.paymentInfo
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });

        modalInstance.result
            .then((result) => {
                if (result.successful) {
                    this._showGenericSuccessAlert('Your payment has been deleted.');
                } else {
                    this._showGenericErrorAlert('We encountered an issue while deleting your payment information.');
                }
            }, (error) => {
                if (!_.isNil(error)) {
                    this._showGenericErrorAlert('We encountered an issue while deleting your payment information.');
                    this.$log.error('Failed to delete customerBillingInfo', error);
                    return this.$q.reject(error);
                }
            })
            .finally(() => {
                return this.refreshData();
            });
    }

    editExpiredCbiOnLoad() {
        if (!_.isNil(this.CustomerBillingInfo) &&
            !_.isNil(this.customerInfo) &&
            !_.isEmpty(this.states) &&
            this.showEditCbiOnLoad) {

            this.showEditCbiOnLoad = false;
            this.$timeout(() => {
                const expiredCbi = this.creditCardService.hasExpiringRMR(this.CustomerBillingInfo);
                if (!_.isNil(expiredCbi)) {
                    return this.editCBI(expiredCbi);
                } else {
                    return this.$q.resolve();
                }
            });
        }
    }

    editCBI(cbi) {
        const modalInstance = this.$uibModal.open({
            template: '<fp-edit-payment-modal config="config"></fp-edit-payment-modal>',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        selectedCBI: cbi,
                        states: this.states,
                        creditCardYears: this.getCreditCardYears(),
                        billingAddress: this.customerInfo.BillingAddress
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            },
            size: 'lg'
        });

        return modalInstance.result
            .then((result) => {
                if (result.Success) {
                    this._showGenericSuccessAlert('Your payment information has been updated.');
                } else if (!result.Success && _.includes(result.Message, 'credit card')) {
                    this._showGenericErrorAlert(
                        result.Message || 'We encountered an issue adding your payment information.');
                } else {
                    this._showGenericErrorAlert('We encountered an issue while updating your payment information.');
                }
            })
            .finally(() => {
                return this.refreshData();
            });
    }

    openMakePaymentModal() {
        const modalInstance = this.$uibModal.open({
            template: '<fp-make-payment-modal config="config"></fp-make-payment-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss,
                    states: this.states
                };
            }
        });

        modalInstance.result
            .then((result) => {
                if (result.success) {
                    this._showGenericSuccessAlert(`A payment of $${result.paymentAmount.toFixed(2)} was made using ${result.creditCardType} ending in ${result.lastFour}. Your account balance after this transaction is $${result.leftOverBalance.toFixed(2)}.`);
                } else {
                    if (!_.isNil(result.error) && result.error.status === 409) {
                        // There is a lot of text in this alert so it should not be closed on a timeout.
                        this.alertService
                            .get(this.ALERT_DIALOG)
                            .setMessage(this.PENDING_PAYMENT_ERROR_MSG)
                            .setType('danger')
                            .open();
                    } else {
                        this._showGenericErrorAlert('There was a problem processing your payment.');
                    }
                }
            }, (error) => {
                if (!_.isNil(error)) {
                    this._showGenericErrorAlert('There was a problem processing your payment.');
                    this.$log.error('Failed to insert customerBillingInfo', error);
                    return this.$q.reject(error);
                }
            })
            .finally(() => {
                return this.refreshData();
            });
    }

    openPaymentHistoryModal() {
        const modalInstance = this.$uibModal.open({
            template: '<fp-payment-history-modal config="config"></fp-payment-history-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });

        modalInstance.result
            .then((result) => {
                if (result.success) {
                    this._showGenericSuccessAlert('');
                } else {
                    this._showGenericErrorAlert('There was a problem loading your payment history.');
                }
            }, (error) => {
                if (!_.isNil(error)) {
                    this._showGenericErrorAlert('There was a problem loading your payment history.');
                    return this.$q.reject(error);
                }
            })
            .finally(() => {
                return this.refreshData();
            });
    }
    
    hasOverdueBalance() {
        return this.paymentSummaryInfo
            && this.paymentSummaryInfo.HasAccountPastDue
            && this.paymentSummaryInfo.AccountPastDue > 0;
    }

    getCreditCardYears() {
        const years = [];
        const currentDateYear = 2000 + ((new Date()).getYear() - 100);
        for (let x = 0; x <= 10; x++) {
            years.push(currentDateYear + x);
        }
        return years;
    }

    setAutopay(cbi) {
        this.selectedCBI = cbi;

        const modalInstance = this.$uibModal.open({
            template: '<fp-edit-autopay-modal config="config"></fp-edit-autopay-modal>',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        selectedCBI: this.selectedCBI,
                        paymentInfo: this.paymentInfo
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            }
        });

        modalInstance.result
            .then((result) => {
                if (result.Success) {
                    this._showGenericSuccessAlert('Your Autopay has been updated.');
                } else {
                    this._showGenericErrorAlert('We encountered an issue while updating your Autopay.');
                }
            })
            .finally(() => {
                return this.refreshData();
            });
    }

    getCustomerBillingInfo() {
        return this.dataContext.customerBillingInfo.getCustomerBillingInfo().then((data) => {
            this.CustomerBillingInfo = data.Data;
            let countCreditCards = 0;
            let countBankAccounts = 0;
            _.each(this.CustomerBillingInfo, (cbi) => {
                this._trimProperties(cbi);
                cbi.FullName = cbi.FirstName + ' ' + cbi.LastName;

                if (cbi.IsCredit) {
                    countCreditCards++;
                    cbi.CardCcNumb = '********' + cbi.LastFour;
                    cbi.CreditCardType = cbi.CreditCardType.toLowerCase();
                    const cardInfoArray = cbi.CCExp.split('/');
                    cbi.CcExpMonth = cardInfoArray[0];
                    cbi.CcExpYear = 2000 + parseInt(cardInfoArray[1]);
                    cbi.CcExpYear = 2000 + parseInt(cardInfoArray[1]);
                } else {
                    countBankAccounts++;
                    cbi.AccountNumber = cbi.AccountNumberValidate = '*****' + cbi.LastFour;
                    cbi.CheckType = cbi.CheckType === 'P' ? 'Personal' : 'Business';
                    cbi.CheckAccountType = cbi.CheckAccountType === 'C' ? 'Checking' : 'Saving';
                    if (cbi.CBIName.substring(0, 2) === 'C-') {
                        cbi.CBIName = 'Checking' + cbi.CBIName.substring(1, cbi.CBIName.length);
                    }
                    else if (cbi.CBIName.substring(0, 2) === 'S-') {
                        cbi.CBIName = 'Saving' + cbi.CBIName.substring(1, cbi.CBIName.length);
                    }
                }
            });
            this.enableAddCBI = countCreditCards <= 3 || countBankAccounts <= 1;
        });
    }

    hasPendingPayments() {
        return _.get(this.pendingPayments, 'length', 0) > 0;
    }

    getMonitoringAdcInfo() {
        return this.dataContext.emergency.getMonitoringAdcInfo().then((result) => {

            if (result.WbServicePlanName === 'Video Only') {
                    result.WbServicePlanName = 'Video Only';
            }

            this.monitoringAdcInfo = result;
        });
    }

    getPaymentInfo() {
        return this.dataContext.payment.getPaymentInfo().then((result) => {
            this.paymentInfo = result.PaymentInfo;
        });
    }

    getPaymentSummary() {
        return this.dataContext.payment.getPaymentSummary().then((result) => {
            this.paymentSummaryInfo = result.PaymentSummaryInfo;
            this.pastDueNotice = {
                amount: _.get(result.PaymentSummaryInfo, 'AccountPastDue')
            };
        });
    }

    getPendingPayments() {
        return this.dataContext.payment
            .getPendingPayments()
            .then((result) => {
                this.pendingPayments = result;
            });
    }

    openAddAddOnModal() {
        const modalInstance = this.$uibModal.open({
            template: '<fp-add-add-on-modal config="config"></fp-add-add-on-modal>',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        safetyButtonAddOn: this.inAppPersonalSafetyButtonAddOn,
                        oldQuantity: this.existingAdditionalAddOnsQuantity,
                        newQuantity: this.additionalAddOnsQuantity
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            },
            size: 'lg'
        });
    }

    editSafetyButtonAddOns() {
        // get 6 months prior date to the current date
        var today = new Date();
        var sixMonthPriordate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
        var formattedSixMonthPriordate = sixMonthPriordate.toLocaleDateString('en-ca');

        this.sixMonthPriorRemovedFeatureAccountAddOns = _.filter(this.accountAddOns, (result) => {
            return result.DateFeatureRemoved != null && result.DateFeatureRemoved >= formattedSixMonthPriordate;
        });
        this.isFeatureRemovedWithInSixMonths = this.sixMonthPriorRemovedFeatureAccountAddOns.length > 0 && this.additionalAddOnsQuantity === 0;

        if (this.existingAdditionalAddOnsQuantity != this.additionalAddOnsQuantity && !this.isFeatureRemovedWithInSixMonths && !_.isNil(this.additionalAddOnsQuantity)) {
            this.showAddOnValidation = false;
            const modalInstance = this.$uibModal.open({
                template: '<fp-confirm-add-on-modal config="config"></fp-confirm-add-on-modal>',
                controller: ($scope) => {
                    'ngInject';
                    $scope.config = {
                        data: {
                            safetyButtonAddOn: this.inAppPersonalSafetyButtonAddOn,
                            oldQuantity: this.existingAdditionalAddOnsQuantity,
                            newQuantity: this.additionalAddOnsQuantity
                        },
                        close: modalInstance.close,
                        dismiss: modalInstance.dismiss
                    };
                },
                size: 'lg'
            });
            modalInstance.result
                .then((result) => {                    
                })
                .finally(() => {
                    this.additionalAddOnsQuantity = this.existingAdditionalAddOnsQuantity;
                    this.showAddOnValidation = false;
                });
        }
        else if (this.isFeatureRemovedWithInSixMonths) {
            this.additionalAddOnsQuantity = this.existingAdditionalAddOnsQuantity;
            this.showAddOnValidation = false;
            const modalInstance = this.$uibModal.open({
                template: '<fp-error-add-on-modal config="config"></fp-error-add-on-modal>',
                controller: ($scope) => {
                    'ngInject';
                    $scope.config = {
                        data: {
                            safetyButtonAddOn: this.inAppPersonalSafetyButtonAddOn,
                            oldQuantity: this.existingAdditionalAddOnsQuantity,
                            newQuantity: this.additionalAddOnsQuantity,
                            isFeatureRemovedWithInSixMonths: this.isFeatureRemovedWithInSixMonths
                        },
                        close: modalInstance.close,
                        dismiss: modalInstance.dismiss
                    };
                },
                size: 'lg'
            });
        }
        else if (this.existingAdditionalAddOnsQuantity == this.additionalAddOnsQuantity) {
            this.showAddOnValidation = false;
            alert('The number of addOns to update should be different than current addOns.');
        }
        else if (_.isNil(this.additionalAddOnsQuantity)) {
            this.showAddOnValidation = true;
        }
    }

    addVideoAnalyticsAddOn() {
        this.dataContext.account.SetAddOnsForVideoAnalytics().then((result) => {
            if (result && result.success) {
                const modalInstance = this.$uibModal.open({
                    template: '<fp-success-add-on-modal config="config"></fp-success-add-on-modal>',
                    controller: ($scope) => {
                        'ngInject';
                        $scope.config = {
                            data: {
                                videoAnalytics: true
                            },
                            close: modalInstance.close,
                            dismiss: modalInstance.dismiss
                        };
                    },
                    size: 'lg'
                });
            }
            else {
                alert('Error adding video analytics.');
            }
        });
    }

    _trimProperties(entity) {
        for (let prop in entity) {
            let value = entity[prop];
            if (_.isString(value)) {
                entity[prop] = entity[prop].trim();
            }
        }
    }

    _showGenericSuccessAlert(dialogMessage) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(`Success! ${dialogMessage}`)
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert(dialogMessage) {

        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(`Error. ${dialogMessage} Please try again.`)
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showPastDueAlert(dialogMessage, dialogType) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(dialogMessage)
            .setType(dialogType)
            .setTimeout(this.constants.pastDueBalanceAlertDuration)
            .open();
    }

    _showRapidAlert() {
        //Check for rapid verification flag
        this.dataContext.account.getEnrolledPrograms()
            .then((response) => {
                let rapidCheck = false;
                _.forEach(response,
                    (program) => {
                        if (program.ProgramID === this.constants.enrolledPrograms.rapidAddressVerification) {
                            rapidCheck = true;
                            return false;
                        }
                    });
                //Logic for showing the alert
                if (rapidCheck) {
                    const user = this.dataContext.user.current;
                    const userCookie = this.$cookies.get(user.UserId);
                    if (userCookie == null) {
                        const today = new Date();
                        const expiresValue = new Date(today);
                        //Set 'expires' option in 2 month
                        expiresValue.setMinutes(today.getMinutes() + this.constants.cache.expiry);
                        this.$cookies.put(
                            user.UserId,
                            user.UserName,
                            {
                                expires: expiresValue
                            });
                        this.alertService
                            .get('rapid')
                            .setMessage(
                            'Please verify your contact information to make sure we have the correct information on file and can provide optimal service.')
                            .setType('success')
                            .open();
                    }
                }
            });
    }
}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        isSaving: '<',
        isLoading: '<',
        states: '<'
    },
    controller: AccountBillingController
};