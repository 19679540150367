import * as _ from 'lodash';

import {
    RetentionRequest,
    QuestionnaireAnswerRequest,
    RetentionOfferType,
    ClaimCertificateDto,
    EmailReturnLabelDto,
    ContractPdfModel,
    GetRenewalContractTextRequest,
    RetentionQuestionnaireSessionDto
} from "../../cancellation/types";

class RetentionDataService {
    private retentionOfferTypes: RetentionOfferType[] | null;
    public retentionEpisode: any;

    constructor(private Restangular: any, private $q: any) {
        'ngInject';
        this.retentionEpisode = null;
    }

    submitCertificate(certificateDto: ClaimCertificateDto) {
        return this.Restangular
            .one('retention')
            .one('certificate')
            .customPOST(certificateDto);
    }

    submitRetentionAttempt(retentionAttempt: RetentionRequest) {
        this.retentionEpisode = this.Restangular
            .all('retention')
            .customPOST(retentionAttempt);

        return this.retentionEpisode;
    }

    getRetentionEpisode(episodeId: number) {
        this.retentionEpisode = this.Restangular
            .all('Retention')
            .one('retention-episode')
            .customGET('',
                {
                    'episodeId': episodeId
                });

        return this.retentionEpisode;
    }

    submitQuestionnaireAnswers(questionnaireAnswer: QuestionnaireAnswerRequest) {
        return this.Restangular
            .all('retention')
            .all('answers')
            .customPOST(questionnaireAnswer);
    }

    getRetentionOfferTypes(): RetentionOfferType[] {
        if (_.isNil(this.retentionOfferTypes)) {
            this.retentionOfferTypes = this.Restangular
                .all('retention')
                .get('offers');
        }

        return this.retentionOfferTypes;
    }

    getRetentionSession(): Promise<RetentionQuestionnaireSessionDto> {
        return this.Restangular.all('retention').get('session');
    }

    validateSession() {
        return this.Restangular
            .all('retention')
            .get('validate');
    }

    getRenewalContractText(getRenewalContractTextRequest: GetRenewalContractTextRequest): ContractPdfModel {
        return this.Restangular
            .all('retention')
            .all('contract-text')
            .customPOST(getRenewalContractTextRequest);
    }

    updateSession(retentionEpisodeId: number) {
        return this.Restangular
            .all('retention')
            .all('update')
            .customPUT({ retentionEpisodeId: retentionEpisodeId });
    }

    UpdateRetentionQuestionnaire(retentionAttempt: RetentionRequest) {
        return this.Restangular
            .all('retention')
            .all('UpdateRetentionQuestionnaire')
            .customPUT(retentionAttempt);
    }

    UpdateRetentionEpisode(retentionAttempt: RetentionRequest) {
        return this.Restangular
            .all('retention')
            .all('UpdateRetentionEpisode')
            .customPUT(retentionAttempt);
    }

    async expireSessionOnPaymentFailure(retentionEpisodeId: number) {
        return this.Restangular
            .all('retention')
            .all('cancellation-payment-failure')
            .customPUT({ retentionEpisodeId: retentionEpisodeId });
    }

    async getRetentionOfferType(retentionOfferTypeId: number): Promise<RetentionOfferType> {
        const p: Promise<RetentionOfferType> = new Promise(async (resolve, reject) => {
            const retentionOfferTypes = await this.getRetentionOfferTypes();

            resolve(_.find(retentionOfferTypes,
                (type: any) => {
                    return type.RetentionOfferTypeID === retentionOfferTypeId;
                }));
        });

        return p;
    }

    async getRetentionAnswerOption() {
        return this.Restangular
            .all('retention')
            .get('retention-answer-options');
    }

    async searchRetentionOffer(retentionOfferTypeID: number) {
        return this.Restangular
            .all('Retention')
            .one('offers-search')
            .customGET('', {
                'retentionOfferTypeID': retentionOfferTypeID
            });
    }


    async getRetentionQuestionAnswers() {
        return this.Restangular
            .all('retention')
            .get('retention-session-answers')
    }


    emailReturnLabel(emailDto: EmailReturnLabelDto) {
        return this.Restangular
            .one('retention')
            .one('email-return-label')
            .customPOST(emailDto);
    }

}

export default RetentionDataService;