class RoutingDataService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    validateRouting(routingNum) {
        return this.Restangular
            .one('routing', routingNum)
            .get();
    }
}

export default RoutingDataService;