import _ from 'lodash';
import Alert from './Alert';

class AlertService {
    /*@ngInject*/
    constructor() {
        this.alerts = {};
    }

    /**
     * Gets the alert with the give name.
     * 
     * If the alert does not exist an exception is thrown.
     * 
     * @param {string} name 
     * @returns {Object} 
     */
    get(name) {
        if (_.isNil(name)) {
            throw 'name cannot be null or undefined.';
        }

        if (_.isNil(this.alerts[name])) {
            throw `An alert with the name ${name} was not found. A fpAlert tag with then name ${name} does not exist in the DOM.`;
        }

        return this.alerts[name];
    }

    _create(name) {
        if (_.isNil(name)) {
            throw 'name cannot be null or undefined.';
        }

        if (!_.isNil(this.alerts[name])) {
            throw `An alert with the name ${name} already exists. There are multiple fpAlert tags with the same name.`;
        }

        this.alerts[name] = new Alert();
        return this.alerts[name];
    }

    _remove(name) {
        if (_.isNil(name)) {
            throw 'name cannot be null or undefined.';
        }

        if (_.isNil(this.alerts[name])) {
            throw `An alert with the name ${name} was not found. A fpAlert tag with then name ${name} does not exist in the DOM.`;
        }

        delete this.alerts[name];
    }
}

export default AlertService;