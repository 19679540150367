import angular from 'angular';
import _ from 'lodash';

import 'angular-ui-router';

import commonModule from '../common';

export default angular.module('fp:customerportal:adc', [
        'ui.router',
        commonModule.name
])
    .config(/*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('adc', {
                url: '/adc',
                parent: 'secure',
                abstract: true
            })
            .state('adc.home', {
                url: '',
                adcUrl: '/web/system/home'
            })
            .state('adc.video', {
                url: '/video',
                adcUrl: '/web/Video/LiveViewFlash.aspx'
            })
            .state('adc.rules', {
                url: '/rules',
                adcUrl: '/web/ember/automation/rules'
            })
            .state('adc.notifications', {
                url: '/notifications',
                adcUrl: '/web/Notifications/NotificationsNew.aspx'
            })
            .state('adc.activity', {
                url: '/activity',
                adcUrl: '/web/system/activity/event-history'
            })
            .state('adc.users', {
                url: '/users',
                adcUrl: '/web/system/users'
            })
            .state('adc.settings', {
                url: '/settings',
                adcUrl: '/web/system/settings'
            })
            .state('adc.editAccountInfo', {
                url: '/editAccountInfo',
                adcUrl: '/web/system/settings/login-information'
            });
    })
    .run(/*@ngInject*/ ($rootScope, $window, $log, dataContext, constants) => {
        $rootScope.$on(constants.events.stateChangeStart, (event, toState, toParams, fromState, fromParams, options) => {
            if (!_.isEmpty(toState.adcUrl)) {
                event.preventDefault();

                dataContext.user.initialize(); // needed due to race with stateChangeStart and secure state resolution
                dataContext.user.getAdcRedirectUrl()
                    .then((result) => {
                        const redirectUrl = `${result}&returnUrl=${toState.adcUrl}`;
                        $log.info(`Redirecting to ${redirectUrl}`);
                        $window.open(redirectUrl, '_self');
                    });
            }
        });
    });