import template from './shell.html';


class LoginShellController {
    /*@ngInject*/
    constructor() {
        this.overridden = false;
    }

    override() {
        this.overridden = true;
    }

}

export default {
template: template,
    bindings: {},
controller: LoginShellController
};