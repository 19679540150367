import template from './header.html';

class HeaderController {
    /*@ngInject*/ 
    constructor($state) {
        this.$state = $state;
    }

    isLoginHome() {
        return this.$state.current.name === 'login.home';
    }
}

export default {
    template: template,
    bindings: {},
    controller: HeaderController
};
