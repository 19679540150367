import angular from 'angular';
import uiMask from 'angular-ui-mask';

import commonModule from '../../../common';

import emergencyContactComponent from './contact.component';
import emergencyViewContactComponent from './viewContact.component';
import emergencyEditContactComponent from './editContact.component';

export default angular
    .module('fp:customerportal:account:emergency:components', [
        uiMask,
        commonModule.name
    ])
    .component('fpEmergencyContact', emergencyContactComponent)
    .component('fpEmergencyViewContact', emergencyViewContactComponent)
    .component('fpEmergencyEditContact', emergencyEditContactComponent);