import DataContext from '../common/data/dataContext.service';
import './cancellation.component.scss';

export class CancellationHeaderController {
    public currentSection = '';
    private dataContext: DataContext;

    constructor($rootScope: ng.IRootScopeService, private $state: any, private $scope: any, dataContext: DataContext) {
        'ngInject';

        this.dataContext = dataContext;

        $scope.$on('$stateChangeSuccess', (_: any, toState: any) => {
            if (toState) {
                this.updateCurrentSection(toState);
            }
        });
    }

    public $onInit() {
        this.updateCurrentSection(this.$state.current);
    }

    private updateCurrentSection(state: any) {
        if (state.name === 'cancellation.overview') {
            this.currentSection = 'overview';
        }
        else if (state.name.startsWith('cancellation.questionnaire')) {
            this.currentSection = 'questionnaire';
        }
        else if (state.name.startsWith('cancellation.confirmation')) {
            this.currentSection = 'confirmation';
        }
    }
}

export const cancellationHeaderComponent = {
    template: require('./header.component.html'),
        controller: CancellationHeaderController
};