import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

export default class {
    /*@ngInject*/
    constructor(dataContext) {
        this.dataContext = dataContext;
    }

    setInitialDate() {
        let date = moment().startOf('day').toDate();
        while (this._isHoliday(date)) {
            date.add(1, 'day');
        }
        return moment().toDate();
    }

    _isHoliday(date) {
        return _.some(this.holidays, (holiday) => {
            let day = moment(holiday);
            return day.isSame(date.date, 'day');
        });
    }

    hasPOBox(address) {
        return this.dataContext.address.isPOBox(address);
    }
}
