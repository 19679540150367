import _ from 'lodash';
import './guardDetailsModal.scss';
import template from './guardDetailsModal.html';

class GuardDetailsModal {
    /* @ngInject */
    constructor(dataContext) {
        this.dataContext = dataContext;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
    }

    $onInit() {
        this.getDispatchGuardDetails(this.data.dispatchId);
    }

    submit() {
        this.$close(true);
    }

    close() {
        this.$close(false);
    }

    getDispatchGuardDetails(dispatchId) {
        this.isGuardDetailLoading = true;
        this.dataContext.dispatch.getDispatchGuardDetails(dispatchId).then((result) => {
            this.guardName = result.GuardName ? result.GuardName : "N/A";
            this.guardImage = result.GuardImage ? "data:image/png;base64," + result.GuardImage.toString() : null;
            this.guardCompany = result.GuardCompany ? result.GuardCompany : "N/A";
            this.guardCompanyImage = result.GuardCompanyImage ? "data:image/png;base64," + result.GuardCompanyImage.toString() : null;
            this.isGuardDetailLoading = false;
        });
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: GuardDetailsModal
};