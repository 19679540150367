let OnLoadIframeDirective = () => {
    'ngInject';
    return {
        restrict: 'A',
        scope: {
            fpOnLoadIframe: '&'
        },
		link: (scope, element, attrs) => {
		    element.on('load', () => {
                scope.fpOnLoadIframe();
		        scope.$apply();
		    });
		}
	};
};

export default OnLoadIframeDirective;