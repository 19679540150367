import * as angular from 'angular';
import * as _ from 'lodash';

export const initRecaptcha = (
    scope: ng.IScope,
    selector: string = "fp-track-order",
    controller: string = "fpTrackOrder",
    action: string = "trackOrderSearch"
) => {
    var executeRecaptchaScript = document.createElement("script");
    executeRecaptchaScript.type = "text/javascript";
    executeRecaptchaScript.textContent = `var onloadCallback = function() {
            grecaptcha.ready(function () {
                grecaptcha.execute("6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM", { action: "${action}" })
                            .then(function (token) {
                                    var recaptchaResponse = document.getElementById("recaptchaResponse");
                                    angular.element(recaptchaResponse).controller("ngModel").$setViewValue(token);
                                    recaptchaResponse.value = token;
                                    angular.element(recaptchaResponse).controller("ngModel").$render();

                                    var el = document.querySelector("${selector}");
                                    var controller = angular.element(el).controller("${controller}");
                                    controller.$scope.captchaToken = token;
                            });
                });
            };
        `;
    angular.element(document.querySelector('head')).append(executeRecaptchaScript);

    var recaptchaApiScript = document.createElement("script");
    recaptchaApiScript.type = "text/javascript";
    recaptchaApiScript.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM";
    angular.element(document.querySelector('head')).append(recaptchaApiScript);
    _.defer(() => {
        scope.$apply();
    });
};