let setFocusDirective = (angular, _, $timeout) => {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope, element, attributes, controller) => {
            let options = _.assign({}, {
                selector: 'input',
                child: true,
                event: 'click',
                delay: 0
            }, attributes.fpSetFocus);

            let targetElement = options.child
                ? element.find(options.selector)
                : angular.element(options.selector);
            let target = targetElement[0];

            if (!_.isNil(target)) {
                element.bind(options.event, () => {
                    $timeout(() => {
                        target.focus();
                    }, options.delay);
                });
            }
        }
    };
};

export default setFocusDirective;
