class ProductService {
    /*@ngInject*/
    constructor(Restangular, _) {
        this.Restangular = Restangular;
        this._ = _;
    }

    getProductFromAdcSku(adcEnum) {
        return this.Restangular
            .one('Product')
            .get({ adcProductEnum: adcEnum })
            .then((response) => {
                return response;
            });
    }

    getProductIdFromSku(sku) {
        return this.Restangular
           .one('Product')
           .all('ProductInfo')
            .customGET('', {
                'sku' : sku
            })
           .then((response) => {
               return response;
           });
    }

    checkActiveSkus(skus) {
        return this.Restangular
            .one('Product')
            .all('CheckActiveSkus')
            .customPOST(skus)
            .then((response) => {
                return response;
            });
    }

    getActiveEquipments() {
        return this.Restangular
            .all('Product')
            .withHttpConfig({ cache: false }) 
            .one('GetActiveEquipments')
            .get()
            .then((response) => {
                return response;
            });
    }

    validateCheckoutCart(skus) {
        return this.Restangular
            .one('Product')
            .all('ValidateCart')
            .customPOST(skus)
            .then((response) => {
                return response;
            });
    }
}

export default ProductService;