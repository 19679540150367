import angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import componentsModule from './components';
import modalsModule from './modals';

import dispatchServicesComponent from './dispatchServices.component';
import upcomingDispatchesComponent from './upcomingDispatches.component';
import dispatchNotificationsComponent from './dispatchNotifications.component';
import dispatchHistoryComponent from './dispatchHistory.component';

export default angular
    .module('fp:customerportal:account:dispatchServices', [
        'ui.router',
        commonModule.name,
        componentsModule.name,
        modalsModule.name
    ])

    .config(/*@ngInject*/($stateProvider, constants) => {
        $stateProvider
            .state('account.dispatchServices',
                {
                    url: '/dispatchServices',
                    template: '<fp-dispatch-services></fp-dispatch-services>',
                    data: {
                        navigationMenu: constants.navigationMenuSections.majorSections.account,
                        navigationSubSection: constants.navigationMenuSections.subSections.emergencySettings,
                        subnav: [
                            {
                                name: 'Upcoming Guard Services',
                                shortName: 'Upcoming',
                                icon: 'fa-person-military-pointing',
                                href: '#upcomingDispatches'
                            },
                            {
                                name: 'Guard History',
                                shortName: 'History',
                                icon: 'fa-file-lines',
                                href: '#dispatchHistory'
                            },
                            {
                                name: 'Notifications',
                                shortName: 'Notifications',
                                icon: 'fa-bell',
                                href: '#dispatchNotifications'
                            }
                        ]
                    }
                })
            .state('account.dispatchServices.upcomingDispatches', {
                data: {
                    scrollTo: 'upcomingDispatches'
                }
            })
            .state('account.dispatchServices.dispatchNotifications', {
                data: {
                    scrollTo: 'dispatchNotifications'
                }
            })
            .state('account.dispatchServices.dispatchHistory', {
                data: {
                    scrollTo: 'dispatchHistory'
                }
            });
    })
    .component('fpDispatchServices', dispatchServicesComponent)
    .component('fpUpcomingDispatches', upcomingDispatchesComponent)
    .component('fpDispatchNotifications', dispatchNotificationsComponent)
    .component('fpDispatchHistory', dispatchHistoryComponent);