import template from './yesNoModal.html';

let yesNoModalDirective = () => {
    return {
        restrict: 'E',
        template: template,
        controller: YesNoModalDirective,  
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            config: '=?'
        }
    };
};

class YesNoModalDirective {
    /* @ngInject */
    constructor(_) {
        this.config = _.merge({}, {
            title: 'Confirm',
            content: 'Are you sure?',
            yesLabel: 'Yes',
            noLabel: 'No',
            showNo: true,
            onYes: () => {
                if (_.isFunction(this.config.close)) {
                    this.config.close(true);
                }
            },
            onNo: () => {
                if (_.isFunction(this.config.close)) {
                    this.config.close(false);
                }
            }
        }, this.config);
    }
}

export default yesNoModalDirective;