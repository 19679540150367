import _ from 'lodash';

import template from './preferences.html';
import AbstractSectionController from '../AbstractSectionController';

class PreferencesController extends AbstractSectionController {
    /*@ngInject*/
    constructor($log, $q, alertService, constants, dataContext) {
        super();
        this.$log = $log;
        this.$q = $q;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;

        this.LOGIN_NAME_MIN_LENGTH = 7;
        this.LOGIN_NAME_MAX_LENGTH = 100;

        this.ALERT_DIALOG_GENERIC_SUCCESS = 'preferences:generic:success';
        this.ALERT_DIALOG_PASSWORD_ERROR = 'preferences:password:error';
        this.ALERT_DIALOG_QUESTION_ERROR = 'preferences:question:error';
        this.ALERT_DIALOG_LOGIN_ERROR = 'preferences:login:error';

        this.ACTION_PASSWORD = 'password';
        this.ACTION_QUESTION = 'security question';
        this.ACTION_LOGIN = 'login name';

        this.EXCEPTION_MESSAGE_DUPLICATE_LOGIN_NAME = 'Duplicate name';
        this.EXCEPTION_MESSAGE_INVALID_PASSWORD = 'Invalid password';
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.preferences);

        this._restorePristineValues();
    }

    onSaveCore() {
        // Not Implemeneted
    }

    onSavePassword() {
        this.isLoading = true;
        return this.dataContext.user.changePasswordForCurrentUser(
                this.preferences.Password,
                this.preferences.NewPassword,
                this.preferences.ConfirmNewPassword)
            .then(() => {
                this.onSave();
                this._showSuccessAlert(this.ACTION_PASSWORD);

                this._onSuccess();
            })
            .catch((error) => {
                if (this._isInvalidPasswordError(error)) {
                    this.$log.info('Error updating password. The provided current password was not correct.');
                    this._showInvalidPasswordErrorAlert();
                } else {
                    this.$log.error('Error updating password.', error);
                    this._showErrorAlert(this.ALERT_DIALOG_PASSWORD_ERROR, this.ACTION_PASSWORD);
                }
                this._restorePristineValues();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onSaveQuestion() {
        this.isLoading = true;
        return this.dataContext.user.changeSecurityQuestionForCurrentUser(
                this.preferences.question.QuestionID,
                this.preferences.question.Answer)
            .then(() => {
                this.onSave();
                this._showSuccessAlert(this.ACTION_QUESTION);
                let question = this.preferences.question;
                this._onSuccess();
                this.preferences.question = question;
            })
            .catch((error) => {
                this.$log.error('Error updating security question.', error);
                this._showErrorAlert(this.ALERT_DIALOG_QUESTION_ERROR, this.ACTION_QUESTION);
                this._restorePristineValues();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onSaveLogin() {
        this.isLoading = true;
        return this.dataContext.user.changeLoginName(
                this.preferences.LoginName)
            .then(() => {
                this.onSave();
                this._showSuccessAlert(this.ACTION_LOGIN);
            })
            .catch((error) => {
                if (this._isDuplicateLoginNameError(error)) {
                    this.$log.info('Error updating login name. The provided login name is already in use.');
                    this._showDuplicateLoginNameErrorAlert();
                } else {
                    this.$log.error('Error updating login name.', error);
                    this._showErrorAlert(this.ALERT_DIALOG_LOGIN_ERROR, this.ACTION_LOGIN); 
                }
                this._restorePristineValues();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.preferences = this.pristine;
    } 

    onSecurityQuestionChange() {
        const eligibleQuestions = this.preferences.questions;
        const questionId = this.preferences.question.QuestionID;
        const questionObj = _.find(eligibleQuestions, { 'QuestionID': questionId });

        this.preferences.question.Question = questionObj.Question;
    }

    /**
     * Returns true if the current login name is valid; otherwise, false.
     * 
     * @returns {boolean} 
     */
    isLoginNameValid() {
        // A new login name cannot be the same as the current login name.
        if (this.preferences.LoginName === this.pristine.LoginName) {
            return false;
        }

        return true;
    }

    _onSuccess() {
        this.preferences = _.cloneDeep(this.pristine);
    }

    _restorePristineValues() {
        this.preferences = _.cloneDeep(this.pristine);

        // Clear the login name when we enter edit mode so that the user does not have to delete their login name
        // from the input before entering their new login name.
        this.preferences.LoginName = null;
        this.preferences.ConfirmLoginName = null;
        this.preferences.question.Answer = null;
    }

    _showSuccessAlert(action) {
        this.alertService
            .get(this.ALERT_DIALOG_GENERIC_SUCCESS)
            .setMessage(`Success! Your ${action} has been updated.`)
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showErrorAlert(dialog, action) {
        this.alertService
            .get(dialog)
            .setMessage(`Error. We encountered an issue while updating your ${action}. Please try again.`)
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showInvalidPasswordErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG_PASSWORD_ERROR)
            .setMessage('The password entered does not match the password we have on record. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showDuplicateLoginNameErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG_LOGIN_ERROR)
            .setMessage('The login name entered is unavailable. Please enter a new login name and try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _isInvalidPasswordError(error) {
        const msg = _.get(error, 'data.Message', null);
        if (msg === null) {
            return false;
        }

        return msg === this.EXCEPTION_MESSAGE_INVALID_PASSWORD;
    }

    _isDuplicateLoginNameError(error) {
        const msg = _.get(error, 'data.Message', null);
        if(msg === null) {
            return false;
        }

        return msg === this.EXCEPTION_MESSAGE_DUPLICATE_LOGIN_NAME;
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        preferences: '<'
    },
    controller: PreferencesController
};