import angular from 'angular';

import constants from './constants.js';

export default angular.module('fp:customerportal:common:constants', [])
    .constant('constants', constants)

    .run(/*@ngInject*/ ($log, constants) => {
        if (constants.debug === true) {
            $log.warn('Debug mode enabled');
        }
    });