import _ from 'lodash';
import template from './permit.html';
import AbstractSectionController from '../AbstractSectionController';

class PermitController extends AbstractSectionController {
    /*@ngInject*/
    constructor($log, $q, alertService, constants, dataContext) {
        super();
        this.$log = $log;
        this.$q = $q;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;
        this.ALERT_DIALOG = 'emergency:permit';
        this.PERMIT_NUMBER_MIN_LENGTH = 3;
        this.PERMIT_NUMBER_MAX_LENGTH = 24;
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.permit);

        // Clear the permit when we enter edit mode so that the user does not have to delete their current permit
        // from the input before entering their new permit.
        this.permit = null;
    }

    onSaveCore() {
        this.isLoading = true;
        return this.dataContext.permit.put(this.permit)
            .then(() => {
                this._showGenericSuccessAlert();
                this.pristine = null;
            })
            .catch((error) => {
                this.$log.error('Error updating Permit Number.', error);
                var errorMessage = 'Error. We encountered an issue while updating your Permit Number. Please try again.';
                this._showGenericErrorAlert(errorMessage, this.constants.alertDuration);
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.permit = this.pristine;
    }

    /**
     * Returns true if the current input is valid; otherwise, false.
     * 
     * @returns {boolean} 
     */
    isValid() {
        // A new permit cannot be the same as the current permit.
        if (this.permit === this.pristine) {
            return false;
        }

        return true;
    }

    /**
     * Returns true if the current input is invalid; otherwise, true.
     * 
     * @returns {boolean} 
     */
    isInvalid() {
        return !this.isValid();
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your Permit Number has been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert(errorMessage, alertDuration) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(errorMessage)
            .setType('danger')
            .setTimeout(alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        permit: '<',
        rapidConnectionStatus: '<'
    },
    controller: PermitController
};