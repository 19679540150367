import template from './contact.html';

class ContactController {
    /*@ngInject*/
    constructor($scope, constants) {
        this.isEditing = this.contact._isNew === true;
        delete this.contact._isNew;

        this.unsubscribe = $scope.$on(constants.events.data.emergencyContactsUpdated, () => {
            this.onCancelEdit();
        });
    }

    $onDestroy() {
        this.unsubscribe();
    }

    onStartEdit() {
        this.isEditing = true;
    }

    onCancelEdit() {
        this.isEditing = false;
    }
}

export default {
    template: template,
    bindings: {
        contact: '<',
        position: '<',
        canRemoveContact: '&',
        canPromoteContact: '&',
        canDemoteContact: '&',
        onRemoveContact: '&',
        onPromoteContact: '&',
        onDemoteContact: '&',
        onSaveContact: '&'
    },
    controller: ContactController
};