import './editContact.scss';
import template from './editContact.html';

class EditContactController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }
}

export default {
    template: template,
    bindings: {
        contact: '<',
        position: '<',
        isEditing: '<',
        canRemoveContact: '&',
        canPromoteContact: '&',
        canDemoteContact: '&',
        onRemoveContact: '&',
        onPromoteContact: '&',
        onDemoteContact: '&',
        onCancelEdit: '&'
    },
    controller: EditContactController
};