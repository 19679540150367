import * as angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import { questionnaireOfferConfirmModal } from './offerConfirmModal.component';
import { cancellationConfirmModal } from './cancellationConfirmModal.component';
import { addressValidationModal } from './addressValidationModal.component';
import { generateShippingLabelModal } from './generateShippingLabelModal.component';
import { offerCommentsModal} from './offerCommentsModal.component'

export const modals = angular.module('fp:customerportal:cancellation:modals',
        [
            'ui.router',
            commonModule.name
        ])
    .component(questionnaireOfferConfirmModal.selector, questionnaireOfferConfirmModal)
    .component(cancellationConfirmModal.selector, cancellationConfirmModal)
    .component(addressValidationModal.selector, addressValidationModal)
    .component(generateShippingLabelModal.selector, generateShippingLabelModal)
    .component(offerCommentsModal.selector, offerCommentsModal);
