let touchStartDirective = () => {
    return {
        restrict: 'A',
        controller: TouchStartDirective
    };
};

class TouchStartDirective {
    /* @ngInject */
    constructor($scope, $element) {
        let onTouchStart = (event) => {
            let method = $element.attr('fp-touchstart');
            $scope.$event = event;
            $scope.$apply(method);
        };
        $element.bind('touchstart', onTouchStart);
    }
}

export default touchStartDirective;