class PasscodeService {
    /*@ngInject*/
    constructor(Restangular, constants, applicationStateService) {
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
    }

    get(rapidStatus) {
        return this.Restangular
            .one('passcode')
            .get({rapidStatus: rapidStatus})
            .then((result) => {
                this.passcode = result;
                return result;
            });
    }

    put(passcode) {
        return this.Restangular
            .one('passcode')
            .customPUT({
                Passcode: passcode
            })
            .then((result) => {
                this.applicationStateService.dataChange(this.constants.sectionTypes.emergencySettings, this.constants.dataTypes.passcode,  this.passcode, passcode, false);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }
}

export default PasscodeService;