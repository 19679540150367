import angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import componentsModule from './components';

import preferencesHomeComponent from './home.component';
import preferencesNotificationComponent from './notifications.component';
import preferencesComponent from './preferences.component';

export default angular
    .module('fp:customerportal:account:preferences',
    [
        'ui.router',
        commonModule.name,
        componentsModule.name
    ])
    .config(/*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('account.preferences',
            {
                url: '/preferences',
                template: '<fp-preferences-home></fp-preferences-home>',
                data: {
                    navigationMenu: constants.navigationMenuSections.majorSections.account,
                    navigationSubSection: constants.navigationMenuSections.subSections.preferences,
                    subnav: [
                        {
                            name: 'System Login Information',
                            shortName: 'Login Info',
                            icon: 'fa-shield',
                            href: '#preferences'
                        },
                        {
                            name: 'Notifications',
                            shortName: 'Notifications',
                            icon: 'fa-bell',
                            href: '#notifications'
                        }
                    ]
                }
            })
            .state('account.preferences.preferences', {
                data: {
                    scrollTo: 'preferences'
                }
            })
            .state('account.preferences.notifications', {
                data: {
                    scrollTo: 'notifications'
                }
            });
    })
    .component('fpPreferencesHome', preferencesHomeComponent)
    .component('fpPreferencesNotifications', preferencesNotificationComponent)
    .component('fpPreferences', preferencesComponent);


