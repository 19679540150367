import angular from 'angular';
import 'angular-ui-router';
import 'angular-ui-bootstrap';

import _ from 'lodash';
import moment from 'moment';

import 'angular-moment';

import 'angulartics';
import 'angulartics-segment';

import constantsModule from './constants';

import dataModule from './data';
import extensionsModule from './extensions';
import alertModule from './alert';
import dialogModule from './dialog';
import utilModule from './util';
import spinnerModule from './spinner';
import loggerModule from './logger';
import creditcardModule from './creditcard';
import maskedPasscodeModule from './maskedPasscode';
import mobileModule from './mobile';
import uiModule from './ui';
import serviceModule from './service';
import modalsModule from './modals';

export default angular.module('fp:customerportal:common', [
    'ui.router',
    'ui.bootstrap',
    'angularMoment',

    'angulartics',
    'angulartics.segment',

    constantsModule.name,
    dataModule.name,
    extensionsModule.name,
    alertModule.name,
    dialogModule.name,
    utilModule.name,
    spinnerModule.name,
    loggerModule.name,
    creditcardModule.name,
    maskedPasscodeModule.name,
    mobileModule.name,
    uiModule.name,
    serviceModule.name,
    modalsModule.name
])
    .constant('_', _)
    .constant('angular', angular)
    .constant('Modernizr', Modernizr) // global

    .config( /*@ngInject*/($logProvider, constants) => {
        $logProvider.debugEnabled(constants.debug === true);
    })

    .config( /*@ngInject*/(RestangularProvider, constants) => {
        RestangularProvider.setBaseUrl(constants.api.prefix);
        RestangularProvider.setDefaultHttpFields({
            timeout: constants.api.timeout,
            warningAfter: constants.api.warningAfter
        });
    })

    .config(/*@ngInject*/($analyticsProvider, constants) => {
        $analyticsProvider.firstPageview(false);
        $analyticsProvider.withAutoBase(false);
        $analyticsProvider.virtualPageviews(true);
        $analyticsProvider.excludeRoutes([constants.fpRegex.segmentExcludedRoutes]);

        $analyticsProvider.developerMode(!window.analyticsConfiguration || window.analyticsConfiguration.enabled === false);

        const appInsights = window.appInsights;

        $analyticsProvider.registerPageTrack((path) => {
            appInsights.trackPageView(path, null, appInsights.config.__superProperties);
        });

        $analyticsProvider.registerEventTrack((eventName, eventProperties) => {
            let properties = {};
            let measurements = {};

            _.each(eventProperties, (value, key) => {
                if (_.isNumber(value)) {
                    measurements[key] = parseFloat(value);
                } else {
                    properties[key] = value;
                }
            });

            appInsights.trackEvent(eventName, properties, measurements);
        });

        $analyticsProvider.registerSetSuperProperties((properties) => {
            appInsights.config.__superProperties = _.merge({}, appInsights.config.__superProperties, properties);
        });
    })

    .config(/*@ngInject*/($urlMatcherFactoryProvider) => {
        $urlMatcherFactoryProvider.caseInsensitive(true);
        $urlMatcherFactoryProvider.strictMode(false);
    })

    .run( /*@ngInject*/($log, constants) => {
        const appInfo = constants.appInfo;
        const dateFormat = 'llll';
        $log.info(appInfo.title + ' started ' + moment().format(dateFormat));
        $log.info('[Version] ' + appInfo.version);
        $log.info('[Build Date] ' + moment(new Date(appInfo.buildDate)).format(dateFormat));
    })

    .run(/*@ngInject*/($rootScope, $analytics, mobileAppContext, constants, dataContext) => {
        $rootScope.$on(constants.events.data.userInfoReceived, (e, user) => {
            $analytics.setUsername(user.UserName);
            $analytics.setSuperProperties({
                UserId: user.UserId,
                UserName: user.UserName
            });

            $analytics.setOptOut(dataContext.user.hasSetting(constants.suppressAnalyticsSettingKey, 'true'));
        });

        const setCustomDimension = () => {
            const isMobileApp = mobileAppContext.get();
            const custom = {
                Source: isMobileApp ? 'Mobile App' : 'Browser'
            };

            $analytics.setSuperProperties(custom);
        };

        setCustomDimension();
        $rootScope.$on(constants.events.mobile.contextChanged, () => {
            setCustomDimension();
        });
    });
