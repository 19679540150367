import { IWindowService } from 'angular';
import { IService as IRestangularService } from 'restangular';
import { IStateService } from 'angular-ui-router';

import * as _ from 'lodash';
import * as moment from 'moment';
import error from '../util/error';

class ApplicationStateDataService {

    Restangular: IRestangularService;
    constants: any;
    $state: IStateService;
    $window: IWindowService;
    mobileAppContext: any;

    /*@ngInject*/
    constructor(
        Restangular: IRestangularService,
        constants: any,
        $state: IStateService,
        $window: IWindowService,
        mobileAppContext: any
    ) {
        'ngInject';
        this.Restangular = Restangular;
        this.constants = constants;
        this.$state = $state;
        this.$window = $window;
        this.mobileAppContext = mobileAppContext;
    }

    insertState(state: any) {
        return this.Restangular
            .withConfig((config) => {
                config.setDefaultHttpFields({
                    ignoreLoadingBar: true
                });
            })
            .all('applicationState')
            .customPUT(state);
    }

    error(sectionType: string, description: any) {
        return this.insertState(this._generatePersistedState(sectionType, this.constants.actionTypes.error, '', error.parse(description)));
    }

    change(sectionType: string, actionType: string, description: string, dataType?: any) {
        return this.insertState(this._generatePersistedState(sectionType, actionType, !_.isNil(dataType) ? dataType : '', description));
    }

    changeAnonymous(sectionType: string, actionType: string, description: string, dataType?: any) {
        return this.insertState(this._generatePersistedState(sectionType, actionType, !_.isNil(dataType) ? dataType : '', description));
    }

    dataChange(sectionType: string, dataType: any, prevObject: any, nextObject: any, isObject?: boolean, identifier?: any) {
        let description = this._generateDescription(prevObject, nextObject, isObject);

        if (!_.isNil(identifier)) {
            description = 'ID: ' + identifier + '. ' + description;
        }

        return this.insertState(this._generatePersistedState(sectionType, this.constants.actionTypes.dataChange, dataType, description));
    }

    dataChangeDispatches(sectionType: string, dataType: any, prevObjects: any, nextObjects: any) {
        let customDescription = '';
        //since we only pass in the updated dispatches we need to cycle through the previous dipatched(full list) and updated dispatches
        _.forEach(nextObjects, (newDispatch: any) => {
            //case for new dispatch
            if (_.isNil(newDispatch.AlarmContactID))
                customDescription += '[' + this._dispatchToString(newDispatch) + '] has been added';

            _.forEach(prevObjects, (oldDispatch: any) => {
                //case for update on existing dispatch
                if (oldDispatch.AlarmContactID === newDispatch.AlarmContactID) {
                    if (oldDispatch.FirstName !== newDispatch.FirstName ||
                        oldDispatch.LastName !== newDispatch.LastName ||
                        oldDispatch.Phone.replace(/-/g, '') !== newDispatch.Phone.replace(/-/g, '') ||
                        oldDispatch.Priority !== newDispatch.Priority)

                        customDescription += '[' + this._dispatchToString(oldDispatch) + '] changed to [' + this._dispatchToString(newDispatch) + ']. |';
                    //case for soft deletion of dispatch
                    else if (oldDispatch.IsActive !== newDispatch.IsActive)
                        customDescription += 'AlarmContactID: [' + newDispatch.AlarmContactID + '] has been deleted. |';
                }
            });

        });
        return this.insertState(this._generatePersistedState(sectionType, this.constants.actionTypes.dataChange, dataType, _.trimEnd(customDescription, '|')));
    }

    /**
     * Generate the state object that is persisted to the server.
     * 
     * @param {int} sectionType 
     * @param {int} actionType 
     * @param {string} description 
     * @returns {Object} 
     */
    _generatePersistedState(sectionType: string, actionType: string, dataType: any, description: string) {
        return {
            DateCreatedUTC: moment().toISOString(),
            Description: description,
            ActionType: actionType,
            SectionType: sectionType,
            DataType: dataType,
            Version: this.constants.appInfo.version,
            Current: JSON.stringify(this.$state.current),
            Url: this.$window.location.href,
            Source: this.mobileAppContext.get() ? 'Mobile App' : 'Browser'
        };
    }

    /**
     * Generate the custom description from prev and next object.
     * 
     * @param {Object} action 
     * @param {Object} state 
     * @param {bool} isObject 
     * @returns {string} custom description 
     */
    _generateDescription(prevObject: any, nextObject: any, isObject: any) {

        let description = '';

        //if not an object just show previous and new value
        if (!_.isNil(isObject) && isObject === false)
            description += '[' + prevObject + '] changed to [' + nextObject + ']';
        //case for object, need to cycle through properties
        else
            _.forEach(_.keys(prevObject), (key: any) => {
                description += !_.isEqual(prevObject[key], nextObject[key]) ? key + ': changed from [' + prevObject[key] + '] to [' + nextObject[key] + '] .| ' : '';
            });

        return _.trimEnd(description, '| ');
    }

    _dispatchToString(dispatch: any) {
        return `${dispatch.FirstName} ${dispatch.LastName}, ${dispatch.Phone.replace(/-/g, '')}, Priority: ${dispatch.Priority}`;
    }
}

export default ApplicationStateDataService;