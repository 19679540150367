import template from './paymentHistoryModal.html';
import _ from 'lodash';
import './paymentHistory.scss';

const PAGE_SIZE = 20;

class PaymentHistoryModalController{
    /*@ngInject*/
    constructor ($q, $timeout, $log, dataContext, alertService, uiGridConstants) {
        this.ALERT_DIALOG = 'PaymentHistoryModalAlert';
        this.$dismiss = this.config.dismiss;

        this.$timeout = $timeout;
        this.dataContext = dataContext;
        this.alertService = alertService;
        this.$log = $log;
        this.$q = $q;

        this.uiGridConstants = uiGridConstants;

        this.invoiceGridOptions = this.getDefaultGridOptions();
        this.paymentGridOptions = this.getDefaultGridOptions();
    }

    $onInit() {
        this.refresh();
    }

    close() {
        this.$dismiss();
    }

    refresh() {
        this.isLoading = true;

        return this.$q.all([
            this.dataContext.payment.getAccountingInfo(),
            this.dataContext.payment.getPaymentsApplied()
        ]).then((results) => {
            let accountingInfo = results[0];
            let paymentsApplied = results[1];
            let matchedPayments = this._matchReceivables(accountingInfo, paymentsApplied);

            this.totalInvoices = accountingInfo.TotalInvoices;
            this.totalPayments = accountingInfo.TotalPayments;
            this.balance = accountingInfo.Balance < 0 ? 0 : accountingInfo.Balance;

            let data = {
                accountingInfo,
                paymentsApplied,
                matchedPayments: matchedPayments
            };

            return this.$timeout(() => {
                this.populateInvoiceGrid(data);
                this.populatePaymentsGrid(data);
            });
        })
        .catch((error) => {
            const message = 'There was a problem getting the payment history';
            this.$log.error(message, error);
            this._showAlert(message);
        })
        .finally(() => {
            this.isLoading = false;
        });
    }

    getDefaultGridOptions() {
        return {
            enableSorting: true,
            enableFiltering: false,
            enableGridMenu: false,
            enableColumnMenus: false,
            enableVerticalScrollbar: this.uiGridConstants.scrollbars.NEVER,
            enableHorizontalScrollbar: this.uiGridConstants.scrollbars.NEVER,
            enablePagination: true,
            enablePaginationControls: true,
            paginationPageSizes: [PAGE_SIZE]
        };
    }

    getGridPagingOptions(data) {
        let dataLength = _.size(data);
        let enablePagination = dataLength > PAGE_SIZE;

        return {
            minRowsToShow: Math.min(dataLength, PAGE_SIZE) + 1.1,
            enablePagination: enablePagination,
            enablePaginationControls: enablePagination
        };
    }

    populateInvoiceGrid(data) {
        let gridData = _.filter(data.matchedPayments, (d) => {
            return (_.includes(d._documentType, 'Invoice')
                || (_.includes(d._documentType, 'Debit') && _.includes(d._description, 'Failed Payment'))
                || (_.includes(d._documentType, 'Debit') && _.includes(d._description, 'Account Charge')))
                && !_.includes(d._documentType, 'PM-Invoice')
                && d._amountDue !== 0;
        });

        let gridOptions = _.merge({}, this.getDefaultGridOptions(), this.getGridPagingOptions(gridData), {
            columnDefs: [{
                displayName: 'Date',
                field: '_invoiceDate',
                type: 'date',
                cellFilter: 'date:\'MM-dd-yyyy\'',
                cellTooltip: 
                    (row, col) => {
                        return row.entity._invoiceDate;
                    }

            }, {
                displayName: 'Amount',
                field: '_amountDue',
                cellFilter: 'currency',
                cellTooltip: 
                 (row, col) => {
                     return row.entity._amountDue;
                 }
            }, {
                displayName: 'Description',
                field: '_description',
                cellTooltip: 
                    (row, col) =>{
                        return row.entity._description;
                    }
            }, {
                displayName: 'Payment Date',
                field: '_paidDate',
                type: 'date',
                cellFilter: 'date:\'MM-dd-yyyy\'',
                cellTooltip: 
                 (row, col) => {
                     return row.entity._paidDate;
                 }
            }]
        });
        gridOptions.data = gridData;
        this.invoiceGridOptions = gridOptions;
    }

    populatePaymentsGrid(data) {
        let gridData = _.filter(data.matchedPayments, (d) => {
            return (_.includes(d._documentType, 'Payment') 
                || _.includes(d._documentType, 'Credit')) 
                    &&  d._paidAmount !== 0; 
        });

        let gridOptions = _.merge({}, this.getDefaultGridOptions(), this.getGridPagingOptions(gridData), {
            columnDefs: [{
                displayName: 'Date',
                field: '_paidDate',
                type: 'date',
                cellFilter: 'date:\'MM-dd-yyyy\'',
                cellTooltip: 
                   (row, col) => {
                       return row.entity._paidDate;
                   }

            }, {
                displayName: 'Amount Paid',
                field: '_paidAmount',
                cellFilter: 'currency',
                cellTooltip: 
                   (row, col) => {
                       return row.entity._paidAmount;
                   }
            }, {
                displayName: 'Description',
                field: '_description',
                cellTooltip: 
                    (row, col) => {
                        return row.entity._description;
                    }
            }, {
                displayName: 'Payment Method',
                field: '_paymentMethod',
                cellTooltip: 
                   (row, col) => {
                       return row.entity._paymentMethod;
                   }
            }]
        });
        gridOptions.data = gridData;
        this.paymentGridOptions = gridOptions;
    }

    _showAlert(dialogMessage) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(`${dialogMessage}`)
            .setType('danger')
            .setTimeout(5000)
            .open();
    }

    _matchReceivables(accountingInfo, paymentsApplied) {
        let copy = _.cloneDeep(accountingInfo.Receivables);

        //filter refunds
        _.forEach(copy, (r) => {
            if (!_.isNil(r)) {
                if (r.DocumentType === 'PM-Payment') {
                    let docNumber = r.DocumentNumber.substring(r.DocumentNumber.length - 12, r.DocumentNumber.length);

                    //the match is based on right 12 on the document number
                    let linkedItems = _.filter(copy, (item) => {
                        return item.DocumentNumber.substring(item.DocumentNumber.length - 12, item.DocumentNumber.length) === docNumber && item.DocumentType !== 'PM-Payment';
                    });

                    //this is just a refund, only has document type PM-Invoice
                    if (linkedItems.length === 1) {
                        r.DocumentDescription = 'Refund';
                    }
                    //this is an equipment refund, has PM-Invoice and Debit Memo
                    if (linkedItems.length === 2) {
                        r.DocumentDescription = 'Refund'; //Equipment Refund
                    }

                    if (linkedItems.length !== 0) {
                        //remove other items from the list
                        _.remove(copy, (item) => {
                            return item.DocumentNumber.substring(item.DocumentNumber.length - 12, item.DocumentNumber.length) === docNumber && item.DocumentType !== 'PM-Payment';
                        });
                    }
                }
            }
        });

        _.forEach(copy, (r) => {
            r._description = r.DocumentDescription;
            r._documentType = r.DocumentType;

            if (_.includes(r.DocumentNumber, 'STDINV')) { //document numbers that start with this are rmr
                r._description = 'Payment'; //RMR
            } else if (_.includes(r.DocumentNumber, 'WB00')) { //document numbers that start with this are equipment purchases
                r._description = 'Payment'; //Equipment
            }

            r._amountDue = r.OriginalDocumentAmount;
            r._invoiceDate = r.DueDate;

            r._paidAmount = r._amountDue;
            r._paidDate = r._invoiceDate;

            if (r.DocumentType === 'Invoice') {
                r._paidAmount = null;
                r._paidDate = null;
                r._description = 'Invoice';

                let payments = _.filter(paymentsApplied, (p) => {
                    return _.trim(p.AppliedToDocNumber) === _.trim(r.DocumentNumber);
                });
                
                if (!_.isNil(payments) && payments.length > 0) {
                    if (payments.length == 1)
                    {
                        let payment = payments[0];
                        r._paidAmount = _.isNumber(payment.AmountApplied)
                            ? payment.AmountApplied
                            : ((_.isNumber(payment.OriginalTrxAmount) && payment.OriginalTrxAmount < 0) ? Math.abs(payment.OriginalTrxAmount) : null);

                        r._paidDate = _.isDate(payment.ApplyDocumentDate)
                            ? payment.ApplyDocumentDate
                            : payment.DocumentDate;
                    } else {
                        let pmt = payments[payments.length - 1];

                        _.forEach(payments, (p) => {
                            r._paidAmount += _.isNumber(p.AmountApplied)
                                ? p.AmountApplied
                                : ((_.isNumber(p.OriginalTrxAmount) && p.OriginalTrxAmount < 0) ? Math.abs(p.OriginalTrxAmount) : 0);
                        });

                        r._paidDate = _.isDate(pmt.ApplyDocumentDate)
                            ? pmt.ApplyDocumentDate
                            : pmt.DocumentDate;
                    }
                }
            } else if (r.DocumentType === 'VOID-Invoice') { 
                r._description = 'Voided Invoice'; 
            } else if (r.DocumentType === 'PM-Payment') { 
                r._description = 'Refund'; 
            } else if (r.DocumentType === 'Credit Memo') { 
                r._description = 'Credit'; 
            } else if (r.DocumentType === 'Debit Memo') {
                if (_.includes(r.DocumentDescription, 'Voided PMT')) {
                    r._description = 'Failed Payment';
                } else {
                    if (_.includes(r.DocumentNumber, 'DEBIT')) {
                        r._description = 'Account Charge';
                        r._paidDate = null;

                        let payments = _.filter(paymentsApplied, (p) => {
                            return _.trim(p.AppliedToDocNumber) === _.trim(r.DocumentNumber);
                        });

                        if (!_.isNil(payments) && payments.length > 0) {
                            if (payments.length == 1)
                            {
                                let payment = payments[0];
                                r._paidDate = _.isDate(payment.ApplyDocumentDate)
                                    ? payment.ApplyDocumentDate
                                    : payment.DocumentDate;
                            } else {
                                let pmt = payments[payments.length - 1];
                                r._paidDate = _.isDate(pmt.ApplyDocumentDate)
                                    ? pmt.ApplyDocumentDate
                                    : pmt.DocumentDate;
                            }
                        }
                    } else {
                        r._description = 'Debit';
                    }
                }
            } else if (r.DocumentType === 'Equipment Return') {
                r._description = 'Return'; //Equipment Return
            }
        });

        let voidedPayments = [];
        _.forEach(copy, (r) => { // second pass
            if (r.DocumentType === 'Payment') {
                r._paidAmount = Math.abs(r.OriginalDocumentAmount);
                r._paidDate = r.DocumentDate;
                r._paymentMethod = r.CheckNumber;

                let appliedInvoiceDocumentNumbers = _.map(paymentsApplied, (p) => {
                    return (_.trim(p.DocumentNumber) === _.trim(r.DocumentNumber) && !_.isEmpty(p.AppliedToDocNumber))
                        ? _.trim(p.AppliedToDocNumber)
                        : undefined;
                });
                r._invoices = _.filter(copy, (p) => {
                    return _.some(appliedInvoiceDocumentNumbers, (num) => {
                        return num === _.trim(p.DocumentNumber);
                    });
                });

                //if the payment is matched to an rmr, change the payment description
                if (r._invoices.length !== 0) {
                    _.forEach(r._invoices, (invoice) => {
                        if (_.includes(invoice.DocumentNumber, 'STDINV'))
                            r._description = 'Payment'; //RMR
                    });
                }

            } else if (r.DocumentType === 'VOID-Payment') {
                let payment = _.find(copy, (p) => {
                    return _.startsWith(_.trim(r.DocumentNumber), _.trim(p.DocumentNumber));
                });

                voidedPayments.push(r);
                if (!_.isNil(payment)) {
                    voidedPayments.push(payment);
                }
            }
        });

        _.pullAll(copy, voidedPayments);

        return copy;
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: PaymentHistoryModalController
};