import template from './breadcrumbs.html';
import _ from 'lodash';
import './breadcrumbs.scss';

class BreadcrumbsController {
    /*@ngInject*/
    constructor(breadcrumbsService) {
        this.breadcrumbsService = breadcrumbsService;
    }

    showBreadcrumbs() {
        return this.breadcrumbsService.hasBreadcrumbs('movingPortal');
    }

    getBreadcrumbs() {
        return _.isNil(this.relocationInfo)
            ? this.breadcrumbsService.getCurrentBreadcrumbs('movingPortal', 'pre')
            : this.breadcrumbsService.getCurrentBreadcrumbs('movingPortal', 'post');
    }
}

export default {
    template: template,
    bindings: {
        relocationInfo: '<'
    },
    controller: BreadcrumbsController
};