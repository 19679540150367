import template from './phoneLink.html';
import _ from 'lodash';
class PhoneController {
    /*@ngInject*/
    constructor (constants, $timeout) {
        this.constants = constants;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.$timeout(() => {
            if (_.isNil(this.number))
                this.number = this.constants.customerSupport.phone;
            if (_.isNil(this.showCountry))
                this.showCountry = true;
        }, 1);
    }
}

export default {
template: template,
    bindings: {
        number: '<',
        showCountry: '<'
    },
    controller: PhoneController
};