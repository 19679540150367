import 'smart-app-banner/dist/smart-app-banner.css';
import SmartAppBanner from 'smart-app-banner';

class SmartAppBannerController {
    /*@ngInject*/
    constructor() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (!(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
            new SmartAppBanner({
                daysHidden: -1, // days to hide banner after close button is clicked (defaults to 15)
                daysReminder: -1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
                appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
                title: 'Frontpoint',
                author: 'Frontpoint',
                button: 'VIEW',
                store: {
                    ios: 'On the App Store',
                    android: 'In Google Play',
                    windows: 'In Windows store'
                },
                price: {
                    ios: 'FREE',
                    android: 'FREE',
                    windows: 'FREE'
                },
                icon: '/content/images/login_frontpoint_key.png' // full path to icon image if not using website icon image
                //, theme: 'android' // put platform type ('ios', 'android', etc.) here to force single theme on all device
                //,
                //force: 'ios' // Uncomment for platform emulation
            });
        }
    }
}

export default {
    bindings: {},
    controller: SmartAppBannerController
};