import moment from 'moment';
import template from './footer.html';

class FooterController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }

    getYear() {
        return moment().year();
    }
}

export default {
    template: template,
    bindings: {},
    controller: FooterController
};