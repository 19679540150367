import _ from 'lodash';

class CustomerSupportHoursService {
    /*@ngInject*/
    constructor(Restangular, $log, constants) {
        this.Restangular = Restangular;
        this.$log = $log;
        this.constants = constants;
    }

    getCompanyHolidays() {
        return this.Restangular
            .all('support')
            .one('holidays')
            .get();
    }
}

export default CustomerSupportHoursService;