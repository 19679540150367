import _ from 'lodash';

import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor($log, dataContext, $q) {
        this.$log = $log;
        this.dataContext = dataContext;
        this.mastercode = null;
        this.permit = null;
        this.dispatch = [];
        this.predispatch = [];
        this.postdispatch = [];
        this.testmode = { IsEnabled: false };
        this.$q = $q;

        this.rapidConnectionStatus = true;
    }

    $onInit() {
        this.getPingRapid()
            .then((result) => {
                this.rapidConnectionStatus = !_.isNil(result) && result === true;
                this.$q.all([
                    this.getDispatches(this.rapidConnectionStatus),
                    this.getTestMode(this.rapidConnectionStatus),
                    this.getPasscode(this.rapidConnectionStatus),
                    this.getPermit()
                ]);
            });
    }

    getDispatches(rapidStatus) {
        this.isDispatchLoading = true;
        return this.dataContext.dispatch.get(rapidStatus)
            .then((result) => {
                _.forEach(result, c => {
                    // The server may return phone numbers that already contain seperators. Remove all non number 
                    // characters. The correct seperator(s) will be added by an angular filter. 
                    c.Phone = c.Phone.replace(/[^0-9]/g, '');
                });

                this.dispatch = _.cloneDeep(result);
                this.predispatch = _.cloneDeep(_.filter(result, c => c.IsPreDispatch));
                this.postdispatch = _.cloneDeep(_.filter(result, c => !c.IsPreDispatch));
            })
            .catch((error) => {
                this.$log.error('Error fetching dispatch contacts.', error);
            })
            .finally(() => {
                this.isDispatchLoading = false;
            });
    }

    getTestMode(rapidStatus) {
        this.isTestModeLoading = true;
        return this.dataContext.testmode.get(rapidStatus)
            .then((result) => {
                this.testmode = _.cloneDeep(result);
            })
            .catch((error) => {
                this.$log.error('Error fetching testmode.', error);
            })
            .finally(() => {
                this.isTestModeLoading = false;
            });
    }

    getPasscode(rapidStatus) {
        this.isPasscodeLoading = true;
        return this.dataContext.passcode.get(rapidStatus)
            .then((result) => {
                this.passcode = _.cloneDeep(result);
            })
            .catch((error) => {
                this.$log.error('Error fetching passcode.', error);
            })
            .finally(() => {
                this.isPasscodeLoading = false;
            });
    }

    getPermit() {
        this.isPermitLoading = true;
        return this.dataContext.permit.get()
            .then((result) => {
                this.permit = _.cloneDeep(result);
            })
            .catch((error) => {
                this.$log.error('Error fetching permit number.', error);
            })
            .finally(() => {
                this.isPermitLoading = false;
            });
    }

    getPingRapid() {
        return this.dataContext.account.pingRapid();
    }

}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};