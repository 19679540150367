import template from './shippingAddress.html';

class ShippingAddressController {
    /*@ngInject*/
    constructor(constants, dataContext, monitoringPlansService) {
        this.constants = constants;
        this.isCameraPlan = monitoringPlansService.isSAVPlan(dataContext.user.current.ServicePlanInfo);
    }
}

export default {
template: template,
    bindings: {
        customerInfo: '<',
        sameAddress: '=',
        isEditing: '&',
        onSaveCore: '&',
        states: '<'
    },
    controller: ShippingAddressController
};