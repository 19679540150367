import * as _ from 'lodash';
import * as angular from 'angular';
import 'angular-ui-router';
import 'ng-idle';
import 'angular-scroll';
import ngIfBootstrapGrid from 'ng-if-bootstrap-grid';

import commonModule from '../common';
import setupModule from '../setup';

import NavMenuItemsService from './navMenuItems.service';
import HeaderService from './header.service';

import './shell.scss';

import shellComponent from './shell.component';
import headerComponent from './header.component';
import footerComponent from './footer.component';
import navComponent from './nav.component';
import mobileFooterComponent from './mobileFooter.component';

import { HomeComponentController } from './home.component';

export default angular.module('fp:customerportal:shell', [
        'ui.router',
        'ngIdle',
        'ui.bootstrap',
        'duScroll',
        'ngIdle',
        ngIfBootstrapGrid.name,
        commonModule.name,
        setupModule.name
    ])

    .config(/*@ngInject*/ ($stateProvider) => {
        $stateProvider
            .state('secure', {
                abstract: true,
                url: '',
                template: '<fp-shell></fp-shell>',
                resolve: {
                    initializeToken: /*@ngInject*/ (dataContext) => {
                        return dataContext.user.initialize();
                    },
                    userInfo: /*@ngInject*/ (dataContext, initializeToken) => {
                        return dataContext.user.getUserInfo();
                    }
                },
                onEnter: /*@ngInject*/ (Idle, Title) => {
                    Title.restore();
                    Idle.watch();
                },
                onExit: /*@ngInject*/ (Idle) => {
                    Idle.unwatch();
                }
            })
            .state('home', {
                url: '/home?redirectTo&anchor',
                controller: HomeComponentController,
                resolve: {
                    initializeToken: /*@ngInject*/ (dataContext) => {
                        return dataContext.user.initialize();
                    },
                    userInfo: /*@ngInject*/ (dataContext, initializeToken) => {
                        return dataContext.user.getUserInfo();
                    }
                }
            });
    })

    .run(/*@ngInject*/ ($rootScope, $document, $state, constants, dataContext) => { // autoscroll to top on state change, using instead of autoscroll="true" to ensure it includes the header
        $rootScope.$state = $state;
        $rootScope.$on(constants.events.stateChangeSuccess, (ev, to, toParams, from, fromParams) => {
            //log state change to database
            if (dataContext.user.isAuthenticated()) {
                //log state change to database
                dataContext.applicationState.change(
                    !_.isNil(to.data) && !_.isNil(to.data.sectionType) ? to.data.sectionType : 'Unknown',
                    constants.actionTypes.stateChange,
                    'from [' +  from.name  + '] to [' +  to.name  + ']');
            }

            $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
            $rootScope.$broadcast(constants.events.hideMenu);
        });
    })
    .run(/*@ngInject*/ ($window, $rootScope, constants, Idle) => {
        $window.addEventListener('message', event => {
            Idle.watch();
            if (event.data === constants.events.iframe.youtube) {
                Idle.setIdle(constants.idle.extendedTimeout);
            } else {
                Idle.setIdle(constants.idle.timeout);
            }
            $rootScope.$broadcast(constants.events.hideMenu, false);
        });
    })
    .run(/*@ngInject*/($rootScope, constants, Idle) => {
        $rootScope.$on(constants.events.stateChangeStart, (event, toState, toParams, fromState, fromParams) => {
            const idleTimeout = Idle.getTimeout();
            if (idleTimeout !== constants.idle.timeout) {
                Idle.setIdle(constants.idle.timeout);
            }
        });

    })

    .value('duScrollBottomSpy', true)
    .value('duScrollOffset', 116)
    .service('navMenuItems', NavMenuItemsService)
    .service('headerService', HeaderService)

    .component('fpShell', shellComponent)
    .component('fpHeader', headerComponent)
    .component('fpFooter', footerComponent)
    .component('fpNav', navComponent)
    .component('fpMobileFooter', mobileFooterComponent);
