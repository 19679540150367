class TrackOrderShellController {

    overridden: boolean;

    /*@ngInject*/
    constructor() {
        this.overridden = false;
    }

    override() {
        this.overridden = true;
    }

}

export default {
    template: require('./shell.html'),
    bindings: {},
    controller: TrackOrderShellController
};