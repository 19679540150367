import template from './movingForm.html';

import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

class MovingFormController {
    /*@ngInject*/
    constructor($q, dataContext, $state, movingService, constants, alertService, segment) {
        this.$state = $state;
        this.$q = $q;
        this.dataContext = dataContext;
        this.movingService = movingService;
        this.alertService = alertService;
        this.format = 'MM/dd/yyyy';
        this.splitForm = false;
        this.isMobile = document.getElementsByClassName('ua-mobile').length > 0;
        this.setDisplayResidenceForm(true);
        this.setContinueButton(true);
        this.datePickerStatus = { opened: false };
        this.constants = constants;
        this.movingAddress = [];
        this.ALERT_DIALOG = 'addresses';
        this.segment = segment;
    }

    $onInit() {
        this.onSetStep({ step: 'newResidence' });
    }

    onCancelCore() {
        this.segment.registerEvent('Move Portal - Cancel Move Details', {});
        this.customerInfo.PremisesAddress = this.pristine.PremisesAddress;
        if (!this.splitForm) {
            this.$state.go('moving');
        }
        this.splitTheForm(false);
    }

    $onChanges() {
        this.dateOptions = {
            minDate: moment().startOf('hour').toDate(),
            maxDate: moment().add(6, 'month').toDate(),
            showWeeks: false,
            maxMode: 'day',
            dateDisabled: (input) => this._isHoliday(input)
        };
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.customerInfo);
        if (!_.isNil(this.customerInfo) && !_.isNil(this.relocationInfo)) {
            this.isMoveActive = _.isNil(this.relocationInfo.AddressProcessedDate) ||
                _.isNil(this.relocationInfo.OrderProcessedDate);

            this.movingAddress.Line1 = this.relocationInfo.Line1;
            this.movingAddress.Line2 = this.relocationInfo.Line2;
            this.movingAddress.City = this.relocationInfo.City;
            this.movingAddress.State = this.relocationInfo.State;
            this.movingAddress.PostalCode = this.relocationInfo.PostalCode;
            this.dateRelocating = new Date(this.relocationInfo.DateRelocating);
        }
    }

    splitTheForm(value) {
        this.splitForm = value;
        this.showNode({ val: value });
    }

    onClickContinue(form) {
        form.attempt = true;
        if (form.$invalid) {
            return false;
        } else {
            const address = {
                line1: this.movingAddress.Line1,
                line2: this.movingAddress.Line2,
                city: this.movingAddress.City,
                state: this.movingAddress.State,
                postalCode: this.movingAddress.PostalCode
            };

            return this.validateAddress(address) //check premise address
                .then((premise) => {
                    this.newResidenceData = {
                        date: this.dateRelocating,
                        address: premise
                    };
                }).then(() => {
                    this.onSetStep({ step: 'delivery' });
                    this.setDisplayResidenceForm(false);
                    this.setContinueButton(false);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    onSave(form) {
        form.attempt = true;
        form.movingDelForm.attempt = true;
        if (form.$valid) {
            return this.onSaveCore();
        } else {
            return false;
        }
    }

    onUpdate(form) {
        form.attempt = true;
        form.movingDelForm.attempt = true;
        if (form.$valid) {
            return this.onSaveCore(true, this.relocationInfo.OrderID);
        } else {
            return false;
        }
    }

    onSaveCore(updateExisting = false, orderId = undefined) {
        this.segment.registerEvent('Move Portal - Submit Move Details', {});
        this.isLoading = true;
        this.disableButton = true;
        const address = !_.isNil(this.newResidenceData) ? this.newResidenceData.address : this.movingAddress;
        const dateRelocating = !_.isNil(this.newResidenceData) ? this.newResidenceData.date : this.dateRelocating;
        const addressProcessedDate = !_.isNil(this.relocationInfo) ? this.relocationInfo.AddressProcessedDate : null;
        const isSameAddress = this.moversKitAddress.isSameAddress;

        const relocationAddress = {
            line1: address.Line1 || address.Address1,
            line2: address.Line2 || address.Address2,
            city: address.City,
            state: address.State,
            postalCode: address.PostalCode,
            dateRelocating: dateRelocating || this.dateMoving,
            orderProcessingDate: this.moversKitDate,
            addressProcessedDate: addressProcessedDate,
            shippingAddress: {
                line1: this.moversKitAddress.Address1,
                line2: this.moversKitAddress.Address2,
                city: this.moversKitAddress.City,
                state: this.moversKitAddress.State,
                postalcode: this.moversKitAddress.PostalCode
            }
        };

        let validPremise, validShipping;
        return this.validateAddress(relocationAddress) //check premise address
            .then((premise) => {
                validPremise = premise;
                return;
            }).then(() => { //check shipping address
                const shippingAddress = relocationAddress.shippingAddress;
                shippingAddress.postalCode = _.trim(relocationAddress.shippingAddress.postalcode);
                shippingAddress.postalcode = _.trim(relocationAddress.shippingAddress.postalcode);
                if (!isSameAddress) {
                    return this.validateAddress(shippingAddress);
                } else {
                    return this.$q.resolve(validPremise);
                }
            }).then((shipping) => {
                validShipping = shipping;
            }).then(() => {
                const updatedAddress = relocationAddress;
                if (!_.isNil(validPremise)) {
                    updatedAddress.city = validPremise.City;
                    updatedAddress.state = validPremise.State;
                    updatedAddress.postalCode = validPremise.PostalCode;
                }
                if (!_.isNil(validShipping)) {
                    updatedAddress.shippingAddress.city = validShipping.City;
                    updatedAddress.shippingAddress.state = validShipping.State;
                    updatedAddress.shippingAddress.postalCode = validShipping.PostalCode;
                }
                if (updateExisting) {
                    updatedAddress.orderID = orderId;
                    return this.dataContext.relocation.updateRelocationInfo(updatedAddress).catch(() => {
                        this.$state.go('moving', null, { reload: true });
                        return this.$q.reject();
                    });
                } else {
                    return this.dataContext.relocation.saveRelocationInfo(updatedAddress).catch(() => {
                        this.$state.go('moving', null, { reload: true });
                        return this.$q.reject();
                    });
                }
            }).then(() => {
                this.$state.go('moving.confirmation');
            }).finally(() => {
                this.isLoading = false;
                this.disableButton = false;
            });
    }

    _isHoliday(date) {
        return this.movingService._isHoliday(date);
    }

    showContinue() {
        if (!_.isNil(this.relocationInfo)) {
            if (!this.splitForm) {
                return true;
            } else {
                return false;
            }
        }
    }

    displayFormComponents(formComponent) {
        if (this.isMobile) {
            return formComponent === 'residenceInfo'
                ? this.displayResidenceForm
                : !this.displayResidenceForm;
        }
        return true;
    }

    displayButton(button) {
        const isUpdate = this.isMoveActive;
        let buttonType = 'continue';
        if (this.isMobile) {
            if (this.displayContinueButton) {
                buttonType = 'continue';
            } else if (isUpdate) {
                buttonType = 'update';
            } else {
                buttonType = 'complete';
            }
        } else {
            if (isUpdate) {
                buttonType = 'update';
            } else {
                buttonType = 'complete';
            }
        }
        return button === buttonType;
    }

    setDisplayResidenceForm(displayResidenceForm) {
        this.displayResidenceForm = displayResidenceForm;
    }

    setContinueButton(displayContinueButton) {
        this.displayContinueButton = displayContinueButton;
    }

    hasPOBox(address) {
        return this.dataContext.address.isPOBox(address);
    }

    datePickerFocus() {
        this.datePickerStatus.opened = true;
    }

    disableNewResidenceForm() {
        if (!_.isNil(this.relocationInfo)) {
            return !_.isNil(this.relocationInfo.AddressProcessedDate);
        }
    }

    validateAddress(address) {
        return this.dataContext.address.validateMelissaAddressModal(address)
            .then((validatedAddress) => {
                if (_.isNil(validatedAddress)) {
                    return this.$q.reject();
                }
                if (this.hasPOBox(validatedAddress)) {
                    this._showPOBoxErrorAlert();
                    return this.$q.reject();
                }
                return this.$q.resolve(validatedAddress);
            });
    }

    registerEventOnStreetAddress() {
        this.segment.registerEvent('Move Portal - Change Moving Street Address',
            {
                Address: this.movingAddress.Line1
            });
    }

    registerEventOnMovingDate() {
        this.segment.registerEvent('Move Portal - Change Moving Date',
            {
                Date: this.dateRelocating
            });
    }

    onClickContactUs() {
        this.segment.registerEvent('Move Portal - Click Contact Us Link', {});
        this.$state.go('support.contact');
    }

    _showPOBoxErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. P.O. boxes are not permitted as an address for your security system.')
            .setType('danger')
            .setTimeout(1000000)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        customerInfo: '<',
        movingStatus: '<',
        states: '<',
        companyHolidays: '<',
        showNode: '&',
        relocationInfo: '<',
        onSetStep: '&',
        step: '<'
    },
    controller: MovingFormController
};