import { Error, TrackOrderDto } from "../../trackOrder/types";

class TrackOrderService {

    rootScope: any;
    api: any;
    constants: any;
    stateService: any;
    q: any;


    /*@ngInject*/
    constructor(
        $rootScope: any,
        Restangular: any,
        constants: any,
        applicationStateService: any,
        $q: any) {
        'ngInject';
        this.rootScope = $rootScope;
        this.api = Restangular;
        this.constants = constants;
        this.stateService = applicationStateService;
        this.q = $q;
    }

    getOrders = (token: string, email: string = null, phone: string = null) => this.api.all('TrackOrder').customGET('', {
        'token': token,
        'email': email,
        'phone': phone,
    }) as Promise<TrackOrderDto[]>;

    emailOrderDetails = (token: string, orderId: number) => this.api.all('TrackOrder').all(orderId).all('email').post({ token });
}

export default TrackOrderService;