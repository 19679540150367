import * as _ from 'lodash';

export class HomeComponentController {
    constructor(
        private readonly $rootScope: any,
        private readonly $location: ng.ILocationService,
        private readonly $state: ng.ui.IStateService,
        private readonly $stateParams: ng.ui.IStateParamsService,
        private readonly constants: any,
        private readonly userInfo: { ServicePlanInfo: any },
        private readonly monitoringPlansService: any) {
        'ngInject';

    }

    public $onInit() {
        const search = (this.$location as any).$$search;
        delete search.redirectTo;
        delete search.anchor;

        const redirectTo = !_.isEmpty(this.$stateParams.redirectTo)
            ? this.$stateParams.redirectTo
            : this.$rootScope.redirectTo;
        const extendedState = this.$stateParams.anchor;

        delete this.$rootScope.redirectTo;

        if (_.isEmpty(redirectTo) || redirectTo === '/home') {
            this.monitoringPlansService.isWSFPlan(this.userInfo.ServicePlanInfo.AdcServicePlan)
                ? this.$state.go(this.constants.homeStatePMCustomers)
                : this.$state.go(this.constants.homeState);
        } else {
            if (_.has(this.constants.ssoRedirectMap, extendedState)) {
                const parent = this.constants.ssoRedirectMap[extendedState].match(/\w*[.]\w*/)[0];
                const stateArray = this.constants.ssoRedirectMap[extendedState].split('.');
                const anchor = stateArray[stateArray.length - 1];
                this.$state.go(parent, { '#': anchor });
            } else {
                this.$location.url(redirectTo);
            }
        }
    }
}

export const homeComponent = {
    controller: HomeComponentController
};
