import angular from 'angular';

import constantsModule from '../constants';

import telFilter from './tel.filter';
import maskedFilter from './masked.filter';
import trustedHtmlFilter from './trustedHtml.filter';
import trustedResourceUrlFilter from './trustedResourceUrl.filter';
import bindFilter from './bind.filter';
import bindHtmlCompileDirective from './bindHtmlCompile.directive';

import scriptDirective from './script.directive';
import textMustMatchDirective from './textMustMatch.directive';
import aDisabledDirective from './aDisabled.directive';
import preventFocusDirective from './preventFocus.directive';
import focusIfDirective from './focusIf.directive';
import setFocusDirective from './setFocus.directive';
import activationService from './activation.service';
import aLogStateDirective from './aLogState.directive';
import onLoadIframeDirective from './onLoadIframe.directive';
import pluginService from './plugin.service';
import outOfSyncService from './outOfSync.service';
import asyncSectionComponent from './asyncSection.component';
import phoneLinkComponent from './phoneLink.component';
import spinnerComponent from './spinner.component';

export default angular.module('fp:customerportal:common:util', [
    constantsModule.name
    ])
    .filter('tel', telFilter)
    .filter('masked', maskedFilter)
    .filter('trustedHtml', trustedHtmlFilter)
    .filter('trustedResourceUrl', trustedResourceUrlFilter)
    .filter('bind', bindFilter)
    .service('activationService', activationService)
    .service('pluginService', pluginService)
    .service('outOfSyncService', outOfSyncService)
    .directive('fpOnLoadIframe', onLoadIframeDirective)
    .directive('script', scriptDirective)
    .directive('fpTextMustMatch', textMustMatchDirective)
    .directive('fpADisabled', aDisabledDirective)
    .directive('fpPreventFocus', preventFocusDirective)
    .directive('fpALogState', aLogStateDirective)
    .directive('fpFocusIf', focusIfDirective)
    .directive('fpSetFocus', setFocusDirective)
    .component('fpPhoneLink', phoneLinkComponent)
    .component('fpAsyncSection', asyncSectionComponent)
    .component('fpSpinner', spinnerComponent)
    .directive('bindHtmlCompile', bindHtmlCompileDirective);