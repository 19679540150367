import angular from 'angular';
import _ from 'lodash';

class UserService {
    /*@ngInject*/
    constructor($rootScope, $q, $location, $http, $cookies, $log, Restangular, fpStore, fpDataCache, mobileAppContext, constants, $state, applicationStateService) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$location = $location;
        this.$cookies = $cookies;
        this.$http = $http;
        this.$log = $log;
        this.Restangular = Restangular;
        this.$state = $state;
        this.applicationStateService = applicationStateService;

        this.fpStore = fpStore;
        this.mobileAppContext = mobileAppContext;
        this.fpDataCache = fpDataCache;
        this.constants = constants;

        this.accessTokenKey = constants.storage.accessTokenKey;
        this.current = {};
    }

    login(username, password, captchaResponse, rememberMe = false, redirectUrl) {
        return this.Restangular
            .withConfig((config) => {
                config.setDefaultHttpFields({
                    ignoreAuthModule: true
                });
            })
            .all('Login')
            .all('RedirectUrl')
            .withHttpConfig({
                cache: false
            })
            .post({
                Username: username,
                Password: password,
                RememberMe: rememberMe,
                CaptchaResponse: captchaResponse,
                ReturnUrl: redirectUrl
            })
            .then((result) => {
                return result;
            })
            .then((result) => {
                this.applicationStateService.change(this.constants.sectionTypes.login, this.constants.actionTypes.login, 'Logged in with password.');
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.login, error);
                return this.$q.reject(error);
            });
    }

    loginWithToken(token, options = {}) {
        const section = (!_.isNil(options) && options.app === true)
            ? this.constants.sectionTypes.appLogin
            : this.constants.sectionTypes.ssoLogin;

        this.setAccessToken(token);
        return this.Restangular
            .all('Login')
            .all('sso')
            .withHttpConfig({
                cache: false
            })
            .post()
            .then((result) => {
                this.applicationStateService.change(section, this.constants.actionTypes.login, 'Logged in with SSO.');
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(section, error);
                return this.$q.reject(error);
            });
    }

    logout() {
        this.applicationStateService.change(this.constants.sectionTypes.logout, this.constants.actionTypes.other, 'The user [' + this.current.UserName + '] has logged out.');
        this.$log.info('Logging out user ' + this.current.UserName);

        const copy = _.assign({}, this.current);
        return this.Restangular
            .all('Account')
            .all('Logout')
            .withHttpConfig({
                cache: false
            })
            .post()
            .then((result) => {
                this.current = {};

                this.clearAccessToken();
                this.$rootScope.$broadcast(this.constants.events.logout, copy);
            })
            .then((result) => {
                this.applicationStateService.change(this.constants.sectionTypes.logout, this.constants.actionTypes.other, 'The user [' + copy.UserName + '] has logged out.');
                return result;
            });
    }

    changePassword(token, username, oldPassword, newPassword, confirm, securityQuestionResponse) {
        return this.Restangular
            .withConfig((config) => {
                config.setDefaultHttpFields({
                    ignoreAuthModule: true
                });
            })
            .all('Login')
            .all('ChangePassword')
            .withHttpConfig({
                cache: false
            })
            .post({
                Token: token,
                Username: username,
                OldPassword: oldPassword,
                NewPassword: newPassword,
                Confirm: confirm,
                SecurityQuestionResponse: securityQuestionResponse
            })
            .then(() => {
                this.applicationStateService.changeAnonymous(this.constants.sectionTypes.forgot, this.constants.actionTypes.other, 'The user [' + username + '] had changed their password to [' + newPassword.substring(0, 3) + '***]', this.constants.dataTypes.changePassword);
            });
    }

    /**
     * Changes the password for the currently logged in user.
     * 
     * @param {string} oldPassword 
     * @param {string} newPassword 
     * @param {string} confirmNewPassword 
     * @returns {promise} 
     */
    changePasswordForCurrentUser(oldPassword, newPassword, confirmNewPassword) {
        if (_.isNil(this.current)) {
            return this.$q.reject('Current user is null or undefined.');
        }

        if (_.isNil(oldPassword)) {
            return this.$q.reject('oldPassword cannot be null or undefined.');
        }

        if (_.isNil(newPassword)) {
            return this.$q.reject('newPassword cannot be null or undefined.');
        }

        if (_.isNil(confirmNewPassword)) {
            return this.$q.reject('confirmNewPassword cannot be null or undefined.');
        }

        if (newPassword !== confirmNewPassword) {
            return this.$q.reject('New password does not match confirmation password.');
        }

        const username = this.current.UserName;

        return this.Restangular
            .all('Login')
            .all('UpdatePassword')
            .customPUT({
                LoginName: username,
                OldPassword: oldPassword,
                NewPassword: newPassword
            });
    }

    forgotUsername(email) {
        return this.Restangular
            .all('Login')
            .all('ForgotUsername')
            .withHttpConfig({
                cache: false
            })
            .post({
                Email: email
            })
            .then((result) => {
                this.applicationStateService.changeAnonymous(this.constants.sectionTypes.forgot, this.constants.actionTypes.other, 'The user with email [' + email + '] has requested a username.');
                return result;
            });
    }

    forgotPassword(username, captchaResponse) {
        return this.Restangular
            .all('Login')
            .all('ForgotPassword')
            .withHttpConfig({
                cache: false
            })
            .post({
                Username: username,
                CaptchaResponse: captchaResponse
            })
            .then((result) => {
                this.applicationStateService.changeAnonymous(this.constants.sectionTypes.forgot, this.constants.actionTypes.other, 'The user [' + username + '] has requested a forgot password.');
                return result;
            });
    }

    getSecurityQuestion(token, username) {
        return this.Restangular
            .all('Login')
            .all('SecurityQuestion')
            .withHttpConfig({
                cache: false
            })
            .customGET('', {
                Username: username,
                Token: token
            });
    }

    /**
     * Gets the security question for the currently logged in user.
     * 
     * @returns {promise} 
     */
    getSecurityQuestionForCurrentUser() {
        if (_.isNil(this.current)) {
            return this.$q.reject('Current user is null or undefined.');
        }

        const token = this.loadAccessToken();
        const username = this.current.UserName;

        if (_.isNil(token) || _.isNil(username)) {
            return this.$q.reject('Token and/or username is null or undefined.');
        }

        return this.getSecurityQuestion(token, username);
    }

    getEligibleSecurityQuestions() {
        return this.Restangular
            .all('Login')
            .all('EligibleSecurityQuestions')
            .getList();
    }

    /**
     * Update the security quesiton for the current user.
     * 
     * @param {number} questionId 
     * @param {string} answer 
     * @returns {promise} 
     */
    changeSecurityQuestionForCurrentUser(questionId, answer) {
        if (_.isNil(questionId)) {
            return this.$q.reject('questionId cannot be null or undefined.');
        }

        if (_.isNil(answer)) {
            return this.$q.reject('answer cannot be null or undefined.');
        }

        if (_.isNil(this.current)) {
            return this.$q.reject('Current user is null or undefined.');
        }

        const username = this.current.UserName;

        return this.Restangular
            .all('Login')
            .all('SecurityQuestion')
            .customPUT({
                LoginName: username,
                QuestionID: questionId,
                Answer: answer
            });
    }

    validateSecurityQuestion(token, username, passwordQuestionResponse) {
        return this.Restangular
            .all('Login')
            .all('SecurityQuestion')
            .withHttpConfig({
                cache: false
            })
            .post({
                Username: username,
                Token: token,
                PasswordQuestionResponse: passwordQuestionResponse
            });
    }

    changeLoginName(loginName) {
        return this.Restangular
            .all('Login')
            .all('LoginName')
            .customPUT({
                NewLoginName: loginName
            })
            .then((result) => {
                this.current.UserName = loginName;
                return result;
            });
    }

    getUserInfo() {
        return this.Restangular
            .all('Account')
            .one('UserInfo')
            .withHttpConfig({
                cache: false
            })
            .get()
            .then((result) => {
                if (this.isUserValid(result)) {
                    const stripped = result.plain();
                    this.current = stripped;
                    this.$rootScope.$broadcast(this.constants.events.data.userInfoReceived, stripped);
                    this.IsImpersonator = result.IsImpersonator;
                    result = stripped;
                } else {
                    result = this.$q.reject('No user found');
                }

                return result;
            }, (error) => {
                this.$log.warn('Failed to get user info', error);
                return this.$q.reject(error);
            });
    }

    getAdcRedirectUrl() {
        return this.Restangular
            .all('Account')
            .all('AdcRedirectUrl')
            .withHttpConfig({
                cache: false
            })
            .post({
                Href: this.$state.href('login.home', null, { absolute: true }) // ADC doesn't support redirects to hash URL's, this will work only if html5 mode is enabled
            })
            .then((result) => {
                this.$log.info('Redirecting to ADC...');
                this.$log.debug(result);
                return result;
            }, (error) => {
                this.$log.error('Failed on ADC redirect', error);
                return this.$q.reject(error);
            });
    }

    getAdcCustomerInfo() {
        return this.Restangular
            .all('Login')
            .one('AdcCustomerInfo')
            .get()
            .then((result) => {
                this.AdcCustomerInfo = (result);
                return result;
            })
            .catch((error) => {
                this.$log.error('Failed to get ADC Customer Info', error);
                return this.$q.reject(error);
            });
    }

    getAddOnInformation() {
        return this.Restangular
            .all('Account')
            .one('AddOnService')
            .get()
            .then((result) => {
                this.current.AddOns = (result.plain());
                return this.current.AddOns;
            })
            .catch((error) => {
                this.$log.error('Failed to get ServiceAddOn Customer Info', error);
                return this.$q.reject(error);
            });
    }

    isAuthenticated() {
        return this.isUserValid(this.current);
    }

    hasSetting(key, value) {
        const setting = _.find(this.current.Settings, (s) => {
            return s.Name === key;
        });
        return !_.isNil(setting) &&
            (_.isNil(value) || (setting.Value === value) || (_.isString(setting.Value) && _.toLower(setting.Value) === _.toLower(value.toString())));
    }

    hasApplicationSetting(key, value) {
        const setting = _.find(this.current.ApplicationSettings, (s) => {
            return s.KeyName === key;
        });
        return !_.isNil(setting) &&
            (_.isNil(value) || (setting.Value === value) || (_.isString(setting.Value) && _.toLower(setting.Value) === _.toLower(value.toString())));
    }

    isAuthorizedAdc(planName) {
        return !_.isEmpty(planName) &&
               !_.startsWith(planName.toLowerCase(), 'protection') &&
               !this.IsImpersonator;
    }

    isUserValid(data) {
        return !_.isNil(data) &&
               data.UserId > 0;
    }

    raiseLoginRequired(force = false) {
        this.$rootScope.$broadcast(this.constants.events.loginRequired, force);
    }

    loadAccessToken() {
        let result = null;
        const temp = this.fpStore.get(this.accessTokenKey);

        if (!_.isEmpty(temp)) {
            result = angular.fromJson(temp);
            if (!this.isTokenValid(result)) {
                result = null;
            }
        }

        return result;
    }

    loadUserName() {
        if (_.isNil(this.current) || _.isNil(this.current.UserName)) {
            return null;
        }

        return this.current.UserName;
    }

    setAccessToken(token, persist) {
        if (this.isTokenValid(token)) {
            this.$http.defaults.headers.common.Authorization = 'Bearer ' + token;

            if (!_.isBoolean(persist)) {
                persist = this.fpStore.has(this.accessTokenKey, 'local');
            }

            const tokenJson = angular.toJson(token);

            this.fpStore.set(this.accessTokenKey, tokenJson, persist ? undefined : 'session');
        } else {
            this.clearAccessToken();
        }
    }

    reset() {
        this.current = {};
        this.clearAccessToken();
        this.fpDataCache.clear();
        this.mobileAppContext.reset();
    }

    clearAccessToken() {
        this.fpStore.remove(this.accessTokenKey);
        this.$cookies.remove(this.constants.cookies.FPSSO);
        delete this.$http.defaults.headers.common.Authorization;
    }

    isTokenValid(token) {
        let result = false;

        if (!_.isEmpty(token)) {
            const accessToken = token;

            result = accessToken !== 'undefined' &&
                accessToken !== 'null' &&
                !_.includes(accessToken, '<!doctype html>');
        }

        return result;
    }

    initialize() {
        const token = this.loadAccessToken();
        this.setAccessToken(token);
        return token;
    }
}

export default UserService;
