class PaymentDataService {
    /*@ngInject*/
    constructor (Restangular, constants,  applicationStateService, $q) {
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
    }

    getPaymentInfo() {
        return this.Restangular
            .all('Payment')
            .customGET('')
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error);
                return this.$q.reject(error);
            });
    }

    // Uses FPWS which then uses Payment API to process the payment. Use the method makePayment() instead
    savePaymentInfo(info) {
        return this.Restangular
            .all('Payment')
            .withHttpConfig({
                // CP-128 payments sometimes take a really long time to process, don't want user thinking it failed prematurely
                timeout: 120000 // wait up to 2 minutes
            })
            .customPOST(info)
            .then((result) => {
                this.applicationStateService.change(this.constants.sectionTypes.payments, this.constants.actionTypes.dataChange, 'A Payment of $' + info.GrandTotal + ' has been made.');
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error.data.ErrorMessage);
                return this.$q.reject(error);
            });
    }

    // Uses the Payment API to process the payment
    async makePayment(paymentInfo) {
        return this.Restangular
            .all('Payment')
            .all('make-payment')
            .withHttpConfig({
                // CP-128 payments sometimes take a really long time to process, don't want user thinking it failed prematurely
                timeout: 120000 // wait up to 2 minutes
            })
            .customPOST(paymentInfo)
            .then((result) => {
                this.applicationStateService.change(this.constants.sectionTypes.payments, this.constants.actionTypes.dataChange, 'A Payment of $' + paymentInfo.Amount + ' has been made.'); 
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error.data.Message);
                return this.$q.reject(error);
            });
    }

    async sendAcaNotification(notificationData) {
        return this.Restangular
            .all('Payment')
            .all('send-aca-notification')
            .customPOST(notificationData)
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error.data.Message);
                return this.$q.reject(error);
            });
    }

    getPaymentSummary() {
        return this.Restangular
            .all('Payment')
            .one('PaymentSummary')
            .get()
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error);
                return this.$q.reject(error);
            });
    }

    getPendingPayments() {
        return this.Restangular
            .all('payment')
            .all('pending')
            .getList()
            .then((res) => {
                return res.plain();
            })
            .catch((err) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, err);
                return this.$q.reject(err);
            });
    }

    getRecentPayments() {
        return this.Restangular
            .all('payment')
            .all('recent')
            .getList()
            .then((res) => {
                return res.plain();
            })
            .catch((err) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, err);
                return this.$q.reject(err);
            });
    }

    getAccountingInfo() {
        return this.Restangular
            .all('payment')
            .one('accounting-info')
            .get()
            .then((res) => {
                return res.plain();
            })
            .catch((err) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, err);
                return this.$q.reject(err);
            });
    }

    getPaymentsApplied() {
        return this.Restangular
            .all('payment')
            .one('payments-applied')
            .get()
            .then((res) => {
                return res.plain();
            })
            .catch((err) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, err);
                return this.$q.reject(err);
            });
    }

}

export default PaymentDataService;