import { VoziqOfferDto} from '../../cancellation/offer/types'
class VoziqService {
   constructor(private Restangular: any) {
       'ngInject';
   }

   async getVoziqOffers() : Promise<[VoziqOfferDto]>{
       return this.Restangular.one('voziq')
           .withHttpConfig({
               cache: false
           }).get();
   }
}

export default VoziqService