import _ from 'lodash';
import template from './movingNotification.html';

class MovingNotficationController {
    /*@ngInject*/
    constructor($state) {
        this.$state = $state;
        this.movingStatuses = [
            {
                status: 'No Status',
                text: "You have no pending equipment transfers at this time"
            },
            {
                status: 'Pending',
                text: "We’re working on your Movers Kit! It will be shipped out on the date that you’ve selected."
            },
            {
                status: 'Sent',
                text: "Your Movers Kit has been sent!"
            }, {
                status: 'Info',
                text: "A Movers Kit has already been sent out to the address you specified. You can't make changes at this time."
            }];
    }

    $onChanges() {
        this.getMovingStatus();
    }

    getMovingStatus() {
        if (!_.isNil(this.movingStatus)) {
            this.message = _.find(this.movingStatuses, { 'status': this.movingStatus });
        } else {
            this.message = _.find(this.movingStatuses, { 'status': 'Info' });
        }
    }

}

export default {
    template: template,
    bindings: {
        movingStatus: '<'
    },
    controller: MovingNotficationController
};