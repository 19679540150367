import angular from 'angular';
import _ from 'lodash';
import template from './postdispatch.html';
import AbstractDispatchController from './AbstractDispatchController.js';

class PostDispatchVerificationController extends AbstractDispatchController {
    /*@ngInject*/
    constructor($scope, $log, $timeout, $q, alertService, constants, dataContext) {
        super($scope, $timeout, constants);

        this.$log = $log;
        this.$q = $q;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;

        var unsub = $scope.$watch(() => {
                return this.isLoading;
            },
            (newValue) => {
                if (newValue === false) {
                    // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
                    // if the customer stops editing without saving.
                    this.pristine = _.cloneDeep(this.contacts);
                    unsub();
                }
            });

        this.ALERT_DIALOG = 'emergency:postdispatch';
    }

    onSaveCore() {
        this.isLoading = true;
        this.stripPhoneNumbers();
        return this.dataContext.dispatch.post(this.contacts)
            .then((result) => {
                this.contacts = _.filter(result, c => !c.IsPreDispatch);
                this.stripPhoneNumbers();
                this._showGenericSuccessAlert();

                this.notifyContactsUpdated();
            })
            .catch((error) => {
                this.$log.error('Error updating postdispatch contacts.', error);
                this._showGenericErrorAlert();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
                this.pristine = _.cloneDeep(this.contacts);
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.contacts = _.cloneDeep(this.pristine);
    }

    getMinNumContacts() {
        return 0;
    }

    getMaxNumContacts() {
        return 6;
    }

    getContacts() {
        return this.contacts;
    }

    isPredispatch() {
        return false;
    }

    isPristine() {
        return _.isEqual(this.pristine, angular.copy(this.contacts));
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your postdispatch contacts have been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your postdispatch contacts. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        contacts: '<',
        rapidConnectionStatus: '<'
    },
    controller: PostDispatchVerificationController
};