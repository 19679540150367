import template from './smsDisclaimerModal.html';

class SmsDisclaimerModalController {
    /*@ngInject*/
    constructor() {

    }

    onAccept() {
        this.onClose({ result: true });
    }

    onCancel() {
        this.onDismiss();
    }
}

export default {
    template: template,
    bindings: {
        onClose: '&',
        onDismiss: '&'
    },
    controller: SmsDisclaimerModalController
};