import template from './deletePaymentModal.html';

import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';

class DeletePaymentModalController {
    /* @ngInject */
    constructor(dataContext, constants) {
        this.dataContext = dataContext;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.constants = constants;
        this.isValid = false;
        this.onLoad(); 
    }

    onLoad() {
        this.paymentType = '';
        if (this.data.cbiToDelete.IsCredit) {
            this.data.cbiToDelete.CreditCardType = this.data.cbiToDelete.CreditCardType.substring(0, 1).toUpperCase() + this.data.cbiToDelete.CreditCardType.substring(1, this.data.cbiToDelete.CreditCardType.length);
        }
        this.cbiToDelete = this.data.cbiToDelete;
        this.customerBillingInfo = this.data.customerBillingInfo;
        this.paymentInfo = this.data.paymentInfo;

        if (this.cbiToDelete.isDefaultPaymentOrRMR) {
            this.configureDeleteMsg();
        }
    }

    configureDeleteMsg () {
        this.canSwitchDefault = true;
        this.availablePayments = [];
        this.availableRmr = [];

        if (this.cbiToDelete.IsDefaultPayment && this.cbiToDelete.IsDefaultRmr && this.cbiToDelete.IsCredit) {
            this.deleteMsg = 'You are about to delete the card that is your default option for Equipment Payments and Autopay!';
            this.paymentType = 'both';
        } else if (this.cbiToDelete.IsDefaultPayment) {
            this.deleteMsg = 'You are about to delete the card that is your default option for Equipment Payments!';
            this.paymentType = 'payment';
        } else if (this.cbiToDelete.IsDefaultRmr && this.cbiToDelete.IsCredit) {
            this.deleteMsg = 'You are about to delete the card that is your default option for Autopay!';
            this.paymentType = 'rmr';
        } else if (this.cbiToDelete.IsDefaultRmr && !this.cbiToDelete.IsCredit) {
            this.deleteMsg = 'You are about to delete the bank account that is your default option for Autopay!';
            this.paymentType = 'rmr';
        }
       
        _.each(this.customerBillingInfo,(i, v) => {
            if (i.IsCredit && i.CustomerBillingInfoID !== this.cbiToDelete.CustomerBillingInfoID && !this.isExpiratedCcExp(i)) {
                this.availablePayments.push(i);
            }
        });

        if (this.paymentType === 'both' && this.availablePayments.length === 0 ||
            this.paymentType === 'payment' && this.availablePayments.length === 0) {
            this.canSwitchDefault = false;
            this.deleteMsg = 'You cannot delete the credit card without having another valid card on file!';
        }

        this.availableRmr = angular.copy(this.availablePayments);
        _.each(this.customerBillingInfo,(i, v) => {
            if (!i.IsCredit && i.CustomerBillingInfoID !== this.cbiToDelete.CustomerBillingInfoID) {
                this.availableRmr.push(i);
            }
        });
    }

    close() {
        this.$dismiss();
    }

    modalDeleteCancel() {
        this.removeCheckState();
    }

    modalDeleteConfirm () {
        switch (this.paymentType) {
            case 'both':
                if (this.selectedRmr.CustomerBillingInfoID === this.selectedPayment.CustomerBillingInfoID) {
                    this.selectedCBI = this.selectedRmr;
                    this.selectedCBI.IsDefaultPayment = true;
                    this.saveCBI(null, true);
                } else {
                    this.selectedCBI = this.selectedPayment;
                    if (!this.selectedRmr.IsCredit) {
                        this.selectedRmr.CheckType = this.selectedRmr.CheckType.substring(0, 1);
                        this.selectedRmr.CheckAccountType = this.selectedRmr.CheckAccountType.substring(0, 1);
                    }
                    this.saveCBI(this.selectedRmr, true);
                }
                break;
            case 'payment':
                this.selectedCBI = this.selectedPayment;
                this.saveCBI(null, true);
                break;
            case 'rmr':
                this.selectedCBI = this.selectedRmr;
                this.saveCBI(null, true);
                break;
        }

        this.removeCheckState();
    }

    selectOneCheckbox (cbi, isAvaiablePayment) {
        if (isAvaiablePayment) {
            _.each(this.availablePayments, (i, v) => {
                if (i.CustomerBillingInfoID === cbi.CustomerBillingInfoID) {
                    i.IsDefaultPayment = true;
                    this.selectedPayment = i;
                } else {
                    i.IsDefaultPayment = false;
                }

            });
        } else {
            _.each(this.availableRmr, (i, v) => {
                if (i.CustomerBillingInfoID === cbi.CustomerBillingInfoID) {
                    i.IsDefaultRmr = true;
                    this.selectedRmr = i;
                    this.getAchOffer(this.selectedRmr);
                } else {
                    i.IsDefaultRmr = false;
                }
            });
        }

        if (!this.selectedPayment && !this.selectedRmr ||
           this.paymentType === 'both' && (!this.selectedPayment || !this.selectedRmr) ||
           this.paymentType === 'payment ' && !this.selectedPayment ||
           this.paymentType === 'rmr' && !this.selectedRmr) {
            this.isValid = false;
        } else 
            this.isValid = true;
    }

    saveCBI(secondCBI, deleteAfter) {

        this.isLoading = true;
        if (this.selectedCBI.IsCredit) {
            this.selectedCBI.CCExp = this.selectedCBI.CcExpMonth + '/' + this.selectedCBI.CcExpYear.toString().substring(2, this.selectedCBI.CcExpYear.toString().length);
            this.selectedCBI.AccountNumber = this.selectedCBI.CardCcNumb;
        } else {
            this.selectedCBI.CheckAccountType = this.selectedCBI.CheckAccountType.substring(0, 1);
            this.selectedCBI.CheckType = this.selectedCBI.CheckType.substring(0, 1);
        }

        if (this.selectedCBI.CustomerBillingInfoID !== 0) {
            this.errorMsg = 'Edit Failed!';
            this.successMsg = 'Edit Successful!';

            if (this.selectedCBI.setToDefaultRmr) {
                this.errorMsg = 'Autopay Update Failed!';
                this.successMsg = 'Autopay Update Successful!';
                this.selectedCBI.IsDefaultRmr = true;
            }

            this.dataContext.customerBillingInfo.updateCustomerBillingInfo(this.selectedCBI)
                .then((result) => {
                    if (secondCBI) {
                        return this.dataContext.customerBillingInfo.updateCustomerBillingInfo(secondCBI).then((result) => {
                            this.confirmedCBIDelete();
                        });
                    } else {
                        this.confirmedCBIDelete();
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    confirmedCBIDelete() {
        this.errorMsg = 'Delete Attempt Failed!';
        this.successMsg = 'Delete Attempt Successful!';

        this.isLoading = true;
        this.dataContext.customerBillingInfo.deleteCustomerBillingInfo(this.cbiToDelete.CustomerBillingInfoID)
            .then((result) => {
                if (result.Success) {
                    this.cbiToDelete.successful = true;
                } else {
                    this.cbiToDelete.successful = false;
                }

                this.$close(this.cbiToDelete);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getAchOffer(cbiSelectedForAutoPay) {
        this.dataContext.customerBillingInfo
            .getACHOfferAsync(cbiSelectedForAutoPay.AccountId, !cbiSelectedForAutoPay.IsCredit).then((result) => {
                if (_.isNil(result) || result.Action === 0) {
                    this.offerActionId = result.Action;
                    return;
                }

                this.offerActionId = result.Action;
                this.effectiveDate = moment(result.Amendment.NewContractSignDate).format('MM/DD/YYYY');
                this.rmrDiscount = (result.Amendment.OldRMR - result.Amendment.NewRMR).toFixed(2);
            });
    }

    removeCheckState() {
        _.each(this.availablePayments, (i, v) => {
            delete i.isChecked;
        });

        _.each(this.availableRmr, (i, v) =>{
            delete i.isChecked;
        });
    }

    isExpiratedCcExp(cbi) {
        if (cbi.IsCredit) {
            let curDate = new Date();
            let cardInfoArray = cbi.CCExp.split('/');
            let cardDate = new Date(cardInfoArray[0] + '/01/20' + cardInfoArray[1]);
            cardDate.setMonth(cardDate.getMonth() + 1);

            return cardDate < curDate;
        } else {
            return false;
        }
    }
}

export default {
template: template,
    bindings: {
    config: '<',
    customerInfo: '<'
    },
controller: DeletePaymentModalController
};