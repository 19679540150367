class RelocationService {
    /*@ngInject*/
    constructor(Restangular, $q, constants, applicationStateService) {
        this.Restangular = Restangular;
        this.$q = $q;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
    }

    getRelocationInfo() {
        return this.Restangular
            .all('relocation')
            .one('info')
            .get()
            .then((response) => {
                return response;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.movingPortal, error);
                return this.$q.reject(error);
            });
    }

    updateRelocationInfo(info) {
        return this.Restangular
            .all('relocation')
            .one('info')
            .customPUT(info)
            .then((result) => {
                return result;
            })
            .catch((error) => {

                this.applicationStateService.error(this.constants.sectionTypes.movingPortal, error);
                return this.$q.reject(error);
            });
    }

    saveRelocationInfo(info) {
        return this.Restangular
            .all('relocation')
            .all('info')
            .post(info)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.movingPortal, error);
                return this.$q.reject(error);
            });
    }

    stopRelocation() {
        return this.Restangular
            .all('relocation')
            .all('cancel')
            .customDELETE()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.movingPortal, error);
                return this.$q.reject(error);
            });
    }
}

export default RelocationService