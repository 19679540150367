class AbstractSectionController {
    constructor() {
        this._isViewing = true;
        this._isEditing = false;
    }

    /**
     * 
     * @returns {} 
     */
    isEditing() {
        return this._isEditing;
    }

    /**
     * 
     * @returns {} 
     */
    isViewing() {
        return this._isViewing;
    }

    /**
     * 
     * @returns {} 
     */
    onEdit() {
        this.startEditing();
        this.onEditCore();
    }

    /**
     * 
     * @returns {} 
     */
    onEditCore() {
        throw 'AbstractSectionController.onEditCore() must be overridden.';
    }

    /**
     * 
     * @returns {} 
     */
    onSave() {
        const result = this.onSaveCore();

        // If the result returned by onSaveCore() is a promise wait until it has resolved before stopping edit mode.
        if (result && result.then) {
            result.then(() => {
                this.stopEditing();
            });
        } else {
            this.stopEditing();
        }
    }

    /**
     * 
     * @returns {} 
     */
    onSaveCore() {
        throw 'AbstractSectionController.onSaveCore() must be overridden.';
    }

    /**
     * 
     * @returns {} 
     */
    onCancel() {
        this.stopEditing();
        this.onCancelCore();
    }

    /**
     * 
     * @returns {} 
     */
    onCancelCore() {
        throw 'AbstractSectionController.onCancelCore() must be overridden.';
    }

    /**
     * 
     * @returns {} 
     */
    onStartEditing() {
        // Empty Default Implementation
    }

    /**
     * 
     * @returns {} 
     */
    onStopEditing() {
        // Empty Default Implementation
    }

    /**
     * Switches the component to edit mode.
     */
    startEditing() {
        if (this._isViewing) {
            this._isEditing = true;
            this._isViewing = false;
            this.onStartEditing();
        }
    }

    /**
     * Switches the component to view mode.
     */
    stopEditing() {
        if (this._isEditing) {
            this._isEditing = false;
            this._isViewing = true;
            this.onStopEditing();
        }
    }
}

export default AbstractSectionController;