import * as angular from 'angular';
import { IModalService } from 'angular-ui-bootstrap';
import * as _ from 'lodash';
import TrackOrderService from '../common/data/trackOrder.service';
import './trackOrder.scss';
import { Error, TrackOrderDto } from './types';
import { initRecaptcha } from './utility';

export class TrackOrderController {
    public message: string;

    public search: string;
    private email: string | null;
    private phone: string | null;
    private searchAttempts: number;
    public error: Error;
    private defaultErrorMessage: string;
    public loading: boolean;
    public widgetId: number;
    public captchaToken: any;
    public refreshTokenAtInterval: any;
    private orders: TrackOrderDto[];
    private selectedOrder: TrackOrderDto | null;

    private location: ng.ILocationService;
    private uibModal: IModalService;
    private knowledgeBaseUrl: string;

    constructor(
        private readonly $scope: ng.IScope | any,
        private readonly $location: ng.ILocationService,
        private readonly $interval: ng.IIntervalService,
        private readonly trackOrderService: TrackOrderService,
        private readonly $uibModal: IModalService,
        private readonly constants: any
    ) {
        'ngInject';

        this.search = null;
        this.email = null;
        this.phone = null;
        this.searchAttempts = 0;
        this.error = { exists: false };
        this.defaultErrorMessage = "Please confirm that the email address and / or phone number is the same as the one used during order placement.";
        this.loading = false;
        this.$scope = $scope;
        this.$scope.captchaToken = null;
        this.orders = [];
        this.refreshTokenAtInterval = null;
        this.orders = [];
        this.selectedOrder = null;

        this.location = $location;
        this.uibModal = $uibModal;
        this.knowledgeBaseUrl = constants.trackOrderConfig.knowledgeBaseUrl;

        this.$scope.$watch('captchaToken', () => {
            var { email } = this.location.search();
            if (email && this.$scope.captchaToken && this.searchAttempts === 0) {
                this.search = email;
                this.submit();
            }
        });
    }

    $onInit() {
        // init captcha onload - will error without doing so
        this.initializeCaptcha();
    }

    public widgetCreated(id: any) {
        this.widgetId = id;
    }

    public initializeCaptcha() {
        initRecaptcha(this.$scope);
    }

    public refreshCaptcha() {
        this.initializeCaptcha();
    }

    public startCaptchaAutoRefresh() {
        this.refreshTokenAtInterval = this.$interval(() => {
            this.initializeCaptcha();
        }, 90 * 1000);
    }

    public $onDestroy() {
        if (this.refreshTokenAtInterval) {
            // when the user leaves, cancel the $timeout instance
            this.$interval.cancel(this.refreshTokenAtInterval);
        }
    }

    private setError() {
        this.error = {
            exists: true,
            message: this.defaultErrorMessage
        };
    }

    public submit() {
        this.loading = true;

        let skipApiCall = false;

        // Determine if email or phone number
        if (this.search.includes('@')) {
            this.email = this.search;
        } else if (this.search.match(/[^$,.\d]/)) {
            // skip api calls on non numeric phone
            skipApiCall = true;
        } else if (this.search.length < 10) {
            // skip api calls if phone number is numeric but not long enough
            skipApiCall = true;
        } else {
            this.phone = this.search;
        }

        if (skipApiCall) {
            this.setError();
            this.loading = false;
            this.searchAttempts++;
            return;
        }

        // Get orders associated with search query
        this.trackOrderService.getOrders(this.$scope.captchaToken, this.email, this.phone).then(res => {
            this.orders = res;
        }).catch(() => {
            this.setError();
        }).finally(() => {
            this.searchAttempts++;
            // Refresh captcha - required so that additional calls do not fail validation
            this.refreshCaptcha();
            this.loading = false;
        });
    }

    openOrder(order: TrackOrderDto) {
        // set selected order in state and then open
        this.selectedOrder = order;
        this.openConfirmationModal();
    }

    openConfirmationModal() {
        var modalInstance = this.uibModal.open({
            template: `<fp-confirmation-modal
                            dismiss="dismiss"
                            order="order"
                            token="token"
                        >
                        </fp-confirmation-modal>`,
            size: 'md',
            backdropClass: 'fp-confirmation-modal-backdrop',
            windowTopClass: 'fp-confirmation-modal-window-top',
            controller: ($scope: any) => {
                'ngInject';
                $scope.dismiss = modalInstance.dismiss;
                $scope.token = this.$scope.captchaToken;
                $scope.order = this.selectedOrder;
            }
        });
        modalInstance.result.then(function (result: any) {
            this.$state.reload();
        });
    }
}

export const trackOrderComponent = {
    template: require('./trackorder.html'),
    bindings: {
        message: '<'
    },
    controller: TrackOrderController
};
