const VALIDATE_ADDRESS_REQUEST = 'ValidateAddress';

class AddressDataService {
    /*@ngInject*/
    constructor(Restangular, _, constants, $uibModal, $q) {
        this.Restangular = Restangular;
        this._ = _;
        this.constants = constants;
        this.$uibModal = $uibModal;
        this.$q = $q;
    }

    validateAddress(info) {
        return this.Restangular
            .all('Address')
            .post(info)
            .then((response) => {
                response._requestType = VALIDATE_ADDRESS_REQUEST;
                response._requestData = this._.cloneDeep(info);
                return response;
            }, (response) => {
                response._requestType = VALIDATE_ADDRESS_REQUEST;
                response._requestData = this._.cloneDeep(info);
                return response;
            });
    }

    /**
     * Returns true if the response returned by AddressDataService.validateAddress() indicates that
     * the input address is valid.
     * 
     * @param {Object} response A response object returned by AddressDataService.validateAddress().
     * @returns {boolean} True if the resonse indicates that the input address is valid, otherwise false. 
     */
    isValidResponse(response) {
        if (this._.isNil(response._requestType) || response._requestType !== VALIDATE_ADDRESS_REQUEST) {
            throw 'Can only validate responses returned by AddressDataService.validateAddress().';
        }

        let inputAddress = response._requestData;

        let inputPostal = inputAddress.PostalCode.indexOf('-') !== -1
            ? inputAddress.PostalCode.split('-')[0]
            : inputAddress.PostalCode;

        // FPWS only validates an address based on address line 1, line 2, and postal code. It returns
        // the city and state that match the given postal code. Therefore, we must validate the city and
        // state that the user entered by comparing them to the suggestions returned by FPWS.
        return !this._.isNil(response.City) &&
                !this._.isNil(response.StateAbrev) &&
                !this._.isNil(response.Zip) &&
                inputAddress.City === response.City &&
                inputAddress.State === response.StateAbrev &&
                inputPostal === response.Zip.split('-')[0];
    }

    isPOBox(address) {
        return this.constants.fpRegex.pobox.test(address.Address1);
    }

    /**
     * returns melissa validated address
     * @param {address} address
     */
    validateMelissaAddressModal(address) {
        const formattedAddress = {
            Address1: address.line1,
            Address2: address.line2,
            City: address.city,
            PostalCode: address.postalCode,
            State: address.state
        };
        return this.validateAddress(formattedAddress) //get valid address
            .then((validAddress) => {
                if (_.isNil(formattedAddress.State)
                    || _.isNil(formattedAddress.City)
                    || _.isNil(formattedAddress.PostalCode)
                    || this.isValidResponse(validAddress)) {
                    return formattedAddress;
                }
                return {
                    Address1: formattedAddress.Line1,
                    Address2: formattedAddress.Line2,
                    City: validAddress.City,
                    PostalCode: validAddress.Zip ? validAddress.Zip.split('-')[0] : null,
                    State: validAddress.StateAbrev
                };
            }).then((validAddress) => { //prompt user to approve corrected address
                if (validAddress.State !== formattedAddress.State ||
                    validAddress.City !== formattedAddress.City ||
                    validAddress.PostalCode !== formattedAddress.PostalCode) {

                    const modalInstance = this.$uibModal.open({
                        template: '<fp-address-validation-modal config="config"></fp-address-validation-modal>',
                        controller: ($scope) => {
                            'ngInject';
                            $scope.config = {
                                data: formattedAddress,
                                close: modalInstance.close,
                                dismiss: modalInstance.dismiss,
                                returnedAddress: validAddress
                            };
                        }
                    });

                    return modalInstance.result.then((approvedAddress) => {
                        if (_.isNil(approvedAddress)) { //cancel is null
                            this.$q.reject();
                        } else {
                            return approvedAddress;
                        }
                    });
                } else {
                    return this.$q.resolve(validAddress);
                }
            });
    }
}

export default AddressDataService;