import angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';

import referHomeComponent from './home.component';
import referComponent from './refer.component';

export default angular.module('fp:customerportal:account:refer',
    [
        'ui.router',
        commonModule.name
    ])
    .config(/*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('account.refer',
            {
                url: '/refer',
                template: '<fp-refer-home></fp-refer-home>',
                data: {
                    navigationMenu: constants.navigationMenuSections.majorSections.account,
                    navigationSubSection: constants.navigationMenuSections.subSections.referral
                }
            });
    })
    .component('fpReferHome', referHomeComponent)
    .component('fpRefer', referComponent);