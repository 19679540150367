import './videoonly.scss'

export class VideoOnlyController {
    constants: any;
    supportPhone: any;

    constructor(
        constants: any
    ) {
        'ngInject';
        this.constants = constants;
    }

    public $onInit() {
        this.supportPhone = this.constants.customerSupport.formattedPhone;
    }
}

export const videoOnlyComponent = {
    template: require('./videoonly.html'),
    controller: VideoOnlyController
};
