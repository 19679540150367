class CreditCardService {
    /*@ngInject*/
    constructor(constants, moment) {
        this.constants = constants;
        this.moment = moment;
    }

    getCardType(creditCardNumber) {
        let result = 'unknown';

        if ((this.constants.fpRegex.mastercard).test(creditCardNumber)) {
            result = 'mastercard';
        }
        else if ((this.constants.fpRegex.visa).test(creditCardNumber)) {
            result = 'visa';
        }
        else if ((this.constants.fpRegex.amex).test(creditCardNumber)) {
            result = 'amex';
        }
        else if ((this.constants.fpRegex.discover).test(creditCardNumber)) {
            result = 'discover';
        }

        return result;
    }

    hasExpiringRMR(customerBillingInfo) {
        const defaultRMR = _.find(customerBillingInfo, { IsDefaultRmr: true });
        if (defaultRMR.IsCredit) {
            const month = defaultRMR.CCExp.substring(0, 2);
            const year = '20' + defaultRMR.CCExp.substring(3, 5);
            const CCExp = this.moment(new Date(year, month, 0)).format('MM/YY');
            const expireWarningTimeFrame = this.moment(new Date(year, month, 0)).subtract(2, 'weeks').format();
            if ((CCExp === this.moment().format('MM/YY')) && (this.moment().format() >= expireWarningTimeFrame)) {
                return defaultRMR;
            } else {
                return null;
            }
        }
        else {
            return null;
        }
    }
}

export default CreditCardService; 