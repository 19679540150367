import angular from 'angular';
import _ from 'lodash';

import template from './predispatch.html';
import AbstractDispatchController from './AbstractDispatchController.js';

class PredispatchVerificationController extends AbstractDispatchController {
    /*@ngInject*/
    constructor($scope, $log, $timeout, $q, alertService, constants, dataContext) {
        super($scope, $timeout, constants);

        this.$scope = $scope;
        this.$log = $log;
        this.$q = $q;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;
        this.isEnrolled = this.isSmsEnabled().then((result) => this.isEnrolled = result);
        var unsub = $scope.$watch(() => {
            return this.isLoading;
        }, (newValue) => {
            if (newValue === false) {
                // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
                // if the customer stops editing without saving.
                this.pristine = _.cloneDeep(this.contacts);
                unsub();
            }
        });

        this.ALERT_DIALOG = 'emergency:predispatch';
    }

    onSaveCore() {
        this.isLoading = true;
        this.stripPhoneNumbers();
        return this.dataContext.dispatch.post(this.contacts)
            .then((result) => {
                this.contacts = _.filter(result, c => c.IsPreDispatch);
                this.stripPhoneNumbers();
                this._showGenericSuccessAlert();

                this.notifyContactsUpdated();
            })
            .catch((error) => {
                this.$log.error('Error updating predispatch contacts.', error);
                this._showGenericErrorAlert();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
                this.pristine = _.cloneDeep(this.contacts);
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.contacts = _.cloneDeep(this.pristine);
    }

    getMinNumContacts() {
        return 2;
    }

    getMaxNumContacts() {
        return 3;
    }

    getContacts() {
        return this.contacts;
    }

    isPredispatch() {
        return true;
    }

    isPristine() {
        return _.isEqual(this.pristine, angular.copy(this.contacts));
    }

    async enrollRapidSMS() {
        if (this.isEnrolled) {
            try {
                await this.dataContext.sms.enrollCentralStationSms();
            } catch (error) {
                this.$log.error('Error occurred while enrolling emergency contacts in central station SMS service.',
                    error);
            }
        } else {
            try {
                await this.dataContext.sms.undoEnrollmentCentralStationSms();
            } catch (error) {
                this.$log.error('Error occurred while enrolling emergency contacts in central station SMS service.', error);
            }
        }
    }

    async isSmsEnabled() {
        try {
            return await this.dataContext.sms.isSmsEnabled();
        } catch (e) {
            this.$log.error('Error fetching SMS enrollment status from Rapid.', e);
        } 
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your predispatch contacts have been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your predispatch contacts. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        contacts: '<',
        rapidConnectionStatus: '<'
    },
    controller: PredispatchVerificationController
};