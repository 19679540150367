import * as _ from 'lodash';
import { voziqOfferModal } from './settings/modals/voziqOfferModal.component';
import  DataContext  from '../common/data/dataContext.service';
import { VoziqOfferDto } from "@app/cancellation/offer/types/index";

export class MyAccountController {
    public alertName = 'account_alert';

    private paymentSummary: any;
    private pendingPayments: any[];

    constructor(
        private $q: ng.IQService,
        private dataContext: DataContext,
        private alertService: any,
        private constants: any,
        private $uibModal:any) {
        'ngInject';
    }

    public $onInit() {
        this.getPaymentInfo()
            .then((result) => {
                this.paymentSummary = result.paymentSummary;
                this.pendingPayments = result.pendingPayments;

                if (_.get(this.constants, 'alerts.pastDuePayment.enabled', true)) {
                    this.showPastDuePaymentReminder();
                }
            });
        this.getVoziqOffers().then((offers) => {
            if (offers.length > 0) {
                this.$uibModal.open({
                    component: voziqOfferModal.selector,
                    size: 'md',
                    resolve: {
                        offers: () => { return this.getVoziqOffers(); }
                    }
                });
            }
        });
    }

    private async getVoziqOffers() {
        const allOffers = await  this.dataContext.voziqService.getVoziqOffers();
        const rmrOffers = allOffers.filter((dto) => {
            return dto.OfferType === 'RMR Change Only';
        });
        return rmrOffers.map((offer: VoziqOfferDto) => {
            const replaced = offer.RMRChange.replace(/[\D]/g, '');
            offer.RMRChange = replaced;
            return offer;
        });
    }

    private getPaymentInfo() {
        return this.$q.all([
            this.dataContext.payment.getPaymentSummary(),
            this.dataContext.payment.getPendingPayments()
        ]).then((results: any[]) => {
            const paymentSummary = results[0].PaymentSummaryInfo;
            const pendingPayments = results[1];

            return {
                paymentSummary,
                pendingPayments
            };
        });
    }

    private showPastDuePaymentReminder() {
        const hasOverdueBalance =
            !_.isNil(this.paymentSummary) &&
                this.paymentSummary.HasAccountPastDue &&
                this.paymentSummary.AccountPastDue > 0;
        const hasPendingPayments = !_.isEmpty(this.pendingPayments);
        const showReminder = hasOverdueBalance && !hasPendingPayments;

        if (showReminder) {
            this.alertService
                .get(this.alertName)
                .setMessage(
                    '<div id="past-due-reminder">' +
                        '<div class="text-uppercase text-bold">Past Due Reminder</div>' +
                        '<p>We have been unable to process your recurring monthly payment. Please ' +
                            '<a ui-sref="account.settings.billing">update your payment information</a>.' +
                        '</p>' +
                    '</div>'
                )
                .setType('danger')
                .open();
        }
    }
}

export const myaccountComponent = {
    controller: MyAccountController,
    template: require('./myaccount.html')
};