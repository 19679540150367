import template from './callUsDataManagementModal.html';

class CallUsDataManagementModalController {
    /*@ngInject*/
    constructor($uibModal, $window) {
        this.$uibModal = $uibModal;
        this.$window = $window;
    }

    onClose() {
        this.onClose();
    }
}

export default {
    template: template,
    bindings: {
        onClose: '&'
    },
    controller: CallUsDataManagementModalController
};