import _ from 'lodash';
import template from './forgotUsername.html';

class ForgotUsernameController {
    /*@ngInject*/
    constructor($log, $state, userService) {
        this.$log = $log;
        this.$state = $state;
        this.userService = userService;

        this.email = null;
    }

    isValid() {
        return !_.isEmpty(this.email);
    }

    submit() {
        this.error = null;
        this.isBusy = true;
        return this.userService
            .forgotUsername(this.email)
            .then((result) => {
                return this.$state.go('login.home', {
                    message: result
                });
            }, (error) => {
                this.$log.error('Failed to request user name', error);

                let errorMessage = null;

                if (!_.isString(error)) {
                    errorMessage = 'Failed to submit user name request, please try again';

                    if (!_.isNil(error.data)) {
                        errorMessage = error.data;
                        if (!_.isEmpty(error.data.Message)) {
                            errorMessage = error.data.Message;
                        }
                    }
                } else {
                    errorMessage = error;
                }

                this.error = errorMessage;
            })
            .finally(() => {
                this.isBusy = false;
            });
    }
}

export default {
    template: template,
    bindings: {
        redirectTo: '@',
        message: '@'
    },
    controller: ForgotUsernameController
};