import './offer.component.scss';

export class OfferController {
    public retentionOfferTypeId = 0;
    public retentionQuestionnaireAnswerID = 0;
    public offer: any;

    constructor(private $stateParams: any, private $state: any, private dataContext: any) {
        'ngInject';
        this.retentionOfferTypeId = $stateParams.retentionOfferTypeId;
        this.retentionQuestionnaireAnswerID = $stateParams.retentionQuestionnaireAnswerID;
    }

    public $onInit() {
        this.dataContext.retention.getRetentionOfferType(this.retentionOfferTypeId).then((offer: any) => {
            this.offer = offer;
        });
    }
}

export const offerComponent = {
    template: require('./offer.component.html'),
    controller: OfferController
};