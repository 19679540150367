import _ from 'lodash';
import template from './datamanagement.html';
import AbstractSectionController from '../AbstractSectionController';

class DataManagementController extends AbstractSectionController {
    /*@ngInject*/
    constructor($uibModal, $window, constants, dataContext, pluginService) {
        super();
        this.$uibModal = $uibModal;
        this.$window = $window;
        this.constants = constants;
        this.dataContext = dataContext;
        this.pluginService = pluginService;
    }

    openModal() {        
        this.$uibModal.open({
            template: '<fp-data-management-modal></fp-data-management-modal>',
            controllerAs: '$ctrl',
            controller: /*@ngInject*/ function() {
                this.title = 'Data Management';
            },
            size: 'lg'
        });
    }    
}

export default {
    template: template,
    bindings: {},
    controller: DataManagementController
};