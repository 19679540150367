import moment from 'moment';
import _ from 'lodash';
import template from './makePaymentModal.html';

class MakePaymentModalController{
    /* @ngInject */
    constructor($scope, $q, $state, $log, $uibModal, dataContext, constants, alertService, creditCardService) {
        this.$scope = $scope;

        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.ALERT_DIALOG = 'MakePaymentModalAlert';

        this.dataContext = dataContext;
        this.alertService = alertService;
        this.creditCardService = creditCardService;
        this.$log = $log;
        this.validCardType = true;
        this.steps = [
            { name: 'stepPaymentAmount', nextStep: 'stepPaymentSelection', prevStep: '', text: 'PAYMENT AMOUNT', visible: false, number: 1, disabled: true }, // disabled
            { name: 'stepPaymentSelection', nextStep: 'stepPaymentType', prevStep: '', text: 'SELECT PAYMENT', visible: true, number: 2 },
            { name: 'stepPaymentType', nextStep: 'stepPaymentAddress', prevStep: 'stepPaymentSelection', text: 'PAYMENT TYPE', visible: false, number: 3 },
            { name: 'stepPaymentAddress', nextStep: 'stepPaymentSubmit', prevStep: 'stepPaymentType', text: 'BILLING ADDRESS', visible: false, number: 4 },
            { name: 'stepPaymentSubmit', nextStep: '', text: 'SUBMIT', prevStep: 'stepPaymentAddress', visible: false, number: 5 }
        ];

        this.creditCards = [
            { CreditCardType : 'visa', curClass: 'fpDisabled'},
            { CreditCardType : 'amex', curClass: 'fpDisabled'},
            { CreditCardType : 'mastercard', curClass: 'fpDisabled'},
            { CreditCardType : 'discover', curClass: 'fpDisabled'}
        ];

        this.states = this.config.states; 

        this.currentStep = _.find(this.steps, (s) => { return s.visible; });
        this.hasSelectedCard = false;
        this.creditCardYears = this.getCreditCardYears();
        this.$state = $state;
        this.modal = $uibModal;
        this.previousBalance = undefined;
        this.constants = constants;
        this.isLoading = true;
        this.modalDuration = 10000;
        this.disableSubmit = false;
        this.$q = $q;

        //remove this when you have new calendar widget
        this.ccExpMonths = [
            { value:1, text:'01', disabled:false },
            { value:2, text:'02', disabled:false },
            { value:3, text:'03', disabled:false },
            { value:4, text:'04', disabled:false },
            { value:5, text:'05', disabled:false },
            { value:6, text:'06', disabled:false },
            { value:7, text:'07', disabled:false },
            { value:8, text:'08', disabled:false },
            { value:9, text:'09', disabled:false },
            { value:10, text:'10', disabled:false },
            { value:11, text:'11', disabled:false },
            { value:12, text:'12', disabled:false }
        ];

        $q.all([
            this.getPaymentInfo(),
            this.getPaymentSummary()
        ]).finally(() => {
            this.previousBalance = this.paymentSummaryInfo.HasAccountPastDue;
            this.payAll = true;
            this.paymentAmount = this.paymentSummaryInfo.AccountPastDue;
            this.isLoading = false;

            if (this.model.IsAcaCustomer && (new Date()).getDate() <= 5) 
                this._showAlert(`Recurring monthly payments may take up to 5 business days to process. Your past due balance of $${ this.paymentSummaryInfo.AccountPastDue.toFixed(2)} may not reflect pending recurring monthly payments until after that time.`);
        });
    }

    goToStepWithCard(selectedCBI) {
        if (selectedCBI) {
            this.currentStep.nextStep = 'stepPaymentSubmit';
            this.steps[4].prevStep = this.currentStep.name;
        } else {
            this.currentStep.nextStep = 'stepPaymentType';
            this.steps[4].prevStep = 'stepPaymentAddress';
        }
        this.selectedCard = selectedCBI;
        this.hasSelectedCard = true;
        this.showAddCard = false;
    }

    goToStep(step) {
        _.each(this.steps,(loopStep) => {
            if (loopStep.name === step.name) {
                loopStep.visible = true;
                this.currentStep = loopStep;
            } else {
                loopStep.visible = false;
            }
        });
    }

    setBillingAddress() {
        if (this.isCheckedBillingAddress) {
            this.selectedCard.Line1 = this.model.CustomerInformation.BillingAddress.Address1;
            this.selectedCard.Line2 = this.model.CustomerInformation.BillingAddress.Address2;
            this.selectedCard.City = this.model.CustomerInformation.BillingAddress.City;
            this.selectedCard.State = this.model.CustomerInformation.BillingAddress.State;
            this.selectedCard.PostalCode = this.model.CustomerInformation.BillingAddress.PostalCode;
        }
    }

    processForwardSteps(step, addressForm, creditCardForm) {
        if (!_.isNil(creditCardForm)) {
            creditCardForm.$setPristine();
        }

        this.incomingStep = step;
        switch (step.name) {
            case 'stepPaymentType':
                this.selectedCard.CCExp = this.selectedCard.CcExpMonth.text + '/' + this.selectedCard.CcExpYear.toString().substring(2, this.selectedCard.CcExpYear.toString().length);
                this.goToNextStep();
                break;
            case 'stepPaymentAddress':
                this.selectedCard.LastFour = this.selectedCard.CardCcNumb.substring(this.selectedCard.CardCcNumb.length - 4, this.selectedCard.CardCcNumb.length);
                this.showAddCard = this.countCreditCards <= 3;
                this.selectedCard.CreditCardType = this.creditCardService.getCardType(this.selectedCard.CardCcNumb);
                this.selectedCard.IsCredit = true;
                this.validateAddress();
                break;
            default :
                this.goToNextStep();
        }

        this.scrollTop();
    }

    goToNextStep() {
        _.each(this.steps, (loopStep) => {
            if (loopStep.name === this.incomingStep.nextStep) {
                loopStep.visible = true;
                this.currentStep = loopStep;
            } else {
                loopStep.visible = false;
            }
        });
    }

    processBackSteps(step) {
        _.each(this.steps, (loopStep) => {
            if (loopStep.name === step.prevStep) {
                loopStep.visible = true;
                this.currentStep = loopStep;
            }
            else {
                loopStep.visible = false;
            }
        });

        this.scrollTop();
    }

    submit(cbi) {
        this.disableSubmit = true;
        if (!cbi.CustomerBillingInfoID) {
            this.model.PaymentInfo.CardCcExp = this.selectedCard.CcExpMonth.text + '/' + this.selectedCard.CcExpYear.toString().substring(2, this.selectedCard.CcExpYear.toString().length);
            this.model.PaymentInfo.CardCcNumb = this.selectedCard.CardCcNumb;
            this.model.PaymentInfo.AccountName = this.selectedCard.FirstName + ' ' + this.selectedCard.LastName;
            this.model.PaymentInfo.CardHolderFirstName = this.selectedCard.FirstName;
            this.model.PaymentInfo.CardHolderLastName = this.selectedCard.LastName;

            this.model.OtherAddress = {};
            this.model.OtherAddress.Address1 = this.selectedCard.Line1;
            this.model.OtherAddress.State = this.selectedCard.State;
            this.model.OtherAddress.City = this.selectedCard.City;
            this.model.OtherAddress.PostalCode = this.selectedCard.PostalCode;
            this.model.AddressType = 'Other';
            if (this.saveCardLater === 'yes') {
                this.model.SaveCustomerBillingInfo = true;
            }
        }
        else {
            this.model.CustomerBillingInfoID = cbi.CustomerBillingInfoID;
        }

        let chargeType = {
            PaymentTypeId: 1,
            Name: 'Past Due Payment',
            Amount: this.paymentAmount
        };

        this.model.ChargeTypes = [];
        this.model.ChargeTypes.push(chargeType);
        this.model.GrandTotal = this.paymentAmount;

        let onSuccess = (resultStatus) => {
            this.dataContext.paymentResponse = {
                success: resultStatus,
                lastFour: (this.selectedCard.LastFour.length !== 4)
                    ? this.selectedCard.CardCcNumb.substring(this.selectedCard.CardCcNumb.length - 4, this.selectedCard.CardCcNumb.length)
                    : this.selectedCard.LastFour,
                creditCardType: _.capitalize(this.selectedCard.CreditCardType),
                paymentAmount: this.model.ChargeTypes[0].Amount,
                isDefaultRmr: this.selectedCard.IsDefaultRmr,
                leftOverBalance: parseFloat(this.paymentSummaryInfo.AccountPastDue) - parseFloat(this.model.ChargeTypes[0].Amount) > 0
                    ? parseFloat(this.paymentSummaryInfo.AccountPastDue) - parseFloat(this.model.ChargeTypes[0].Amount)
                    : 0,
                showAcaMessage: this.model.IsAcaCustomer && (new Date()).getDate() <= 10

            };
            return this.$close(this.dataContext.paymentResponse);
        };

        this.isLoading = true;
        this.dataContext.payment.savePaymentInfo(this.model)
            .then((result) => {
                return onSuccess(true);
            })
            .catch((error) => {
                if (_.isNil(error)) {
                    return onSuccess(false);
                }
                if (error.status === 409) {
                    this.$log.error('Error processing payment. There is another payment pending.');
                    return this.$close({ error: error });
                } else {
                    this.$log.error('Error processing payment', error);
                }

                this.disableSubmit = false;
                return onSuccess(false);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getPaymentInfo() {
        return this.dataContext.payment.getPaymentInfo()
            .then((result) => {
                this.model = result;
                this.model.AddressType = 'Billing';
                this.selectedAddress = result.CustomerInformation.BillingAddress;
                this.model.GrandTotal = this.paymentAmount;
                if (!_.isNil(this.model.CustomerInformation.BillingAddress)) {
                    this.billingAddress = this.model.CustomerInformation.BillingAddress.Address1 + ', ' + this.model.CustomerInformation.BillingAddress.City + ', ' + this.model.CustomerInformation.BillingAddress.State + ', ' + this.model.CustomerInformation.BillingAddress.PostalCode;
                }

                this.dataContext.customerBillingInfo.getCustomerBillingInfo()
                    .then((data) => {
                        this.CustomerBillingInfo = data.Data;
                        this.countCreditCards = 0;

                        _.each(this.CustomerBillingInfo, (cbi) => {
                            this.trimProperties(cbi);
                            if (cbi.IsCredit) {
                                this.countCreditCards++;
                                cbi.CardCcNumb = '********' + cbi.LastFour;
                                cbi.CreditCardType = cbi.CreditCardType.toLowerCase();
                                let cardInfoArray = cbi.CCExp.split('/');
                                cbi.CcExpMonth = cardInfoArray[0];
                                cbi.CcExpYear = 2000 + parseInt(cardInfoArray[1]);
                            }
                            else {
                                cbi.AccountNumber = cbi.AccountNumberValidate = '*****' + cbi.LastFour;
                                cbi.CheckType = cbi.CheckType === 'P' ? 'Personal' : 'Business';
                                cbi.CheckAccountType = cbi.CheckAccountType === 'C' ? 'Checking' : 'Saving';
                                if (cbi.CBIName.substring(0, 2) === 'C-') {
                                    cbi.CBIName = 'Checking' + cbi.CBIName.substring(1, cbi.CBIName.length);
                                } else if (cbi.CBIName.substring(0, 2) === 'S-') {
                                    cbi.CBIName = 'Saving' + cbi.CBIName.substring(1, cbi.CBIName.length);
                                }
                            }
                        });
                    });
            });
    }

    getPaymentSummary() {
        return this.dataContext.payment.getPaymentSummary()
            .then((result) => {
                this.payAll = result.PaymentSummaryInfo.AccountPastDue > 0;
                this.paymentSummaryInfo = result.PaymentSummaryInfo;
            });
    }

    getCreditCardYears() {
        let years = [];
        let currentDateYear = 2000 + ((new Date()).getYear() - 100);
        for (let x = 0; x <= 10; x++) {
            years.push(currentDateYear + x);
        }
        return years;
    }

    trimProperties(entity) {
        for (let prop in entity) {
            let value = entity[prop];
            if (_.isString(value)) {
                entity[prop] = entity[prop].trim();
            }
        }
    }

    validateAddress () {
        let address = {
            Address1 : this.selectedCard.Line1,
            Address2 : this.selectedCard.Line2,
            City: this.selectedCard.City,
            PostalCode: this.selectedCard.PostalCode,
            State: this.selectedCard.State
        };
        this.dataContext.address.validateAddress(address)
            .then((response) => {
                if (!this.dataContext.address.isValidResponse(response)) {
                    let returnedAddress = {
                        Address1 : address.Address1,
                        Address2 : address.Address2,
                        City: response.City,
                        PostalCode: response.PostalCode,
                        State: response.StateAbrev
                    };

                    let modalInstance = this.modal.open({
                        template: '<fp-address-validation-modal config="config"></fp-address-validation-modal>',
                        controller: ($scope) => {
                            'ngInject';
                            $scope.config = {
                                data: address,
                                close: modalInstance.close,
                                dismiss: modalInstance.dismiss,
                                returnedAddress: returnedAddress
                            };
                        }
                    });
                    
                    modalInstance.result
                        .then((result) => {
                            if (_.isNil(result)) {
                                this.isLoading = false;
                            } else {
                                if (this.selectedCard.State !== result.State || this.selectedCard.City != result.City) {
                                    this.selectedCard.State = result.State;
                                    this.selectedCard.City = result.City;
                                }
                                this.goToNextStep();
                            }
                        });
                } else {
                    this.goToNextStep();
                }
            });
    }

    amountSelectedClick(isPreviousBalance) {
        if (!_.isNil(this.paymentSummaryInfo)) {
            if (this.paymentSummaryInfo.AccountPastDue === 0 && isPreviousBalance) {
                this.previousBalance = undefined;
            }
            else {
                this.previousBalance = isPreviousBalance;
            }
        }
    }

    disableCCExpMonths() {
        _.each(this.ccExpMonths, (month) => {
            if (moment().year() === this.selectedCard.CcExpYear) {
                month.disabled = month.value <= moment().month();
            }
            else {
                month.disabled = false;
            }
        });
    }

    close () {
        this.$dismiss();
    }

    isMatchingCardType(cardType) {
        return this.getSelectedCardType() === cardType;
    }

    getSelectedCardType() {
        return !_.isNil(this.selectedCard) && this.creditCardService.getCardType(this.selectedCard.CardCcNumb);
    }

    scrollTop() {
        this.$scope.$emit(this.constants.events.modalOpened);
    }

    _showAlert(dialogMessage) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(`${dialogMessage}`)
            .setType('success')
            .setTimeout(5000)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: MakePaymentModalController
};