import template from './creditLogo.html';
import './creditcard.scss';

let creditLogoDirective = () => {
    return {
        restrict: 'E',
        template: template,
        controller: CreditLogoDirective,  
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            cardType: '='
        }
    };
};

class CreditLogoDirective {
    /* @ngInject */
    constructor() {
    }
}

export default creditLogoDirective;