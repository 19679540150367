
export class Address {
    Line1: string | null;
    Line2?: string | null;
    City: string | null;
    State: string | null;
    PostalCode: string | null;
    Attention?: string | null;
    AddressId?: number | null;
    Country?: string | null;
    County?: string | null;
}

export class RetentionRequest {
    retentionOfferTypeId?: number | null;
    questionnaireAnswerId?: number | null;
    shippingAddress?: Address | null;
    retentionEpisodeTypeId: number | null;
    timeFrameId?: number | null;
    customerPaymentId?: number | null;
    certificateUrl?: string | null;
    closedDate?: Date | null;
}
