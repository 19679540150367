import _ from 'lodash';
import './bookDispatchServiceModal.scss';
import template from './bookDispatchServiceModal.html';

class BookDispatchServiceModalController {
    /* @ngInject */
    constructor($q, dataContext, $scope, constants) {
        this.data = this.config.data;
        this.dataContext = dataContext;
        this.$q = $q;
        this.$scope = $scope;
        this.constants = constants;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;

        this.userSettingProcessSalesTax = _.find(this.dataContext.user.current.Settings, function (s) { return s.Name === "CustomerPortal:ProcessSalesTax" });
        this.userSettingProcessPayment = _.find(this.dataContext.user.current.Settings, function (s) { return s.Name === "CustomerPortal:ProcessPayment" });

        this.disableSubmit = false;
        this.taxReadyInstances = null;
        this.dispatchInstanceCalculationComplete = false;

        this.totalDispatchFee = 0.00;
        this.totalSalesTax = 0.00;
        this.totalCharge = 0.00;

        this.dispatchInstances = this.data.dispatchInstances;

    }

    $onInit() {
        this.isBookingLoading = true;

        this.dataContext.account.getDispatchTax(this.dispatchInstances).then((results) => {
            this.taxReadyInstances = results;

            this.totalDispatchFee = results.DispatchesTax.RetailCost;
            this.totalSalesTax = results.DispatchesTax.TaxAmount;
            this.totalCharge = results.DispatchesTax.TotalAmountWithTax;

        }).finally(() => {
            this.isBookingLoading = false;
            this.dispatchInstanceCalculationComplete = true;
        }).catch((error) => {
            this.isBookingLoading = false;
        });

        this.$scope.$on(this.constants.events.dispatch.finalizePaidDispatch, (event, success, dispatchInstances) => {
            if (success) {
                this.$close({ success: success, dispatchInstances: dispatchInstances });
            } else {
                this.$close({ success: success, dispatchInstances: dispatchInstances });
            }
        });

        this.$scope.$on(this.constants.events.dispatch.failedPaidDispatch, (event, closeModal) => {
            this.isBookingLoading = false;
            this.disableSubmit = false;

            if (closeModal) {
                this.$close();
            }
        });
    }

    submit() {

        this.disableSubmit = true;
        this.isBookingLoading = true;

        if ((document.getElementsByName("xxTrustedFormCertUrl")[0])) {
            this.taxReadyInstances.trustedFormCertificateURL = (document.getElementsByName("xxTrustedFormCertUrl")[0]).value;
        }

        if (this.userSettingProcessPayment != undefined && this.userSettingProcessPayment.Value === "False") {

            //do not process payment, go right to scheduling
            this.dataContext.dispatch.createUserDispatches(this.taxReadyInstances).then((result) => {
                this.disableSubmit = false;
                this.$close({ success: true, dispatchInstances: this.taxReadyInstances });
            });

        } else {
            //process payment
            this.$scope.$broadcast(this.constants.events.dispatch.processDispatchPayment, this.taxReadyInstances);
        }
    }

    close() {
        this.$close();
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: BookDispatchServiceModalController
};