import './commonModal.scss';

export class GenerateShippingLabelModalController {
    public modalInstance: any;
    public resolve: any;
    private emailAddress: any;

    /* @ngInject */
    constructor(private $uibModal: any,
        private $q: any,
        private constants: any,
        private $state: ng.ui.IStateService) {
        'ngInject';
        this.emailAddress = this.resolve.email;
    }

    public confirm() {
        this.modalInstance.close(this.emailAddress);
    }
}

export const generateShippingLabelModal = {
    selector: 'generateShippingLabelModal',
    template: require('./generateShippingLabelModal.html'),
    bindings: {
        modalInstance: '<',
        resolve: '<'
    },
    controller: GenerateShippingLabelModalController
};