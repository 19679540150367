import * as _ from 'lodash';
import template from './docusignContractModal.html';

class DocusignContractModalController{

    /* @ngInject */
    constructor($window,
        constants,
        dataContext,
        $rootScope,
        $scope)
    {
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.$window = $window;
        this.constants = constants;
        this.dataContext = dataContext;
        this.$rootScope = $rootScope;
        this.$scope = $scope;
    }

    $onInit() {
        this.isLoading = true;
        this.isComplete = false;
        this.isFailed = false;

        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningCompleted') {
                    this.isComplete = true;
                    this.$scope.$apply();
                }
            }, false);

        window.addEventListener("message",
            (msg) => {
                if (msg.data === 'DocusignSigningDeclined') {
                    this.isFailed = true;
                }
            }, false);

        this.dataContext.contractRenewal.getContractSigningUrl(this.config.offerId).then((result) => {
            this.docusignUrl = result;
            this.isLoading = false;
            this.$scope.$apply();
        }).catch((error) => {
            this.isLoading = false;
            this.isFailed = true;
            this.$scope.$apply();
        });
    }

    close(closingCommand) {
        this.$close(closingCommand);
        let chatBtn = document.querySelector(".ie-div-position-customer-chat");
        if (chatBtn) {
            chatBtn.style.display = "block";
        }
    }
}

export default {
    template: template,
    bindings: {
        config: '='
    },
    controller: DocusignContractModalController
};
