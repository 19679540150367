import './offer.component.scss';
import * as _ from 'lodash';

export class AdditionalOfferController {
    public retentionOfferTypeId = 0;
    public retentionQuestionnaireAnswerID = 0;
    public retentionAnswerOptionID = 0;
    public offer: any;
    public secondaryOffer: any;

    constructor(private $stateParams: any, private $state: any, private dataContext: any, private readonly constants: any) {
        'ngInject';
        this.retentionOfferTypeId = $stateParams.retentionOfferTypeId;
        this.retentionQuestionnaireAnswerID = $stateParams.retentionQuestionnaireAnswerID;
        this.retentionAnswerOptionID = $stateParams.retentionAnswerOptionID
    }

    public $onInit() {
        this.dataContext.retention.getRetentionOfferType(this.retentionOfferTypeId).then((offer: any) => {
            this.offer = offer;            
        });
    }
}

export const additionalOfferComponent = {
    template: require('./additionalOffer.component.html'),
    controller: AdditionalOfferController
};