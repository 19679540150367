import './cancellation.component.scss';
import './linkExpired.component.scss';

export class LinkExpiredController {

}

export const linkExpiredComponent = {
    template: require('./LinkExpired.component.html'),
    controller: LinkExpiredController
};