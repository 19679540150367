import _ from 'lodash';

import template from './dispatchHistory.html';
import './dispatchHistory.scss';

class DispatchHistoryController {
    /*@ngInject*/
    constructor($log, dataContext, $q, constants, mobileAppContext) {
        this.$log = $log;
        this.dataContext = dataContext;
        this.$q = $q;
        this.constants = constants;
        this.retrievedReports = [];
        this.$mobileAppContext = mobileAppContext;
        this.isMobileApp = this.$mobileAppContext.get() || window.innerWidth <= 768;

        this.dispatchRecordTableSlice = [];
        this.dispatchRecordPageNumber = 1;

        this.showNextPage = false;
        this.showPreviousPage = false;
    }

    $onChanges(changes) {
        if (changes.dispatchHistory && changes.dispatchHistory.currentValue.length > 0) {
            this.initializeHistoryTable();
        }
    } 

    openFeedback() {
        var rel = "noreferrer noopener";
        window.open(this.constants.dispatchFeedbackURL, "_blank", rel);
    }

    retrieveReport(dispatchData) {
        this.isLoading = true;
        var previousReport = _.find(this.retrievedReports,
            (r) => {
                return r.dispatchId == dispatchData.DispatchID;
            });

        if (!previousReport) {

            this.dataContext.dispatch.getUserDispatchReport(dispatchData.DispatchID).then((result) => {

                var reportDate = new Date(dispatchData.ScheduleDate);

                var formattedDate = (reportDate.getMonth() + 1).toString().padStart(2, '0') + '-' +
                    reportDate.getDate().toString().padStart(2, '0') + '-' +
                    reportDate.getFullYear();

                this.retrievedReports.push({
                    dispatchId: dispatchData.DispatchID,
                    dispatchDate: formattedDate,
                    dispatchReportBytes: result
                });

                this.downloadFile(result, formattedDate, dispatchData.DispatchID);
                this.isLoading = false;
            });
        } else {
            this.downloadFile(previousReport.dispatchReportBytes, previousReport.dispatchDate, previousReport.dispatchId);
            this.isLoading = false;
        }

        
    }

    downloadFile(stream, formattedDate, dispatchId) {
        var bytes = this.base64ToArrayBuffer(stream);
        var reportTitle = (`Frontpoint Dispatch Report - Incident No ${dispatchId} - ${formattedDate}`).toString();
        this.saveByteArray(reportTitle, bytes);
    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    saveByteArray(reportName, byte) {
        var blob = new Blob([byte], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (navigator.userAgent.match(/Android/i)) {
            var url = window.URL || window.webkitURL;
            window.open(url.createObjectURL(blob));
        }
        else {
            var fileName = reportName;
            link.download = fileName;
            link.click();
        } 
    }

    getTimezoneAbbreviation(timeZone) {
        return this.dataContext.dispatch.getUserTimeZoneAbbreviation(timeZone);
    }

    initializeHistoryTable() {
        this.dispatchRecordTableSlice = this.dispatchHistory;
        
        if (this.dispatchRecordTableSlice[0].TotalPages > 1) {
            this.showNextPage = true;
            this.showPreviousPage = true;
            this.TotalPages = this.dispatchRecordTableSlice[0].TotalPages;
        }
    }

    getNextPage() {
        var pageNumber = this.dispatchRecordPageNumber + 1;

        var searchModel = {
            PageNumber: pageNumber,
            PageSize: 5,
            IsSortAsc: true
        };

        this.isLoading = true;
        this.dataContext.dispatch.getUserDispatchHistory(searchModel).then((result => {
            this.dispatchRecordTableSlice = [result];
            this.dispatchRecordPageNumber = pageNumber;
            this.isLoading = false;
        }));
    }

    getPreviousPage() {
        var pageNumber = this.dispatchRecordPageNumber - 1;

        var searchModel = {
            PageNumber: pageNumber,
            PageSize: 5,
            IsSortAsc: true
        };

        this.isLoading = true;
        this.dataContext.dispatch.getUserDispatchHistory(searchModel).then((result => {
            this.dispatchRecordTableSlice = [result];
            this.dispatchRecordPageNumber = pageNumber;
            this.isLoading = false;
        }));
    }
}

export default {
    template: template,
    bindings: {
        dispatchHistory: '<',
        isLoading: '<'
    },
    controller: DispatchHistoryController
};