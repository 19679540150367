import _ from 'lodash';
import template from './syncRapidModal.html';

class SyncRapidModalController {
    /* @ngInject */
    constructor($q, dataContext) {
        this.data = this.config.data;
        this.dataContext = dataContext;
        this.$q = $q;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.premiseAddress = this.config.data.premiseAddress;
        this.rapidPremiseAddress = this.config.data.rapidPremiseAddress;
        this.customerInfo = this.config.data.customerInfo;
    }

    submit() {
        let customerInfoCopy = _.cloneDeep(this.customerInfo);
        customerInfoCopy.BillingAddress = null;
        customerInfoCopy.CustomerInformation = null;
        customerInfoCopy.ShippingAddress = null;

        if (this.selection === 'rapid') {
            customerInfoCopy.PremisesAddress = {
                Address1: this.rapidPremiseAddress.Line1,
                Address2: this.rapidPremiseAddress.Line2,
                City: this.rapidPremiseAddress.City,
                State: this.rapidPremiseAddress.State,
                PostalCode: this.rapidPremiseAddress.PostalCode
            }
        } else {
            customerInfoCopy.PremisesAddress = {
                Address1: this.premiseAddress.Address1,
                Address2: this.premiseAddress.Address2,
                City: this.premiseAddress.City,
                State: this.premiseAddress.State,
                PostalCode: this.premiseAddress.PostalCode
            }
        }
        this.$close();

        return this.dataContext.account.updateContactInfo(customerInfoCopy)
            .catch((error) => {
                this.$log.error('Failed to edit account contact', error);
            });
    }

    close() {
        this.$close();
    }
}

export default {
    template: template,
    bindings: { config: '<' },
    controller: SyncRapidModalController
};