import './ecommInfoModal.scss';
import template from './ecommInfoModal.html';
import _ from 'lodash';

class EcommInfoModalController {
    /*@ngInject*/
    constructor($location, settingsDataService, constants, dataContext, $state, monitoringPlansService) { 
        this.settingsDataService = settingsDataService;
        this.dataContext = dataContext;
        this.$state = $state;
        this.constants = constants;
        this.$location = $location;
        this.$close = this.config.close;
        this.monitoringPlansService = monitoringPlansService;
    }

    doRedirect() {
        this.hasViewedModal();
        return this.$state.go('home', this.$state.params);
    }

    goToShop() {
        this.hasViewedModal();
        return this.$state.go('shop.home');
    }

    hasViewedModal() {
        const settings = [{
            Name: this.constants.beta.eComm.settingKey,
            Value: 'False'
        }];

        this.settingsDataService.updateUserSettings(settings);
    }
}

export default {
    template: template,
    bindings: {
        config: '<'     
    },
    controller: EcommInfoModalController
};