class DocumentDataService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    getContractByteFile(){
        return this.Restangular
            .all('Document')
            .one('ContractByteFile')
            .get();
    }

    getContractRawFile(){
        return this.Restangular
            .all('Document')
            .one('ContractRawFile')
            .get();
    }

    getContractSummary(){
        return this.Restangular
            .all('Document')
            .one('ContractSummary')
            .get();
    }

    getInsuranceRawFile(){
        return this.Restangular
            .all('Document')
            .one('Insurance')
            .get();
    }

    getInsuranceByteFile() {
        return this.Restangular
            .all('Document')
            .one('InsuranceBytes')
            .get();
    }
}

export default DocumentDataService;