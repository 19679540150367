export interface SalesCampaign {
    ShopCampaignID: number;
    Name: string;
    Path: string;
}

export default class SalesService {

    constructor(private Restangular: any) {
        'ngInject';
    }

    getCampaign(name: string): SalesCampaign {
        return this.Restangular
            .all('marketing')
            .customGET('campaign', {
                'name': name
            });
    }
}