import * as angular from 'angular';

import 'angular-ui-router';
import 'angular-http-auth';
import 'ng-idle';
import 'angular-match-height';
import 'angular-recaptcha';
import { radioUpgradeComponent } from './radioUpgrade.component';
import commonModule from '../common';
import headerComponent from '../common/header/header.component';
import footerComponent from '../common/footer/footer.component';
import shellComponent from './shell/shell.component';

export const radioUpgradeModule = angular.module('fp:customerportal:radioupgrade',[
    'ui.router',
    'http-auth-interceptor',
    'ngIdle',
    commonModule.name,
    ]).config(/*ngInject*/($stateProvider: ng.ui.IStateProvider, constants: any) => {
    $stateProvider.state('radioUpgrade',
            {
                url: '/radioUpgrade/:id',
                abstract: true,
                template: require('./shell/shell.html'),
                resolve: {
                    clearUserSession: /*ngInject*/(dataContext: any) => {
                        dataContext.user.reset();
                    }
                },
                data: {
                    sectionType: constants.sectionTypes.radioUpgrade,
                    hideNavigation: true
                }
            })
        .state('radioUpgrade.home',
            {
                url: '',
                template: '<fp-radio-upgrade></fp-radio-upgrade>',
            });
})
.component('fpRadioUpgrade', radioUpgradeComponent)
    .component('fpRadioUpgradeHeader', headerComponent)
    .component('fpRadioUpgradeFooter', footerComponent)
    .component('fpRadioUpgradeShell', shellComponent);
