import template from './movingProgressBar.html';
import _ from 'lodash';

import './movingProgressBar.scss';

class MovingProgressBarController {
    /*@ngInject*/
    constructor($state) {
        this.$state = $state;
        this.isMobile = document.getElementsByClassName('ua-mobile').length > 0;
    }

    $onInit() {
        this.showNode = false;
        this.steps = [
            {
                step: 'newResidence', value: 1
            }, {
                step: 'delivery', value: 2
            }, {
                step: 'confirmation', value: 3
            }
        ];
    }

    showProgressBar() {
        return _.includes(this.$state.current.name, 'moving')
            && this.$state.current.name !== 'moving.info'
            && this.$state.current.name !== 'moving';
    }

    showCircle(section) {
        const currentStep = !_.isNil(_.get(this, '$state.$current.data.navigationStep', undefined))
            ? this.$state.$current.data.navigationStep
            : this.step;
        if (section === currentStep) {
            return 'active';
        }
        return 'inactive';
    }

    fillCircle(step) {
        const currentStep = !_.isNil(_.get(this, '$state.$current.data.navigationStep', undefined))
            ? this.$state.$current.data.navigationStep
            : this.step;
        const currentValue = _.find(this.steps, { step: currentStep }).value;
        const circleValue = _.find(this.steps, { step: step }).value;
        if (circleValue <= currentValue) {
            return 'active';
        }
        return 'inactive';
    }
}

export default {
    template: template,
    bindings: {
        step: '<'
    },
    controller: MovingProgressBarController
};