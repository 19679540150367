import _ from 'lodash';
import template from './pets.html';
import './pets.scss';

import AbstractSectionController from '../AbstractSectionController';

class AccountPetsController extends AbstractSectionController {
    /*@ngInject*/
    constructor(dataContext, constants, alertService) {
        super();

        this.dataContext = dataContext;
        this.constants = constants;
        this.alertService = alertService;
        this.ALERT_DIALOG = 'petInfo';

        this.minCatValue = 0;
        this.maxCatValue = 25;
        this.minDogValue = 0;
        this.maxDogValue = 25;

    }

    buttonControl(petType) {

        if (petType === 'cats') {

            if (this.catsQuantity === this.minCatValue) {

                this.disableCatDecrement = true;
            }

            if (this.catsQuantity > this.minCatValue) {
                this.disableCatDecrement = false;
            }

            if (this.catsQuantity < this.maxCatValue) {
                this.disableCatIncrement = false;
            }

            if (this.catsQuantity === this.maxCatValue) {
                this.disableCatIncrement = true;
            }
        }

        if (petType === 'dogs') {

            if (this.dogsQuantity === this.minDogValue) {

                this.disableDogDecrement = true;
            }

            if (this.dogsQuantity > this.minDogValue) {
                this.disableDogDecrement = false;
            }

            if (this.dogsQuantity < this.maxDogValue) {
                this.disableDogIncrement = false;
            }

            if (this.dogsQuantity === this.maxDogValue) {
                this.disableDogIncrement = true;
            }
        }
    }

    resetIllegals(petType) {

        if (petType === 'cats') {
            if (this.catsQuantity == null) {
                this.catsQuantity = 0;
            }

            if (this.catsQuantity > this.maxCatValue) {
                this.catsQuantity = 0;
            }

            if (this.catsQuantity < this.minCatValue) {
                this.catsQuantity = 0;
            }
        }

        if (petType === 'dogs') {
            if (this.dogsQuantity == null) {
                this.dogsQuantity = 0;
            }

            if (this.dogsQuantity > this.maxDogValue) {
                this.dogsQuantity = 0;
            }

            if (this.dogsQuantity < this.minDogValue) {
                this.dogsQuantity = 0;
            }
        }

        this.buttonControl(petType);
    }

    decrement(petType) {

        if (petType === 'cats') {
            this.catsQuantity--;
        }

        if (petType === 'dogs') {
            this.dogsQuantity--;
        }

        this.buttonControl(petType);
    }

    increment(petType) {

        if (petType === 'cats') {
            this.catsQuantity++;
        }
        
        if (petType === 'dogs') {
            this.dogsQuantity++;
        }

        this.buttonControl(petType);
    }

    assemblePetsString(result) {

        let assembledPetsString = "";

        if (!result) {
            assembledPetsString = "No pets defined";
            return assembledPetsString;
        }

        if (result.Dogs != 0) {
            assembledPetsString += `${result.Dogs} dog`;

            if (result.Dogs > 1) {
                assembledPetsString += "s"
            }
        }

        if (result.Dogs != 0 && (result.Cats != 0 || result.OtherPets)) {
            assembledPetsString += ", "
        }

        if (result.Cats != 0) {
            assembledPetsString += `${result.Cats} cat`;

            if (result.Cats > 1) {
                assembledPetsString += "s";
            }
        }

        if (result.Cats != 0 && result.OtherPets) {
            assembledPetsString += ", "
        }

        if (result.OtherPets) {
            assembledPetsString += `${result.OtherPets}`;
        }

        return assembledPetsString;
    }
    
    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        
        //no pets defined yet in DB
        if (this.customerPets == null) {
            this.catsQuantity = 0;
            this.dogsQuantity = 0;
        }
        else {
            this.catsQuantity = this.customerPets.Cats;
            this.dogsQuantity = this.customerPets.Dogs;
            this.otherPets = this.customerPets.OtherPets;
        }
        

        if (this.catsQuantity === this.minCatValue) {
            this.disableCatDecrement = true;
        }

        if (this.catsQuantity === this.maxCatValue) {
            this.disableCatIncrement = true;
        }

        if (this.dogsQuantity === this.minDogValue) {
            this.disableDogDecrement = true;
        }

        if (this.dogsQuantity === this.maxDogValue) {
            this.disabledogIncrement = true;
        }

    }
    
    onSaveCore() {
        this.isLoading = true;
        
        return this.dataContext.pets.updateCustomerPets({ Cats: this.catsQuantity, Dogs: this.dogsQuantity, OtherPets: this.otherPets})
            .then(() => {
                this._showGenericSuccessAlert();
            })
            .catch((error) => {
                this.$log.error('Failed to edit account pets', error);
                this._showGenericErrorAlert();
            })
            .finally(() => {
                this.getCustomerPetInformation();
                this.isLoading = false;
                this.stopEditing();
                
            });
    }

    onCancelCore() {
        
    }

    getCustomerPetInformation() {
        return this.dataContext.pets.getCustomerPets()
            .then((result) => {
                this.customerPetsString = this.assemblePetsString(result);
                this.customerPets = result;
            });
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your account information has been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your account information. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        customerPets: '<',
        customerPetsString : '<',
        isLoading: '<'
    },
    controller: AccountPetsController
};