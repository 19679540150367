import './commonModal.scss';

export class CancellationConfirmModalController {
    public modalInstance: any;

    /* @ngInject */
    constructor(private $uibModal: any,
        private dataContext: any,
        private $q: any,
        private $state: ng.ui.IStateService) {
        'ngInject';
    }

    public close() {
        this.modalInstance.dismiss();
    }

    public confirm() {
        this.modalInstance.close();
    }
}

export const cancellationConfirmModal = {
    selector: 'cancellationConfirmModal',
    template: require('./cancellationConfirmModal.html'),
    bindings: {
        modalInstance: '<'
    },
    controller: CancellationConfirmModalController
};