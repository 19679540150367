class SessionDataService {
    getSessionRedirect() {
        const data = sessionStorage.getItem('sessionRedirect');
        return data ? JSON.parse(data) : null;
    }

    setSessionRedirect(state: string, stateParams: any) {
        sessionStorage.setItem('sessionRedirect', JSON.stringify({ state, stateParams }));
    }

    /**
     * Clears the session redirect from session storage
     */
    clearSessionRedirect() {
        sessionStorage.removeItem('sessionRedirect');
    }
}

export default SessionDataService;