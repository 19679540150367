import * as angular from 'angular';
import * as _ from 'lodash';

import 'angular-ui-router';
import 'angular-http-auth';
import 'ng-idle';
import 'angular-match-height';
import 'angular-recaptcha';

import singleSignOnComponent from './singleSignOn.component';

import { authDirective } from './auth.directive';

import commonModule from '../common';
import forgotPasswordModule from './forgotPassword';
import changePasswordModule from './changePassword';

import { loginComponent } from './login.component';
import loginHeaderComponent from './header.component';
import loginFooterComponent from './footer.component';
import loginShellComponent from './shell.component';

export const loginModule = angular.module('fp:customerportal:login', [
        'ui.router',
        'http-auth-interceptor',
        'vcRecaptcha',
        'ngIdle',
        'partnermarketing.matchHeight',

        commonModule.name,
        forgotPasswordModule.name,
        changePasswordModule.name
])

    .config(/*@ngInject*/ ($stateProvider: ng.ui.IStateProvider, constants: any) => {
        $stateProvider
            .state('login', {
                url: '',
                abstract: true,
                template: require('./shell.html'),
                resolve: {
                    clearUserSession: /*@ngInject*/ (dataContext: any) => {
                        dataContext.user.reset();
                    }
                },
                data: {
                    sectionType: constants.sectionTypes.login,
                    hideNavigation: true
                }
            })
            .state('login.home', {
                url: '/login?redirectTo&message&anchor',
                template: '<fp-login message="message"></fp-login>',
                controller: /*@ngInject*/ ($scope: any, $stateParams: ng.ui.IStateParamsService) => {
                    $scope.message = $stateParams.message;
                }
            })
            .state('login.singleSignOn', {
                url: '/SingleSignOn?token&redirectTo&app&offer_id&offer_hardware_type',
                template:
                    '<fp-single-sign-on token="token" redirect-to="redirectTo" app="app" query-params="queryParams">' +
                    '</fp-single-sign-on>',
                controller: /*@ngInject*/ ($scope: any, $stateParams: ng.ui.IStateParamsService,
                                           mobileAppContext: any) => {
                    $scope.token = $stateParams.token;
                    $scope.redirectTo = $stateParams.redirectTo;
                    $scope.queryParams = $stateParams;
                    
                    let isApp = false;
                    const appParam = $stateParams.app;
                    if (!_.isNil(appParam)) {
                        isApp = _.isArray(appParam)
                            ? _.some(appParam, (p: string) => p === 'true')
                            : appParam === 'true';
                    }

                    $scope.app = isApp;

                    mobileAppContext.set(isApp);
                },
                data: {
                    // no actual view associated with this, keep the splash
                    // up so we don't see a partially rendered shell
                    showSplash: true
                }
            });
    })

    .config(/*@ngInject*/ (IdleProvider: any, KeepaliveProvider: any, constants: any) => {
        IdleProvider.idle(constants.idle.timeout);
        IdleProvider.timeout(constants.idle.warning);
        KeepaliveProvider.interval(constants.idle.keepalive);
    })

    .run(/*@ngInject*/ ($rootScope: ng.IRootScopeService, dataContext: any, constants: any) => {
        $rootScope.$on(constants.events.logout, () => {
            dataContext.user.reset();
        });
    })

    .run(/*@ngInject*/($rootScope: IAppRootScope, $state: ng.ui.IStateService,
                       $location: ng.ILocationService, dataContext: any, constants: any) => {
        $rootScope.$on(constants.events.idleTimeout, () => {
            if (dataContext.user.isAuthenticated()) {
                const redirectTo = $location.url();
                $rootScope.redirectTo = redirectTo;

                $state.go('login.home', {
                    message: 'Logged out due to inactivity'
                });
            }
        });
    })

    .run(/*@ngInject*/($rootScope: ng.IRootScopeService, $window: ng.IWindowService, $state: ng.ui.IStateService,
                       $q: ng.IQService, $log: ng.ILogService, constants: any, fpStore: any, dataContext: any) => {

        $rootScope.$on(constants.events.stateChangeStart,
            (event: ng.IAngularEvent, toState: ng.ui.IState,
             toParams: any, fromState: ng.ui.IState, fromParams: any) => {

            if (_.startsWith(fromState.name, 'login.home')) {
                const homePagePreference = fpStore.get(constants.storage.homePagePreferenceKey);
                if (homePagePreference) {
                    $log.info('Redirecting to myfrontpoint.com...');

                    event.preventDefault();

                    let redirecting = false;
                    return dataContext.user.getUserInfo()
                        .then(() => {
                            if (dataContext.user.isAuthenticated()) {
                                return dataContext.user.getAdcRedirectUrl()
                                    .then((result: string) => {
                                        if (!_.isEmpty(result)) {
                                            redirecting = true;
                                            $window.location.href = result;
                                        } else {
                                            $log.warn('Failed to get ADC redirect URL, empty URL returned');
                                        }
                                    }, (error: any) => {
                                        $log.error('Failed to get ADC redirect URL', error);
                                    });
                            }

                            return $q.resolve();
                        })
                        .finally(() => {
                            if (!redirecting) {
                                // overwrite the setting if we're not redirecting, presumably because the user is PM
                                fpStore.local.set(constants.storage.homePagePreferenceKey, false);
                                $state.go(toState.name, toParams);
                            }
                        });
                }
            }

            return $q.resolve();
        });
    })

    .component('fpSingleSignOn', singleSignOnComponent)
    .directive('fpAuth', authDirective)
    .component('fpLogin', loginComponent)
    .component('fpLoginHeader', loginHeaderComponent)
    .component('fpLoginFooter', loginFooterComponent)
    .component('fpLoginShell', loginShellComponent);
