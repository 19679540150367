import angular from 'angular';
import commonModule from '../../../common';

import premiseAddressComponent from './premiseAddress.component';
import shippingAddressComponent from './shippingAddress.component';
import billingAddressComponent from './billingAddress.component';
import pendingPaymentsComponent from './pendingPayments.component';
import paymentCardComponent from './paymentCard.component';

export default angular
.module('fp:customerportal:myaccount:settings:components',
[
    commonModule.name
])
.component('fpPremiseAddress', premiseAddressComponent)
.component('fpShippingAddress', shippingAddressComponent)
.component('fpBillingAddress', billingAddressComponent)
.component('fpPendingPayments', pendingPaymentsComponent)
.component('fpPaymentCard', paymentCardComponent);