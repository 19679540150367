import angular from 'angular';
import _ from 'lodash';

import 'restangular';
import 'angular-cache';
import 'angular-storage';
import 'angular-cookies';

import constantsModule from '../constants';

import AccountDataService from './account.service';
import AddressDataService from './address.service';
import CustomerBillingInfoDataService from './customerBillingInfo.service';
import EmergencyDataService from './emergency.service';
import PaymentDataService from './payment.service';
import StatesDataService from './states.service';
import RoutingDataService from './routing.service';
import DocumentDataService from './document.service';
import PanelDataService from './panel.service';
import ApplicationStateService from './applicationState.service';
import DataContextService from './dataContext.service';
import DispatchService from './dispatch.service';
import MasterCodeService from './mastercode.service';
import TestModeService from './testmode.service';
import PasscodeService from './passcode.service';
import PermitService from './permit.service';
import SupportService from './support.service';
import ProductService from './product.service';
import MonitoringPlansService from './monitoringPlans.service';
import RelocationService from './relocation.service';
import RetentionService from './retention.service';
import TrackOrderService from './trackOrder.service';
import PetsDataService from './pets.service';
import MedicalInfoService from './medicalInfo.service';

import DataCacheService from './dataCache.service';
import StoreService from './store.service';
import UserService from './user.service';
import SettingsService from './settings.service';
import ConfigurationsService from './configurations.service';
import SmsService from './sms.service';
import ContractRenewalService from './contractRenewal.service';
import MarketingService from './marketing.service';
import VoziqService from './voziq.service';
import CancelService from './cancel.service';
import SessionDataService from './session.service';

export default angular.module('fp:customerportal:common:data', [
    'ngCookies',
    'angular-storage',
    'angular-cache',
    'restangular',

    constantsModule.name
])

    .service('accountDataService', AccountDataService)
    .service('addressDataService', AddressDataService)
    .service('customerBillingInfoDataService', CustomerBillingInfoDataService)
    .service('emergencyDataService', EmergencyDataService)
    .service('paymentDataService', PaymentDataService)
    .service('statesDataService', StatesDataService)
    .service('routingDataService', RoutingDataService)
    .service('documentDataService', DocumentDataService)
    .service('settingsDataService', SettingsService)
    .service('panelDataService', PanelDataService)
    .service('applicationStateService', ApplicationStateService)
    .service('dispatchService', DispatchService)
    .service('mastercodeService', MasterCodeService)
    .service('testmodeService', TestModeService)
    .service('passcodeService', PasscodeService)
    .service('permitService', PermitService)
    .service('supportService', SupportService)
    .service('productService', ProductService)
    .service('monitoringPlansService', MonitoringPlansService)
    .service('relocationService', RelocationService)
    .service('retentionService', RetentionService)
    .service('trackOrderService', TrackOrderService)
    .service('petsDataService', PetsDataService)
    .service('medicalInformationService', MedicalInfoService)
    .service('dataContext', DataContextService)
    .service('fpDataCache', DataCacheService)

    .service('fpStore', StoreService)
    .service('userService', UserService)
    .service('configurationsService', ConfigurationsService)
    .service('smsService', SmsService)
    .service('contractRenewalService', ContractRenewalService)
    .service('marketingService', MarketingService)
    .service('voziqService', VoziqService)
    .service('cancelService', CancelService)
    .service('sessionDataService', SessionDataService)

    .config(/*@ngInject*/(storeProvider) => {
        storeProvider.setCaching(false);
        storeProvider.setStore('sessionStorage');
    })

    .run(/*@ngInject*/($http, $rootScope, Restangular, fpDataCache, constants) => {
        $http.defaults.cache = fpDataCache.instance;
        Restangular.setResponseInterceptor((data, operation, what, url, response, deferred) => {
            if (!_.startsWith(operation, 'get') && what !== 'applicationState') { // clear the cache for any non-GET messages except for log entries
                fpDataCache.clear();
                if (_.includes(url, '/Account/CustomerInfo')) {
                    $rootScope.$broadcast(constants.events.refresh.customer, data);
                } else if (_.includes(url, '/Account/MonitoringInfo')) {
                    $rootScope.$broadcast(constants.events.refresh.monitoring, data);
                } else if (_.includes(url, '/Account/PaymentInfo')) {
                    $rootScope.$broadcast(constants.events.refresh.payment, data);
                }
            }
            return data;
        });
    });