export default class ContractRenewalService {

    constructor(private Restangular: any) {
        'ngInject';
    }

    public async getContractSigningUrl(offerId?: number) {
        if (typeof offerId !== "undefined") {
            return this.Restangular
                .all('contract-renewal')
                .one('contract-signing-url')
                .withHttpConfig({
                    cache: false
                })
                .customGET('',{'offerId': offerId});
        }
        return this.Restangular
            .all('contract-renewal')
            .one('contract-signing-url')
            .withHttpConfig({
                cache: false
            })
            .get();
    }
}