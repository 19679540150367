import _ from 'lodash';

import template from './dispatchServices.html';

class DispatchServicesController {
    /*@ngInject*/
    constructor($log, dataContext, $q, $scope, constants) {
        this.$log = $log;
        this.dataContext = dataContext;
        this.$q = $q;
        this.$scope = $scope;
        this.constants = constants;
        this.parentViewIsLoading = true;

        //Component Loading Control
        this.upcomingIsLoading = false;
        this.notificationsIsLoading = false;
        this.historyIsLoading = false;

        //Returned Values
        this.dispatchNotificationTypes = [];
        this.userDispatchNotificationSettings = [];
        this.userUpcomingDispatches = [];
        this.dispatchHistory = [];
        this.dispatchDiscountGroups = [];

        //Promises
        this.userHistoryDispatchesPromises = [];
        this.userNotificationPromises = [];
        this.userUpcomingDispatchesPromises = [];

    }

    $onInit() {
        this.generateUserDispatchData();

        this.$scope.$on(this.constants.events.dispatch.retrieveUserDispatchHistory, (event) => {
            this.historyIsLoading = true;
            this.generateUserDispatchHistoryData();
        });
    }

    generateUserHistoryDispatchesPromise(searchModel) {
        return new Promise((resolve, reject) => {
            this.dataContext.dispatch.getUserDispatchHistory(searchModel)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    generateDispatchNotificationsPromises() {
        var notificationTypePromise = new Promise((resolve, reject) => {
            this.dataContext.dispatch.getDispatchNotificationTypes()
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });

        var userNotificationSettingsPromise = new Promise((resolve, reject) => {
            this.dataContext.dispatch.getUserDispatchNotificationSettings()
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });

        this.userNotificationPromises.push(notificationTypePromise);
        this.userNotificationPromises.push(userNotificationSettingsPromise);

    }

    createUserUpcomingDispatchDataPromises() {
        var userUpcomingDispatchDataPromise = new Promise((resolve, reject) => {

            var searchModel = {
                PageSize: 5,
                IsSortAsc: true
            };

            this.dataContext.dispatch.getUserUpcomingDispatches(searchModel)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });

        this.userUpcomingDispatchesPromises.push(userUpcomingDispatchDataPromise);
    }

    createUserHistoryDispatchDataPromises() {
        var searchModel = {
            PageSize: 5,
            IsSortAsc: true
        };

        this.userHistoryDispatchesPromises.push(this.generateUserHistoryDispatchesPromise(searchModel));
    }

    generateUserDispatchData() {
        this.createUserHistoryDispatchDataPromises();
        this.createUserUpcomingDispatchDataPromises();
        this.generateDispatchNotificationsPromises();

        this.retrieveCustomerDispatchData();

    }

    generateUserDispatchHistoryData() {

        //just resetting the promises so we dont retrieve data twice from initialization of these promises
        this.userHistoryDispatchesPromises = [];

        this.createUserHistoryDispatchDataPromises();
        this.retrieveCustomerDispatchHistoryData();
    }

    retrieveCustomerDispatchData() {
        this.dataContext.dispatch.getDispatchDiscountGroups().then(results => {
            this.dispatchDiscountGroups = results;
        });

        // Get Upcoming Dispatches //
        Promise.all(this.userUpcomingDispatchesPromises).then(results => {
            this.userUpcomingDispatches = results;
        });

        // Get non-pending or processing dispatches, so user history // 
        Promise.all(this.userHistoryDispatchesPromises).then(results => {
            this.dispatchHistory = results;
        });

        // Get use notification settings // 
        Promise.all(this.userNotificationPromises).then(results => {
            this.dispatchNotificationTypes = results[0];
            this.userDispatchNotificationSettings = results[1];
        }).then(() => {
            this.parentViewIsLoading = false;
        });
    }

    retrieveCustomerDispatchHistoryData() {

        // Get non-pending or processing dispatches, so user history // 
        Promise.all(this.userHistoryDispatchesPromises).then(results => {
            this.dispatchHistory = results;

        }).then(() => {
            this.historyIsLoading = false;
        });
    }
}

export default {
    template: template,
    bindings: {},
    controller: DispatchServicesController
};