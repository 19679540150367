import _ from 'lodash';

class OutOfSyncService {
    /*@ngInject*/
    constructor() {
    }

    emailOutOfSync(customerInformation, adcCustomerInformation) {     
        return (customerInformation.Email !== adcCustomerInformation.PrimaryEmail);
    }

    phoneOutOfSync(customerInformation, adcCustomerInformation) {     
        adcCustomerInformation.PrimaryPhoneNumber = _.replace(adcCustomerInformation.PrimaryPhoneNumber, '+1', '');
        return (customerInformation.Phone !== adcCustomerInformation.PrimaryPhoneNumber);
    }
}
export default OutOfSyncService; 