import DataContext from '../common/data/dataContext.service';
import './v2.component.scss';

export class CancellationV2Controller {
    constructor(private dataContext: DataContext,
        private $state: ng.ui.IStateService) {
            'ngInject';
    }

    public async $onInit() {
        // this component is the hook/interception for a self service retention episode
        // we should show a loading indicator while we create the necessary resources
        // and once complete push the user back into the existing flow
        const token = this.dataContext.cancel.getToken();
        const isTokenValid = await this.dataContext.cancel.isTokenValid(token)

        // start the self service flow if it's not already started
        if (this.$state.current.name == 'cancel' && !(await this.dataContext.cancel.hasSelfServiceRetentionOpen())) {
            const result = await this.dataContext.cancel.startSelfServiceRetention(token);
            if (result) {
                this.$state.go('cancellation.overview');
            } else {
                this.$state.go('expired');
            }
        } else if (this.$state.current.name == 'cancel' && isTokenValid) {
            this.$state.go('cancellation.overview');
        } else if (this.$state.current.name == 'cancel' && !isTokenValid) {
            this.$state.go('expired');
        }

        // otherwise we're on the contact page, and it's fine
    }

}

export const cancelV2Component = {
    selector: 'fpCancelV2',
    template: require('./v2.component.html'),
    controller: CancellationV2Controller
};