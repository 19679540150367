import * as _ from 'lodash';
import * as angular from 'angular';

import 'angular-ui-bootstrap';
import 'angular-ui-router';

import commonModule from '../../common';
import componentsModule from './components';
import modalsModule from './modals';

import './settings.scss';

import settingsAddressComponent from './address.component';
import settingsBillingComponent from './billing.component';
import settingsAccountComponent from './account.component';
import settingsHomeComponent from './home.component';
import settingsPetsComponent from './pets.component'
import settingsMedicalInfoComponent from './medicalInfo.component';

export default angular.module('fp:customerportal:myaccount:settings',
    [
        'ui.bootstrap',
        'ui.router',
        commonModule.name,
        componentsModule.name,
        modalsModule.name
    ])
    .config( /*@ngInject*/ ($stateProvider: ng.ui.IStateProvider, constants: any) => {
        $stateProvider
            .state('account.settings',
                {
                    url: '/settings?editCBI&makePayment',
                    template: '<fp-settings-home></fp-settings-home>',
                    data: {
                        navigationMenu: constants.navigationMenuSections.majorSections.account,
                        navigationSubSection: constants.navigationMenuSections.subSections.accountSettings,
                        subnav: [
                            {
                                name: 'Account Info',
                                shortName: 'Account',
                                icon: 'fa-user',
                                href: '#account'
                            },
                            {
                                name: 'Pets',
                                shortName: 'Pets',
                                icon: 'fa-paw',
                                href: '#pets'
                            },
                            {
                                name: 'Medical Info',
                                shortName: 'Medical',
                                icon: 'fa-medkit',
                                href: '#medicalInfo'
                            },
                            {
                                name: 'Account Addresses',
                                shortName: 'Addresses',
                                icon: 'fa-home',
                                href: '#address'
                            },
                            {
                                name: 'Account Billing',
                                shortName: 'Billing',
                                icon: 'fa-credit-card',
                                href: '#billing'
                            }
                        ]
                    }
                })
            .state('account.settings.info',
                {
                    data: {
                        scrollTo: 'account'
                    }
                })
            .state('account.settings.address',
                {
                    data: {
                        scrollTo: 'address'
                    }
                })
            .state('account.settings.billing',
                {
                    data: {
                        scrollTo: 'billing'
                    },
                    params: {
                        editCBI: true
                    }
                })
            .state('account.setting.medicalInfo',
                {
                    data: {
                        scrollTo: 'medicalInfo'
                    }
                });
    })
    .component('fpSettingsAddress', settingsAddressComponent)
    .component('fpSettingsBilling', settingsBillingComponent)
    .component('fpSettingsAccount', settingsAccountComponent)
    .component('fpSettingsHome', settingsHomeComponent)
    .component('fpSettingsPets', settingsPetsComponent)
    .component('fpSettingsMedicalInfo', settingsMedicalInfoComponent);
