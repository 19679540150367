import template from './stopMoveModal.html';
import './stopMoveModal.scss';

class StopMoveModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, $q) {
        this.$uibModal = $uibModal;
        this.$q = $q;
        this.premises = this.config.data.premisesAddress;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.dataContext = dataContext;
    }

    close() {
        this.$dismiss();
    }

    stopMove() {
        return this.dataContext.relocation.stopRelocation().then(() => {
            this.$close(true);
            return true;
        }).catch(() => {
            this.$close(false);
            return false;
        });
    }
}

export default {
    template: template,
    bindings: {
        config: '<',
        relocationInfo: '<'
    },
    controller: StopMoveModalController
};