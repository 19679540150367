import _ from 'lodash';
import './syncInfoModal.scss';
import template from './syncInfoModal.html';

class SyncInfoModalController {
    /* @ngInject */
    constructor($q, dataContext, outOfSyncService) {
        this.data = this.config.data;
        this.dataContext = dataContext;
        this.$q = $q;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.customerInformation = this.config.data.customerInformation;
        this.adcCustomerInformation = this.config.data.adcCustomerInformation;
        this.outOfSyncEmail = false;
        this.disabledEmail = false;
        this.outOfSyncPrimaryPhone = false;
        this.disabledPhone = false;
        this.outOfSyncService = outOfSyncService;
        this.syncedCustomerInformation = _.clone(this.customerInformation);
    }

    $onInit() {
        this.checkOutOfSync();
    }

    checkOutOfSync() {
        if (this.outOfSyncService.emailOutOfSync(this.customerInformation, this.adcCustomerInformation)) {
            this.outOfSyncEmail = true;
            this.syncedCustomerInformation.Email = '';
            if (this.customerInformation.Email === '') {
                this.disabledEmail = true;
            }
        }

        if (this.outOfSyncService.phoneOutOfSync(this.customerInformation, this.adcCustomerInformation)) {   
            this.outOfSyncPrimaryPhone = true;
            this.syncedCustomerInformation.Phone = '';
            if (this.customerInformation.Phone === '') {
                this.disabledPhone = true;
            }
        } 
    }

    submit() {
        let info = {
            CustomerInformation: this.syncedCustomerInformation
        };
        this.dataContext
            .account
            .updateContactInfo(info)
            .then(() => {
                this.$close();
            });
    }

    close() {
        this.$dismiss();
    }

}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: SyncInfoModalController
};