import _ from 'lodash';
import template from './alert.html';

class AlertController {
    /*@ngInject*/
    constructor(alertService) {
        this.alertService = alertService;
    }

    $onInit() {
        if (_.isNil(this.name)) {
            throw 'The component fpAlert name binding cannot be null or undefined.';
        }

        this.alert = this.alertService._create(this.name);
    }

    $onDestroy() {
        this.alertService._remove(this.name);
    }
}

export default {
    template: template,
    bindings: {
        name: '@'
    },
    controller: AlertController
};
