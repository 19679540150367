import template from './asyncSection.html';

class AsyncSectionController {
    /*@ngInject*/
    constructor() {
    }
}

export default {
    template: template,
    transclude: true,
    bindings: {
        onLoad: '&',
        isLoading: '<',
        showSpinner: '<'
    },
    controller: AsyncSectionController
};