import angular from 'angular';
import template from './contentModal.html';

class ContentModalController {
    /*@ngInject*/
    constructor($window) {
        this.$window = $window;
    }

    $onInit() {
        angular.element(this.$window).on('resize', this.onAdjustHeight.bind(this));
        this.onAdjustHeight();
    }

    $onDestroy() {
        angular.element(this.$window).off('resize', this.onAdjustHeight.bind(this));
    }

    onClose() {
        this.onClose();
    }

    onAdjustHeight() {
        this.height = this.$window.innerHeight / 1.75;
    }
}

export default {
    template: template,
    bindings: {
        title: '@',
        url: '@',
        onClose: '&'
    },
    controller: ContentModalController
};