import angular from 'angular';

import commonModule from '../../../common';

import passwordRequirementsComponent from './passwordRequirements.component';
import smsDisclaimerComponent from './smsDisclaimer.component';
import smsDisclaimerModalComponent from './smsDisclaimerModal.component';

export default angular
    .module('fp:customerportal:account:preferences:components',
    [
        commonModule.name
    ])
    .component('fpPasswordRequirements', passwordRequirementsComponent)
    .component('fpSmsDisclaimer', smsDisclaimerComponent)
    .component('fpSmsDisclaimerModal', smsDisclaimerModalComponent);


