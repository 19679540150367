import angular from 'angular';

import constantsModule from '../constants';

import _ from 'lodash';
import { JL } from 'jsnlog';

export default angular.module('fp:customerportal:common:logger', [
    constantsModule.name
])
    .constant('_', _)

    .constant('loggerName', 'Client')

    .config(/*@ngInject*/ ($provide) => {
        $provide.decorator('$log', /*@ngInject*/ ($delegate) => {
            const errorFn = $delegate.error;
            $delegate.error = (msg, exception) => {
                if (exception && exception.config) {
                    // exception.config.data may contain PII that should not be logged.
                    delete exception.config.data;
                }

                errorFn(msg, exception);
            };

            return $delegate;
        });
    })

    .config(/*@ngInject*/ ($provide, loggerName, constants) => {
        if (constants.debug !== true) {
            let getMessage = (msg) => {
                let result = msg;

                if (!_.isEmpty(msg.Message)) {
                    result = msg.Message;
                } else if (!_.isNil(msg.data) && !_.isEmpty(msg.data.Message)) {
                    result = msg.data.Message;
                }

                return result;
            };

            $provide.decorator('$log', /*@ngInject*/ ($delegate) => {
                let logFn = $delegate.log;
                $delegate.log = (msg) => {
                    logFn(msg);
                    JL(loggerName).trace(getMessage(msg));
                };

                let debugFn = $delegate.debug;
                $delegate.debug = (msg) => {
                    debugFn(msg);
                    JL(loggerName).debug(getMessage(msg));
                };

                let infoFn = $delegate.info;
                $delegate.info = (msg) => {
                    infoFn(msg);
                    JL(loggerName).info(getMessage(msg));
                };

                let warnFn = $delegate.warn;
                $delegate.warn = (msg) => {
                    warnFn(msg);
                    JL(loggerName).warn(getMessage(msg));
                };

                let errorFn = $delegate.error;
                $delegate.error = (msg, exception) => {
                    errorFn(msg, exception);
                    JL(loggerName).fatalException(getMessage(msg), exception);
                };

                return $delegate;
            });
        }
    });