import './cancellationComplete.component.scss';
import * as _ from 'lodash';

import { generateShippingLabelModal } from './modals/generateShippingLabelModal.component'

export class CancellationCompleteController {
    public timeFrameId: number;
    public isLoading: boolean;
    private cancelDate: Date;
    public retentionEpisodeId = 0;

    constructor(private readonly dataContext: any,
        private readonly constants: any,
        private $stateParams: any,
        private readonly $log: any,
        private readonly $uibModal: any) {
        'ngInject';
        this.retentionEpisodeId = $stateParams.retentionEpisodeID;
    }

    public $onInit() {
        this.isLoading = true;
        this.dataContext.account.getTimeFrame().then((result: any) => {
            this.timeFrameId = result.TimeFrameId;

            if (this.timeFrameId === this.constants.timeFrames.RFTP) {
                this.dataContext.account.getCustomerInfo().then((result: any) => {
                    let modalInstance = this.$uibModal.open({
                        component: generateShippingLabelModal.selector,
                        size: 'lg',
                        resolve: {
                            email: () => { return result.CustomerInformation.Email; }
                        }
                    });
                    // Generate return label
                    return modalInstance.result.then(async (result: any) => {
                        this.isLoading = true;
                        await this.dataContext.retention.emailReturnLabel({ recipientEmail: result });
                        this.isLoading = false;
                    });
                });
            } else {
                this.dataContext.retention.getRetentionEpisode(this.retentionEpisodeId).then((response: any) => {
                    if (!_.isNil(response) && !_.isNil(response.CancelDate)) {
                        this.cancelDate = response.CancelDate;
                    }
                });
            }
        }).finally(async () => {
            try {
                this.isLoading = true;
                await this.dataContext.retention.updateSession(this.retentionEpisodeId);
            } catch (error) {
                this.$log.error(error);
            }
            this.isLoading = false;
        });
    }
}

export const cancellationCompleteComponent = {
    template: require('./cancellationComplete.component.html'),
    controller: CancellationCompleteController
};