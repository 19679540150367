import template from './movingDelivery.html';

import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

class MovingDeliveryController {
    /*@ngInject*/
    constructor($state, $q, dataContext, movingService, constants, segment) {
        this.$state = $state;
        this.$q = $q;
        this.dataContext = dataContext;
        this.movingService = movingService;
        this.constants = constants;
        this.format = 'MM/dd/yyyy';
        this.isSameAddress = false;
        this.datePickerStatus = {
            opened: false
        };
        this.segment = segment;
    }

    $onChanges() {
        let initialDate = moment().add(this.constants.MovePortal.disableDateRange, 'days').startOf('hour').toDate();
        this.dateOptions = {
            minDate: initialDate,
            maxDate: moment().add(6, 'month').toDate(),
            showWeeks: false,
            maxMode: 'day',
            initDate: initialDate,
            dateDisabled: (input) => this._isHoliday(input)
        };
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.customerInfo);
        if (!_.isNil(this.customerInfo)) {
            this.moversKitAddress = this.customerInfo.PremisesAddress;
            this.moversKitAddress.Email = this.customerInfo.CustomerInformation.Email;
        }
        if (!_.isNil(this.relocationInfo)) {
            const today = moment().format();
            const orderDate = moment(this.relocationInfo.OrderProcessingDate).format();
            this.isOrderActive = today <= orderDate;
            this.isOrderActive ? this.moversKitDate = new Date(this.relocationInfo.OrderProcessingDate) : this.moversKitDate = new Date(moment());

            if (!_.isNil(this.relocationInfo.ShippingAddress)) {
                this.moversKitAddress.Address1 = this.relocationInfo.ShippingAddress.Line1;
                this.moversKitAddress.Address2 = this.relocationInfo.ShippingAddress.Line2;
                this.moversKitAddress.City = this.relocationInfo.ShippingAddress.City;
                this.moversKitAddress.State = this.relocationInfo.ShippingAddress.State;
                this.moversKitAddress.PostalCode = _.trim(this.relocationInfo.ShippingAddress.PostalCode);
            }
        }
    }

    _isHoliday(date) {
        return this.movingService._isHoliday(date);
    }

    makeSameAddress() {
        var email = this.moversKitAddress.Email;
        if (this.isSameAddress) {
            this.moversKitAddress = this.movingAddress;
            this.moversKitAddress.Address1 = this.movingAddress.Line1;
            this.moversKitAddress.Address2 = this.movingAddress.Line2;
            this.moversKitAddress.isSameAddress = this.isSameAddress;
        } else {
            this.moversKitAddress = this.pristine.PremisesAddress;
        }
        this.moversKitAddress.Email = email;
    }

    disableMoversKit() {
        if (!_.isNil(this.relocationInfo)) {
            return !_.isNil(this.relocationInfo.OrderProcessedDate);
        }
    }

    onCancelCore() {
        this.onFormUpdate(false);
        this.splitForm = false;
    }

    onFormUpdate(value) {
        this.splitForm = value;
        this.updateNode({ val: this.splitForm });
    }

    datePickerFocus() {
        this.datePickerStatus.opened = true;
    }

    registerEventOnStreetAddress() {
        this.segment.registerEvent('Move Portal - Change Movers Kit Street Address',
            {
                Address: this.moversKitAddress.Address1
            });
    }

    registerEventOnDeliveryDate() {
        this.segment.registerEvent('Move Portal - Change Movers Kit Date',
            {
                Date: this.moversKitDate
            });
    }
}

export default {
    template: template,
    bindings: {
        companyHolidays: '<',
        customerInfo: '<',
        movingStatus: '<',
        moversKitAddress: '=',
        moversKitDate: '=',
        splitForm: '<',
        states: '<',
        updateNode: '&',
        relocationInfo: '<',
        movingAddress: '<'
    },
    controller: MovingDeliveryController
};