import _ from 'lodash';
import template from './medicalInfoDelete.html';

class MedicalInfoDeleteModalController {
    /*@ngInject*/
    constructor() {
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
    }

    close() {
        this.$close(false);
    }

    save() {
        this.$close(true);
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: MedicalInfoDeleteModalController
};