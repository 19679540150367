import angular from 'angular';

import constantsModule from '../constants';

import creditLogoDirective from './creditLogo.directive';
import creditCardService from './creditCard.service';
import creditCardListComponent from './creditCardList.component';

export default angular.module('fp:customerportal:common:creditcard',
    [
        constantsModule.name
    ])
    .directive('fpCreditCardLogo', creditLogoDirective)
    .service('creditCardService', creditCardService)
    .component('fpCreditCardListInfo', creditCardListComponent);