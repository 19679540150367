import moment from 'moment';
import _ from 'lodash';
import luhn from 'fast-luhn';

import template from './addPaymentModal.html';
import './addPaymentModal.scss';

class AddPaymentModalController {
    /* @ngInject */
    constructor($timeout, $uibModal, dataContext, constants, creditCardService, alertService, $scope, $log) {
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.$timeout = $timeout;
        this.constants = constants;
        this.validCardType = true;
        this.$scope = $scope;
        this.$log = $log;

        this.dataContext = dataContext;
        this.creditCardService = creditCardService;
        this.$uibModal = $uibModal;
        this.customerBillingInfo = this.config.data.customerBillingInfo;
        this.alertService = alertService;

        this.showStep1 = true;
        this.selectedCBI = this.data.selectedCBI;
        this.isACH = true;
        this.selectedCBI.IsCredit = !this.isACH;
        this.creditCardYears = this.data.creditCardYears;
        this.states = this.data.states;
        this.isAddressValidated = false;
        this.billingAddress = this.data.billingAddress.Address1 + ', ' + this.data.billingAddress.City + ', ' + this.data.billingAddress.State + ', ' + this.data.billingAddress.PostalCode;
        this.hasOverdueBalance = this.data.hasOverdueBalance;
        this.accountPastDueBalance = this.data.accountPastDueBalance;
        this.hasAcceptedOverdueBalance = false;

        this.ccExpMonths = [
            { value: 1, text: '01', disabled: false },
            { value: 2, text: '02', disabled: false },
            { value: 3, text: '03', disabled: false },
            { value: 4, text: '04', disabled: false },
            { value: 5, text: '05', disabled: false },
            { value: 6, text: '06', disabled: false },
            { value: 7, text: '07', disabled: false },
            { value: 8, text: '08', disabled: false },
            { value: 9, text: '09', disabled: false },
            { value: 10, text: '10', disabled: false },
            { value: 11, text: '11', disabled: false },
            { value: 12, text: '12', disabled: false }
        ];

        this.creditCards = [
            { CreditCardType: 'visa', curClass: 'fpDisabled' },
            { CreditCardType: 'amex', curClass: 'fpDisabled' },
            { CreditCardType: 'mastercard', curClass: 'fpDisabled' },
            { CreditCardType: 'discover', curClass: 'fpDisabled' }
        ];
        this.ALERT_DIALOG = 'AddPaymentModalAlert';
    }

    cancelStep(step) {
        switch (step) {
            case 'stepOne':
            case 'stepThree':
                this.showStep1 = this.showStep3 = false;
                break;
            case 'stepBack':
                this.showStep1 = true;
                this.showStep3 = false;
                break;
        }
    }

    close() {
        this.$dismiss();
    }

    hasDefaultPaymentMethod() {
        let hasDefaultPayment = _.some(this.customerBillingInfo, (cbi) => {
            return (cbi.IsDefaultPayment === true);
        });

        return hasDefaultPayment;
    }

    saveCBI() {
        this.isLoading = true;
        if (this.selectedCBI.IsCredit && !this.isAddressValidated) {
            let creditCardNumber = this.selectedCBI.CardCcNumb.toString();
            let luhnValid = luhn(creditCardNumber);
            if (luhnValid) {
                return this.validateAddress();
            } else {
                let newcbi = this.configureAddNewCBI();
                newcbi.successful = false;
                newcbi.message = 'Your credit card number is not valid.';
                this.$close(newcbi);
            }
        } else if (!this.isRoutingValidated) {
            return this.validateRouting();
        }
        else {
            return this.processSave();
        }
    }

    submitStep(step, cbiForm, addressForm) {
        if (!cbiForm.$valid || (addressForm && (!cbiForm.$valid || !addressForm.$valid))) {
            if (!_.isNil(cbiForm) && !_.isNil(cbiForm.$error) && !_.isNil(cbiForm.$error.required))
                _.each(cbiForm.$error.required, (er) => {
                    er.$dirty = true;
                });

            if (!_.isNil(addressForm) && !_.isNil(addressForm.$error) && !_.isNil(addressForm.$error.required))
                _.each(addressForm.$error.required, (er) => {
                    er.$dirty = true;
                });
            return;
        }

        switch (step) {
            case 'stepOne':
                if (!this.selectedCBI.IsCredit) {
                    this.saveCBI();
                } else {
                    this.showStep1 = false;
                    this.showStep3 = true;
                }
                break;
            case 'stepThree':
                this.saveCBI();
                this.showStep1 = false;
                this.showStep3 = true;
                break;
        }

        this.scrollTop();
    }

    toggleCBI(isACH) {
        this.isACH = isACH;
        this.selectedCBI.IsCredit = !isACH;
        this.cbiForm = isACH ? 'bankAccountForm' : 'creditCardForm';
    }

    setBillingAddress() {
        if (this.isCheckedBillingAddress) {
            this.selectedCBI.Line1 = this.data.billingAddress.Address1;
            this.selectedCBI.Line2 = this.data.billingAddress.Address2;
            this.selectedCBI.City = this.data.billingAddress.City;
            this.selectedCBI.State = this.data.billingAddress.State;
            this.selectedCBI.PostalCode = this.data.billingAddress.PostalCode;
        }
    }

    processSave() {
        this.isLoading = true;
        let newcbi = this.configureAddNewCBI();
        if (this.doesDuplicateExists(newcbi)) {
            newcbi.successful = false;
            newcbi.message = 'Duplicate form of payment already exists.';
            this.$close(newcbi);
        } else {
            return this.dataContext.customerBillingInfo.saveCustomerBillingInfo(newcbi)
                .then((result) => {
                    newcbi.successful = result.Success;

                    if (this.selectedCBI.IsCredit && this.hasOverdueBalance && this.hasAcceptedOverdueBalance) {
                        newcbi.OverdueBalancePaymentAttempted = true;

                        let paymentInfoPastDueBalance = {
                            CustomerBillingInfoID: result.Data[0].CustomerBillingInfoID,
                            UserCustomerID: result.Data[0].UserCustomerID,
                            Amount: this.accountPastDueBalance,
                            PaymentType: 1
                        };

                        this.dataContext.payment.makePayment(paymentInfoPastDueBalance).then((result) => {
                                newcbi.OverdueBalancePaymentSuccessful = true;
                            }).then(() => {
                                this.$close(newcbi);
                            })
                            .catch(error => {
                                newcbi.OverdueBalancePaymentSuccessful = false;
                                this.isLoading = false;
                                newcbi.successful = false;
                                this.$close(newcbi);
                            });
                    } else {
                        this.$close(newcbi);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    doesDuplicateExists(newcbi) {
        let cbiACHLastFour = 0;
        let ACHLastFour = 0;
        return _.some(this.customerBillingInfo, (cbi) => {
            let exists = false;
            if (cbi.IsCredit && newcbi.IsCredit) {
                let cbiLastFour = cbi.CardCcNumb.substring(_.size(cbi.CardCcNumb) - 4, _.size(cbi.CardCcNumb));
                let inputLastFour = _.toString(newcbi.AccountNumber).substring(_.size(_.toString(newcbi.AccountNumber)) - 4, _.size(_.toString(newcbi.AccountNumber)));
                if (newcbi.CCExp === cbi.CCExp && inputLastFour === cbiLastFour) {
                    exists = true;
                }
            }
            if (!cbi.IsCredit && !newcbi.IsCredit) {
                if (_.size(cbi.LastFour) < 4) {
                    cbiACHLastFour = _.padStart(cbiACHLastFour, 4, '0');
                } else {
                    cbiACHLastFour = cbi.AccountNumber.substring(_.size(cbi.AccountNumber) - 4, _.size(cbi.AccountNumber));
                }
                if (_.size(newcbi.AccountNumber) < 4) {
                    ACHLastFour = _.padStart(newcbi.AccountNumber, 4, '0');
                } else {
                    ACHLastFour = newcbi.AccountNumber.substring(_.size(_.toString(newcbi.AccountNumber)) - 4, _.size(_.toString(newcbi.AccountNumber)));
                }
                let cbiRoutingLastFour = _.toString(cbi.RoutingNumber).substring(_.size(_.toString(cbi.RoutingNumber)) - 4, _.size(_.toString(cbi.RoutingNumber)));
                let ACHRoutingLastFour = _.toString(newcbi.RoutingNumber).substring(_.size(_.toString(newcbi.RoutingNumber)) - 4, _.size(_.toString(newcbi.RoutingNumber)));
                if (ACHLastFour === cbiACHLastFour && cbiRoutingLastFour === ACHRoutingLastFour) {
                    exists = true;
                }
            }
            return exists;
        });
    }

    validateRouting() {
        this.dataContext.routing.validateRouting(this.selectedCBI.RoutingNumber)
            .then((response) => {
                this.isRoutingValidated = true;
                this.saveCBI();
            })
            .catch((error) => {
                this._showAlert(`We could not validate the routing number ${this.selectedCBI.RoutingNumber}.`);
                this.isLoading = false;
            })
            .finally(() => {
                this.showStep1 = true;
                this.showStep3 = false;
            });
    }

    validateAddress() {
        let address = {
            Address1: this.selectedCBI.Line1,
            Address2: this.selectedCBI.Line2,
            City: this.selectedCBI.City,
            PostalCode: this.selectedCBI.PostalCode,
            State: this.selectedCBI.State
        };

        return this.dataContext.address.validateAddress(address)
            .then((response) => {
                if (!this.dataContext.address.isValidResponse(response)) {
                    let returnedAddress = {
                        Address1: address.Address1,
                        Address2: address.Address2,
                        City: response.City,
                        PostalCode: response.PostalCode,
                        State: response.StateAbrev
                    };

                    let modalInstance = this.$uibModal.open({
                        template: '<fp-address-validation-modal config="config"></fp-address-validation-modal>',
                        controller: ($scope) => {
                            'ngInject';
                            $scope.config = {
                                data: address,
                                close: modalInstance.close,
                                dismiss: modalInstance.dismiss,
                                returnedAddress: returnedAddress
                            };
                        }
                    });

                    modalInstance.result.then((result) => {
                        this.isAddressValidated = true;
                        if (this.selectedCBI.State !== result.State || this.selectedCBI.City != result.City) {
                            this.selectedCBI.State = result.State;
                            this.selectedCBI.City = result.City;
                        }
                        return this.processSave();
                    });
                } else {
                    this.isAddressValidated = true;
                    return this.processSave();
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    configureAddNewCBI() {
        return {
            CustomerBillingInfoID: 0,
            UserCustomerID: null,
            ContactID: 0,
            AccountNumber: this.selectedCBI.IsCredit ? this.selectedCBI.CardCcNumb : this.selectedCBI.AccountNumber,
            RoutingNumber: this.selectedCBI.IsCredit ? null : this.selectedCBI.RoutingNumber,
            VerificationNumber: null,
            IsCredit: this.selectedCBI.IsCredit,
            DateCreated: null,
            CreatedByID: 0,
            IsActive: true,
            IsDeleted: false,
            PaymentDay: null,
            CCExp: this.selectedCBI.IsCredit ? this.selectedCBI.CcExpMonth.text + '/' + _.toString(this.selectedCBI.CcExpYear).substring(2, _.toString(this.selectedCBI.CcExpYear).length) : null,
            CreditCardType: this.getSelectedCardType(),
            CheckType: this.selectedCBI.IsCredit ? null : 'P',
            CheckAccountType: this.selectedCBI.IsCredit ? null : 'C',
            LeadID: null,
            LastFour: this.selectedCBI.IsCredit ? _.toString(this.selectedCBI.CardCcNumb).substring(_.toString(this.selectedCBI.CardCcNumb).length - 4, _.toString(this.selectedCBI.CardCcNumb).length)
                : _.toString(this.selectedCBI.AccountNumber).substring(_.toString(this.selectedCBI.AccountNumber).length - 4, _.toString(this.selectedCBI.AccountNumber).length),
            AccountId: 0,
            NickName: '',
            AddressID: 0,
            IsDefaultPayment: this.hasDefaultPaymentMethod() ? false : true,
            IsDefaultRmr: false,
            Line1: this.selectedCBI.Line1,
            Line2: this.selectedCBI.Line2,
            City: this.selectedCBI.City,
            State: this.selectedCBI.State,
            PostalCode: this.selectedCBI.PostalCode,
            Attention: null,
            FirstName: this.selectedCBI.FirstName,
            LastName: this.selectedCBI.LastName,
            OverdueBalancePaymentAttempted: false,
            OverdueBalancePaymentSuccessful: null

        };
    }

    disableCCExpMonths() {
        _.each(this.ccExpMonths, (month) => {
            if (moment().year() === this.selectedCBI.CcExpYear) {
                month.disabled = month.value <= moment().month();
            }
            else {
                month.disabled = false;
            }
        });
    }

    isMatchingCardType(cardType) {
        return this.getSelectedCardType() === cardType;
    }

    getSelectedCardType() {
        return !_.isNil(this.selectedCBI) && this.creditCardService.getCardType(this.selectedCBI.CardCcNumb);
    }

    getCreditCardYears() {
        let years = [];
        let currentDateYear = 2000 + ((new Date()).getYear() - 100);
        for (let x = 0; x <= 10; x++) {
            years.push(currentDateYear + x);
        }
        return years;
    }

    _showAlert(dialogMessage) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(`${dialogMessage}`)
            .setType('danger')
            .setTimeout(5000)
            .open();
    }

    scrollTop() {
        this.$scope.$emit(this.constants.events.modalOpened);
    }
}

export default {
    template: template,
    bindings: {
        config: '<',
        customerInfo: '<'
    },
    controller: AddPaymentModalController
};