import _ from 'lodash';
import './dispatchTimeHelperModal.scss';
import template from './dispatchTimeHelperModal.html';

class DispatchTimeHelperModalController {
    /* @ngInject */
    constructor($q, dataContext, $scope, constants) {
        this.data = this.config.data;
        this.dataContext = dataContext;
        this.$q = $q;
        this.$scope = $scope;
        this.constants = constants;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;

    }

    close() {
        this.$close();
    }

}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: DispatchTimeHelperModalController
};