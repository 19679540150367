import template from './confirmAddOnModal.html';

class ConfirmAddOnModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, constants, $log, alertService) {
        this.$modal = $uibModal;
        this.$log = $log;
        this.dataContext = dataContext;
        this.constants = constants;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.alertService = alertService;
    }

    close() {
        this.$dismiss();
    }

    $onInit() {
        this.removeAddOns = this.data.oldQuantity - this.data.newQuantity;
        if (this.removeAddOns > 0) {
            this.isDecreasing = true;
        }
        else {
            this.isDecreasing = false;
            this.newAddOnsCount = this.data.newQuantity - this.data.oldQuantity;
            this.rmr = (this.data.safetyButtonAddOn[0].Price * this.newAddOnsCount).toFixed(2);
        }
    }

    confirm() {
        this.$close();
        const addOnInfo = {
            OldQuantity: this.data.oldQuantity,
            NewQuantity: this.data.newQuantity,
            addOn: 'Noonlight' // safety button addOn name
        };
        this.dataContext.account.updateAccountAddOns(addOnInfo).then((result) => {
            if (result && result.success) {
                const modalInstance = this.$modal.open({
                    template: '<fp-success-add-on-modal config="config"></fp-success-add-on-modal>',
                    controller: ($scope) => {
                        'ngInject';
                        $scope.config = {
                            data: {
                                confirm: true
                            },
                            close: modalInstance.close,
                            dismiss: modalInstance.dismiss
                        };
                    },
                    size: 'lg'
                });
            }
            if (result && !result.success && result.message) {
                const modalInstance = this.$modal.open({
                    template: '<fp-error-add-on-modal config="config"></fp-error-add-on-modal>',
                    controller: ($scope) => {
                        'ngInject';
                        $scope.config = {
                            data: {
                                safetyButtonAddOn: this.data.safetyButtonAddOn,
                                oldQuantity: this.data.oldQuantity,
                                newQuantity: this.data.newQuantity,
                                isFeatureRemovedWithInSixMonths: false
                            },
                            close: modalInstance.close,
                            dismiss: modalInstance.dismiss
                        };
                    },
                    size: 'lg'
                });
            }
        });
    }

    next() {
        this.$close();
        const modalInstance = this.$modal.open({
            template: '<fp-enrollment-add-on-modal config="config"></fp-enrollment-add-on-modal>',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        safetyButtonAddOn: this.data.safetyButtonAddOn,
                        oldQuantity: this.data.oldQuantity,
                        newQuantity: this.data.newQuantity
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            },
            size: 'lg'
        });
    }
    
}

export default {
    template: template,
    bindings: {
        config: '<',
        safetyButtonAddOn: '<',
        oldQuantity: '<',
        newQuantity: '<'
    },
    controller: ConfirmAddOnModalController
};