import _ from 'lodash';

class SingleSignOnController {
    /*@ngInject*/
    constructor($state, $q, $log, dataContext, authService, constants, $cookies) {
        this.$state = $state;
        this.$q = $q;
        this.$log = $log;
        this.dataContext = dataContext;
        this.authService = authService;
        this.ssoRedirectMap = constants.ssoRedirectMap;
        this.constants = constants;
        this.$cookies = $cookies;
    }

    $onInit() {
        this.loginWithToken(this.token, this.redirectTo, this.queryParams);
    }

    loginWithToken(token, redirectTo, queryParams) {
        return this.dataContext.user.loginWithToken(token, { app: this.app })
            .then(() => {
                return this.dataContext.user.getUserInfo()
                    .then((userInfo) => {
                        let isState = redirectTo && (redirectTo.indexOf('http') < 0 || redirectTo.includes('state'));

                        if (!_.isNil(isState) && isState === true) {

                            const redirectToState = this.getRedirectState(redirectTo);

                            this.$log.info(
                                `Successful SSO as ${userInfo.UserName}, redirecting to ${redirectToState.state}...`);

                            const today = new Date();
                            const expiresValue = new Date(today);
                            //Set 'expires' option in 2 month
                            expiresValue.setMinutes(today.getMinutes() + this.constants.cache.expiry);
                            this.$cookies.put(
                                'IsFPCustomer',
                                true,
                                {
                                    expires: expiresValue,
                                    domain: 'frontpointsecurity.com'
                                });

                            this.authService.loginConfirmed();
                            return this._checkShopOffers(redirectToState, queryParams).then((newState) => {

                                if (newState.state === 'support.contact') {
                                    this.$log.info('Navigating to contact us...');
                                    window.location.href = this.constants.urls.contact;
                                } else if (newState.state === 'support.support') {
                                    this.$log.info('Navigating to support...');
                                    window.location.href = this.constants.urls.support;
                                }

                                return this.$state.go(newState.state, newState.stateParams, { location: 'replace' });
                            });
                        }

                        const sessionRedirect = this.dataContext.session.getSessionRedirect();
                        if (!redirectTo && sessionRedirect) {
                            this.$log.info(`Successful SSO as ${userInfo.UserName}, redirecting to ${sessionRedirect.state}...`);
                            this.dataContext.session.clearSessionRedirect();
                            return this.$state.go(sessionRedirect.state, sessionRedirect.stateParams, { location: 'replace' });
                        }

                        // if custom was passed in the redirect will be the full URL
                        this.$log.info(`Navigating to custom url...${redirectTo}`);
                        window.location.href = redirectTo;
                    });
            })
            .catch((error) => {
                return this.handleError(error);
            });
    }

    handleError(error) {
        if (error.message !== 'transition prevented') {
            this.$log.error('Error in Single Sign On', error);
            return this.$state.go('login.home', null, { location: 'replace' });
        } else {
            return this.$q.resolve(true);
        }
    }

    getRedirectState(redirectTo) {

        // remove origin if specified
        if (redirectTo.includes('http')) {
            redirectTo = new URL(redirectTo).pathname;
        }

        // get destination and custom params from redirect url
        let [destination, params] = redirectTo && redirectTo.split('?');

        // if route params used, convert to url param format
        if (destination.includes('state') && !params) {
            params = destination.slice(1).split("/").reduce((route, val, i) => i % 2 == 0 ? route + "&" + val : route + "=" + val);
        }

        // convert url params to dictionary
        const stateParams = params && params.split('&').reduce((stateParams, val) => {
            const [key, value] = val.split('=');
            return { ...stateParams, [key]: value };
        }, {});

        // override state if explicitly set in redirect params
        const stateIsParam = stateParams && Object.keys(stateParams).includes('state');
        if (stateIsParam) {
            destination = stateParams.state;
            delete stateParams.state;
        }

        destination = _.isString(destination) ? destination.toLowerCase() : '';

        // lookup matching state in constants
        let match = _.has(this.ssoRedirectMap, destination)
            ? this.ssoRedirectMap[destination]
            : this.ssoRedirectMap['']; // default

        let result = {};
        if (_.isString(match)) {
            result.state = match;
            result.stateParams = stateParams;
        } else {
            result = _.cloneDeep(match);
        }

        return result;
    }

    _checkShopOffers(redirectToState, queryParams) {
        let redirectPromise = {
            state: redirectToState.state,
            stateParams: {
                ...redirectToState.stateParams,
                ...queryParams
            }
        };

        let offerId = _.get(queryParams, 'offer_id', null);
        let offerHardwareType = _.get(queryParams, 'offer_hardware_type', null);
        if (redirectToState.state.toUpperCase() === 'SHOP.HOME' && !_.isNil(this.queryParams)) {
            if (!_.isNil(offerId) && !_.isNil(offerHardwareType)) {
                const offerHardwareTypeRedirect = _.find(this.constants.offerHardwareTypeRedirectStates, { adcOfferHardwareType: parseInt(offerHardwareType) });
                if (!_.isNil(offerHardwareTypeRedirect)) {
                    redirectPromise.state = offerHardwareTypeRedirect.redirectState;
                } else {
                    redirectPromise = this.dataContext.product.getProductFromAdcSku(offerHardwareType).then(product => {
                        return {
                            state: 'shop.offer',
                            stateParams: {
                                ...redirectPromise.stateParams,
                                offer_id: offerId,
                                offer_hardware_type: offerHardwareType,
                                offerId: offerId,
                                productId: _.replace(product.SKUManufacturer, '^', '__')
                            }
                        };
                    });
                }
            }
        }
        return this.$q.resolve(redirectPromise);
    }
}

export default {
    bindings: {
        token: '<',
        redirectTo: '<',
        app: '<',
        queryParams: '<'
    },
    controller: SingleSignOnController
};
