import template from './pendingPayments.html';

class PendingPaymentsController {
    /*@ngInject*/
    constructor(constants) {
        this.constants = constants;
    }

    hasUnsettledPayments() {
        return this.payments && this.payments.length > 0;
    }
}

export default {
    template: template,
    bindings: {
        payments: '<'
    },
    controller: PendingPaymentsController
};