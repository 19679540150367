class ActivationService {
    /*@ngInject*/
    constructor(dataContext) {
        this.dataContext = dataContext;
    }

    isPendingActivation() {
        return this.dataContext.account.getAccountSummary()
            .then((result) => {
                return result.Status === 'Pending Activation';
            });
    }
}

export default ActivationService; 