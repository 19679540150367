export class QuestionnaireAnswerTemplate {

    constructor(retentionAnswerOptionId: number, name: string, reason: string,
        parentRetentionAnswerOptionId: number, iconName: string, isActive: boolean,
        retentionOfferTypeId: number) {
        this.RetentionAnswerOptionId = retentionAnswerOptionId;
        this.Name = name;
        this.Reason = reason;
        this.ParentRetentionAnswerOptionId = parentRetentionAnswerOptionId;
        this.IconName = iconName;
        this.IsActive = isActive;
    }

    RetentionAnswerOptionId: number;
    Name: string | null;
    Reason: string | null;
    ParentRetentionAnswerOptionId: number | null;
    IconName: string | null;
    IsActive: boolean;
}