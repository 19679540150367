import _ from 'lodash';
import template from './forgotPassword.html';

class ForgotPasswordController {
    /*@ngInject*/
    constructor($log, $state, userService, $scope, $interval) {
        this.$log = $log;
        this.$state = $state;
        this.userService = userService;
        this.captchaToken = null;
        this.username = null;
        this.widgetId = null;
        this.isBusy = false;
        this.$interval = $interval;
        this.$scope = $scope;
        this.refreshTokenAtInterval = null;
    }

    $onInit() {
        this.initializeCaptcha();
        this.refreshCaptchaToken();
    }

    initializeCaptcha() {
        var executeRecaptchaScript = document.createElement("script");
        executeRecaptchaScript.type = "text/javascript";
        executeRecaptchaScript.textContent =
            'var onloadCallback = function() {grecaptcha.ready(function () {grecaptcha.execute("6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM", { action: "portalLogin" }).then(function (token) { var recaptchaResponse = document.getElementById("recaptchaResponse"); angular.element(recaptchaResponse).controller("ngModel").$setViewValue(token); recaptchaResponse.value = token; angular.element(recaptchaResponse).controller("ngModel").$render();});});};';
        angular.element(document.querySelector('head')).append(executeRecaptchaScript);

        var recaptchaApiScript = document.createElement("script");
        recaptchaApiScript.type = "text/javascript";
        recaptchaApiScript.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM";
        angular.element(document.querySelector('head')).append(recaptchaApiScript);
        _.defer(() => this.$scope.$apply());
    }

    refreshCaptchaToken() {
        this.refreshTokenAtInterval = this.$interval(() => {
                this.initializeCaptcha();
            },
            90 * 1000);
    }

    formFieldsPopulated() {
        return (!_.isNil(this.username) && !_.isNil(this.password));
    }

    $onDestroy() {
        if (this.refreshTokenAtInterval) {
            // when the user leaves, cancel the $interval instance
            this.$interval.cancel(this.refreshTokenAtInterval);
        }
    }

    isValid() {
        return !_.isEmpty(this.username);
    }

    widgetCreated(id) {
        this.widgetId = id;
    }

    submit() {
        this.isBusy = true;
        return this.userService
                .forgotPassword(this.username, this.captchaToken)
                .then((result) => {
                    return this.$state.go('login.home',

                            {
                                message: result
                            });
                    },
                    (error) => {
                        this.initializeCaptcha();
                        this.$log.error('Failed to reset password', error);
                        let errorMessage = null;

                        if (!_.isString(error)) {
                            errorMessage = 'Failed to submit password request, please try again';

                            if (!_.isNil(error.data) && error.data !== '') {
                                errorMessage = error.data;
                                if (!_.isEmpty(error.data.Message)) {
                                    errorMessage = error.data.Message;
                                }
                            }
                        } else {
                            errorMessage = error;
                        }

                        this.error = errorMessage;
                    })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    }

export default {
    template: template,
    bindings: {
        redirectTo: '@',
        message: '@'
    },
    controller: ForgotPasswordController
};