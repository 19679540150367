import './commonModal.scss';
import * as _ from 'lodash';

export class AddressValidationModalController {
    public modalInstance: any;
    public resolve: any;
    private address: any;
    private returnedAddress: any;
    private showNewAddress: boolean;
    private hasAddressLine2: boolean;

    /* @ngInject */
    constructor(private $uibModal: any,
        private dataContext: any,
        private $q: any,
        private $state: ng.ui.IStateService) {
        'ngInject';
        this.showNewAddress = false;
        this.hasAddressLine2 = false;
        this.address = this.resolve.address;
        this.returnedAddress = this.resolve.returnedAddress;

        if ((!_.isNil(this.returnedAddress) &&
            !_.isNil(this.returnedAddress.State) &&
            !_.isNil(this.returnedAddress.City) &&
            !_.isNil(this.returnedAddress.PostalCode) &&
            this.returnedAddress.City !== ' ' &&
            this.returnedAddress.State !== ' ' &&
            this.returnedAddress.PostalCode !== ' ')
            && (this.returnedAddress.State !== this.address.State || this.returnedAddress.City !== this.address.City || this.returnedAddress.PostalCode !== this.address.PostalCode)) {
            this.showNewAddress = true;
        }

        if (!_.isNil(this.returnedAddress.Address2) && this.returnedAddress.Address2 !== ' ') {
            this.hasAddressLine2 = true;
        }
    }

    public close() {
        this.modalInstance.dismiss();
    }

    public confirm() {
        if (this.showNewAddress) {
            this.address.City = this.returnedAddress.City;
            this.address.State = this.returnedAddress.State;
            this.address.PostalCode = this.returnedAddress.PostalCode;
        }
        this.modalInstance.close(this.address);
    }
}

export const addressValidationModal = {
    selector: 'addressValidationModal',
    template: require('./addressValidationModal.html'),
    bindings: {
        modalInstance: '<',
        resolve: '<'
    },
    controller: AddressValidationModalController
};