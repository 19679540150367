import _ from 'lodash';
import moment from 'moment';
import './testmode.scss';
import template from './testmode.html';
import AbstractSectionController from '../AbstractSectionController';

class MonitoringStatusController extends AbstractSectionController {
    /*@ngInject*/
    constructor($log, $q, $scope, alertService, constants, dataContext) {
        super();
        this.$log = $log;
        this.$q = $q;
        this.$scope = $scope;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;
        this.ALERT_DIALOG = 'emergency:testmode';
        this.MAX_DATE = moment().add(180, 'd').startOf('h').toDate();
        this.MIN_DATE = moment().startOf('h').toDate();

        this.dateOptions = {
            minDate: this.MIN_DATE,
            maxDate: this.MAX_DATE,
            showWeeks: false
        };
    }

    $onInit() {
        // uib-timepicker does not support ngChange attribute so use a watch to capture whenever the model 
        // (this.testmode.EndDateModel) has changed and update the moment object (this.testmode.EndDate).
        this.unsubscribeTestModeDateTime = this.$scope.$watch(() => this.testmode.EndDateModel, this.onDateChanged.bind(this));
    }

    $onChanges(changesObj) {
        if (_.get(changesObj, 'testmode.currentValue', false)) {
            const testmode = changesObj.testmode.currentValue;

            // Convert a moment date to a normal date that can easily be bound and updated using ng-model.
            testmode.EndDateModel = moment.isMoment(testmode.EndDate)
                ? testmode.EndDate.toDate()
                : testmode.EndDate;
        }
    }

    $onDestroy() {
        this.unsubscribeTestModeDateTime();
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.testmode);
    }

    onSaveCore() {
        this.isLoading = true;

        const testModeClone = _.cloneDeep(this.testmode);

        testModeClone.EndDate = new moment(Date.UTC(
            testModeClone.EndDate.year(),
            testModeClone.EndDate.month(),
            testModeClone.EndDate.date(),
            testModeClone.EndDate.hour(),
            testModeClone.EndDate.minute()));

        return this.dataContext.testmode.put(testModeClone)
            .then(() => {
                this._showGenericSuccessAlert();
            })
            .catch((error) => {
                this.$log.error('Error updating testmode.', error);
                this._showGenericErrorAlert();
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
                this.pristine = null;
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.testmode = this.pristine;
    }

    onDateChanged() {
        // Convert the normal date object created in $onChanges() back to a moment object.
        this.testmode.EndDate = moment(this.testmode.EndDateModel);
    }

    onTestModeEnabled() {
        this.testmode.EndDateModel = moment().add(7, 'd').startOf('h').toDate();
    }

    getTimeZoneDescription() {
        let currentTime = this.testmode.TimeZoneDescription;
        if (!_.isNil(this.testmode.EndDateModel)) {
            if (moment(this.testmode.EndDateModel).isDST() === true ) {
                currentTime = _.replace((this.testmode.TimeZoneDescription), 'Standard', 'Daylight');
                return currentTime;
            }
            return currentTime;
        }
        return currentTime || 'Premise Time'; 
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your monitoring status been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your monitoring status. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
template: template,
    bindings: {
        isLoading: '<',
        testmode: '<',
        rapidConnectionStatus: '<'
    },
    controller: MonitoringStatusController
};