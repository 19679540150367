import _ from 'lodash';

import template from './header.html';

class HeaderController {
    /*@ngInject*/ 
    constructor($rootScope, $scope, $state, constants, dataContext, fpStore, headerService, mobileAppContext) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.dataContext = dataContext;
        this.headerService = headerService;
        this.fpStore = fpStore;
        this.constants = constants;
        this.customerSupport = constants.customerSupport;
        this.mobileAppContext = mobileAppContext;
        
        this.isAuthorizedAdc = false;
        $scope.$on(constants.events.data.monitoringInfoReceived, (e, data) => {
            this.isAuthorizedAdc = !_.isNil(data) &&
                                   !_.isNil(data.MonitoringAdcInformation) &&
                                   this.dataContext.user.isAuthorizedAdc(data.MonitoringAdcInformation.WbServicePlanName);
        });
        
        $scope.$on(constants.events.stateChangeSuccess, (event, toState) => {
            this.subnav = [];
            if (toState.data) {
                this.navItems = this._getNavItems(toState.name) || [];
            }
        });
    }

    $onInit() {
        this.navItems = this._getNavItems();
    }

    isAuthenticated() {
        return this.dataContext.user.isAuthenticated();
    }

    logout() {
        this.dataContext.user.logout();
    }

    displaySideNavbar(show) {
        this.$rootScope.$broadcast(this.constants.events.showMenu, show);
    }

    getAnchorOffset() {
        return this.mobileAppContext.get() ? '55px' : '116px';
    }

    getMainTitle() {
        return _.isNil(this.$state.$current.data) || _.isNil(this.$state.$current.data.navigationMenu) 
            ? ''
            : this.$state.$current.data.navigationMenu;
    }
    
    _getNavItems() {
        return this.headerService.getEnabledSubnavItems(this.$state.current.name);
    }
}

export default {
    template: template,
    controller: HeaderController,
    transclude: true,
    bindings: {
        inputOverlay: '<'
    }
};
