import _ from 'lodash';
import angular from 'angular';

const videoAddons = ['ProVideo', 'ProVideoPlus', 'BasicDoorbell', 'DoorbellCameras', 'Video24x7PerSVR'];
const automationAddons = ['LightAutomation', 'ThermostatControl', 'ProVideo', 'ProVideoPlus', 'TwoFiftyMBExtraVideoStorage',
    'DigitalInputVideos', 'ZWaveLights', 'ZWaveEnergy', 'ImageSensorAlarms', 'ImageSensorPlus', 'ImageSensorExtraUploads',
    'ZWaveLocks', 'ZWaveThermostats', 'SmartEnergyPlus', 'LutronRemoteAccess', 'GarageDoors', 'AdvancedEnergy', 'AdvancedAutomation',
    'LutronIntegration', 'LiftMasterIntegration', 'SchneiderIntegration', 'Video24x7PerSVR', 'WaterManagement', 'NESTIntegration', 'EnterpriseEnergy',
    'CosaIntegration', 'DoorbellCameras', 'BasicDoorbell'];

export default class {
    /*@ngInject*/
    constructor($window, dataContext, constants, monitoringPlansService) {
        this.$window = $window;
        this.dataContext = dataContext;
        this.user = dataContext.user;
        this.constants = constants;
        this.monitoringPlansService = monitoringPlansService;
    }

    getAll() {
        let servicePlanInfo = this.user.current.ServicePlanInfo || {};
        let isWsfUser = this.monitoringPlansService.isWSFPlan(servicePlanInfo);
        let isCameraPlanUser = this.monitoringPlansService.isSAVPlan(servicePlanInfo);

        let navHasDispatchBetaConfig = this.constants.navHasDispatchBetaConfig;
        let userHaDispatchBetaAccess = this.dataContext.user.hasSetting('CustomerPortal:UserHasDispatchBetaAccess', 'true');
        let coverageProvidesDispatchAccess = this.dataContext.user.hasApplicationSetting('CoverageProvidesDispatchAccess', 'true');

        let shouldDisplayDispatchMenuItems = ((navHasDispatchBetaConfig && userHaDispatchBetaAccess) || (servicePlanInfo.HasDispatchServiceCoverage && coverageProvidesDispatchAccess));


        return [
            { name: 'Home', icon: 'icn-home.svg', iconActive: 'icn-gn-home.svg', sref: 'adc.home', enabled: !isWsfUser },
            {
                name: 'Video', icon: 'icn-video.svg', iconActive: 'icn-gn-video.svg', sref: 'adc.video', enabled: () => {
                    let intersect = _.intersection(videoAddons, servicePlanInfo.Addons);
                    return intersect.length > 0 && !isWsfUser;
                }
            },
            { name: 'Automation', icon: 'icn-automation.svg', iconActive: 'icn-gn-automation.svg', sref: 'adc.rules', enabled: this.isAutomationEnabled(servicePlanInfo) },
            { name: 'Notifications', icon: 'icn-notifications.svg', iconActive: 'icn-gn-notifications.svg', sref: 'adc.notifications', enabled: !isWsfUser },
            { name: 'Activity', icon: 'icn-activity.svg', iconActive: 'icn-gn-activity.svg', sref: 'adc.activity', enabled: !isWsfUser },
            { name: 'Users', icon: 'icn-users.svg', iconActive: 'icn-gn-users.svg', sref: 'adc.users', enabled: !isWsfUser },
            {
                name: 'My Account',
                icon: 'icn-my-account.svg',
                iconActive: 'icn-gn-my-account.svg',
                sref: 'account.settings',
                section: this.constants.navigationMenuSections.majorSections.account,
                sub: [{
                    name: 'Account Settings',
                    sref: 'account.settings'
                }, {
                    name: 'Emergency Settings',
                    sref: 'account.emergency'
                }, {
                    name: 'On-Demand Services',
                    sref: 'account.dispatchServices',
                    enabled: shouldDisplayDispatchMenuItems
                },{
                    name: 'Documents',
                    sref: 'account.documents'
                }, {
                    name: 'Preferences',
                    sref: 'account.preferences'
                }, {
                    name: 'Track Package',
                    href: '/trackOrder?email=',
                    target: "_blank"
                }]
            },
            {
                name: 'Sensors',
                icon: 'Sensors_Page_black.svg',
                iconActive: 'Sensors_Page_green.svg',
                sref: 'sensors.status',
                section: this.constants.navigationMenuSections.majorSections.sensors,
                enabled: false // As per Hadi & Matt we are not planning on rolling out sensor renaming
            },
            {
                name: 'Equipment Setup',
                icon: 'icn-add-equipment.svg',
                iconActive: 'icn-gn-add-equipment.svg',
                sref: 'setup',
                section: this.constants.navigationMenuSections.majorSections.setupEquipment,
                enabled: !isCameraPlanUser
            },
            {
                name: 'Move Portal',
                icon: 'icn-move-portal.svg',
                iconActive: 'icn-gn-move-portal.svg',
                sref: 'moving',
                section: this.constants.navigationMenuSections.majorSections.movePortal,
                enabled: !isCameraPlanUser
            },
            {
                name: 'Shop',
                icon: 'icn-shop.svg',
                iconActive: 'icn-gn-shop.svg',
                sref: 'shop.home',
                enabled: true
            },
            {
                name: 'Support',
                icon: 'icn-support.svg',
                iconActive: 'icn-gn-support.svg',
                href: 'https://www.myfrontpoint.com/web/system/support',
                target: '_blank',
                rel: 'noopener noreferrer',
                section: this.constants.navigationMenuSections.majorSections.support
            },
            {
                name: 'Settings',
                icon: 'icn-settings.svg',
                iconActive: 'icn-gn-settings.svg',
                sref: 'adc.settings',
                enabled: !isWsfUser
            }
        ];
    }

    getEnabled() {
        return _.filter(this.getAll(), (i) => {
            if (i.sub) {
                i.sub = _.filter(i.sub,
                    (s) => {
                        return _.isNil(s.enabled) || (_.isFunction(s.enabled) && s.enabled() === true) || (s.enabled === true);
                    });
            }
            return _.isNil(i.enabled) || (_.isFunction(i.enabled) && i.enabled() === true) || (i.enabled === true);
        });
    }

    isSetupEquipmentEnabled() {
        const betaSettings = _.get(this.constants, 'beta.addEquipment', {});
        return (this.user.hasSetting(betaSettings.settingKey, 'true') &&
            (_.isEmpty(betaSettings.platforms) ||
                _.some(betaSettings.platforms, (p) => angular.element(this.$window.document.querySelector('html')).hasClass(p))));
    }

    isAutomationEnabled(servicePlanInfo) {
        let videoIntersect = _.intersection(videoAddons, servicePlanInfo.Addons);
        let automationIntersect = _.intersection(automationAddons, servicePlanInfo.Addons);
        if (videoIntersect.length > 0 && _.isNil(servicePlanInfo.AdcServicePlan))
            return true;
        if (servicePlanInfo.PanelType === this.constants.panels.iq)
            return true;
        if (servicePlanInfo.PanelType === this.constants.panels.xt) {
            if (automationIntersect.length > 0)
                return true;
        }
        return false;
    }

}