import _ from 'lodash';

class MonitoringPlansService {
    /*@ngInject*/
    constructor(Restangular, constants) {
        this.constants = constants;
        this.Restangular = Restangular;
        this.wsfPlan = _.get(this.constants, 'adcPlans.WSF', {});
        this.videoOnlyPlan = _.get(this.constants, 'adcPlans.VideoOnly', {});
    }

    getRequiredPlan(productSkus) {
        return this.Restangular
            .one('MonitoringPlan')
            .one('RequiredMonitoringPlan')
            .get({ productSkus: productSkus })
            .then((response) => {
                return response;
            });
    }

    getItemsRequiringUpgrade() {
        return this.Restangular
            .one('MonitoringPlan')
            .one('UpgradeRules')
            .get()
            .then((response) => {
                return response;
            });
    }

    isSAVPlan(ServicePlanInfo) {
        return (ServicePlanInfo || {}).AdcServicePlan === this.videoOnlyPlan;
    }

    isWSFPlan(ServicePlanInfo) {
        return (ServicePlanInfo || {}).AdcServicePlan === this.wsfPlan;
    }
}

export default MonitoringPlansService;