import _ from 'lodash';
import AbstractSectionController from '../AbstractSectionController.js';

const ANIMATION_DELAY = 400;

class AbstractDispatchController extends AbstractSectionController {
    constructor($scope, $timeout, constants) {
        super();

        this.$scope = $scope;
        this.$timeout = $timeout;
        this.constants = constants;
    }

    onEditCore() {
        // Not Implemented
    }

    onSaveCore() {
        throw 'AbstractDispatchController.onSaveCore() must be overridden.';
    }

    onCancelCore() {
        // Not Implemented
    }

    /**
     * Gets the maxinum number of contacts that this controller supports.
     * 
     * @returns {number} 
     */
    getMaxNumContacts() {
        throw 'AbstractDispatchController.getMaxNumContacts() must be overridden.';
    }

    /**
     * Gets the minimum number of contacts that this controller supports.
     * 
     * @returns {number} 
     */
    getMinNumContacts() {
        throw 'AbstractDispatchController.getMinNumContacts() must be overridden.';
    }

    /**
     * Gets a mutable array of contacts.
     * 
     * @returns {Array} 
     */
    getContacts() {
        throw 'AbstractDispatchController.getContacts() must be overridden.';
    }

    /**
     * Gets an immutable array of active contacts.
     * 
     * @returns {Array} 
     */
    getActiveContacts() {
        return _.filter(this.contacts, c => c.IsActive) || [];
    }

    /**
     * Returns the number of contacts.
     * 
     * @returns {number} 
     */
    getContactsCount() {
        return this.getContacts().length;
    }

    /**
     * Returns the number of active contacts.
     * 
     * @returns {number} 
     */
    getActiveContactsCount() {
        return this.getActiveContacts().length;
    }

    /**
     * 
     * @returns {} 
     */
    isPredispatch() {
        throw 'AbstractDispatchController.isPredispatch() must be overridden.';
    }

    /**
     * Invoked when a contact should be added.
     */
    onAddContact() {
        if (!this.canAddContact()) {
            return;
        }

        this.startEditing();

        const contacts = this.getContacts();
        contacts[contacts.length] = this.createNewContact();
    }

    /**
     * Invoked when a contact should be removed.
     * 
     * @param {Object} contact
     */
    onRemoveContact(contact) {
        if (!this.canRemoveContact()) {
            return;
        }
        
        this.startEditing();

        contact.IsActive = false;

        const contacts = this.getContacts();
        _.remove(contacts, c => c === contact);

        // A null, undefined, or 0 AlarmContactID indicates the contact has not be persisted and should permently be
        // removed from the collection.
        if (!_.isNil(contact.AlarmContactID) || contact.AlarmContactID === 0) {
            contacts[contacts.length] = contact;
        }

        this.updatePriorities();
    }

    /**
     * Inovked when a contact should be promoted.
     * 
     * @param {Object} contact
     */
    onPromoteContact(contact) {
        if (!this.canPromoteContact(contact)) {
            return;
        }

        const contacts = this.getContacts();
        const index = _.indexOf(contacts, contact);

        this._animatePromote(contact);

        if (index - 1 >= 0) {
            this._animateDemote(contacts[index - 1]);
        }

        this.startEditing();

        AbstractDispatchController.swap(contacts, index, index - 1);
        this.updatePriorities();
    }

    /**
     * Invoked when a contact should be demoeted.
     * 
     * @param {Object} contact
     */
    onDemoteContact(contact) {
        if (!this.canDemoteContact(contact)) {
            return;
        }

        const contacts = this.getContacts();
        const index = _.indexOf(contacts, contact);

        this._animateDemote(contact);

        if (index + 1 < contacts.length) {
            this._animatePromote(contacts[index + 1]);
        }

        this.startEditing();

        AbstractDispatchController.swap(contacts, index, index + 1);
        this.updatePriorities();
    }

    /**
     * Returns true if the given contact can be promoted.
     * 
     * @param {Object} contact 
     * @returns {boolean} 
     */
    canPromoteContact(contact) {
        return _.head(this.getActiveContacts()) !== contact;
    }

    /**
     * Returns true if the given contact can be demoted.
     * 
     * @param {Object} contact 
     * @returns {boolean} 
     */
    canDemoteContact(contact) {
        return _.last(this.getActiveContacts()) !== contact;
    }

    /**
     * Returns true if a contact can be added.
     * 
     * @returns {boolean} 
     */
    canAddContact() {
        return !this.hasMaxContacts();
    }

    /**
     * Returns true if a contact can be removed.
     * 
     * @returns {boolean} 
     */
    canRemoveContact() {
        return !this.hasMinContacts();
    }

    /**
     * Creates a new empty contact.
     * 
     * @returns {Object} 
     */
    createNewContact() {
        return {
            DateCreated: new Date().getUTCDate(),
            FirstName: null,
            LastName: null,
            Phone: null,
            AltPhone: null,
            IsPreDispatch: this.isPredispatch(),
            Priority: _.max(_.map(this.getContacts(), c => c.Priority)) + 1,
            IsActive: true,
            // Used by contact.component.js to determine if a contact is new and should be displayed in edit mode.
            // This value is deleted after the first time it is displayed.
            _isNew: true 
        };
    }

    /**
     * Returns true if the customer currently has the maximum number of contacts.
     * 
     * @returns {boolean} 
     */
    hasMaxContacts() {
        return this.getActiveContactsCount() >= this.getMaxNumContacts();
    }

    /**
     * Returns true if the customer currently has the minimum number of contacts.
     * 
     * @returns {boolean} 
     */
    hasMinContacts() {
        return this.getActiveContactsCount() <= this.getMinNumContacts();
    }

    /**
     * Mutate the contacts array removing all non number characters from all contact phone numbers;
     */
    stripPhoneNumbers() {
        _.forEach(this.getContacts(), c => {
            c.Phone = c.Phone.replace(/[^0-9]/g, '');
        });
    }

    /**
     * 
     * @returns {} 
     */
    updatePriorities() {
        const contacts = this.getContacts();
        for (let i = 0; i < contacts.length; i++) {
            contacts[i].Priority = i + 1;
        }
    }

    notifyContactsUpdated() {
        this.$scope.$broadcast(this.constants.events.data.emergencyContactsUpdated, this.getContacts());
    }

    _animatePromote(contact) {
        this.notifyContactsUpdated();
        return this.$timeout(() => {
            contact.promoting = true;
        }, 0)
        .finally(() => {
            return this.$timeout(() => {
                delete contact.promoting;
            }, ANIMATION_DELAY);
        });
    }

    _animateDemote(contact) {
        this.notifyContactsUpdated();
        return this.$timeout(() => {
            contact.demoting = true;
        }, 0)
        .finally(() => {
            return this.$timeout(() => {
                delete contact.demoting;
            }, ANIMATION_DELAY);
        });
    }

    /**
     * 
     * @param {} array 
     * @param {} x 
     * @param {} y 
     * @returns {} 
     */
    static swap(array, x, y) {
        const temp = array[x];
        array[x] = array[y];
        array[y] = temp;
    }
}

export default AbstractDispatchController;