import './offer.component.scss';
import { RetentionRequest } from '../types/RetentionRequest';
import { RetentionOfferType } from "../types/RetentionOfferType";
import * as _ from 'lodash';
import { ContractPdfModel } from "../types/ContractPdfModel";

export class RateLockOfferController {
    public retentionOfferTypeId = 0;
    public retentionQuestionnaireAnswerID = 0;
    public retentionAnswerOptionID = 0;
    public offer: RetentionOfferType;
    public currentDate: Date;
    public isSubmitting: boolean = false;
    public contractText: ContractPdfModel;
    public isLoading: boolean = true;
    public isComplete: boolean;
    public isDocusignComplete: boolean = false;
    public isDocusignFailed: boolean = false;
    public docusignUrl: any;
    public offerInfo: any;
    public isFailed: boolean;
    public questionInfo: any;
    public tier0Offer: any;


    constructor($stateParams: any, private readonly constants: any, private readonly dataContext: any, private readonly $window: ng.IWindowService, private $scope: any, private readonly $state: any, private readonly $log: any, private $uibModal: any) {
        'ngInject';
        this.retentionOfferTypeId = $stateParams.retentionOfferTypeId;
        this.retentionQuestionnaireAnswerID = $stateParams.retentionQuestionnaireAnswerID;
        this.retentionAnswerOptionID = $stateParams.retentionAnswerOptionID;
        this.offerInfo = $stateParams.offerInfo;
        this.tier0Offer = $stateParams.tier0Offer;
    }

    async $onInit() {
        this.isComplete = false;
        this.currentDate = new Date();

        //this should only fire on page reload, UI navigation will populate the values view the router, this is our stopgap
        if (this.offerInfo == null) {
            this.offerInfo = JSON.parse(localStorage.getItem('offer'));
        }

        if (this.tier0Offer == null) {
            this.tier0Offer = JSON.parse(localStorage.getItem('tier0OfferToDisplay'));
        }
        const retentionAttempt: RetentionRequest = {
            retentionOfferTypeId: this.retentionOfferTypeId,
            questionnaireAnswerId: this.retentionQuestionnaireAnswerID,
            retentionEpisodeTypeId: this.constants.retentionEpisodeType.Save,
            closedDate: new Date()
        };

        this.dataContext.retention.submitRetentionAttempt({
            retentionEpisodeTypeId: this.constants.retentionEpisodeType.Save,
            retentionOfferTypeId: this.retentionOfferTypeId
        }).then(() => {
            this.dataContext.retention.UpdateRetentionQuestionnaire(retentionAttempt).then(() => {
                this.dataContext.retention.UpdateRetentionEpisode(retentionAttempt).then(() => {
                    this.isComplete = true;
                    this.isLoading = false;
                }).catch((error: any[]) => {
                    this.$log.error('Error updating retention session.', error);
                });
            }).catch((error: any[]) => {
                this.$log.error('Error updating retention questionnaire.', error);
            });
        }).catch((error: any[]) => {
            this.$log.error('Error submitting retention attempt.', error);
        });
    }

    navigateToHome() {
        this.$state.go('home');
    }
}

export const rateLockOfferComponent = {
    template: require('./rateLockOffer.component.html'),
    controller: RateLockOfferController
};