import './featureNotAvailable.scss';
import Template from './featureNotAvailable.html';

class DesktopComponent {
    /*@ngInject*/
    constructor() {
    }
}

export default {
    template: Template,
    controller: DesktopComponent,
    bindings: {}
};