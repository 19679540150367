import AccountDataService from "./account.service";
import ApplicationStateDataService from "./applicationState.service";
import ContractRenewalService from "./contractRenewal.service";
import RetentionDataService from "./retention.service";
import MarketingService from "./marketing.service";
import VoziqService from "./voziq.service";
import PetsDataService from "./pets.service";
import CancelService from "./cancel.service";
import SessionDataService from "./session.service";
import MedicalInformationService from "./medicalInfo.service";

class DataContext {
    account: AccountDataService;
    address: any;
    cancel: CancelService;
    customerBillingInfo: any;
    emergency: any;
    payment: any;
    states: any;
    user: any;
    routing: any;
    document: any;
    settings: any;
    panel: any;
    applicationState: ApplicationStateDataService;
    dispatch: any;
    mastercode: any;
    testmode: any;
    passcode: any;
    permit: any;
    product: any;
    supportService: any;
    monitoringPlans: any;
    relocation: any;
    configurations: any;
    retention: RetentionDataService;
    contractRenewal: ContractRenewalService;
    sms: any;
    marketingService: MarketingService;
    voziqService: VoziqService;
    pets: PetsDataService;
    medicalInformation: MedicalInformationService;
    session: SessionDataService;
    isLoading: boolean;

    /*@ngInject*/
    constructor(
        accountDataService: AccountDataService,
        addressDataService: any,
        cancelService: CancelService,
        customerBillingInfoDataService: any,
        emergencyDataService: any,
        paymentDataService: any,
        statesDataService: any,
        userService: any,
        routingDataService: any,
        documentDataService: any,
        settingsDataService: any,
        applicationStateService: ApplicationStateDataService,
        panelDataService: any,
        dispatchService: any,
        mastercodeService: any,
        testmodeService: any,
        passcodeService: any,
        permitService: any,
        supportService: any,
        productService: any,
        monitoringPlansService: any,
        relocationService: any,
        configurationsService: any,
        retentionService: any,
        smsService: any,
        contractRenewalService: ContractRenewalService,
        marketingService: MarketingService,
        voziqService: VoziqService,
        petsDataService: PetsDataService,
        medicalInformationService: MedicalInformationService,
        sessionDataService: SessionDataService
    ) {
        'ngInject';
        this.account = accountDataService;
        this.address = addressDataService;
        this.cancel = cancelService;
        this.customerBillingInfo = customerBillingInfoDataService;
        this.emergency = emergencyDataService;
        this.payment = paymentDataService;
        this.states = statesDataService;
        this.user = userService;
        this.routing = routingDataService;
        this.document = documentDataService;
        this.settings = settingsDataService;
        this.panel = panelDataService;
        this.applicationState = applicationStateService;
        this.dispatch = dispatchService;
        this.mastercode = mastercodeService;
        this.testmode = testmodeService;
        this.passcode = passcodeService;
        this.permit = permitService;
        this.product = productService;
        this.supportService = supportService;
        this.monitoringPlans = monitoringPlansService;
        this.relocation = relocationService;
        this.configurations = configurationsService;
        this.retention = retentionService;
        this.sms = smsService;
        this.contractRenewal = contractRenewalService;
        this.marketingService = marketingService;
        this.voziqService = voziqService;
        this.pets = petsDataService;
        this.medicalInformation = medicalInformationService;
        this.session = sessionDataService;
        this.isLoading = false;
    }
}

export default DataContext;
