import template from './termsAddOnModal.html';


class TermsAddOnModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, constants, $log, alertService) {
        this.$modal = $uibModal;
        this.$log = $log;
        this.dataContext = dataContext;
        this.constants = constants;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.alertService = alertService;
    }

    close() {
        this.$dismiss();
    }

    $onInit() {
        this.rmr = this.data.safetyButtonAddOn[0].Price;
    }

    enroll() {
        this.close();
        const addOnInfo = {
            oldQuantity: this.data.oldQuantity,
            newQuantity: this.data.newQuantity,
            addOn: 'Noonlight' // addOn name to add safety button
        };
        this.dataContext.account.addAccountAddOns(addOnInfo).then((result) => {
            if (result) {
                const modalInstance = this.$modal.open({
                    template: '<fp-success-add-on-modal config="config"></fp-success-add-on-modal>',
                    controller: ($scope) => {
                        'ngInject';
                        $scope.config = {
                            data: {
                                
                            },
                            close: modalInstance.close,
                            dismiss: modalInstance.dismiss
                        };
                    },
                    size: 'lg'
                });
            }
        });
        
    }
    
}

export default {
    template: template,
    bindings: {
        config: '<',
        safetyButtonAddOn: '<',
        oldQuantity: '<',
        newQuantity: '<'
    },
    controller: TermsAddOnModalController
};