import _ from 'lodash';

class CustomerBillingInfoDataService {
    /*@ngInject*/
    constructor(Restangular, constants,  applicationStateService, $q) {
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
    }

    getCustomerBillingInfo() {
        return this.Restangular
            .all('CustomerBillingInfo')
            .customGET('')
            .then((result) => {
                this.CustomerBillingInfo = _.cloneDeep(result.Data);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, this.constants.actionTypes.error, error);
                return this.$q.reject(error);
            });
    }

    getACHOfferAsync(accountId, newPaymentMethodIsACH) {
        return this.Restangular
            .all('CustomerBillingInfo')
            .one('ACHOffer')
            .customGET('', {
                'accountId': accountId,
                'newPaymentMethodIsACH': newPaymentMethodIsACH
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, this.constants.actionTypes.error, error);
                return this.$q.reject(error);
            });
    }

    updateCustomerBillingInfo(info) {
        return this.Restangular
            .all('CustomerBillingInfo')
            .withHttpConfig({
                // CP-128 payments sometimes take a really long time to update cbi, don't want user thinking it failed prematurely
                timeout: 120000 // wait up to 2 minutes
            })
            .customPUT(info)
            .then((result) => {
                _.forEach(this.CustomerBillingInfo, (cbi) => {
                    if (cbi.CustomerBillingInfoID === result.Data[0].CustomerBillingInfoID) {
                        //need to clean object
                        delete cbi.$$hashKey;
                        delete cbi.AccountNumber;
                        delete cbi.CcExpMonth;
                        delete cbi.CcExpYear;
                        delete cbi.CardCcNumb;
                        delete cbi.FullName;
                        delete cbi.displayAccountNumberValidate;
                        delete cbi.displayCardCcNumb;
                        delete cbi.displayAccountNumber;
                        if (!_.isNil(cbi.CreditCardType))
                            cbi.CreditCardType = _.startCase(cbi.CreditCardType);

                        cbi.PostalCode = _.trim(cbi.PostalCode);

                        this.applicationStateService.dataChange(this.constants.sectionTypes.payments, this.constants.dataTypes.customerBillingInfo, cbi, result.Data[0], null, cbi.CustomerBillingInfoID);
                    }
                });
                return result;
            })
             .catch((error) => {
                 this.applicationStateService.error(this.constants.sectionTypes.payments, error);
                 return this.$q.reject(error);
             });
}

    saveCustomerBillingInfo(info) {
        return this.Restangular
            .all('CustomerBillingInfo')
            .post(info)
            .then((result) => {
                if (result.Success)
                    this.applicationStateService.change(this.constants.sectionTypes.payments, this.constants.actionTypes.dataChange, 'CustomerBillingInfoID: [' + result.Data[0].CustomerBillingInfoID + '] has been added.');
                else 
                    this.applicationStateService.error(this.constants.sectionTypes.payments, result.ErrorMessage);
                
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error);
                return this.$q.reject(error);
            });
    }

    deleteCustomerBillingInfo(cbiID) {
        return this.Restangular
            .one('CustomerBillingInfo', cbiID)
            .remove()
            .then((result) => {
                this.applicationStateService.change(this.constants.sectionTypes.payments, this.constants.actionTypes.dataChange, ' CustomerBillingInfoID: [' + cbiID + '] has been deleted.');
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.payments, error);
                return this.$q.reject(error);
            });
    }
}

export default CustomerBillingInfoDataService;