import * as _ from 'lodash';
import DataContext from '../common/data/dataContext.service';

export interface IAuthDirectiveScope extends ng.IScope {
    isAuthenticated: boolean;
}

export const authDirective = ($location: ng.ILocationService, $state: ng.ui.IStateService,
                              $timeout: ng.ITimeoutService, $log: ng.ILogService,
                              $rootScope: IAppRootScope, $window: ng.IWindowService, authService: any, dataContext: DataContext) => {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope: IAuthDirectiveScope) => {
            scope.isAuthenticated = false;

            scope.$on('event:auth-loginRequired', (event: ng.IAngularEvent, response: any) => {
                authService.loginCancelled('Login required', response); // Spins forever otherwise...
                if (!_.startsWith($state.current.name, 'login') && !$state.current.name.includes('trackOrder')) {
                    $log.debug('Login Required, redirecting to login page');

                    if ($state.current.name === 'cancel') {
                        // when we come back, we want to redirect to the cancel page
                        dataContext.session.setSessionRedirect('cancel', null);
                    }

                    const redirectTo = $location.url();
                    $rootScope.redirectTo = redirectTo;

                    $timeout(() => {
                        const url = encodeURIComponent(`${redirectTo}`);
                        const redirectToUrl = `${$window.location.origin}/login?redirectTo=${url}`;
                        $window.location.href = redirectToUrl;
                    });
                }
            });

            scope.$on('event:auth-loginConfirmed', () => {
                scope.isAuthenticated = true;
                $log.info('Login successful');
            });
        }
    };
};
