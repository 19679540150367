import angular from 'angular';
import _ from 'lodash';

let bindHtmlCompileDirective = ($compile) => {
    'ngInject';
    return {
        restrict: 'A',
        link: (scope, element, attrs) => {
            scope.$watch(() => {
                return scope.$eval(attrs.bindHtmlCompile);
            }, (value) => {
                element.html(value && value.toString());
                // If scope is provided use it, otherwise use parent scope
                let compileScope = scope;
                if (!_.isNil(attrs.bindHtmlScope)) {
                    let temp = scope.$eval(attrs.bindHtmlScope);
                    compileScope = angular.extend(scope.$new(true), temp);
                }
                $compile(element.contents())(compileScope);
            });
        }
    };
};

export default bindHtmlCompileDirective;