import _ from 'lodash';

class Alert {
    constructor() {
        this._message = null;
        this._type = null;
        this._open = false;
        this._timeout = null;
        this._onCloseListener = _.noop;

        this.VALID_TYPES = ['success', 'info', 'warning', 'danger'];
    }

    /**
     * Sets the message displayed by the alert dialog.
     * 
     * @param {string} message 
     * @returns {Object} The alert dialog.
     */
    setMessage(message) {
        if (_.isNil(message)) {
            throw 'message cannot be null or undefined.';
        }

        this._message = message;
        return this;
    }

    /**
     * Sets the type of alert dialog (success, info, warning, danger).
     * 
     * @param {string} type 
     * @returns {Object} The alert dialog.
     */
    setType(type) {
        if (_.isNil(type)) {
            throw 'type cannot be null or undefined.';
        }

        if (!_.includes(this.VALID_TYPES, type)) {
            throw `Invalid type ${type}.`;
        }

        this._type = type;
        return this;
    }

    /**
     * Sets the timeout after which the dialog is dismissed.
     * 
     * @param {number} timeout 
     * @returns {Object} The alert dialog.
     */
    setTimeout(timeout) {
        if (_.isNil(timeout)) {
            throw 'timeout cannot be null or undefined.';
        }

        if (!_.isNumber(timeout)) {
            throw 'timeout must be a number.';
        }

        this._timeout = timeout;
        return this;
    }

    /**
     * Sets the callback that is invoked when the dialog is closed (by the user or timeout).
     * 
     * @param {function} listener 
     * @returns {Object} The alert dialog. 
     */
    setOnCloseListener(listener) {
        if (_.isNil(listener)) {
            throw 'listener cannot be null or undefined.';
        }

        if (!_.isFunction(listener)) {
            throw 'listener must be a function.';
        }

        this._onCloseListener = listener;
        return this;
    }

    /**
     * Open the dialog.
     */
    open() {
        this._open = true;
    }

    /**
     * Close the dialog.
     */
    close() {
        this._open = false;
        this._onCloseListener();
    }

    /**
     * Toggle the dialog open or closed depending on its current state.
     */
    toggle() {
        this._open = !this._open();
    }
}

export default Alert;