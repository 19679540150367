import moment from 'moment';

class TestModeService {
    /*@ngInject*/
    constructor(Restangular, applicationStateService, constants, $q) {
        this.Restangular = Restangular;
        this.applicationStateService = applicationStateService;
        this.constants = constants;
        this.$q = $q;
    }

    get(rapidStatus) {
        return this.Restangular
            .one('testmode')
            .get({rapidStatus: rapidStatus})
            .then((result) => {
                const plain = result.plain();
                plain.EndDate = moment(plain.EndDate);
                return plain;
            });
    }

    put(testmode) {
        const enddate = moment.isMoment(testmode.EndDate)
            ? testmode.EndDate.toDate()
            : testmode.EndDate;

        return this.Restangular
            .one('testmode')
            .customPUT({
                IsEnabled: testmode.IsEnabled,
                EndDate: enddate
            })
            .then((result) => {
                this.applicationStateService.change(this.constants.sectionTypes.emergencySettings,
                    this.constants.dataTypes.testmode,
                    testmode.IsEnabled ? 
                        `Test mode was set to ${enddate}.` :
                        'The account is now active.');
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }
}
export default TestModeService;