import * as _ from 'lodash';
import moment from 'moment';
import luhn from 'fast-luhn';
import template from './editPaymentModal.html';


class EditPaymentModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, constants, $log, alertService) {
        this.$modal = $uibModal;
        this.$log = $log;
        this.dataContext = dataContext;
        this.constants = constants;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.alertService = alertService;
    }

    close() {
        this.$dismiss();
    }

    $onInit() {
        this.ALERT_DIALOG = 'EditPaymentModalAlert';
        this.isAddressValidated = false;
        this.selectedCBI = this.data.selectedCBI || {};

        this.isACH = !this.selectedCBI.IsCredit;
        this.creditCardYears = this.data.creditCardYears;
        this.states = this.data.states;
        if (!_.isNil(this.data.billingAddress)) {
            this.billingAddress = this.data.billingAddress.Address1 + ', ' + this.data.billingAddress.City + ', ' + this.data.billingAddress.State + ', ' + this.data.billingAddress.PostalCode;
        }

        this.selectedCBI.displayAccountNumber =  _.clone(this.selectedCBI.AccountNumber);
        this.selectedCBI.displayAccountNumberValidate =    _.clone(this.selectedCBI.AccountNumber);
        this.selectedCBI.displayCardCcNumb =  _.clone( this.selectedCBI.CardCcNumb);
        this.selectedCBI.displayRoutingNumber =   _.clone(this.selectedCBI.RoutingNumber);
        this.isCheckedBillingAddress = false;

        this.showAccountNumber =  this.showAccountNumberValidate = this.showCardCcNumb = false;

        //remove this when you have new calendar widget
        this.ccExpMonths = [
            { value:1, text:'01', disabled:false},
            { value:2, text:'02', disabled:false},
            { value:3, text:'03', disabled:false},
            { value:4, text:'04', disabled:false},
            { value:5, text:'05', disabled:false},
            { value:6, text:'06', disabled:false},
            { value:7, text:'07', disabled:false},
            { value:8, text:'08', disabled:false},
            { value:9, text:'09', disabled:false},
            { value:10, text:'10', disabled:false},
            { value:11, text:'11', disabled:false},
            { value:12, text:'12', disabled:false}
        ];

        _.each(this.ccExpMonths, (month) => {
            if (month.text === this.selectedCBI.CcExpMonth) {
                this.selectedCBI.CcExpMonth = month;
            }

            if (moment().year() === this.selectedCBI.CcExpYear)  {
                month.disabled = month.value <= moment().month();
            }
            else {
                month.disabled = false;
            }
        });
    }

    disableCCExpMonths() {
        _.each(this.ccExpMonths, (month) => {
            if (moment().year() === this.selectedCBI.CcExpYear) {
                month.disabled = month.value <= moment().month();
            } else {
                month.disabled = false;
            }
        });
    }

    validateRouting() {
        this.dataContext.routing.validateRouting(this.selectedCBI.RoutingNumber).then((response) => {
                this.isRoutingValidated = true;
                this.saveCBI();
        })
        .catch((error) => {
            this._showAlert(`We could not validate the routing number ${this.selectedCBI.RoutingNumber}.`);
        })
        .finally(() => {
            this.isLoading = false;
        });
    }

    validateSave(step, cbiForm, addressForm) {
        if (!cbiForm.$valid || (addressForm && (!cbiForm.$valid || !addressForm.$valid))) {
            return;
        }
        this.saveCBI();
    }

    setBillingAddress() {
        if (this.isCheckedBillingAddress) {
            this.selectedCBI.Line1 = this.data.billingAddress.Address1;
            this.selectedCBI.Line2 = this.data.billingAddress.Address2;
            this.selectedCBI.City = this.data.billingAddress.City;
            this.selectedCBI.State = this.data.billingAddress.State;
            this.selectedCBI.PostalCode = this.data.billingAddress.PostalCode;
        }
    }

    validateAddress() {
        let address = {
            Address1 : this.selectedCBI.Line1,
            Address2 : this.selectedCBI.Line2,
            City: this.selectedCBI.City,
            PostalCode: this.selectedCBI.PostalCode,
            State: this.selectedCBI.State
        };

        this.dataContext.address.validateAddress(address).then((response) => {
            if (!this.dataContext.address.isValidResponse(response)) {
                let returnedAddress = {
                    Address1 : address.Address1,
                    Address2 : address.Address2,
                    City: response.City,
                    PostalCode: response.PostalCode,
                    State: response.StateAbrev
                };

                let modalInstance = this.$modal.open({
                    template: '<fp-address-validation-modal config="config"></fp-address-validation-modal>',
                    controller: ($scope) => {
                        'ngInject';
                        $scope.config = {
                            data: address,
                            close: modalInstance.close,
                            dismiss: modalInstance.dismiss,
                            returnedAddress: returnedAddress
                        };
                    }
                });

                modalInstance.result.then((result) => {
                    if (_.isNil(result)) {
                        this.isLoading = false;
                        this.isAddressValidated = false;
                    }
                    else {
                        this.isAddressValidated = true;
                        if (this.selectedCBI.State !== result.State || this.selectedCBI.City  != result.City) {
                            this.selectedCBI.State = result.State;
                            this.selectedCBI.City = result.City;
                        }

                        this.processSave();
                    }
                });
            } else {
                this.isAddressValidated = true;
                this.processSave();
            }
        });
    }

    saveCBI() {
        this.isLoading = true;
        if (this.selectedCBI.IsCredit && !this.isAddressValidated) {
            let creditCardNumber = this.selectedCBI.CardCcNumb.toString();
            // Not checking against the Luhn algorithm if CC is unchanged
            let luhnValid = creditCardNumber.startsWith('*') || luhn(creditCardNumber);
            if (luhnValid) {
                this.validateAddress();
                this.isRoutingValidated = true;
            } else {
                var result = {
                    Success: false,
                    Message: 'Your credit card number is not valid.'
                };
                this.$close(result);
            }
        } else if (!this.isRoutingValidated) {
            this.validateRouting();
        } else {
            this.processSave();
        }
    }

    processSave() {
        if (this.isAddressValidated) {
            this.selectedCBI.CCExp = this.selectedCBI.CcExpMonth.text + '/' + this.selectedCBI.CcExpYear.toString().substring(2, this.selectedCBI.CcExpYear.toString().length);
            this.selectedCBI.AccountNumber = this.selectedCBI.CardCcNumb;
            this.selectedCBI.LastFour = this.selectedCBI.CardCcNumb.toString().substring(this.selectedCBI.CardCcNumb.toString().length - 4, this.selectedCBI.CardCcNumb.toString().length);
        }
        else {
            this.selectedCBI.CheckAccountType = 'C';
            this.selectedCBI.CheckType = 'P';
        }
        this.dataContext.customerBillingInfo.updateCustomerBillingInfo(this.selectedCBI)
            .then((result) => {
                this.$close(result);
            }, (error) => {
                var result = { Success: false };
                this.$log.error('Failed to edit customerBillingInfo', error);
                this.$close(result);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    clear(element) {
        switch (element) {
            case 'accountNumber':
                this.selectedCBI.AccountNumber = '';
                this.showAccountNumber = true;
                break;
            case 'accountNumberValidate':
                this.selectedCBI.AccountNumberValidate = '';
                this.showAccountNumberValidate = true;
                break;
            case 'creditCardNumber':
                this.selectedCBI.CardCcNumb = '';
                this.showCardCcNumb = true;
                break;
            case 'routingNumber':
                this.selectedCBI.RoutingNumber = '';
                this.showRoutingNumber = true;
                break;
        }
    }

    _showAlert(dialogMessage) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(`${dialogMessage}`)
            .setType('danger')
            .setTimeout(5000)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        config: '<',
        customerInfo: '<'
    },
    controller: EditPaymentModalController
};