import template from './home.html';

import _ from 'lodash';

class HomeController {
    /*@ngInject*/
    constructor($scope, $filter, $q, dataContext, constants) {
        this.dataContext = dataContext;
        this.constants = constants;
        this.$filter = $filter;
        this.$q = $q;

        $scope.$on(constants.events.data.contactInfoUpdated, () => {
            this.refresh();
        });
    }

    $onInit() {
        this.refresh();
    }

    refresh() {
        this.isLoading = true;
        return this.$q.all([
            this.getCustomerInformationV2(),
            this.getCustomerPetInformation(),
            this.getStates()
        ])
            .finally(() => {
                this.isLoading = false;
                if (!_.isNil(this.customerInfo.Phone)) {
                    this.customerInfo.Phone = this.$filter('tel')(this.customerInfo.Phone);
                }
                if (!_.isNil(this.customerInfo.AltPhone)) {
                    this.customerInfo.AltPhone = this.$filter('tel')(this.customerInfo.AltPhone);
                }
            });
    }

    assemblePetsString(result) {

        let assembledPetsString = "";

        if (!result) {
            assembledPetsString = "No pets defined";
            return assembledPetsString;
        }

        if (result.Dogs != 0) {
            assembledPetsString += `${result.Dogs} dog`;

            if (result.Dogs > 1) {
                assembledPetsString += "s"
            }
        }

        if (result.Dogs != 0 && (result.Cats != 0 || result.OtherPets)) {
            assembledPetsString += ", "
        }

        if (result.Cats != 0) {
            assembledPetsString += `${result.Cats} cat`;

            if (result.Cats > 1) {
                assembledPetsString += "s";
            }
        }

        if (result.Cats != 0 && result.OtherPets) {
            assembledPetsString += ", "
        }

        if (result.OtherPets) {
            assembledPetsString += `${result.OtherPets}`;
        }

        return assembledPetsString;
    }

    getCustomerInfo() {
        return this.dataContext.account.getCustomerInfo()
            .then((result) => {
                this.customerInfo = result;
                this.sameAddress = this.dataContext.account.sameShippingAndBillingAndPremisesAddress(this.customerInfo.ShippingAddress, this.customerInfo.BillingAddress, this.customerInfo.PremisesAddress);
            });
    }

    getCustomerInformationV2() {
        return this.dataContext.account.getCustomerInformationV2()
            .then((result) => {
                this.customerInfo = result;
                this.sameAddress = this.dataContext.account.sameShippingAndBillingAndPremisesAddress(this.customerInfo.ShippingAddress, this.customerInfo.BillingAddress, this.customerInfo.PremisesAddress);
            });
    }

    getCustomerPetInformation() {
        return this.dataContext.pets.getCustomerPets()
            .then((result) => {
                this.customerPetsString = this.assemblePetsString(result);
                this.customerPets = result;
            });
    }

    getStates() {
        return this.dataContext.states.getStates().then((result) => {
            let states = [];
            _.forEach(result.Data, (stateInfo) => {
                states.push(stateInfo.State);
            });
            this.states = states;
        });
    }

}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};