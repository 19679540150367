import './commonModal.scss';
import '../cancellation.component.scss';
import { QuestionnaireAnswerRequest } from '../types/QuestionnaireAnswerRequest';
import { RetentionRequest, Address } from '../types/RetentionRequest';
import * as _ from 'lodash';
import { addressValidationModal } from '../../cancellation/modals/addressValidationModal.component'
import { ContractTypeEnum } from '../types/ContractTypeEnum';

export class OfferConfirmModalController {
    public modalInstance: any;
    public resolve: any;
    public shipingAddress: Address = new Address();
    private states: any = [];
    public isLoading: boolean;
    public showAddressOption: boolean = false;
    public isFormInvalid: boolean = false;
    public isSubmitting: boolean = false;
    public offerInfo: any;
    public tier0OfferToDisplay: any;
    public isRenewalOffer: any;
    public offerVerbiage: string;


    /* @ngInject */
    constructor(private $uibModal: any,
        private dataContext: any,
        private $q: any,
        private $scope: any,
        private $state: ng.ui.IStateService,
        private readonly constants: any) {
        'ngInject';

        this.$scope = $scope;
        this.offerInfo = this.resolve.offerInfo;

        this.isRenewalOffer = (this.resolve.ContractTypeID != ContractTypeEnum.MonthToMonth && this.resolve.ContractTypeID != ContractTypeEnum.Variable);
    }

    public $onInit() {
        if (this.resolve.cancellationReasonMoving) {
            this.showAddressOption = true;
        }

        this.$scope.$watch('shippingForm.$invalid', (isInvalid: boolean) => {
            if (!isInvalid) {
                this.isFormInvalid = false;
            } else {
                this.isFormInvalid = true;
            }
        });

        this.dataContext.states.getStates().then((result: any) => {
            this.states = _.concat(this.states, result.Data);
            return this.states;
        });

        this.dataContext.account.getCustomerInfo()
            .then((result: any) => {
                this.shipingAddress.Line1 = result.ShippingAddress.Address1;
                this.shipingAddress.Line2 = result.ShippingAddress.Address2;
                this.shipingAddress.City = result.ShippingAddress.City;
                this.shipingAddress.State = result.ShippingAddress.State;
                this.shipingAddress.PostalCode = result.ShippingAddress.PostalCode;
            });

        this.prepareOfferVerbiage(this.offerInfo);
    }

    public prepareOfferVerbiage(offer: any) {
        /*
         *  Things to consider for an offer:
         *      - is there a new rmr price
         *      - what is the contract type (length) associated with the offer
         *      - is there a new equipment coupon
         *      - only one offer will ever be processed through this fx at a time
         */

        if (offer.OfferTier === 0) {
            this.offerVerbiage = 'get your monthly billing rate-lock of $' + offer.NewRMR;

            localStorage.setItem('offerVerbiage', this.offerVerbiage);
            return;
        }

        if (offer.NewRMR != null && offer.ContractTypeID != null && offer.CouponAmount == null) {
            //this is our contract renewal at a specific price

            if (offer.ContractTypeID == ContractTypeEnum.OneYear || offer.ContractTypeID == ContractTypeEnum.ThreeYear) {
                this.offerVerbiage = 'get your monthly monitoring lowered to just $' +
                    offer.NewRMR +
                    ' with a ' +
                    offer.ContractTypeID +
                    '-year renewal';
            }

            else {
                this.offerVerbiage = 'get your monthly monitoring lowered to just $' + offer.NewRMR + 'with a renewal.';
            }

            localStorage.setItem('offerVerbiage', this.offerVerbiage);
            return;
        }

        if (offer.NewRMR != null && offer.ContractTypeID != null && offer.CouponAmount != null) {
            //this is our contract renewal at a specific price

            if (offer.ContractTypeID == ContractTypeEnum.OneYear || offer.ContractTypeID == ContractTypeEnum.ThreeYear) {
                this.offerVerbiage = 'get your monthly monitoring lowered to just $' +
                    offer.NewRMR +
                    ' and a $' +
                    offer.CouponAmount +
                    ' discount towards new equipment with a ' +
                    offer.ContractTypeID +
                    '-year renewal';
            }

            else {
                this.offerVerbiage = 'get your monthly monitoring lowered to just $' + offer.NewRMR + ' and a $' + offer.CouponAmount + 'discount towards new equipment with a with a renewal.';
            }

            localStorage.setItem('offerVerbiage', this.offerVerbiage);
            return;
        }

        if (offer.CouponAmount != null && offer.ContractTypeID != null && offer.RMRChange == null) {
            //this is our equipment coupon offer with a contract renewal

            if (offer.ContractTypeID == ContractTypeEnum.OneYear || offer.ContractTypeID == ContractTypeEnum.ThreeYear) {
                this.offerVerbiage = 'get a $' +
                    offer.CouponAmount +
                    ' discount towards new equipment with a ' +
                    offer.ContractTypeID +
                    '-year renewal';
            }

            else {
                this.offerVerbiage = 'get a $' + offer.CouponAmount + ' discount towards new equipment with a renewal.';
            }

            localStorage.setItem('offerVerbiage', this.offerVerbiage);
            return;
        }

        if (offer.CouponAmount != null && offer.ContractTypeID != null && offer.RMRChange != null) {
            //this is our rmr change offer, requires a coupon, a contract type, and rmrchange value
            let posval = offer.RMRChange * -1;

            this.offerVerbiage = 'get $' +
                posval +
                ' off your monthly monitoring and a $' +
                offer.CouponAmount +
                ' discount towards new equipment with a ' +
                offer.ContractTypeID +
                '-year renewal';

            localStorage.setItem('offerVerbiage', this.offerVerbiage);
            return;
        }

        if (offer.RMRChange != null && offer.CouponAmount != null) {
            //this is our flat-rate reduction and coupon bundle
            this.offerVerbiage = 'get your monthly monitoring lowered by $' +
                Math.abs(offer.RMRChange) +
                ' and a $' +
                offer.CouponAmount +
                ' discount towards new equipment';

            localStorage.setItem('offerVerbiage', this.offerVerbiage);
            return;
        }

    }

    public close() {
        this.modalInstance.close();
    }

    public async confirm() {
        this.isSubmitting = true;

        if (!this.showAddressOption) {
            await this.submit();
            this.modalInstance.close();

            return;
        }
        else {
            this.validateAddress(this.shipingAddress)
                .then(async (result: any) => {
                    if (!result) {
                        this.isSubmitting = false;
                        return false;
                    }

                    await this.submit();
                    this.modalInstance.close();
                    return true;
                });

            return;
        }
    }

    private async submit() {
        //tracking of questionnaire answers already occured, now just submitting offer selection
        this.isLoading = true;

        if (this.offerInfo.OfferTier != 0) {
            this.$state.go('cancellation.offer.renewal',
                {
                    retentionQuestionnaireAnswerID: 1,
                    retentionOfferTypeId: this.resolve.offerInfo.RetentionOfferTypeID,
                    retentionAnswerOptionID: 12,
                    offerInfo: this.offerInfo,
                    offerVerbiage: this.offerVerbiage,
                    term: this.offerInfo.ContractTypeID,
                    tier0Offer: this.tier0OfferToDisplay
                });

            this.isLoading = false;
        } else {
            this.$state.go('cancellation.offer.ratelock',
                {
                    retentionQuestionnaireAnswerID: 1,
                    retentionOfferTypeId: this.resolve.offerInfo.RetentionOfferTypeID,
                    retentionAnswerOptionID: 12,
                    offerInfo: this.offerInfo,
                    offerVerbiage: this.offerVerbiage,
                    term: this.offerInfo.ContractTypeID,
                    tier0Offer: this.tier0OfferToDisplay
                });

            this.isLoading = false;
        }

       
    }

    validateAddress(address: Address) {
        let formattedAddress = {
            Address1: address.Line1,
            Address2: address.Line2,
            City: address.City,
            PostalCode: address.PostalCode,
            State: address.State
        };

        return this.dataContext.address.validateAddress(formattedAddress)
            .then((response: any) => {
                if (!this.dataContext.address.isValidResponse(response)) {
                    let returnedAddress = {
                        Address1: address.Line1,
                        Address2: address.Line2,
                        City: response.City,
                        PostalCode: response.Zip,
                        State: response.StateAbrev
                    };

                    let modalInstance = this.$uibModal.open({
                        component: addressValidationModal.selector,
                        size: 'md',
                        resolve: {
                            address: () => { return address; },
                            returnedAddress: () => { return returnedAddress; }
                        }
                    });

                    return modalInstance.result.then((result: any) => {
                        if (_.isNil(result)) {
                            return false;
                        } else {
                            if (address.State !== result.State || address.City !== result.City) {
                                address.State = result.State;
                                address.City = result.City;
                            }
                            return true;
                        }
                    }, () => {
                        return false;
                    });
                } else {
                    return true;
                }
            });
    }

    async continueToCancel() {
        this.$state.go('cancellation.confirmation', {
            retentionAnswerOptionID: 1,
            //uirouter, state.go, how to pass object between views              
            tier0OfferToDisplay: this.tier0OfferToDisplay
        });
    }
}

export const questionnaireOfferConfirmModal = {
    selector: 'questionnaireOfferConfirmModal',
    template: require('./offerConfirmModal.html'),
    bindings: {
        modalInstance: '<',
        resolve: '<'
    },
    controller: OfferConfirmModalController
};