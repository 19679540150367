import angular from 'angular';
import 'angular-ui-router';

import commonModule from '../common';
import modalsModule from './modals';

import movingComponent from './moving.component';
import movingInfoComponent from './movingInfo.component';
import movingFormComponent from './movingForm.component';
import movingDeliveryComponent from './movingDelivery.component';
import movingConfirmationComponent from './movingConfirmation.component';
import movingBannerComponent from './movingBanner.component';
import linkNavComponent from './linkNav.component';
import movingProgressBarComponent from './movingProgressBar.component';
import movingNotificationComponent from './movingNotification.component';
import congratsComponent from './congrats.component';
import movingService from './moving.service';

export default angular
    .module('fp:customerportal:movePortal',
        [
            'ui.router',
            commonModule.name,
            modalsModule.name
        ])
    .config(/*@ngInject*/
        ($stateProvider, constants) => {
            $stateProvider
                .state('moving',
                    {
                        parent: 'secure',
                        url: '/movePortal',
                        template: '<fp-moving></fp-moving>',
                        data: {
                            navigationMenu: constants.navigationMenuSections.majorSections.movePortal
                        },
                        onEnter: function (dataContext, $state, monitoringPlansService, $timeout) {
                            let servicePlanInfo = dataContext.user.current.ServicePlanInfo || {};
                            let isCameraPlanUser = monitoringPlansService.isSAVPlan(servicePlanInfo);
                            if (isCameraPlanUser) {
                                $timeout(() => {
                                    $state.go('unavailable');
                                });
                            }
                        }
                    })
                .state('moving.info',
                    {
                        url: '/info',
                        template: '<fp-moving-info></fp-moving-info>'
                    })
                .state('moving.form',
                    {
                        url: '/form',
                        template: '<fp-moving-form></fp-moving-form>'
                    })
                .state('moving.confirmation',
                    {
                        url: '/confirmation',
                        template: '<fp-moving-confirmation></fp-moving-confirmation>',
                        data: {
                            navigationStep: 'confirmation'
                        }
                    });

        })
    .component('fpMoving', movingComponent)
    .component('fpMovingInfo', movingInfoComponent)
    .component('fpMovingForm', movingFormComponent)
    .component('fpMovingDelivery', movingDeliveryComponent)
    .component('fpMovingConfirmation', movingConfirmationComponent)
    .component('fpMovingBanner', movingBannerComponent)
    .component('fpLinkNav', linkNavComponent)
    .component('fpMovingProgressBar', movingProgressBarComponent)
    .component('fpMovingNotification', movingNotificationComponent)
    .component('fpCongrats', congratsComponent)

    .service('movingService', movingService);