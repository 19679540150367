let preventFocusDirective = ($timeout) => {
    'ngInject';

    return {
        compile: () => {
            return (scope, element) => {
                element.on('focus', () => {
                    $timeout(() => {
                        element[0].blur();
                    });
                });
            };
        }
    };
};

export default preventFocusDirective;