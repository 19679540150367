class StatesDataService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    getStates() {
        return this.Restangular
            .all('States')
            .customGET('');
    }
}

export default StatesDataService;