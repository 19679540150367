import _ from 'lodash';
import template from './home.html';

class HomeController {
    /*@ngInject*/
    constructor($log, $q, dataContext) {
        this.$log = $log;
        this.$q = $q;
        this.dataContext = dataContext;

        this.customer = {};
        this.notifications = {};
        this.preferences = {};
        this.isLoadingPreferences = true;
        this.isLoadingNotifications = true;
    }

    $onInit() {
        this.preferences.LoginName = this.dataContext.user.loadUserName();
        this.$q.all([
                this.dataContext.user.getEligibleSecurityQuestions(),
                this.dataContext.user.getSecurityQuestionForCurrentUser()
            ])
            .then((result) => {
                const question = _.find(result[0], {'Question': result[1]});

                this.preferences.questions = result[0];
                this.preferences.question = {};
                if (!_.isNil(question)) {
                    this.preferences.question.QuestionID = question.QuestionID;
                    this.preferences.question.Question = question.Question;
                }
            })
            .catch((error) => {
                this.$log.error('Error fetching preferences.', error);
            })
            .finally(() => {
                this.isLoadingPreferences = false;
            });

        this.$q.all([
                this.dataContext.account.getCustomerInfo(),
                this.dataContext.settings.getSettings()
            ])
            .then((result) => {
                this.customer = result[0].CustomerInformation;
                this.notifications = result[1];
            })
            .catch((error) => {
                this.$log.error('Error fetching customer and/or notifications.', error);
            })
            .finally(() => {
                this.isLoadingNotifications = false;
            });
    }
}

export default {
    template: template,
    bindings: {},
    controller: HomeController
};