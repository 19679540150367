import './commonModal.scss';
import '../cancellation.component.scss';
import * as _ from 'lodash';


export class OfferCommentsModalController {
    public modalInstance: any;


    /* @ngInject */
    constructor(private $uibModal: any,
        private dataContext: any,
        private $q: any,
        private $scope: any,
        private $state: ng.ui.IStateService,
        private readonly constants: any) {
        'ngInject';
    }

    public $onInit() {

    }

    public close() {
        this.modalInstance.close();
    }

}

export const offerCommentsModal = {
    selector: 'offerCommentsModal',
    template: require('./offerCommentsModal.html'),
    bindings: {
        modalInstance: '<',
        resolve: '<'
    },
    controller: OfferCommentsModalController
};