import * as _ from 'lodash';

import './creditCardExpireWarningModal.scss';
import template from './creditCardExpireWarningModal.html';

class CreditCardExpireWarningModalController {
    /*@ngInject*/
    constructor($location, settingsDataService, constants, dataContext, $state, moment) {
        this.settingsDataService = settingsDataService;
        this.dataContext = dataContext;
        this.$state = $state;
        this.constants = constants;
        this.moment = moment;
        this.$location = $location;
        this.$close = this.config.close;
    }

    doRedirect() {
        this.insertUserSetting();
        this.$state.go('home', this.$state.params);
    }

    addNewCBI() {
        this.insertUserSetting();
        this.$state.go('account.settings.billing');
    }

    insertUserSetting() {
        const settings = [{
            Name: this.constants.alerts.expiredCard.settingKey,
            Value: 'False'
        }];

        this.settingsDataService.insert(settings);
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: CreditCardExpireWarningModalController
};