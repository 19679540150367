import * as angular from 'angular';
import { IStateService, IStateParamsService } from 'angular-ui-router';
import DataContext from '../common/data/dataContext.service';

import './campaign.scss';

export class MarketingCampaignController {

    $scope: ng.IScope;
    $q: ng.IQService;
    $window: ng.IWindowService;
    $state: IStateService;
    $stateParams: IStateParamsService;
    $log: ng.ILogService;
    dataContext: DataContext;
    constants: any;
    
    isLoading: boolean;
    loadingText: string;
    name: string;

    constructor(
        $scope: ng.IScope,
        $q: ng.IQService,
        $window: ng.IWindowService,
        $state: IStateService,
        $stateParams: IStateParamsService,
        $log: ng.ILogService,
        dataContext: DataContext,
        constants: any
    ) {
        'ngInject';

        this.$scope = $scope;
        this.$q = $q;
        this.$window = $window;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.dataContext = dataContext;
        this.$log = $log;
        this.constants = constants;

        // always display loading spinner
        this.isLoading = true;
        this.loadingText = "Redirecting to Custom Offer...";
        this.name = $stateParams.name;
    }

    async $onInit() {
        try {

            // get destination url for campaign
            const campaign = await this.dataContext.marketingService.getCampaign(this.name);
            if (!campaign) throw "campaign does not exist";

            // create destination url
            const destination = new URL(campaign.Path, window.location.origin);

            // redirect to campaign url
            window.location.href = destination.href;
        } catch (error) {

            // default to shop home page for any error
            this.$log.info("An error happened while trying to redirect user to the specified campaign.", error);
            this.$state.go('shop.home');
        }
    }
}

export const marketingCampaignComponent = {
    template: require('./campaign.html'),
    controller: MarketingCampaignController
};
