import './jci.scss'

export class JciController {
    constants: any;
    supportPhone: any;

    constructor(
        constants: any
    ) {
        'ngInject';
        this.constants = constants;
    }

    public $onInit() {
        this.supportPhone = this.constants.customerSupport.jciFormattedPhone;
    }
}

export const jciComponent = {
    template: require('./jci.html'),
    controller: JciController
};