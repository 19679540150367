import _ from 'lodash';
import template from './addressValidationModal.html';

class AddressValidationModalController{
    /*@ngInject*/
    constructor (dataContext) {
        this.address = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.returnedAddress = this.config.returnedAddress;
        this.showNewAddress = false;
        if ((!_.isNil(this.returnedAddress) && !_.isNil(this.returnedAddress.State) && !_.isNil(this.returnedAddress.City) && !_.isNil(this.returnedAddress.PostalCode) && this.returnedAddress.City !== '' && this.returnedAddress.State !== '' && this.returnedAddress.PostalCode !== '')
            && (this.returnedAddress.State !== this.address.State || this.returnedAddress.City !== this.address.City || this.returnedAddress.PostalCode !== this.address.PostalCode)) {
            this.showNewAddress = true;
        }
    }

    close() {
        this.$close(null);
    }

    save() {
        if (this.showNewAddress === true) {
            this.address.City = this.returnedAddress.City;
            this.address.State = this.returnedAddress.State;
            this.address.PostalCode = this.returnedAddress.PostalCode;
        }
        this.$close(this.address);
    }
}

export default {
    template: template,
    bindings: {
        config: '<'
    },
    controller: AddressValidationModalController
};