import template from './smsDisclaimer.html';

class SmsDisclaimerController {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {},
    controller: SmsDisclaimerController
};