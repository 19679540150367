import * as angular from 'angular';
import * as _ from 'lodash';
import './login.scss';

export class LoginController {
    public anchor: string;
    public message: string;

    public username: string;
    public password: string;
    public rememberMe: boolean;
    public hasError: boolean;
    public isBusy: boolean;
    public redirectUrl: string;
    public widgetId: number;
    public captchaToken: any;
    public refreshTokenAtInterval: any;

    constructor(
        private readonly $q: ng.IQService,
        private readonly $log: ng.ILogService,
        private readonly $window: ng.IWindowService,
        private readonly authService: any,
        private readonly $state: ng.ui.IStateService,
        private readonly $scope: ng.IScope,
        private readonly $interval: ng.IIntervalService,
        private readonly dataContext: any,
        private readonly $cookies: any,
        private readonly constants: any
    ) {
        'ngInject';

        this.username = null;
        this.password = null;
        this.rememberMe = false;
        this.hasError = false;
        this.redirectUrl = null;
        this.isBusy = false;
        this.captchaToken = null;
        this.$scope = $scope;
        this.initializeCaptcha();
        this.refreshCaptchaToken();
        this.refreshTokenAtInterval = null;
    }

    public widgetCreated(id: any) {
        this.widgetId = id;
    }

    public initializeCaptcha() {
        var executeRecaptchaScript = document.createElement("script");
        executeRecaptchaScript.type = "text/javascript";
        executeRecaptchaScript.textContent =
            'var onloadCallback = function() {grecaptcha.ready(function () {grecaptcha.execute("6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM", { action: "portalLogin" }).then(function (token) { var recaptchaResponse = document.getElementById("recaptchaResponse"); angular.element(recaptchaResponse).controller("ngModel").$setViewValue(token); recaptchaResponse.value = token; angular.element(recaptchaResponse).controller("ngModel").$render();});});};';
        angular.element(document.querySelector('head')).append(executeRecaptchaScript);

        var recaptchaApiScript = document.createElement("script");
        recaptchaApiScript.type = "text/javascript";
        recaptchaApiScript.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=6LdJ2vUcAAAAALfvFO-h0qTNyP_BhtMtDziJEEeM";
        angular.element(document.querySelector('head')).append(recaptchaApiScript);
        _.defer(() => this.$scope.$apply());
    }

    public refreshCaptchaToken() {
        this.refreshTokenAtInterval = this.$interval(() => {
                this.initializeCaptcha();
            },
            90 * 1000);
    }

    public formFieldsPopulated() {
        return (!_.isNil(this.username) && !_.isNil(this.password));
    }

    public $onDestroy() {
        if (this.refreshTokenAtInterval) {
            // when the user leaves, cancel the $timeout instance
            this.$interval.cancel(this.refreshTokenAtInterval);
        }
    }

    public submit() {
        this.isBusy = true;
        this.showError(null);
        const urlParams = new URLSearchParams(window.location.search);
        this.redirectUrl = urlParams.get('redirectTo');

        this.dataContext.user.login(this.username, this.password, this.captchaToken, this.rememberMe, this.redirectUrl)
            .then((loginResult: any) => {

                const today = new Date();
                const expiresValue = new Date(today);
                //Set 'expires' option in 2 month
                expiresValue.setMinutes(today.getMinutes() + this.constants.cache.expiry);
                this.$cookies.put(
                    'IsFPCustomer',
                    true,
                    {
                        expires: expiresValue,
                        domain: 'frontpointsecurity.com'
                    });

                if (!_.isEmpty(loginResult)) {
                    this.authService.loginConfirmed();
                    
                    if (this.redirectUrl && this.redirectUrl.indexOf('supportkbredirect') != -1) {
                        var baseUrl = 'https://help.frontpointsecurity.com';
                        const tokenCookie = this.$cookies.get('GlobalFPToken');

                        const target = urlParams.get('target');
                        if (target) {
                            baseUrl = target;
                        }

                        this.$window.open(`${baseUrl}?${tokenCookie ? `t=${tokenCookie}&` : ''}`, '_self');
                    }
                    else {
                        this.$window.open(loginResult, '_self');
                    }
                }
            },
               (error: any) => {
                    this.initializeCaptcha();
                    this.$log.error(error);
                    let errorMessage = error;
                    if (!_.isNil(error.data)) {
                        if (!_.isEmpty(error.data.RedirectUrl)) {
                            this.$window.location.href = decodeURIComponent(error.data.RedirectUrl).replace('#', '');
                            errorMessage = null;
                        } else if (!_.isEmpty(error.data.Reason)) {
                            errorMessage = error.data.Reason;
                        } else if (!_.isEmpty(error.data.Message)) {
                            errorMessage = error.data.Message;
                        } else {
                            errorMessage = error.data;
                        }
                    }

                    if (!_.isNil(errorMessage) &&
                        errorMessage.toString().toLowerCase() === 'password change required') {
                        return this.$state.go('login.changePassword', {
                            message: 'Password change required'
                        });
                    } else {
                        this.showError(errorMessage);
                        return this.$q.reject(error);
                    }
                })
            .finally(() => {
                this.isBusy = false;
            });
    }

    public showError(message: string) {
        this.hasError = !_.isEmpty(message);
        this.message = message;
    }
}

export const loginComponent = {
    template: require('./login.html'),
    bindings: {
        message: '<'
    },
    controller: LoginController
};
