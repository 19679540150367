import * as angular from 'angular';
import * as _ from 'lodash';
import { IModalService } from 'angular-ui-bootstrap'
import TrackOrderService from '../../common/data/trackOrder.service';
import { TrackOrderDto } from '../types';
import './confirmationModal.component.scss';
import { initRecaptcha } from '../utility';

class ConfirmationModal {
    $scope: ng.IScope;
    trackOrderService: TrackOrderService;

    dismiss: any;
    token: string;
    order: TrackOrderDto;

    constructor($scope: ng.IScope, trackOrderService: TrackOrderService) {
        'ngInject';
        this.$scope = $scope;
        this.trackOrderService = trackOrderService;
    }

    emailOrderDetails() {
        this.trackOrderService.emailOrderDetails(this.token, this.order.OrderNumber).finally(() => {
            initRecaptcha(this.$scope);
            this.dismiss();
        });
    }
}

export default {
    template: require('./confirmationModal.component.html'),
    bindings: {
        token: '<',
        order: '<',
        dismiss: '<'
    },
    controller: ConfirmationModal
};