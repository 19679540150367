class DispatchService {
    /*@ngInject*/
    constructor(Restangular, constants, applicationStateService) {
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
    }

    get(rapidStatus) {
        return this.Restangular
            .one('dispatch')
            .get({rapidStatus: rapidStatus})
            .then((result) => {
                this.dispatches = result.plain();
                return result.plain();
            });
    }

    post(contacts) {
        return this.Restangular
            .all('dispatch')
            .post(contacts)
            .then((result) => {
                this.applicationStateService.dataChangeDispatches(this.constants.sectionTypes.emergencySettings, this.constants.dataTypes.preDispatch, this.dispatches, contacts);
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }

    searchDispatch(searchModel) {
        return this.Restangular
            .all('dispatch')
            .one('search-dispatch')
            .customPOST(searchModel)
            .then((result => {
                return result;
            }));
    }

    getDispatchNotificationTypes() {
        return this.Restangular
            .all('dispatch')
            .one('get-dispatch-notification-types')
            .get()
            .then((result => {
                return result;
            }));
    }

    getUserDispatchNotificationSettings() {
        return this.Restangular
            .all('dispatch')
            .one('get-user-dispatch-notification-settings')
            .get()
            .then((result) => {
                return result;
            });
    }

    setUserDispatchNotificationSettings(dispatchNotificationSetting) {
        return this.Restangular
            .all('dispatch')
            .one('set-user-dispatch-notification-setting')
            .customPUT(dispatchNotificationSetting)
            .then((result) => {
                return result;
            });
    }

    getUserUpcomingDispatches(searchModel) {
        return this.Restangular
            .all('dispatch')
            .one('get-user-upcoming-dispatches')
            .customPOST(searchModel)
            .then((result => {
                return result;
            }));
    }

    getUserDispatchHistory(searchModel) {
        return this.Restangular
            .all('dispatch')
            .one('get-user-dispatch-history')
            .customPOST(searchModel)
            .then((result => {
                return result;
            }));
    }

    createUserDispatches(dispatchInstances) {
        return this.Restangular
            .all('dispatch')
            .one('create-user-dispatches')
            .customPOST(dispatchInstances)
            .then((result) => {
                return result;
            });
    }

    cancelUserDispatch(dispatchId) {
        return this.Restangular
            .all('dispatch')
            .one('cancel-user-dispatch')
            .customPOST(dispatchId)
            .then((result) => {
                return result;
            });
    }

    updateUserDispatch(dispatchUpdateInstance) {
        return this.Restangular
            .all('dispatch')
            .one('update-user-dispatch')
            .customPOST(dispatchUpdateInstance)
            .then((result) => {
                return result;
            });
    }

    getUserDispatchReport(dispatchId) {
        return this.Restangular
            .all('dispatch')
            .one('get-user-dispatch-report')
            .customGET('', {
                'dispatchId': dispatchId
            })
            .then((result) => {
                return result;
            }); 
    }

    getDispatchGuardDetails(dispatchId) {
        return this.Restangular
            .all('dispatch')
            .one('get-dispatch-guard-details')
            .customGET('', {
                'dispatchId': dispatchId
            })
            .then((result) => {
                return result;
            });
    }

    getUserTimeZoneAbbreviation(userTimeZone) {
        switch (userTimeZone) {
        case "(UTC-05:00) Eastern Time (US & Canada)":
            return "EST";
            case "Eastern Standard Time":
                return "EST";

        case "(UTC-06:00) Central Time (US & Canada)":
            return "CST";
            case "Central Standard Time":
                return "CST";

        case "(UTC-07:00) Mountain Time (US & Canada)":
            return "MST";
            case "Mountain Standard Time":
                return "MST";

        case "(UTC-08:00) Pacific Time (US & Canada)":
            return "PST";
            case "Pacific Standard Time":
                return "PST";

        case "(UTC-09:00) Alaska Time (US & Canada)":
            return "AKST";
            case "Alaska Standard Time":
                return "AKST";

        case "(UTC-10:00) Hawaii Time":
            return "HST";
            case "Hawaii Standard Time":
                return "HST";

        default:
            return "";
        }
    }

    getUserIANATimeZone(userTimeZone) {
        switch (userTimeZone) {
        case "(UTC-05:00) Eastern Time (US & Canada)":
            return "America/New_York";

        case "(UTC-06:00) Central Time (US & Canada)":
            return "America/Chicago";

        case "(UTC-07:00) Mountain Time (US & Canada)":
            return "America/Denver";

        case "(UTC-08:00) Pacific Time (US & Canada)":
            return "America/Los_Angeles";

        case "(UTC-09:00) Alaska Time (US & Canada)":
            return "America/Anchorage";

        case "(UTC-10:00) Hawaii Time":
            return "Pacific/Honolulu";

        default:
            return "";
        }
    }

    getDispatchDiscountGroups() {
        return this.Restangular
            .all('dispatch')
            .one('get-dispatch-discount-groups')
            .get()
            .then((result) => {
                return result;
            }); 
    }
}

export default DispatchService;