import * as moment from 'moment';
import './contact-to-cancel.component.scss';
import { RequestCallbackDto } from '@app/common/data/cancel.service';
import DataContext from '../common/data/dataContext.service';

interface CallbackSlot {
    text: string,
    value: number
}

export class ContactToCancelController {
    model: RequestCallbackDto = {
        phone: '',
        followUpDate: moment().add(1, 'd').toDate(),
        timeframe: 1
    };
    dateOptions = {
        minDate: moment().startOf('h').toDate(),
        maxDate: moment().add(14, 'd').startOf('h').toDate(),
        showWeeks: false,
        dateDisabled : ({date, mode}: any) => {
            const now = moment();
            const diff = now.diff(date, 'd');
            if (diff > 0) {
                // disable past dates
                return true;
            }

            // disable weekends
            return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
        }
    };

    error: string;
    success = false;
    loading = false;

    timeframes: CallbackSlot[] = [{ text: 'Morning', value: 1 }, { text: 'Afternoon', value: 2 }, { text: 'Evening', value: 3 }]
    availableTimes: CallbackSlot[] = [];

    /*@ngInject*/
    constructor(private $log: ng.ILogService, private dataContext: DataContext) {
        'ngInject';
    }

    public async $onInit() {
        await this.onDateChange();
    }

    async onDateChange() {
        this.availableTimes.length = 0;
        this.dataContext.cancel.getHelpWindows(this.model.followUpDate).then((slots: { Date: string, Window: number}[]) => {
            slots.forEach(slot => {
                const tf = this.timeframes.find(p => p.value == slot.Window);
                if (tf == null) {
                    this.$log.warn('could not find timeframe for slot', slot);
                    return;
                }
                this.availableTimes.push({ text: tf.text, value: tf.value });
            });
        });
    }

    async submitRequest() {
        this.error = '';
        this.loading = true;

        if (this.model.phone == null || this.model.phone.length != 10) {
            this.setError('Please enter a valid phone number.');
            return;
        }

        if (this.model.followUpDate == null) {
            this.setError('Please select a date.');
            return;
        }

        if (this.model.timeframe == null) {
            this.setError('Please select a time.');
            return;
        }

        try {
            await this.dataContext.cancel.requestCallback(this.model);
            this.success = true;
        } catch (err) {
            this.setError('An error occurred while submitting your request. Please try again later.');
        } finally {
            this.loading = false;
        }
    }

    setError(error: string) {
        this.error = error;
        this.loading = false;
    }
}

export const contactToCancelComponent = {
    selector: 'fpContactToCancel',
    template: require('./contact-to-cancel.component.html'),
    controller: ContactToCancelController
};