import angular from 'angular';

import _ from 'lodash';

import touchStartDirective from './touchstart.directive';
import touchEndDirective from './touchend.directive';

import lodashConfig from './lodash.config';

export default angular.module('fp:customerportal:common:extensions', [])
    .constant('_', _)

    .config(lodashConfig)

    .directive('fpTouchstart', touchStartDirective)
    .directive('fpTouchend', touchEndDirective);