import _ from 'lodash';
import template from './account.html';
import AbstractSectionController from '../AbstractSectionController';

class AccountInfoController extends AbstractSectionController {
    /*@ngInject*/
    constructor(dataContext, constants, alertService, userService, monitoringPlansService) {
        super();

        this.dataContext = dataContext;
        this.constants = constants;
        this.customerSupport = constants.customerSupport;
        this.alertService = alertService;
        this.ALERT_DIALOG = 'accountInfo';

        this.hasVideoPlan = monitoringPlansService.isSAVPlan(userService.current.ServicePlanInfo);
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.customerInfo);
    }

    onSaveCore() {
        this.isLoading = true;
        let customerInfoCopy = _.cloneDeep(this.customerInfo);

        if (!_.isNil(customerInfoCopy.CustomerInformation.Phone)) {
            customerInfoCopy.CustomerInformation.Phone = customerInfoCopy.CustomerInformation.Phone.replace(this.constants.fpRegex.allSpecialCharacters, '');
        }
        if (!_.isNil(customerInfoCopy.CustomerInformation.AltPhone)) {
            customerInfoCopy.CustomerInformation.AltPhone = customerInfoCopy.CustomerInformation.AltPhone.replace(this.constants.fpRegex.allSpecialCharacters, ''); 
        }
        if (!_.isNil(customerInfoCopy.PremisesAddress)) {
            customerInfoCopy.PremisesAddress = null;
        }
        if (!_.isNil(customerInfoCopy.BillingAddress)) {
            customerInfoCopy.BillingAddress = null;
        }
        if (!_.isNil(customerInfoCopy.ShippingAddress)) {
            customerInfoCopy.ShippingAddress = null;
        }

        return this.dataContext.account.updateContactInfo(customerInfoCopy)
            .then(() => {
                this._showGenericSuccessAlert();
            })
            .catch((error) => {
                this.$log.error('Failed to edit account contact', error);
                this._showGenericErrorAlert();
            })
            .finally(() => {
                this.isLoading = false;
                this.stopEditing();
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.customerInfo = this.pristine;
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your account information has been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your account information. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        isLoading: '<'
    },
    controller: AccountInfoController
};