import './cancellation.component.scss';

export class CancellationController {
    public currentSection = '';

    constructor(
        private $stateParams: any,
        private $state: any,
        private $scope: any,
        private readonly constants: any,
        private readonly dataContext: any) {
        'ngInject';

        $scope.$on('$stateChangeSuccess', (event: any, toState: any) => {
            if (toState) {
                this.updateCurrentSection(toState);
            }
        });
    }

    public $onInit() {
        this.updateCurrentSection(this.$state.current);
    }

    private updateCurrentSection(state: any) {
        if (state.name.includes('cancellation.offer')) {
            this.currentSection = 'offer';
        }
        else if (state.name.includes('cancellation.cancellationComplete')) {
            this.currentSection = 'cancellationComplete';
        }
        else if (state.name.includes('cancellation.expired')) {
            this.currentSection = 'linkExpired';
        }
        else {
            this.currentSection = '';
        }
    }
}

export const cancellationComponent = {
    template: require('./cancellation.component.html'),
    controller: CancellationController
};