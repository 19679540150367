import { IQService, IRootScopeService } from 'angular';
import { IService as IRestangularService } from 'restangular';
import * as _ from 'lodash';
import ApplicationStateDataService from './applicationState.service';
import { CentralStationContactWithMedicalInformationDto } from '../../myaccount/settings/types/CentralStationContactWithMedicalInformationDto';

class MedicalInformationService {

    $rootScope: IRootScopeService;
    Restangular: IRestangularService;
    constants: any;
    applicationStateService: ApplicationStateDataService;
    $q: IQService;

    /*@ngInject*/
    constructor(
        $rootScope: IRootScopeService,
        Restangular: IRestangularService,
        constants: any,
        applicationStateService: ApplicationStateDataService,
        $q: IQService
    ) {
        'ngInject';
        this.$rootScope = $rootScope;
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
    }

    getSiteContactsAndConditions() {
        return this.Restangular
            .all('medical-information')
            .one('get-site-contacts-and-condition-counts')
            .get()
            .then((result) => {
                return result;
            });
    }

    setSiteContactsAndConditions(contactsWithMedicalInformation: CentralStationContactWithMedicalInformationDto[]) {
        return this.Restangular
            .all('medical-information')
            .one('create-medical-contacts')
            .customPUT(contactsWithMedicalInformation)
            .then((result) => {
                return result;
            });
    }

    deleteSiteContact(contactNumber: number) {
        return this.Restangular
            .all('medical-information')
            .one('delete-contact', contactNumber)
            .remove()
            .then((result) => {
                return result;
            });
    }

    getConditionList() {
        return this.Restangular
            .all('medical-information')
            .one('get-condition-list')
            .get()
            .then((result) => {
                return result;
            });
    }
}

export default MedicalInformationService;