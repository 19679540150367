export class RetentionOfferType {
    RetentionOfferTypeID: number;
    Name: string;
    IsDeleted: boolean;
    MonthsFree: number | null;
    CouponAmount: number | null;
    NewRMR: number | null;
    FreeProductID: number | null;
    ContractTypeID: number | null;
    CouponID: number | null;
}