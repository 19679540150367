import _ from 'lodash';

class PanelService {
    /*@ngInject*/
    constructor (Restangular) {
        this.Restangular = Restangular;
    }

    getVersion() {
        return this.Restangular
            .one('system')
            .one('panel')
            .one('version')
            .get();
    }

    getModemVersion() {
        return this.Restangular
            .one('account')
            .one('products')
            .get()
            .then(products => {
                return _.some(products, { ProductName: 'TMO 2G SIM' }) ? '2G' : '3G';
            });
    }
}

export default PanelService;