import { IService as IRestangularService } from 'restangular';

class CancelService {
    constructor(private $log: ng.ILogService, private Restangular: IRestangularService) {
        'ngInject';
    }

    getToken(): string | null {
        // Try to get the token from the URL
        let url = new URL(window.location.href);
        let token = url.searchParams.get('t');

        // If the token wasn't in the URL, check session storage
        token = token || sessionStorage.getItem('fpx_token');

        if (token) {
            sessionStorage.setItem('fpx_token', token);
        }

        return token;
    }


    async startSelfServiceRetention(token: string | undefined | null): Promise<boolean> {
        this.$log.debug('checking cancellation token: ' + token);
        if (token == null) {
            return false;
        }

        try {
            // service returns Ok() on success, otherwise something failed.
            await this.Restangular.one('retention').one('self-service').one('requests').one(token).customPOST();

            return true;
        } catch (err) {
            this.$log.error('failed to start self service retention flow', err);

            return false;
        }
    }

    async isTokenValid(token: string | null): Promise<boolean> {
        if (token == null) {
            return false;
        }

        try {
            const response = await this.Restangular
                .one('retention')
                .one('self-service')
                .one('token')
                .customGET(token);

            this.$log.debug('retention/self-service/token', response);
            return response.Valid;
        } catch (err) {
            return false;
        }
    }

    async hasSelfServiceRetentionOpen(): Promise<boolean> {
        try {
            const response = await this.Restangular.one('retention').one('self-service').one('requests').customGET('');
            this.$log.debug('retention/self-service', response);
            return response.Exists;
        } catch (err) {
            // default to true in case something failed, the user should call in
            return true;
        }
    }

    async requestCallback(callback: RequestCallbackDto): Promise<void> {
        try {
            await this.Restangular.one('retention').one('self-service').one('help').customPOST(callback);
        } catch (err) {
            this.$log.error('failed to request callback', err);
            throw err;
        }
    }

    async getHelpWindows(callbackDate: Date): Promise<any[]> {
        try {
            return this.Restangular
                .one('retention')
                .one('self-service')
                .one('help')
                .all('slots?callbackDate=' + callbackDate.toISOString())
                .getList().then((slots) => {
                    return slots;
                });
        } catch (err) {
            this.$log.error('failed to get callback slots', err);
            return [];
        }
    }
}

export interface RequestCallbackDto {
    phone: string;
    followUpDate: Date;
    timeframe: Timeframe;
}

export enum Timeframe {
    Any = 0,
    Morning = 1,
    Afternoon = 2,
    Evening = 3,
}

export default CancelService;