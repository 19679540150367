let maskedFilter = (_) => {
    'ngInject';
    let mask = (text, maskCharacter, length) => {
        return _.times(length, () => {
            return maskCharacter;
        }).join('');
    };

    return (text, options) => {
        if (_.isEmpty(text)) {
            text = '';
        }

        let opts = _.merge({}, {
            enabled: true,
            maskCharacter: '*',
            length: undefined,
            minLength: 0,
            pad: {
                enabled: true,
                length: 12,
                char: ' '
            }
        }, options);

        let length = (_.isNumber(opts.length) && opts.length > 0)
            ? opts.length
            : text.length;
        if (length <= opts.minLength) {
            length = opts.minLength;
        }

        let result = opts.enabled
            ? mask(text, opts.maskCharacter, length)
            : text;

        if (opts.pad.enabled) {
            result = _.padStart(result, opts.pad.length, opts.pad.char);
        }

        return result;
    };
};

export default maskedFilter;