import template from './dataManagementModal.html';

class DataManagementModalController {
    /*@ngInject*/
    constructor($uibModal, $window) {
        this.$uibModal = $uibModal;
        this.$window = $window;
    }

    callUs() {
        this.$uibModal.open({
            template: '<fp-call-us-data-management-modal data-on-close="$dismiss()"></fp-call-us-data-management-modal>',
            controllerAs: '$ctrl',
            controller: /*@ngInject*/ function () {
                this.title = 'Call US Data Management';
            },
            size: 'lg'
        });
    }
}

export default {
    template: template,
    bindings: {
        title: '@',
        url: '@',
        onClose: '&'
    },
    controller: DataManagementModalController
};