import './viewContact.scss';
import template from './viewContact.html';

class ViewContactController {
    /*@ngInject*/
    constructor() {
        
    }
}

export default {
    template: template,
    bindings: {
        contact: '<',
        position: '<',
        canRemoveContact: '&',
        canPromoteContact: '&',
        canDemoteContact: '&',
        onRemoveContact: '&',
        onPromoteContact: '&',
        onDemoteContact: '&',
        onStartEdit: '&'
    },
    controller: ViewContactController
};