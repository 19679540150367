import template from './addAddOnModal.html';


class AddAddOnModalController {
    /* @ngInject */
    constructor($uibModal, dataContext, constants, $log, alertService) {
        this.$modal = $uibModal;
        this.$log = $log;
        this.dataContext = dataContext;
        this.constants = constants;
        this.data = this.config.data;
        this.$close = this.config.close;
        this.$dismiss = this.config.dismiss;
        this.alertService = alertService;
        this.addOnsQuantity = 1;
        this.disableNextButton = false;
    }

    close() {
        this.$dismiss();
    }

    $onInit() {
        this.safetyButtonAddOn = this.data.safetyButtonAddOn;
        this.totalRMR = (this.addOnsQuantity * this.safetyButtonAddOn[0].Price).toFixed(2);
    }

    calculateRMR() {
        this.totalRMR = (this.addOnsQuantity * this.safetyButtonAddOn[0].Price).toFixed(2);
        if (this.addOnsQuantity == null || this.addOnsQuantity == undefined) {
            this.disableNextButton = true;
        } else {
            this.disableNextButton = false;
        }
    }

    next() {
        this.close();
        const modalInstance = this.$modal.open({
            template: '<fp-enrollment-add-on-modal config="config"></fp-enrollment-add-on-modal>',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        safetyButtonAddOn: this.data.safetyButtonAddOn,
                        oldQuantity: this.data.oldQuantity,
                        newQuantity: this.addOnsQuantity
                    },
                    close: modalInstance.close,
                    dismiss: modalInstance.dismiss
                };
            },
            size: 'lg'
        });
    }
    
}

export default {
    template: template,
    bindings: {
        config: '<',
        safetyButtonAddOn: '<',
        oldQuantity: '<',
        newQuantity: '<'
    },
    controller: AddAddOnModalController
};