class PluginService {
    hasAXPlugin(plugins) {
        try {
            //check for ie
            new ActiveXObject('ShockwaveFlash.ShockwaveFlash');
            return true;
        } catch (e) {
            for (let x = 0; x < plugins.length; x++) {
                for (let y = 0; y < 100; y++) {
                    if (plugins[x][y] && plugins[x][y].suffixes === 'pdf') {
                        return true;
                    }
                }
            }
            return false;
        }
    }
}

export default PluginService; 