import angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import componentsModule from './components';

import emergencyHomeComponent from './home.component';
import emergencyPasscodeComponent from './passcode.component';
import emergencyPostdispatchComponent from './postdispatch.component';
import emergencyPredispatchComponent from './predispatch.component';
import emergencyPermitComponent from './permit.component';
import emergencyTestmodeComponent from './testmode.component';

export default angular
    .module('fp:customerportal:account:emergency', [
        'ui.router',
        commonModule.name,
        componentsModule.name
    ])

    .config(/*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('account.emergency',
            {
                url: '/emergency',
                template: '<fp-emergency-home></fp-emergency-home>',
                data: {
                    navigationMenu: constants.navigationMenuSections.majorSections.account,
                    navigationSubSection: constants.navigationMenuSections.subSections.emergencySettings,
                    subnav: [
                        {
                            name: 'Security Passcode',
                            shortName: 'Passcode',
                            icon: 'fa-lock',
                            href: '#passcode'
                        },
                        {
                            name: 'Permit Number',
                            shortName: 'Permit',
                            icon: 'fa-file-text',
                            href: '#permitNumber'
                        },
                        {
                            name: 'Predispatch Verification',
                            shortName: 'Predispatch',
                            icon: 'fa-paper-plane-o',
                            href: '#predispatch'
                        },
                        {
                            name: 'Postdispatch Verification',
                            shortName: 'Postdispatch',
                            icon: 'fa-paper-plane-o',
                            href: '#postdispatch'
                        },
                        {
                            name: 'Monitoring Status',
                            shortName: 'Monitoring',
                            icon: 'fa-cogs',
                            href: '#testmode'
                        }
                    ]
                }
            })
            .state('account.emergency.passcode', {
                data: {
                    scrollTo: 'passcode'
                }
            })
            .state('account.emergency.predispatch', {
                data: {
                    scrollTo: 'predispatch'
                }
            })
            .state('account.emergency.postdispatch', {
                data: {
                    scrollTo: 'postdispatch'
                }
            })
            .state('account.emergency.testmode', {
                data: {
                    scrollTo: 'testmode'
                }
            })
            .state('account.emergency.permit', {
                data: {
                    scrollTo: 'permit'
                }
            });
    })
    .component('fpEmergencyHome', emergencyHomeComponent)
    .component('fpEmergencyPasscode', emergencyPasscodeComponent)
    .component('fpEmergencyPostdispatch', emergencyPostdispatchComponent)
    .component('fpEmergencyPredispatch', emergencyPredispatchComponent)
    .component('fpEmergencyPermit', emergencyPermitComponent)
    .component('fpEmergencyTestmode', emergencyTestmodeComponent);