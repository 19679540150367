import template from './spinner.html';

class SpinnerController {
    /*@ngInject*/
    constructor() {

    }
}

export default {
    template: template,
    transclude: true,
    bindings: {
        onLoad: '&',
        isLoading: '<',
        loadingText: '<'
    },
    controller: SpinnerController
};