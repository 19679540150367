import angular from 'angular';
import 'angular-ui-router';

import commonModule from '../../common';
import componentsModule from './components';

import documentsContractComponent from './contract.component';
import documentsHomeComponent from './home.component';
import documentsInsuranceComponent from './insurance.component';
import documentsDataManagementComponent from './dataManagement.component';
import documentsDataManagementModalComponent from './dataManagementModal.component';
import documentsCallUsDataManagementModalComponent from './callUsDataManagementModal.component';

export default angular
    .module('fp:customerportal:account:documents',
    [
        'ui.router',
        commonModule.name,
        componentsModule.name
    ])
    .config(/*@ngInject*/ ($stateProvider, constants) => {
        $stateProvider
            .state('account.documents',
            {
                url: '/documents',
                template: '<fp-documents-home></fp-documents-home>',
                data: {
                    navigationMenu: constants.navigationMenuSections.majorSections.account,
                    navigationSubSection: constants.navigationMenuSections.subSections.documents,
                    subnav: [
                        {
                            name: 'Contract Information',
                            shortName: 'Contract',
                            icon: 'fa-file-text',
                            href: '#contract'
                        },
                        {
                            name: 'Certificate of Insurance',
                            shortName: 'Insurance',
                            icon: 'fa-life-ring',
                            href: '#insurance'
                        }
                    ]
                }
            })
            .state('account.documents.contract', {
                data: {
                    scrollTo: 'contract'
                }
            })
            .state('account.documents.insurance', {
                data: {
                    scrollTo: 'insurance'
                }
            });
    })
    .component('fpDocumentsHome', documentsHomeComponent)
    .component('fpContract', documentsContractComponent)
    .component('fpInsurance', documentsInsuranceComponent)
    .component('fpDataManagement', documentsDataManagementComponent)
    .component('fpDataManagementModal', documentsDataManagementModalComponent)
    .component('fpCallUsDataManagementModal', documentsCallUsDataManagementModalComponent);


