import template from './maskedPasscode.html';

let maskedPasscodeDirective = () => {
    return {
        restrict: 'E',
        template: template,
        controller: MaskedPasscode,
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            passcode: '=',
            showButton: '=?',
            buttonPosition: '=?'
        }
    };
};

class MaskedPasscode {
    /*@ngInject*/ 
    constructor(_, Modernizr) {
        this.isTouch = Modernizr.touch;
        this.isPasscodeMasked = true;

        if (_.isNil(this.showButton)) {
            this.showButton = true;
        }

        if (_.isNil(this.buttonPosition)) {
            this.buttonPosition = 'right';
        }
    }
}

export default maskedPasscodeDirective;
