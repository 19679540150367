import _ from 'lodash';

import template from './address.html';
import AbstractSectionController from '../AbstractSectionController';

class AccountAddressController extends AbstractSectionController {
    /*@ngInject*/
    constructor(dataContext, constants, $uibModal, alertService) {
        super();
        this.dataContext = dataContext;
        this.constants = constants;
        this.customerSupport = constants.customerSupport;
        this.$uibModal = $uibModal;
        this.ALERT_DIALOG = 'addresses';
        this.alertService = alertService;
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.customerInfo);
    }

    onSaveCore() {
        this.isLoading = true;
        return this.validateAddress(this.customerInfo.ShippingAddress, 'shipping').then((result) => {
            if (!result) {
                this.isLoading = false;
                return false;
            }
            if (!this.sameAddress) {
                this.validateAddress(this.customerInfo.BillingAddress, 'billing').then((result) => {
                    if (result) {
                        this.validateAddress(this.customerInfo.PremisesAddress, 'premises').then((result) => {
                            if (result) {
                                this.processSave();
                            } else {
                                this.isLoading = false;
                            }
                        });
                    } else {
                        this.isLoading = false;
                    }
                });
            } else {
                return this.processSave();
            }
        });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.customerInfo = this.pristine;
        this.sameAddress = this.dataContext.account.sameShippingAndBillingAndPremisesAddress(this.customerInfo.ShippingAddress, this.customerInfo.BillingAddress, this.customerInfo.PremisesAddress);
    }

    processSave() {
        //have to null these fields to save
        if (!_.isNil(this.customerInfo.CustomerInformation)) {
            this.customerInfo.CustomerInformation = null;
        }

        if (this.sameAddress) {
            this.customerInfo.BillingAddress = _.cloneDeep(this.customerInfo.ShippingAddress);
            this.customerInfo.PremisesAddress = _.cloneDeep(this.customerInfo.ShippingAddress);
        }

        const panelType = this.dataContext.user.current.ServicePlanInfo.PanelType;

        if ((this.pristine.PremisesAddress.Address1 == this.customerInfo.PremisesAddress.Address1 &&
            this.pristine.PremisesAddress.Address2 == this.customerInfo.PremisesAddress.Address2 &&
            this.pristine.PremisesAddress.City == this.customerInfo.PremisesAddress.City &&
            this.pristine.PremisesAddress.State == this.customerInfo.PremisesAddress.State &&
            _.trim(this.pristine.PremisesAddress.PostalCode) == _.trim(this.customerInfo.PremisesAddress.PostalCode)) ||
            panelType === this.constants.panels.unknown) {
            this.customerInfo.PremisesAddress = null;
        }

        let addressDataTypes = [];

        if (!_.isNil(this.customerInfo.PremisesAddress) &&
            !_.isEqual(this.customerInfo.PremisesAddress, this.pristine.PremisesAddress)) {
            addressDataTypes.push(this.constants.dataTypes.premisesAddress);
        }
        if (!_.isNil(this.customerInfo.BillingAddress) &&
            !_.isEqual(this.customerInfo.BillingAddress, this.pristine.BillingAddress)) {
            addressDataTypes.push(this.constants.dataTypes.billingAddress);
        }
        if (!_.isNil(this.customerInfo.ShippingAddress) &&
            !_.isEqual(this.customerInfo.ShippingAddress, this.pristine.ShippingAddress)) {
            addressDataTypes.push(this.constants.dataTypes.shippingAddress);
        }

        this.dataContext.account.updateAddress(this.customerInfo, addressDataTypes)
            .then(() => {
                //bring back the nulled fields
                if (this.customerInfo.PremisesAddress == null) {
                    this.customerInfo.PremisesAddress = this.pristine.PremisesAddress;
                }
                this.customerInfo.CustomerInformation = this.pristine.CustomerInformation;
                this._showGenericSuccessAlert();
            })
            .catch(error => {
                this.$log.error('Failed to save addresses', error);
                this._showGenericErrorAlert();
            })
            .finally(() => {
                this.isLoading = false;
                this.stopEditing();
                this.sameAddress = this.dataContext.account.sameShippingAndBillingAndPremisesAddress(this.customerInfo.ShippingAddress, this.customerInfo.BillingAddress, this.customerInfo.PremisesAddress);
            });
    }

    validateAddress(address, type) {
        let formattedAddress = {
            Address1: address.Address1,
            Address2: address.Address2,
            City: address.City,
            PostalCode: address.PostalCode,
            State: address.State
        };

        return this.dataContext.address.validateAddress(formattedAddress)
            .then((response) => {
                if (_.isNil(formattedAddress.Address1) ||
                    _.isNil(formattedAddress.City) ||
                    _.isNil(formattedAddress.PostalCode)) {
                    return false;
                }

                if (type === 'shipping' || type === 'premises' || this.sameAddress) {
                    if (this.hasPOBox(formattedAddress)) {
                        this._showPOBoxErrorAlert();
                        this.isLoading = false;
                        return false;
                    }
                }

                if (!this.dataContext.address.isValidResponse(response)) {
                    let returnedAddress = {
                        Address1: address.Address1,
                        Address2: address.Address2,
                        City: response.City,
                        PostalCode: response.PostalCode,
                        State: response.StateAbrev
                    };

                    let modalInstance = this.$uibModal.open({
                        template: '<fp-address-validation-modal config="config"></fp-address-validation-modal>',
                        controller: ($scope) => {
                            'ngInject';
                            $scope.config = {
                                data: address,
                                close: modalInstance.close,
                                dismiss: modalInstance.dismiss,
                                returnedAddress: returnedAddress
                            };
                        }
                    });

                    return modalInstance.result.then((result) => {
                        if (_.isNil(result)) {
                            this.isLoading = false;
                            return false;
                        } else {
                            if (address.State !== result.State || address.City !== result.City) {
                                address.State = result.State;
                                address.City = result.City;
                            }
                            return true;
                        }
                    });
                } else {
                    return true;
                }
            });
    }

    hasPOBox(address) {
        return this.dataContext.address.isPOBox(address);
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your addresses have been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. We encountered an issue while updating your addresses. Please try again.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showPOBoxErrorAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Error. P.O. boxes are not permitted as an address for your security system.')
            .setType('danger')
            .setTimeout(this.constants.alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        customerInfo: '<',
        sameAddress: '<',
        states: '<',
        isLoading: '<'
    },
    controller: AccountAddressController
};