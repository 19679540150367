let aLogStateDirective = (constants, dataContext, _, $state) => {
    'ngInject';

    return {
        restrict: 'A',
        compile: (tElement, tAttrs) => {
            return (scope, element, attributes) => {
                let description = !_.isNil(attributes.fpALogState)  ?  attributes.fpALogState : 'Unknown';
                element.on('click', (e) => {
                    dataContext.applicationState.change(
                          !_.isNil($state) && !_.isNil($state.current) && !_.isNil($state.current.data) &&  !_.isNil($state.current.data.sectionType) ?  $state.current.data.sectionType : 'Unknown',
                          constants.actionTypes.click,
                          description);
                });
            };
        }
    };
};

export default aLogStateDirective;

