import template from "./congrats.html";
import './congrats.scss';

class CongratsController {
    /*@ngInject*/
    constructor(dataContext, segment,$state,constants) {
        this.segment = segment;
        this.dataContext = dataContext;
        this.$state = $state;
        this.constants = constants;
    }

    onRedirectToShop() {
        let user = this.dataContext.user.current;
        this.segment.registerEvent('Move Portal - Navigate to Shop',
            {
                Email: user.Email
            });
        this.$state.go('shop.home');
    }
}

export default {
    template: template,
    bindings: {},
    controller: CongratsController
};