import _ from 'lodash';
import template from './passcode.html';
import AbstractSectionController from '../AbstractSectionController';

class PasscodeController extends AbstractSectionController {
    /*@ngInject*/
    constructor($log, $q, alertService, constants, dataContext) {
        super();
        this.$log = $log;
        this.$q = $q;
        this.alertService = alertService;
        this.constants = constants;
        this.dataContext = dataContext;

        this.ALERT_DIALOG = 'emergency:passcode';

        this.PASSCODE_MIN_LENGTH = 1;
        this.PASSCODE_MAX_LENGTH = 11;
    }

    onEditCore() {
        // Save a copy of the data before the customer edits the data. This allows us to restore the pristine values
        // if the customer stops editing without saving.
        this.pristine = _.cloneDeep(this.passcode);

        // Clear the passcode when we enter edit mode so that the user does not have to delete their current passcode
        // from the input before entering their new passcode.
        this.passcode = null;
    }

    onSaveCore() {
        this.isLoading = true;
        return this.dataContext.passcode.put(this.passcode)
            .then((result) => {
                if (!_.isNil(result.ErrorMessage)) {
                    return this.$q.reject(result);
                }
                this._showGenericSuccessAlert();
                this.pristine = null;
                this.confirmPasscode = null;
            })
            .catch((error) => {
                this.$log.error('Error updating passcode.', error);
                var errorMessage = 'Error. We encountered an issue while updating your passcode. Please try again.';
                var alertDuration = this.constants.alertDuration;

                if (!_.isNil(error.ErrorMessage) && _.includes(error.ErrorMessage, 'Prohibited')) {
                    errorMessage = 'The supplied passcode is not valid, please enter a new passcode.';
                    alertDuration = this.constants.passcodeProhibitedAlertDuration;
                }

                this._showGenericErrorAlert(errorMessage, alertDuration);
                return this.$q.reject(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onCancelCore() {
        // The customer has stopped editing without saving. Restore the pristine value.
        this.passcode = this.pristine;
        this.confirmPasscode = null;
    }

    /**
     * Returns true if the current input is valid; otherwise, false.
     * 
     * @returns {boolean} 
     */
    isValid() {
        // A new passcode cannot be the same as the current passcode.
        if (this.passcode === this.pristine) {
            return false;
        }

        return true;
    }

    /**
     * Returns true if the current input is invalid; otherwise, true.
     * 
     * @returns {boolean} 
     */
    isInvalid() {
        return !this.isValid();
    }

    _showGenericSuccessAlert() {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage('Success! Your passcode has been updated.')
            .setType('success')
            .setTimeout(this.constants.alertDuration)
            .open();
    }

    _showGenericErrorAlert(errorMessage, alertDuration) {
        this.alertService
            .get(this.ALERT_DIALOG)
            .setMessage(errorMessage)
            .setType('danger')
            .setTimeout(alertDuration)
            .open();
    }
}

export default {
    template: template,
    bindings: {
        isLoading: '<',
        passcode: '<',
        rapidConnectionStatus: '<'
    },
    controller: PasscodeController
};