import _ from 'lodash';
import moment from 'moment';
import AbstractSectionController from '../AbstractSectionController';

import template from './upcomingDispatches.html';
import './upcomingDispatches.scss';

//TODO: make sure when repeating events the errorconfig properties for date dont carry over so they have to set a date value

class UpcomingDispatchesController extends AbstractSectionController {
    /*@ngInject*/
    constructor($log, dataContext, $q, constants, $uibModal, settingsDataService, $document, mobileAppContext, $scope, alertService, moment) {
        super();

        this.$log = $log;
        this.dataContext = dataContext;
        this.$q = $q;
        this.constants = constants;
        this.$uibModal = $uibModal;
        this.$document = $document;
        this.$mobileAppContext = mobileAppContext;
        this.$scope = $scope;
        this.alertService = alertService;
        this.user = dataContext.user;
        this.moment = moment;
        this.isMobileApp = this.$mobileAppContext.get() || window.innerWidth <= 768;

        this.ALERT_DIALOG = "upcomingDispatches";
        this.settingsDataService = settingsDataService;
        this.dispatchProtocolHelper = 'dispatchProtocolHelper';
        this.dispatchTimeHelper = 'dispatchTimeHelper';
        this.dispatchCancellationHelper = 'dispatchCancellationHelper';
        this.dispatchTypes = this.constants.DispatchTypes;

        this.dispatchScheduleTimes = ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00'];
        this.dispatchScheduleMeridiem = ['PM', 'AM'];

        this.totalDispatchFee = 0.00;
        this.totalDispatchFeeAfterExtraDiscount = 0.00;
        this.totalSalesTax = 0.00;

        this.isModifying = false;
        this.modifyUpdateBypass = false;
        this.hasCompletedDispatchesWithin7Days = false;

        this.dispatchRecordTableSlice = [];
        this.dispatchCompleteHistory = [];
        this.dispatchRecordPageNumber = 1;

        this.showNextPage = false;
        this.showPreviousPage = false;
        this.timeValidationHelper = null;
        this.getUserDispatchCompleteHistory();
    }

    //because we wait on data from the parent view dispatchServices we cannot initialize the table before data is available, this ensure that
    $onChanges(changes) {
        if (changes.upcomingDispatches && changes.upcomingDispatches.currentValue.length > 0) {
            this.initializeDispatchTable();
        }

        if (this.dispatchDiscountGroups && this.dispatchDiscountGroups[0] && this.dispatchDiscountGroups[0].DispatchQuantityDiscountPricing) {
            this.firstDispatchPrice = _.find(this.dispatchDiscountGroups[0].DispatchQuantityDiscountPricing, d => d.Quantity === 1).Price;
            this.secondDispatchPrice = _.find(this.dispatchDiscountGroups[0].DispatchQuantityDiscountPricing, d => d.Quantity === 2).Price;
            this.additionalDispatchPrice = _.find(this.dispatchDiscountGroups[0].DispatchQuantityDiscountPricing, d => d.Quantity === 0).Price;
        }
        this.userSettingProcessPayment = _.find(this.dataContext.user.current.Settings, function (s) { return s.Name === "CustomerPortal:ProcessPayment" });
    }

    onEditCore() {
        this.isModifying = false;
        this.dispatchToModify = [];

        this.dispatchInstances = [
            {
                asapDispatch: false,
                backwardsStartTime: false,
                callForSuspiciousCarPerson: false,
                customerPaymentID: 0,
                dispatchTypeID: 1,
                dispatchTypeName: "Perimeter Check",
                gateCode: null,
                hasDispatchConfigError: false,
                hasDispatchDateError: false,
                knockForResponse: false,
                mustSelectScheduleType: false,
                mustSelectDispatchDate: false,
                scheduledDispatch: false,
                scheduledDispatchDate: null,
                scheduledDispatchEndTime: null,
                scheduledDispatchEndTimeMeridiem: null,
                scheduledDispatchStartTime: null,
                scheduledDispatchStartTimeMeridiem: null,
                tooSmallWindow: false,
                tooSoonStart: false
            }];

        this.updateDispatchesCost();
    }

    onCancelCore() {
        this.stopEditing();
    }

    updateDispatchesCost() {
        var dispatchFee = 0.00;
        var dispatchFeeAfterExtraDiscount = 0.00;
        var formattedDate = moment().add(-7, 'days').format();
        var completedDisaptchesWithIn7Days = _.filter(this.dispatchCompleteHistory, function (s) {
            return s.CompletedDate >= formattedDate;
        });
        if (completedDisaptchesWithIn7Days && completedDisaptchesWithIn7Days.length > 0) {
            this.hasCompletedDispatchesWithin7Days = true;
        }
        this.dispatchInstances.forEach((i) => {
            if (i && i.dispatchCostAfterDiscount) {
                dispatchFee += i.dispatchCostAfterDiscount;
                if (i.dispatchCostAfterExtraDiscount) {
                    dispatchFeeAfterExtraDiscount += i.dispatchCostAfterExtraDiscount;
                }
            }
            else if (i) {
                if (this.dispatchDiscountGroups[0] && this.dispatchDiscountGroups[0].DispatchQuantityDiscountPricing) {
                    
                    if (this.hasCompletedDispatchesWithin7Days) {                        
                        i.dispatchCostAfterExtraDiscount = this.dispatchInstances.length === 1 ? this.secondDispatchPrice : this.additionalDispatchPrice;
                        dispatchFeeAfterExtraDiscount += i.dispatchCostAfterExtraDiscount;
                        i.dispatchCost = i.dispatchCostAfterExtraDiscount;
                    }
                    var newQuantityPricing = _.find(this.dispatchDiscountGroups[0].DispatchQuantityDiscountPricing, d => d.Quantity === this.dispatchInstances.length);
                    i.dispatchCostAfterDiscount = newQuantityPricing ? newQuantityPricing.Price : this.additionalDispatchPrice;
                    i.dispatchDiscountGroupID = this.dispatchDiscountGroups[0].DispatchDiscountGroupID;
                    dispatchFee += i.dispatchCostAfterDiscount;
                    i.dispatchCost = !this.hasCompletedDispatchesWithin7Days ? i.dispatchCostAfterDiscount : i.dispatchCostAfterExtraDiscount;
                }
                else {
                    var dispatchType = _.find(this.constants.DispatchTypes, dt => dt.dispatchTypeID === i.dispatchTypeID);

                    i.dispatchCost = dispatchType.dispatchCost;
                    dispatchFee += i.dispatchCost;
                }
            }
            if (this.userSettingProcessPayment && this.userSettingProcessPayment.Value.toUpperCase() === 'FALSE') {
                i.dispatchCost = 0.00;
            }
        });

        if (!this.userSettingProcessPayment || (this.userSettingProcessPayment && this.userSettingProcessPayment.Value.toUpperCase() !== 'FALSE')) {
            this.totalDispatchFee = dispatchFee;
            this.totalDispatchFeeAfterExtraDiscount = dispatchFeeAfterExtraDiscount;
        }
    }

    cancelDispatch(dispatchData) {
        var modalInstance = this.$uibModal.open({
            template: '<fp-cancel-dispatch-service-modal config="config"></fp-cancel-dispatch-service-modal>',
            size: 'md',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        targetDispatch: dispatchData
                    },
                    close: modalInstance.close
                };
            }
        });

        modalInstance.result.then(
            (result) => {
                if (result) {
                    this.isLoading = true;
                    this.dataContext.dispatch.cancelUserDispatch(dispatchData.DispatchID).then((result) => {
                        var updateDispatches = false;

                        if (result.DispatchUpdateSucceeded === true && result.DispatchRefundSucceeded === true) {
                            if (result.CreditRequestQueueID !== 0) {
                                this.showStatusMessage(this.ALERT_DIALOG,
                                    'success',
                                    (`Your dispatch has been cancelled. A refund in the amount of $${result.RefundAmount} has been issued`));
                                updateDispatches = true;
                            } else {
                                this.showStatusMessage(this.ALERT_DIALOG,
                                    'warning',
                                    ('Your dispatch has been cancelled. No refund has been issued for this cancellation event as no associated payment was found.'));
                                updateDispatches = true;
                            }
                        }

                        else if (result.DispatchUpdateSucceeded === true && result.DispatchRefundSucceeded === false) {
                            this.showStatusMessage(this.ALERT_DIALOG,
                                'warning',
                                "Your dispatch has been cancelled but an issue was encountered when generating the refund. Please contact support for additional assistance");
                            updateDispatches = true;
                        }
                        else if (result.DispatchUpdateSucceeded === false && result.DispatchRefundSucceeded === false) {
                            this.showStatusMessage(this.ALERT_DIALOG,
                                'danger',
                                "An issue was encountered when attempting to cancel your dispatch. Please try again, or contact support for additional assistance");
                        }

                        if (updateDispatches === true) {

                            var searchModel = {
                                PageSize: 5,
                                IsSortAsc: true
                            };

                            //update user dispatch history
                            this.$scope.$emit(this.constants.events.dispatch.retrieveUserDispatchHistory);

                            this.dataContext.dispatch.getUserUpcomingDispatches(searchModel).then((results) => {
                                this.upcomingDispatches = [results];
                                this.initializeDispatchTable();
                                this.stopEditing();
                                this.isLoading = false;
                            });
                        }
                    });
                }
            }
        );
    }

    modifyDispatch(dispatchData) {

        this.isModifying = true;
        this.stopEditing();

        this.dispatchToModify = [
            {
                asapDispatch: this.isDispatchASAP(dispatchData),
                backwardsStartTime: false,
                customerPaymentID: dispatchData.CustomerPaymentID,
                dispatchID: dispatchData.DispatchID,
                dispatchCost: dispatchData.RetailPrice,
                dispatchTypeID: dispatchData.DispatchProtocolID,
                dispatchTypeName: dispatchData.Type,
                gateCode: _.find(dispatchData.DispatchAttributeList, function (a) { return a.Name === "Gate Code" }) ? _.find(dispatchData.DispatchAttributeList, function (a) { return a.Name === "Gate Code" }).Value.split("Gate Code is ")[1] : null,
                hasDispatchConfigError: false,
                hasDispatchDateError: false,
                knockForResponse: _.find(dispatchData.DispatchAttributeList, function (a) { return a.Name === "Knock" }) ? true : false,
                mustSelectScheduleType: false,
                mustSelectDispatchDate: false,
                scheduledDispatch: !(this.isDispatchASAP(dispatchData)),
                scheduledDispatchDate: new Date(dispatchData.ScheduleDate),
                scheduledDispatchEndTime: (new Date(dispatchData.ScheduleEndDate).getHours() > 12 ?
                    (new Date(dispatchData.ScheduleEndDate).getHours() - 12).toString() + ":00" :
                    (
                        new Date(dispatchData.ScheduleEndDate).getHours() === 0 ?
                            "12:00" :
                            new Date(dispatchData.ScheduleEndDate).getHours().toString() + ":00"
                    )
                ),
                scheduledDispatchEndTimeMeridiem: (new Date(dispatchData.ScheduleEndDate).getHours() >= 12 ? "PM" : "AM"),
                scheduledDispatchStartTime: (new Date(dispatchData.ScheduleDate).getHours() > 12 ?
                        (new Date(dispatchData.ScheduleDate).getHours() - 12).toString() + ":00" :
                        (
                            new Date(dispatchData.ScheduleDate).getHours() === 0 ?
                                "12:00" :
                                new Date(dispatchData.ScheduleDate).getHours().toString() + ":00"
                        )
                ),
                scheduledDispatchStartTimeMeridiem: (new Date(dispatchData.ScheduleDate).getHours() >= 12 ? "PM" : "AM"),
                tooSmallWindow: false,
                tooSoonStart: false
            }];
    }

    shouldShowModify(targetDispatchID) {
        if (this.dispatchToModify != undefined && this.dispatchToModify[0] != undefined
            && targetDispatchID === this.dispatchToModify[0].dispatchID && this.isModifying) {
            return true;
        }

        return false;
    }

    stopModifying() {
        this.isModifying = false;
        this.dispatchToModify = [];
    }

    addDispatchInstance() {
        //the hashkey object is duplicating, and i think thats causing problems
        var baseInstance = this.dispatchInstances[0];

        this.dispatchInstances.push({
            dispatchTypeID: baseInstance.dispatchTypeID,
            dispatchTypeName: baseInstance.dispatchTypeName,
            gateCode: (typeof baseInstance.gateCode === undefined) ? null : baseInstance.gateCode,
            instanceHasBeenModified: false,
            scheduledDispatch: (typeof baseInstance.scheduledDispatch == undefined) ? false : baseInstance.scheduledDispatch,
            scheduledDispatchEndTime: (typeof baseInstance.scheduledDispatchEndTime === undefined) ? null : baseInstance.scheduledDispatchEndTime,
            scheduledDispatchEndTimeMeridiem: (typeof baseInstance.scheduledDispatchEndTimeMeridiem === undefined) ? null : baseInstance.scheduledDispatchEndTimeMeridiem,
            scheduledDispatchStartTime: (typeof baseInstance.scheduledDispatchStartTime === undefined) ? null : baseInstance.scheduledDispatchStartTime,
            scheduledDispatchStartTimeMeridiem: (typeof baseInstance.scheduledDispatchStartTimeMeridiem === undefined) ? null : baseInstance.scheduledDispatchStartTimeMeridiem,
            asapDispatch: (typeof baseInstance.asapDispatch === undefined) ? false : baseInstance.asapDispatch,
            knockForResponse: (typeof baseInstance.knockForResponse === undefined) ? false : baseInstance.knockForResponse,
            mustSelectDispatchDate: (baseInstance.scheduledDispatch ? true : false)
        });

        this.updateDispatchesCost();
    }

    removeDispatchInstance(index) {
        var hasBeenModified = this.dispatchInstances[index].instanceHasBeenModified;

        //make sure any repeat dispatches that have been modified are approved to be removed before hand
        if (hasBeenModified) {
            var modalInstance = this.$uibModal.open({
                template: '<fp-remove-dispatch-instance-modal config="config"></fp-remove-dispatch-instance-modal>',
                size: 'md',
                controller: ($scope) => {
                    'ngInject';
                    $scope.config = {
                        data: {
                            modal: this.$uibModal
                        },
                        close: modalInstance.close,
                        dismiss: modalInstance.dismiss
                    };
                }
            });

            modalInstance.result.then(
                (result) => {
                    if (result === true) {
                        this.dispatchInstances.splice(index, 1);
                        this.updateDispatchesCost();
                    }
                }
            );
        }
        else {
            this.dispatchInstances.splice(index, 1);
            this.updateDispatchesCost();
        }
    }

    setDispatchScheduleType(index, scheduleForLater) {

        if (this.isModifying) {
            if (scheduleForLater) {
                this.dispatchToModify[0].scheduledDispatch = true;
                this.dispatchToModify[0].asapDispatch = false;
                this.dispatchToModify[0].mustSelectDispatchDate = true;

                //we do this here instead of ng-init because when we repeat our events ng-init will override any copied values
                this.dispatchToModify[0].scheduledDispatchStartTime = '12:00';
                this.dispatchToModify[0].scheduledDispatchStartTimeMeridiem = "PM";
                this.dispatchToModify[0].scheduledDispatchEndTime = '4:00';
                this.dispatchToModify[0].scheduledDispatchEndTimeMeridiem = "PM";
            } else {
                this.dispatchToModify[0].scheduledDispatch = false;
                this.dispatchToModify[0].asapDispatch = true;
                this.dispatchToModify[0].mustSelectDispatchDate = false;
                this.dispatchToModify[0].hasDispatchDateError = false;
                this.dispatchToModify[0].scheduledDispatchDate = null;

                //reset any previously set date values
                this.dispatchToModify[0].scheduledDispatchStartTime = '12:00';
                this.dispatchToModify[0].scheduledDispatchStartTimeMeridiem = "PM";
                this.dispatchToModify[0].scheduledDispatchEndTime = '4:00';
                this.dispatchToModify[0].scheduledDispatchEndTimeMeridiem = "PM";
                this.dispatchToModify[0].scheduledDispatchDate = undefined;
            }

            //must select either ASAP or Schedule
            this.dispatchToModify[0].mustSelectScheduleType = false;
            this.modifyUpdateBypass = true;
        }

        else {
            if (scheduleForLater) {
                this.dispatchInstances[index].scheduledDispatch = true;
                this.dispatchInstances[index].asapDispatch = false;
                this.dispatchInstances[index].mustSelectDispatchDate = true;

                //we do this here instead of ng-init because when we repeat our events ng-init will override any copied values
                this.dispatchInstances[index].scheduledDispatchStartTime = '12:00';
                this.dispatchInstances[index].scheduledDispatchStartTimeMeridiem = "PM";
                this.dispatchInstances[index].scheduledDispatchEndTime = '4:00';
                this.dispatchInstances[index].scheduledDispatchEndTimeMeridiem = "PM";
            } else {
                if (this.dispatchInstances.length > 1) {
                    var modalInstance = this.$uibModal.open({
                        template: '<fp-change-dispatch-type-modal config="config"></fp-change-dispatch-type-modal>',
                        size: 'md',
                        controller: ($scope) => {
                            'ngInject';
                            $scope.config = {
                                data: {
                                    modal: this.$uibModal
                                },
                                close: modalInstance.close,
                                dismiss: modalInstance.dismiss
                            };
                        }
                    });

                    modalInstance.result.then(
                        (result) => {
                            if (result === true) {
                                this.dispatchInstances[index].scheduledDispatch = false;
                                this.dispatchInstances[index].asapDispatch = true;
                                this.dispatchInstances[index].mustSelectDispatchDate = false;
                                this.dispatchInstances[index].hasDispatchDateError = false;
                                this.dispatchInstances[index].scheduledDispatchDate = null;
                                this.dispatchInstances[index].tooSoonStart = false;

                                //reset any previously set date values
                                this.dispatchInstances[index].scheduledDispatchStartTime = '12:00';
                                this.dispatchInstances[index].scheduledDispatchStartTimeMeridiem = "PM";
                                this.dispatchInstances[index].scheduledDispatchEndTime = '4:00';
                                this.dispatchInstances[index].scheduledDispatchEndTimeMeridiem = "PM";
                                this.dispatchInstances[index].scheduledDispatchDate = undefined;

                                //ASAPs are not allowed to be repeated as per SOP
                                //We reset length of dispatch instances to 1
                                //currently no modal/warning, etc, perhaps in the future

                                this.dispatchInstances = this.dispatchInstances.splice(0, 1);
                            } else {
                                this.dispatchInstances[index].scheduledDispatch = true;
                                this.dispatchInstances[index].asapDispatch = false;
                            }
                        }
                    );
                } else {
                    this.dispatchInstances[index].scheduledDispatch = false;
                    this.dispatchInstances[index].asapDispatch = true;
                    this.dispatchInstances[index].mustSelectDispatchDate = false;
                    this.dispatchInstances[index].hasDispatchDateError = false;
                    this.dispatchInstances[index].scheduledDispatchDate = null;
                    this.dispatchInstances[index].tooSoonStart = false;

                    //reset any previously set date values
                    this.dispatchInstances[index].scheduledDispatchStartTime = '12:00';
                    this.dispatchInstances[index].scheduledDispatchStartTimeMeridiem = "PM";
                    this.dispatchInstances[index].scheduledDispatchEndTime = '4:00';
                    this.dispatchInstances[index].scheduledDispatchEndTimeMeridiem = "PM";
                    this.dispatchInstances[index].scheduledDispatchDate = undefined;

                    //ASAPs are not allowed to be repeated as per SOP
                    //We reset length of dispatch instances to 1
                    //currently no modal/warning, etc, perhaps in the future

                    this.dispatchInstances = this.dispatchInstances.splice(0, 1);
                }
            }

            //must select either ASAP or Schedule
            this.dispatchInstances[index].mustSelectScheduleType = false;
            this.dispatchInstances[index].instanceHasBeenModified = true;
            //only update on creation, not applicable to modify scenario
            this.updateDispatchesCost();
        }

        this.updateDispatchConfigErrorStatus();
    }

    maxScheduleDate() {
        // add 6 months to the current date
        var today = new Date();        
        var maxscheduledate = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
        var formatteddate = maxscheduledate.toLocaleDateString('en-ca');
        return formatteddate;
    }

    minScheduleDate() {
        var today = new Date();
        var formattedDate = today.toISOString().slice(0, 10);
        return formattedDate;
    }

    validateDispatchTime(dispatchInstance) {

        var now = this.getUserPremiseDateTime();
        this.timeValidationHelper = now.format('MM-DD-YYYY hh:mm A');

        var startTime = parseInt(dispatchInstance.scheduledDispatchStartTime.replace(/:00$/, ''));
        var endTime = parseInt(dispatchInstance.scheduledDispatchEndTime.replace(/:00$/, ''));
        if (isNaN(startTime) ||
            isNaN(endTime) ||
            dispatchInstance.scheduledDispatchStartTimeMeridiem === undefined ||
            dispatchInstance.scheduledDispatchEndTimeMeridiem === undefined) {
            return;
        }

        if (dispatchInstance.scheduledDispatchStartTimeMeridiem === 'PM' &&
            dispatchInstance.scheduledDispatchStartTime !== '12:00') {
            startTime = startTime + 12;
        }

        if (dispatchInstance.scheduledDispatchEndTimeMeridiem === 'PM' &&
            dispatchInstance.scheduledDispatchEndTime !== '12:00') {
            endTime = endTime + 12;
        }

        //is start time after end time?
        if (dispatchInstance.scheduledDispatchStartTimeMeridiem === dispatchInstance.scheduledDispatchEndTimeMeridiem) {
           if (dispatchInstance.scheduledDispatchStartTimeMeridiem === 'AM' &&
               dispatchInstance.scheduledDispatchEndTimeMeridiem === 'AM') {
                   if (startTime !== 12) {
                       if (endTime < startTime) {
                           dispatchInstance.backwardsStartTime = true;
                       } else {
                           dispatchInstance.backwardsStartTime = false;
                       }
                   }
           } else {
               if (startTime > endTime) {
                   dispatchInstance.backwardsStartTime = true;
               } else {
                   dispatchInstance.backwardsStartTime = false;
               }
           }
        } else {
            dispatchInstance.backwardsStartTime = false;
        }

        //is window less than 4 hours?
        if (endTime - startTime < 4) {

            //this checks for the am/pm pm/am scenarios
            if (dispatchInstance.scheduledDispatchStartTimeMeridiem !==
                dispatchInstance.scheduledDispatchEndTimeMeridiem) {

                //morning -> evening
                if (dispatchInstance.scheduledDispatchStartTimeMeridiem === "AM" &&
                    dispatchInstance.scheduledDispatchEndTimeMeridiem === "PM") {

                    //0 is 12AM -> 12PM selection which is valid
                    if (endTime - startTime < 4 && endTime - startTime !== 0) {
                        dispatchInstance.tooSmallWindow = true;
                    } 
                }

                //evening -> morning
                if (dispatchInstance.scheduledDispatchStartTimeMeridiem === "PM" &&
                    dispatchInstance.scheduledDispatchEndTimeMeridiem === "AM") {

                    //any noon -> AM time is more than 4 hours
                    if (startTime !== 12) {

                        var timeTo24 = 24 - startTime;

                        if (endTime === 12) {
                            //midnight end time
                            if (timeTo24 + 0 < 4) {
                                dispatchInstance.tooSmallWindow = true;
                            } else {
                                dispatchInstance.tooSmallWindow = false;
                            }
                        } else {
                            if (timeTo24 + endTime < 4) {
                                dispatchInstance.tooSmallWindow = true;
                            } else {
                                dispatchInstance.tooSmallWindow = false;
                            }
                        }
                        
                    } else {
                        dispatchInstance.tooSmallWindow = false;
                    }
                }
            } else {
                    if (dispatchInstance.scheduledDispatchEndTimeMeridiem === 'AM' &&
                        dispatchInstance.scheduledDispatchStartTimeMeridiem === 'AM') {
                        if (startTime === 12) {
                            if (endTime - 0 < 4 || endTime - 0 === 12) {
                                dispatchInstance.tooSmallWindow = true;
                            } else {
                                dispatchInstance.tooSmallWindow = false;
                            }
                        } else {
                            if (endTime - startTime < 4) {
                                dispatchInstance.tooSmallWindow = true;
                            } else {
                                dispatchInstance.tooSmallWindow = false;
                            }
                        }
                    } else {
                        dispatchInstance.tooSmallWindow = true;
                    }
                }
        } else {
            dispatchInstance.tooSmallWindow = false;
        }

        if (dispatchInstance.scheduledDispatchDate != undefined) {
            var dispatchMonth = dispatchInstance.scheduledDispatchDate.getMonth() + 1;
            var dispatchDay = dispatchInstance.scheduledDispatchDate.getDate();

            if ((dispatchMonth === (now.month() + 1)) && (dispatchDay === now.date())) {
                if (startTime - 1 <= now.hours()) {
                    dispatchInstance.tooSoonStart = true;
                } else {
                    dispatchInstance.tooSoonStart = false;
                }
            } else {
                dispatchInstance.tooSoonStart = false;
            }
        }
        else {
            dispatchInstance.tooSoonStart = false;
        }
        dispatchInstance.instanceHasBeenModified = true;

        this.updateDispatchConfigErrorStatus();
    }

    addToCart() {

        var instanceHasError = this.validateFormRequirements();

        if (instanceHasError === false) {
            this.updateDispatchConfigErrorStatus();

            var modalInstance = this.$uibModal.open({
                template: '<fp-book-dispatch-service-modal config="config"></fp-book-dispatch-service-modal>',
                size: 'lg',
                controller: ($scope) => {
                    'ngInject';
                    $scope.config = {
                        data: {
                            modal: this.$uibModal,
                            dispatchInstances: this.dispatchInstances
                        },
                        close: modalInstance.close,
                        dismiss: modalInstance.dismiss
                    };
                }
            });

            modalInstance.result.then(
                (result) => {
                    if (result != undefined) {
                        this.stopEditing();

                        if (result.success) {
                            this.isLoading = true;

                            var searchModel = {
                                PageSize: 5,
                                IsSortAsc: true
                            };

                            this.dataContext.dispatch.getUserUpcomingDispatches(searchModel).then((results) => {
                                this.upcomingDispatches = [results];
                                this.initializeDispatchTable();
                                this.stopEditing();
                                this.isLoading = false;

                                this.showStatusMessage(this.ALERT_DIALOG, 'success', 'Booking Complete! Update your notification preferences at the bottom of this page.');
                            });
                        }
                        //we check explicitly here as we only pass back success false on failed processing. simply closing the modal will not trigger this
                        if (result.success === false) {
                            this.showStatusMessage(this.ALERT_DIALOG, 'danger', 'An error occurred when attempting to book your dispatches. Please try again.');
                        }
                    }
                }
            );
        }

    }

    updateDispatch() {
        var modifiedDispatchHasError = this.validateFormRequirements();

        if (!modifiedDispatchHasError) {
            this.isLoading = true;

            this.dispatchToModify[0].scheduledDispatchDate = new Date(this.dispatchToModify[0].scheduledDispatchDate.toDateString());

            this.dataContext.dispatch.updateUserDispatch(this.dispatchToModify[0]).then((result) => {

                var searchModel = {
                    PageSize: 5,
                    IsSortAsc: true
                };

                //update user dispatch history
                this.$scope.$emit(this.constants.events.dispatch.retrieveUserDispatchHistory);

                this.dataContext.dispatch.getUserUpcomingDispatches(searchModel).then((results) => {
                    this.upcomingDispatches = [results];
                    this.dispatchRecordPageNumber = 1;
                    this.initializeDispatchTable();
                    this.dispatchToModify = [];
                    this.isModifying = false;
                    this.isLoading = false;
                });
            });
        }
    }

    validateFormRequirements() {
        var anyInstanceHasError = false;

        //consider requirements for dispatch being modified
        if (this.isModifying) {

            //make sure dispatch time selection made
            if (this.dispatchToModify[0].asapDispatch === false && this.dispatchToModify[0].scheduledDispatch === false) {
                this.dispatchToModify[0].mustSelectScheduleType = true;
                this.dispatchToModify[0].hasDispatchConfigError = true;
                anyInstanceHasError = true;
            }

            //make sure date selection made
            if (this.dispatchToModify[0].scheduledDispatchDate === null || this.dispatchToModify[0].scheduledDispatchDate === undefined) {
                this.dispatchToModify[0].hasDispatchDateError = true;
                this.dispatchToModify[0].hasDispatchConfigError = true;
                anyInstanceHasError = true;
            } else {
                this.dispatchToModify[0].mustSelectDispatchDate = false;
            }

            //make sure time range is correct
            if (this.dispatchToModify[0].backwardsStartTime ||
                this.dispatchToModify[0].tooSmallWindow ||
                this.dispatchToModify[0].tooSoonStart) {
                this.dispatchToModify[0].hasDispatchConfigError = true;
                anyInstanceHasError = true;
            }
        }

        //consider requirements for dispatch(es) being created
        else {

            this.dispatchInstances.forEach(function (instance) {
                //make sure dispatch time selection made
                if (instance.asapDispatch === false && instance.scheduledDispatch === false) {
                    instance.mustSelectScheduleType = true;
                    instance.hasDispatchConfigError = true;
                    anyInstanceHasError = true;
                }

                //make sure date selection made
                if ((instance.scheduledDispatchDate === null || instance.scheduledDispatchDate === undefined) && instance.asapDispatch === false) {
                    instance.hasDispatchDateError = true;
                    instance.hasDispatchConfigError = true;
                    anyInstanceHasError = true;
                } else {
                    instance.mustSelectDispatchDate = false;
                }

                //make sure time range is correct
                if (instance.backwardsStartTime ||
                    instance.tooSmallWindow ||
                    instance.tooSoonStart) {
                    instance.hasDispatchConfigError = true;
                    anyInstanceHasError = true;
                }
            });
        }

        this.updateDispatchConfigErrorStatus();
        return anyInstanceHasError;
    }

    updateDispatchConfigErrorStatus() {

        //consider error status for dispatch being modified
        if (this.isModifying) {
            if (!this.dispatchToModify[0].backwardsStartTime &&
                !this.dispatchToModify[0].hasDispatchDateError &&
                !this.dispatchToModify[0].mustSelectScheduleType &&
                !this.dispatchToModify[0].tooSmallWindow &&
                !this.dispatchToModify[0].tooSoonStart &&
                !this.dispatchToModify[0].mustSelectDispatchDate) {

                this.dispatchToModify[0].hasDispatchConfigError = false;
            } else {
                this.dispatchToModify[0].hasDispatchConfigError = true;
            }

        }

        //consider error status for dispatch(es) being created
        else {
            this.dispatchInstances.forEach(function (instance) {
                if (!instance.backwardsStartTime &&
                    !instance.hasDispatchDateError &&
                    !instance.mustSelectScheduleType &&
                    !instance.tooSmallWindow &&
                    !instance.tooSoonStart &&
                    !instance.mustSelectDispatchDate) {

                    instance.hasDispatchConfigError = false;
                } else {
                    instance.hasDispatchConfigError = true;
                }
            });
        }

    }

    initializeDispatchTable() {
        this.dispatchRecordTableSlice = this.upcomingDispatches;
        if (this.dispatchRecordTableSlice[0].TotalPages > 1) {
            this.showNextPage = true;
            this.showPreviousPage = true;
            this.TotalPages = this.dispatchRecordTableSlice[0].TotalPages;
        }
    }

    getNextPage() {
        var pageNumber = this.dispatchRecordPageNumber + 1;

        var searchModel = {
            PageNumber: pageNumber,
            PageSize: 5,
            IsSortAsc: true
        };

        this.isLoading = true;
        this.dataContext.dispatch.getUserUpcomingDispatches(searchModel).then((result => {
            this.dispatchRecordTableSlice = [result];
            this.dispatchRecordPageNumber = pageNumber;
            this.isLoading = false;
        }));
    }

    getPreviousPage() {
        var pageNumber = this.dispatchRecordPageNumber - 1;

        var searchModel = {
            PageNumber: pageNumber,
            PageSize: 5,
            IsSortAsc: true
        };

        this.isLoading = true;
        this.dataContext.dispatch.getUserUpcomingDispatches(searchModel).then((result => {
            this.dispatchRecordTableSlice = [result];
            this.dispatchRecordPageNumber = pageNumber;
            this.isLoading = false;
        }));
    }

    isDispatchASAP(dispatchData) {
        var now = new Date();
        var nowFormatted = now.toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' });

        var scheduledDate = new Date(dispatchData.ScheduleDate);
        var scheduleDateFormatted = scheduledDate.toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' });

        var dateCreated = new Date(dispatchData.DateCreated);
        var dateCreatedFormatted = dateCreated.toLocaleDateString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' });

        var scheduleDateMatchesDateCreated = (scheduleDateFormatted === dateCreatedFormatted);
        var nowMatchesScheduleDate = (nowFormatted === scheduleDateFormatted);

        if (scheduleDateMatchesDateCreated && nowMatchesScheduleDate) {
            return true;
        }

        return false;
    }

    openDispatchProtocolHelperModal() {
        var modalInstance = this.$uibModal.open({
            template: '<fp-dispatch-protocol-helper-modal config="config"></fp-dispatch-protocol-helper-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        modal: this.$uibModal
                    },
                    close: modalInstance.close
                };
            }
        });
    }

    openDispatchTimeHelperModal() {
        var modalInstance = this.$uibModal.open({
            template: '<fp-dispatch-time-helper-modal config="config"></fp-dispatch-time-helper-modal>',
            size: 'lg',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        modal: this.$uibModal
                    },
                    close: modalInstance.close
                };
            }
        });
    }

    dispatchIsModifiable(dispatchData) {
        var now = new Date();
        var scheduleDate = new Date(dispatchData.ScheduleDate);

        if (now < scheduleDate) {
            return true;
        }

        return false;
    }

    dateSelectionMade(dispatchInstance) {

        if (dispatchInstance.scheduledDispatchDate !== null && dispatchInstance.scheduledDispatchDate !== undefined) {
            dispatchInstance.mustSelectDispatchDate = false;
            dispatchInstance.hasDispatchDateError = false;
        }

        this.validateDispatchTime(dispatchInstance);
    }

    getTimezoneAbbreviation(timeZone) {
        return this.dataContext.dispatch.getUserTimeZoneAbbreviation(timeZone);
    }

    showStatusMessage(dialog, type, message) {
        this.alertService
            .get(dialog)
            .setMessage(message)
            .setType(type)
            .setTimeout(this.constants.dispatchAlertDuration)
            .open();
    }

    isOvernightDispatch(dispatchData) {
        var scheduleDate = dispatchData.ScheduleDate.split('T')[0];
        var scheduleEndDate = dispatchData.ScheduleEndDate.split('T')[0];

        if (scheduleDate === scheduleEndDate) {
            return false;
        }

        return true;
    }

    showGuardDetails(dispatchId) {
        var modalInstance = this.$uibModal.open({
            template: '<fp-guard-details-modal config="config"></fp-guard-details-modal>',
            size: 'md',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        modal: this.$uibModal,
                        dispatchId: dispatchId
                    },
                    close: modalInstance.close
                };
            }
        });
    }

    shouldShowGuardDetails(dispatchData) {
        var dispatchEventTypeTaken = this.constants.DispatchEventType.TAKEN;
        if (dispatchData.DispatchEventList.length > 0) {
            var searchResult = _.find(dispatchData.DispatchEventList,
                function(e) {
                    return e.DispatchEventTypeID === dispatchEventTypeTaken;
                });

            if (searchResult != null) {
                return true;
            }
        }

        return false;
    }

    openCancellationHelperModal() {
        var modalInstance = this.$uibModal.open({
            template: '<fp-cancellation-helper-modal config="config"></fp-cancellation-helper-modal>',
            size: 'md',
            controller: ($scope) => {
                'ngInject';
                $scope.config = {
                    data: {
                        modal: this.$uibModal
                    },
                    close: modalInstance.close
                };
            }
        });
    }

    getUserPremiseDateTime() {
        var convertedTimezone = this.dataContext.dispatch.getUserIANATimeZone(this.user.current.ServicePlanInfo.TimeZoneID);
        var momentConverted = this.moment.tz(convertedTimezone);

        return momentConverted;
    }

    getUserDispatchCompleteHistory() {
        var searchModel = {
            PageNumber: 1,
            PageSize: 100,
            IsCompleted: true
        };
        this.dataContext.dispatch.searchDispatch(searchModel).then((result => {
            if (result) {
                this.dispatchCompleteHistory = result.Data;
            }
        }));
    }

}

export default {
    template: template,
    bindings: {
        upcomingDispatches: '<',
        isLoading: '<',
        dispatchDiscountGroups: '<'
    },
    controller: UpcomingDispatchesController
};