import DataContext from '../common/data/dataContext.service';
import './cancellation.component.scss';

export class OverviewController {
    dataContext: DataContext;

    constructor(dataContext: DataContext) {
        'ngInject';

        this.dataContext = dataContext;
    }
}

export const overviewComponent = {
    template: require('./overview.component.html'),
    controller: OverviewController
};