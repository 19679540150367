import angular from 'angular';

import commonModule from '../../../common';

import documentsContentModalComponent from './contentModal.component';

export default angular
    .module('fp:customerportal:account:documents:components',
    [
        commonModule.name
    ])
    .component('fpDocumentsContentModal', documentsContentModalComponent);