class SettingsDataService {
    /*@ngInject*/
    constructor(Restangular) {
        this.Restangular = Restangular;
    }

    getSettings() {
        return this.Restangular
            .all('settings')
            .customGET('');
    }

    save(settings) {
        return this.Restangular
            .all('settings')
            .customPUT(settings);
    }

    insert(settings) {
        return this.Restangular
            .all('settings')
            .customPOST(settings);
    }
    updateUserSettings(settings) {
        return this.Restangular
            .all('settings')
            .one('UserSettings')
            .customPUT(settings);
    }
}

export default SettingsDataService;