import './passwordRequirements.scss';
import template from './passwordRequirements.html';
import LengthPasswordValidator from './validators/LengthPasswordValidator';
import RegexPasswordValidator from './validators/RegexPasswordValidator';

class PasswordRequirementsController {
    /*@ngInject*/
    constructor() {
        this.PASSWORD_MIN_LENGTH = 10;
        this.PASSWORD_MAX_LENGTH = 100;

        this.PASSWORD_VALIDATOR_LETTER = new RegexPasswordValidator(/(?=.*[a-z]).+/);
        this.PASSWORD_VALIDATOR_NUMBER = new RegexPasswordValidator(/[0-9]/);
        this.PASSWORD_VALIDATOR_SYMBOL = new RegexPasswordValidator(/[!"#$%&'()*+,\-.\/:;<=>?@\[\]\\^_{|}~]/);
        this.PASSWORD_VALIDATOR_LENGTH = new LengthPasswordValidator(this.PASSWORD_MIN_LENGTH, this.PASSWORD_MAX_LENGTH);
    }

    valuesMatch(password, confirm) {
        if (password !== undefined && confirm !== undefined && password === confirm) {
            return true;
        }
        return false;
    }
}

export default {
    template: template,
    bindings: {
        password: '<',
        confirm: '<'
    },
    controller: PasswordRequirementsController
};