import angular from 'angular';
import 'angular-ui-grid';
import 'angular-ui-bootstrap';

import commonModule from '../../../common';

import bookDispatchServiceModal from './bookDispatchServiceModal.component';
import cancelDispatchServiceModal from './cancelDispatchServiceModal.component';
import dispatchProtocolHelperModal from './dispatchProtocolHelperModal.component';
import removeDispatchInstanceModal from './removeDispatchInstanceModal.component';
import changeDispatchTypeModal from './changeDispatchTypeModal.component';
import guardDetailsModal from './guardDetailsModal.component';
import dispatchTimeHelperModal from './dispatchTimeHelperModal.component';
import cancellationHelperModal from './cancellationHelperModal.component';


export default angular.module('fp:customerportal:myaccount:dispatchServices:modals',
        [
            'ui.bootstrap',
            'ui.grid',
            'ui.grid.pagination',
            commonModule.name
        ])
    .component('fpBookDispatchServiceModal', bookDispatchServiceModal)
    .component('fpDispatchProtocolHelperModal', dispatchProtocolHelperModal)
    .component('fpCancelDispatchServiceModal', cancelDispatchServiceModal)
    .component('fpRemoveDispatchInstanceModal', removeDispatchInstanceModal)
    .component('fpChangeDispatchTypeModal', changeDispatchTypeModal)
    .component('fpGuardDetailsModal', guardDetailsModal)
    .component('fpDispatchTimeHelperModal', dispatchTimeHelperModal)
    .component('fpCancellationHelperModal', cancellationHelperModal);
    