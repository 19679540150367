class EmergencyDataService {
    /*@ngInject*/
    constructor(Restangular, constants, $rootScope, applicationStateService, $q) {
        this.$rootScope = $rootScope;
        this.Restangular = Restangular;
        this.constants = constants;
        this.applicationStateService = applicationStateService;
        this.$q = $q;
    }
    getMonitoringAdcInfo() {
        return this.Restangular
            .all('Account')
            .one('MonitoringAdcInfo')
            .get()
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }

    getCustomerInfo() {
        return this.Restangular
            .all('Emergency')
            .one('CustomerInfo')
            .get()
            .then((result) => {
                return result;
            })
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }

    getCustomerTimeZone() {
        return this.Restangular
            .all('Emergency')
            .one('CustomerInfo/TimeZone')
            .get()
            .catch((error) => {
                this.applicationStateService.error(this.constants.sectionTypes.emergencySettings, error);
                return this.$q.reject(error);
            });
    }

    broadcast(event, data) {
        return this.$rootScope.$broadcast(event, data);
    }
}

export default EmergencyDataService;