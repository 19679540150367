import '@babel/polyfill';
import './vendor';
import 'angular-recaptcha';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/v4-shims.scss';
import 'angular-loading-bar/build/loading-bar.css';
import 'angular-ui-grid/ui-grid.css';
import '../content/styles/app.scss';

import * as angular from 'angular';
import * as _ from 'lodash';

import adcModule from './adc';
import commonModule from './common';
import { loginModule } from './login';
import movingModule from './moving';
import { myaccountModule } from './myaccount';
import { cancellationModule } from './cancellation';
import shellModule from './shell';
import shopModule from './shop';
import { trackOrderModule } from './trackOrder';
import { radioUpgradeModule } from './radioUpgrade';

declare const Modernizr: any;

const deps = [
    'ui.router',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'ngTouch',
    'ngMessages',
    'angular-storage',
    'angularMoment',
    'rzModule', // slider
    'angular-loading-bar',
    'duScroll',
    'vcRecaptcha',
    myaccountModule.name,
    commonModule.name,
    shellModule.name,
    loginModule.name,
    trackOrderModule.name,
    adcModule.name,
    shopModule.name,
    movingModule.name,
    cancellationModule.name,
    radioUpgradeModule.name
];

const app = angular
    .module('fp:customerportal', deps)

    .constant('Modernizr', Modernizr) // global
    .config(/*@ngInject*/($urlRouterProvider: ng.ui.IUrlRouterProvider) => {
        $urlRouterProvider.otherwise('/home');
    })

    .config(/*@ngInject*/ ($locationProvider: ng.ILocationProvider) => {
        $locationProvider.html5Mode(true);
    })
    
    .config(/*@ngInject*/ ($provide: ng.auto.IProvideService, $uibModalProvider: any, constants: any) => {
        $uibModalProvider.options.backdrop = 'static'; // don't allow clicking outside the modal to dismiss it

        $provide.decorator('$uibModal', /*@ngInject*/ ($delegate: any, $rootScope: ng.IRootScopeService) => {
            const open = $delegate.open;
            $delegate.open = function() {
                const r = open.apply(this, arguments);
                r.opened.then(() => {
                    $rootScope.$broadcast(constants.events.modalOpened);
                });
                r.closed.then(() => {
                    $rootScope.$broadcast(constants.events.modalClosed);
                });
                return r;
            };
            return $delegate;
        });
    })

    .config(/*@ngInject*/ ($uibTooltipProvider: any) => {
        $uibTooltipProvider.options({
            popupDelay: 500
        });
    })

    .config(/*@ngInject*/ ($httpProvider: ng.IHttpProvider) => {
        $httpProvider.useApplyAsync(true);

        // http://stackoverflow.com/questions/16098430/angular-ie-caching-issue-for-http
        // initialize get if not there
        if (_.isNil($httpProvider.defaults.headers.get)) {
            $httpProvider.defaults.headers.get = {};
        }

        // disable IE ajax request caching
        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        // extra
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get.Pragma = 'no-cache';
    })

    .config(/*@ngInject*/ ($compileProvider: ng.ICompileProvider) => {
        if (!window.location.host.match(/localhost/)) {
            $compileProvider.debugInfoEnabled(false);
        }
    })
    
    .config(/*@ngInject*/ (cfpLoadingBarProvider: any) => {
        cfpLoadingBarProvider.includeSpinner = false;
    })

    .run(/*@ngInject*/ ($rootScope: ng.IRootScopeService) => {
        ($rootScope as any).isTouchScreen = Modernizr.touch;
    })

    // Add support for custom external parameter on UI-router states, automatically redirecting when present
    .run(/*@ngInject*/($rootScope: ng.IRootScopeService, $window: ng.IWindowService,
                       $document: ng.IDocumentService, $state: ng.ui.IStateService, $timeout: ng.ITimeoutService,
                       $uibModalStack: any, scrollContainerAPI: any, constants: any,
                       dataContext: any, $q: ng.IQService) => {
        $rootScope.$on(constants.events.stateChangeStart,
            (event, toState, toParams, fromState, fromParams, options) => {

            // remove smartbanner if it exists
            if (toState.name !== 'login.home' && toState.name !== 'adc.home') {
                const banner = $document[0].getElementsByClassName('smartbanner');
                const htmlParent = $document[0].getElementsByTagName('html')[0];
                if (banner.length !== 0) {
                    banner[0].parentElement.removeChild(banner[0]);
                    htmlParent.classList.remove('smartbanner-show');
                }
            }

            $uibModalStack.dismissAll();
            const external = toState.external;
            const redirect = (url: any) => {
                if (_.isString(url)) {
                    event.preventDefault();

                    let path = toState.url === '*path'
                        ? _.replace(url, '{path}', toParams.path)
                        : url;

                    const keys = _.keys(toParams);
                    if (!_.isEmpty(keys)) {
                        let temp = path;
                        _.forEach(keys, (key) => {
                            temp = _.replace(temp, `{${key}}`, toParams[key]);
                        });
                        path = temp;
                    }

                    const replace = options.location === 'replace';
                    if (replace) {
                        $window.location.replace(path);
                    } else {
                        $window.open(path, _.isString(external.target) ? external.target : '_blank');
                    }
                }
            };
            if (!_.isNil(external)) {
                if (_.isFunction(external.url)) {
                    external.url(toParams, dataContext, $q).then((url: string) => {
                        redirect(url);
                    });
                } else {
                    redirect(external.url);
                }
            }
        });

        $rootScope.$on(constants.events.viewContentLoaded, (event) => {
            const scope = event.targetScope;
            const currentState = $state.current;
            const scrollTo: string = String(_.get(currentState, 'data.scrollTo'));
            if (_.isString(scrollTo)) {
                $timeout(() => {
                    const element = document.getElementById(scrollTo);
                    if (!_.isNil(element)) {
                        const container = scrollContainerAPI.getContainer(scope);
                        container.scrollToElement(angular.element(element));
                    }
                });
            }
        });
    })

    .run(/*@ngInject*/ ($rootScope: IAppRootScope, $timeout: ng.ITimeoutService, $log: ng.ILogService, constants: any) => {
        $rootScope.isAppReady = false;
        let unsub = $rootScope.$on(constants.events.stateChangeSuccess,
            (event, toState, toParams, fromState, fromParams) => {
            if (_.isNil(toState.data) || !toState.data.showSplash) {
                $timeout(() => {
                    $rootScope.isAppReady = true;

                    if (_.isFunction(unsub)) {
                        unsub();
                        unsub = null;
                    } else {
                        $log.info('Failed to unsubscribe one-time stateChangeSuccess');
                    }
                }, 0);
            }
        });
    })

    .run(/*@ngInject*/ ($rootScope: ng.IRootScopeService, $timeout: ng.ITimeoutService,
                        $document: ng.IDocumentService, constants: any) => {
        $rootScope.$on(constants.events.modalOpened, () => {
            $timeout(() => {
                const target = $document[0].querySelector('.modal');
                if (!_.isNil(target)) {
                    ($document as any).scrollToElement(target, 0);
                }
            }, 1);
        });
    });


    angular.bootstrap(document, ['fp:customerportal']);
    
export default app;
